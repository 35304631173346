import { Box, BoxProps, Tooltip } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { memo } from 'react';

import { ReactComponent as DotIcon } from './navIcons/ic_dot.svg';

type Props = {
  isFilterSelected: boolean;
};

function FilterButton({ isFilterSelected, ...other }: BoxProps & Props) {
  const theme = useTheme();
  return (
    <Box {...other}>
      <Tooltip title="Filter" placement={'top'}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="24"
            height="24"
            rx="3.5"
            fill={alpha(theme.palette.grey[900], 0.3)}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.25 8.25C5.25 8.6625 5.5875 9 6 9H18C18.4125 9 18.75 8.6625 18.75 8.25C18.75 7.8375 18.4125 7.5 18 7.5H6C5.5875 7.5 5.25 7.8375 5.25 8.25ZM11.25 16.5H12.75C13.1625 16.5 13.5 16.1625 13.5 15.75C13.5 15.3375 13.1625 15 12.75 15H11.25C10.8375 15 10.5 15.3375 10.5 15.75C10.5 16.1625 10.8375 16.5 11.25 16.5ZM15.75 12.75H8.25C7.8375 12.75 7.5 12.4125 7.5 12C7.5 11.5875 7.8375 11.25 8.25 11.25H15.75C16.1625 11.25 16.5 11.5875 16.5 12C16.5 12.4125 16.1625 12.75 15.75 12.75Z"
            fill="#919EAB"
          />
          <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#454F5B" />
        </svg>
      </Tooltip>
      {isFilterSelected && (
        <DotIcon
          style={{
            position: 'absolute',
            right: '-5px',
            top: '-11px',
            color: theme.palette.primary.main,
          }}
        />
      )}
    </Box>
  );
}

export default memo(FilterButton);
