import { Drawer, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import SalesforceOpportunityStages from '../../../@types/api/salesforceOpportunityStages';
import Deal from '../../../@types/core/deal';
import { ReactComponent as CloseNavIcon } from '../../../assets/icons/navIcons/closeNavIcon.svg';
import { useAuthContext } from '../../../auth/useAuthContext';
import { IDataNavSection, NavSectionVertical } from '../../../components/nav-section';
import { LOCAL_STORAGE, NAV, SESSION_STORAGE } from '../../../config';
import { useActiveSectionContext } from '../../../hooks/useActiveNavSection';
import useResponsive from '../../../hooks/useResponsive';
import NavHeader from './NavHeader';
import NavSectionItems from './NavSectionItems';
import Searchbar from './Searchbar';
import UserBadge from './UserBadge';
import AccountsNavSection from './accounts/AccountsNavSection';
import NotesNavSection from './notes/NotesNavSection';
import TemplatesNavSection from './templates/TemplatesNavSection';
import { useFilters } from './useFilters';
import { useFiltersGroup } from './useFiltersGroup';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: IDataNavSection[];
  deals: Deal[];
  favoriteDeals: IDataNavSection[];
  stages: SalesforceOpportunityStages[];
};

export enum NavSection {
  MEETINGS = 'Meetings',
  DEALS = 'Deals',
  ACCOUNTS = 'Accounts',
  MY_NOTES = 'My Notes',
  TEMPLATES = 'Templates',
  NOTES = 'Notes',
}

export default function NavVertical({ open, setOpen, data, deals, favoriteDeals, stages }: Props) {
  const theme = useTheme();
  const { user } = useAuthContext();
  const location = useLocation();

  const { activeSection, setActiveSection } = useActiveSectionContext();

  const { filteredData, handleCheckbox, handleSelectAll, localDataFilters } = useFilters({
    data,
    stages,
    user,
    filter: LOCAL_STORAGE.DEALS_FILTERS,
  });

  const { groupedItems, handleOnClickGroupItem, localStorageGroup } = useFiltersGroup({
    filteredDeals: filteredData as IDataNavSection[],
  });

  const isDesktop = useResponsive('up', 1118);

  const handleOpenDrawer = () => setOpen((prev) => !prev);

  useEffect(() => {
    setActiveSection(sessionStorage.getItem(SESSION_STORAGE.SELECTED_SECTION) || null);
  }, [location.pathname, setActiveSection]);

  const hideContent = activeSection === NavSection.TEMPLATES;

  const renderContent = (
    <>
      <CloseNavIcon
        onClick={handleOpenDrawer}
        style={{
          position: 'absolute',
          top: '76px',
          right: '-12px',
          zIndex: '9900',
          color: theme.palette.common.white,
          cursor: 'pointer',
          transform: !open ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      />
      <NavHeader />
      {!hideContent && <Searchbar />}
      <NavSectionItems activeSection={activeSection} setActiveSection={setActiveSection} />

      {activeSection === NavSection.DEALS && (
        <NavSectionVertical
          deals={deals}
          favoriteDeals={favoriteDeals}
          items={data}
          handleCheckbox={handleCheckbox}
          handleSelectAll={handleSelectAll}
          handleOnClickGroupItem={handleOnClickGroupItem}
          localDataFilters={localDataFilters}
          localStorageGroup={localStorageGroup}
          data={groupedItems}
          stages={stages}
        />
      )}
      {activeSection === NavSection.MY_NOTES && <NotesNavSection />}
      {activeSection === NavSection.ACCOUNTS && (
        <AccountsNavSection
          deals={deals}
          filteredDeals={filteredData as IDataNavSection[]}
          items={data}
          handleCheckbox={handleCheckbox}
          handleSelectAll={handleSelectAll}
          handleOnClickGroupItem={handleOnClickGroupItem}
          localDataFilters={localDataFilters}
          localStorageGroup={localStorageGroup}
          stages={stages}
        />
      )}
      {activeSection === NavSection.TEMPLATES && <TemplatesNavSection />}
      <UserBadge
        user={user}
        sx={{ position: activeSection === NavSection.DEALS ? 'static' : 'fixed' }}
      />
    </>
  );
  return (
    <>
      {isDesktop ? (
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          anchor={'left'}
          disableScrollLock
          disableEnforceFocus
          hideBackdrop
          keepMounted={true}
          disablePortal
          tabIndex={0}
          aria-modal={true}
          sx={{
            position: 'inherit',
            flexShrink: { lg: 0 },
            width: open ? NAV.W_DASHBOARD : '20px',
          }}
          PaperProps={{
            sx: {
              tabIndex: 0,
              overflowY: 'visible',
              width: NAV.W_DASHBOARD,
              borderRight: `1px solid ${alpha(theme.palette.grey[700], 0.4)}`,
              visibility: open ? 'visible' : 'visible !important',
              left: open ? '0' : '20px',
              transform: open ? 'none' : 'translateX(-280px) !important',
            },
            style: {
              backgroundColor: alpha(theme.palette.grey[900], 0.93),
              backdropFilter: `blur(10px)`,
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          anchor={'left'}
          disableScrollLock
          disableEnforceFocus
          disablePortal={true}
          keepMounted={true}
          aria-modal={true}
          tabIndex={0}
          sx={{
            visibility: !open ? 'visible' : 'visible',
            '.MuiModal-backdrop': {
              backgroundColor: alpha(theme.palette.common.black, 0.7),
            },
            position: 'inherit',
            width: open ? NAV.W_DASHBOARD : '20px',
          }}
          PaperProps={{
            sx: {
              overflowY: 'visible',
              width: NAV.W_DASHBOARD,
              borderRight: `1px solid ${alpha(theme.palette.grey[700], 0.4)}`,
              visibility: open ? 'visible' : 'visible !important',
              left: open ? '0' : '20px',
              transform: open ? 'none' : 'translateX(-280px) !important',
            },
            style: {
              backgroundColor: alpha(theme.palette.grey[900], 0.93),
              backdropFilter: `blur(10px)`,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}
