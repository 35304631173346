import { ListItem } from '@tiptap/extension-list-item';

export const ContextListItem = ListItem.extend({
  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem(this.name),
      Tab: () => {
        this.editor.commands.sinkListItem(this.name);
        return true;
      },
      'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
    };
  },
});
