import { Box } from '@mui/material';
import { Node, mergeAttributes } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { addRowAfter, deleteRow } from 'prosemirror-tables';
import { Decoration, DecorationSet } from 'prosemirror-view';
import React from 'react';
import { createRoot } from 'react-dom/client';

// import { ReactComponent as AddIcon } from '../../../../assets/icons/tiptapIcons/addIcon.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/tiptapIcons/closeIcon.svg';
import { Tooltip } from './TableTooltip';
import {
  getCellsInColumn,
  isRowSelected,
  isTableSelected,
  selectRow,
  selectTable,
} from './TipTapTableUtils';

// import i18n from '../../../i18n';

export interface TableCellOptions {
  HTMLAttributes: Record<string, any>;
}

export const TableCell = Node.create<TableCellOptions, { clearCallbacks: Array<() => void> }>({
  name: 'tableCell',
  content: 'block+',
  tableRole: 'cell',
  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      colspan: {
        default: 1,
        parseHTML: (element) => {
          const colspan = element.getAttribute('colspan');
          const value = colspan ? parseInt(colspan, 10) : 1;
          return value;
        },
      },
      rowspan: {
        default: 1,
        parseHTML: (element) => {
          const rowspan = element.getAttribute('rowspan');
          const value = rowspan ? parseInt(rowspan, 10) : 1;
          return value;
        },
      },
      colwidth: {
        default: null,
        parseHTML: (element) => {
          const colwidth = element.getAttribute('colwidth');
          const value = colwidth ? [parseInt(colwidth, 10)] : null;

          return value;
        },
      },
      style: {
        default: null,
      },
      class: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'td' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['td', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addStorage() {
    return {
      clearCallbacks: [],
    };
  },

  onDestroy() {
    this.storage.clearCallbacks.forEach((cb) => cb());
    this.storage.clearCallbacks.length = 0;
  },

  // @ts-ignore
  addProseMirrorPlugins() {
    const { isEditable } = this.editor;

    return [
      new Plugin({
        key: new PluginKey('table-cell-control'),
        props: {
          decorations: (state) => {
            if (!isEditable) {
              return DecorationSet.empty;
            }
            const { doc, selection } = state;
            const decorations: Decoration[] = [];
            const cells = getCellsInColumn(0)(selection);
            if (cells) {
              this.storage.clearCallbacks.forEach((cb) => cb());
              this.storage.clearCallbacks.length = 0;
              cells.forEach(({ pos }, index) => {
                if (index === 0) {
                  decorations.push(
                    Decoration.widget(pos + 1, () => {
                      let className = 'grip-table';
                      const selected = isTableSelected(selection);
                      if (selected) {
                        className += ' selected';
                      }
                      const grip = document.createElement('a');
                      const root = createRoot(grip);

                      root.render(
                        <Box className="deleteTable">
                          <Tooltip editor={this.editor} title={'Delete table'} placement={'top'}>
                            <CloseIcon />
                          </Tooltip>
                        </Box>
                      );

                      grip.className = className;
                      grip.addEventListener('mousedown', (event) => {
                        event.preventDefault();
                        event.stopImmediatePropagation();

                        if (selected && !(event.target === grip)) {
                          this.editor.chain().focus().deleteTable().run();
                        }

                        if (!selected && event.target === grip) {
                          this.editor.view.dispatch(
                            // @ts-ignore
                            selectTable(this.editor.state.tr)
                          );
                        }
                      });
                      return grip;
                    })
                  );
                }
                decorations.push(
                  Decoration.widget(pos + 1, () => {
                    const rowSelected = isRowSelected(index)(selection);
                    let className = 'grip-row';
                    if (rowSelected) {
                      className += ' selected';
                    }
                    if (index === 0) {
                      className += ' first';
                    }
                    if (index === cells.length - 1) {
                      className += ' last';
                    }
                    const grip = document.createElement('a');

                    const root = createRoot(grip);

                    const handleClearRoot = () => {
                      this.storage.clearCallbacks.push(() => {
                        root.unmount();
                      });
                    };

                    root.render(
                      rowSelected ? (
                        <span className="delete">
                          <Box className="deleteBox">
                            <Tooltip editor={this.editor} title={'Delete row'} placement={'top'}>
                              <CloseIcon />
                            </Tooltip>
                          </Box>
                        </span>
                      ) : (
                        <></>
                        // <span className="add">
                        //   <Box className="addBox">
                        //     <Tooltip
                        //       editor={this.editor}
                        //       title={'Insert row below'}
                        //       placement={'top'}
                        //     >
                        //       <AddIcon />
                        //     </Tooltip>
                        //   </Box>
                        //   <div className="rowMarker" />
                        // </span>
                      )
                    );

                    grip.className = className;
                    grip.addEventListener(
                      'mousedown',
                      (event: any) => {
                        event.preventDefault();
                        event.stopImmediatePropagation();

                        if (event.target === grip) {
                          this.editor.view.dispatch(
                            // @ts-ignore
                            selectRow(index)(this.editor.state.tr)
                          );
                        }

                        if (
                          event.target !== grip &&
                          rowSelected &&
                          !(event.target?.nodeName === 'SPAN')
                        ) {
                          deleteRow(this.editor.state, this.editor.view.dispatch);
                          handleClearRoot();
                        }
                        if (
                          event.target !== grip &&
                          !rowSelected &&
                          !(event.target?.nodeName === 'SPAN')
                        ) {
                          addRowAfter(this.editor.state, this.editor.view.dispatch);
                          handleClearRoot();
                        }
                      },
                      true
                    );

                    return grip;
                  })
                );
              });
            }
            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});
