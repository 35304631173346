import { Backdrop, Box } from '@mui/material';
import { type NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { BASE_URL } from 'src/config';

const MIN_WIDTH = 60;
const MAX_WIDTH = 620;
const MAX_HEIGHT = 500;

const useEvent = <T extends (...args: any[]) => any>(handler: T): T => {
  const handlerRef = useRef<T | null>(null);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  return useCallback((...args: Parameters<T>): ReturnType<T> => {
    if (handlerRef.current === null) {
      throw new Error('Handler is not assigned');
    }
    return handlerRef.current(...args);
  }, []) as T;
};

const ContextImageComponent = ({ node, updateAttributes }: NodeViewProps) => {
  const [zoomOpen, setZoomOpen] = React.useState(false);
  const [zoomScale, setZoomScale] = useState(1);

  useEffect(() => {
    if (node.attrs.src.includes('https://context-app-prod.s3.us-east-1.amazonaws.com')) {
      const imageSrc = node.attrs.src.replace(
        'https://context-app-prod.s3.us-east-1.amazonaws.com',
        `${BASE_URL}/images`
      );
      setTimeout(() => updateAttributes({ src: `${imageSrc}` }), 0);
    }
  });

  const calculateZoomScale = useCallback(() => {
    const padding = 80;
    const maxWindowHeight = window.innerHeight - padding;
    const maxWindowWidth = window.innerWidth - padding;

    const maxHorizontalScale = maxWindowWidth / node.attrs.width;
    const maxVerticalScale = maxWindowHeight / node.attrs.height;

    return Math.min(maxHorizontalScale, maxVerticalScale);
  }, [node.attrs]);

  useEffect(() => {
    function handleResize() {
      if (zoomOpen) {
        setZoomScale(calculateZoomScale());
      }
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculateZoomScale, zoomOpen]);

  const handleZoomIn = () => {
    setZoomScale(calculateZoomScale());
    setZoomOpen(true);
  };

  const handleClose = () => {
    setZoomOpen(false);
  };

  const imgAttr = (({ originalWidth, originalHeight, ...obj }) => obj)(node.attrs);

  const nodeWidth = useMemo(
    () => node.attrs.width,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const nodeHeight = useMemo(
    () => node.attrs.height,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const aspectRatio = nodeWidth / nodeHeight;

  useEffect(() => {
    if (nodeWidth > MAX_WIDTH) {
      const scale = node.attrs.width / MAX_WIDTH;
      setTimeout(() => updateAttributes({ width: MAX_WIDTH, height: nodeHeight / scale, scale }));
      return;
    }

    if (nodeHeight > MAX_HEIGHT) {
      const scale = node.attrs.height / MAX_HEIGHT;
      setTimeout(() => updateAttributes({ height: MAX_HEIGHT, width: nodeWidth / scale, scale }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [editing, setEditing] = useState(false);
  const [resizingStyle, setResizingStyle] = useState<
    Pick<CSSProperties, 'width' | 'height'> | undefined
  >();

  // Lots of work to handle "not" div click events.
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setEditing(false);
      }
    };
    // Add click event listener and remove on cleanup
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [editing]);

  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (zoomOpen) {
        e.preventDefault();
      }

      if (e.key === 'Escape') {
        setZoomOpen(false);
      }
    },
    [zoomOpen]
  );

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);

    return () => window.removeEventListener('keydown', keyDownHandler);
  }, [keyDownHandler, zoomOpen]);

  const handleMouseDown = useEvent((event: React.MouseEvent<HTMLDivElement>) => {
    if (!imgRef.current) return;
    event.preventDefault();
    const direction = event.currentTarget.dataset.direction || '--';
    const initialXPosition = event.clientX;

    const currentHeight = imgRef.current.height;
    const currentWidth = imgRef.current.width;

    let newHeight = currentHeight;
    let newWidth = currentWidth;

    const transform = direction[1] === 'w' ? -1 : 1;

    const removeListeners = () => {
      window.removeEventListener('mousemove', mouseMoveHandler);
      window.removeEventListener('mouseup', removeListeners);
      updateAttributes({ width: newWidth, height: newHeight });
      setResizingStyle(undefined);
      event.stopPropagation();
    };

    const mouseMoveHandler = (event: MouseEvent) => {
      newWidth = Math.max(currentWidth + transform * (event.clientX - initialXPosition), MIN_WIDTH);
      newHeight = newWidth / aspectRatio;

      const maxHeight = MAX_WIDTH / aspectRatio;
      const minHeight = nodeHeight < 150 / aspectRatio ? nodeHeight : 150 / aspectRatio;
      const minWidth = nodeWidth < 150 ? nodeWidth : 150;

      if (newHeight > maxHeight) {
        newHeight = maxHeight;
      }

      if (newWidth > MAX_WIDTH) {
        newWidth = MAX_WIDTH;
      }
      if (newHeight < minHeight) {
        newHeight = minHeight;
      }

      if (newWidth < minWidth) {
        newWidth = minWidth;
      }

      setResizingStyle({ width: newWidth, height: newHeight });
      // If mouse is up, remove event listeners
      if (!event.buttons) removeListeners();
    };

    window.addEventListener('mousemove', mouseMoveHandler);
    window.addEventListener('mouseup', removeListeners);
  });

  const dragButton = (direction: string) => (
    <div
      style={{
        padding: '5px',
        position: 'absolute',
        top: 'calc(50% - 15px)',
        right: direction === 'ew' ? 'auto' : '0',
        cursor: `col-resize`,
      }}
    >
      <div
        role="button"
        tabIndex={0}
        onMouseDown={handleMouseDown}
        data-direction={direction}
        style={{
          height: '30px',
          width: '7px',
          backgroundColor: 'rgb(0,0,0, 0.3)',
          border: '1px solid white',

          borderRadius: '5px',
        }}
      />
    </div>
  );
  return (
    <>
      <NodeViewWrapper
        ref={containerRef}
        as="div"
        draggable
        data-drag-handle
        onMouseOver={() => setEditing(true)}
        onMouseOut={() => setEditing(false)}
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            position: 'relative',
            display: 'inline-block',
            // Weird! Basically tiptap/prose wraps this in a span and the line height causes an annoying buffer.
            lineHeight: '0px',
          }}
        >
          <img
            {...imgAttr}
            ref={imgRef}
            style={{
              ...resizingStyle,
              cursor: 'zoom-in',
              borderRadius: '8px',
            }}
            alt="img"
            onClick={handleZoomIn}
          />
          {editing && (
            <>
              {dragButton('e')}
              {dragButton('ew')}
            </>
          )}
        </div>
      </NodeViewWrapper>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 3000 }}
        open={zoomOpen}
        onClick={handleClose}
        autoFocus
      >
        <Box
          component={'img'}
          src={node.attrs.src}
          sx={{
            width: node.attrs.width,
            height: node.attrs.height,
            cursor: 'zoom-out',
            // transition: 'transform 0.5s ease-in-out',
            transform: `scale(${zoomOpen ? zoomScale : 1})`,
          }}
          onClick={handleZoomIn}
          alt={node.attrs.src}
        />
      </Backdrop>
    </>
  );
};

export default ContextImageComponent;
