import { Box, Stack, Typography } from '@mui/material';
import { SxProps, alpha, useTheme } from '@mui/material/styles';

import { AuthUserType } from '../../../auth/types';
import UserMenuPopover from '../header/UserMenuPopover';

const UserBadge = ({ user, sx }: { sx: SxProps; user: AuthUserType }) => {
  const theme = useTheme();
  return (
    <>
      {user?.email && (
        <Stack
          direction="row"
          alignItems="center"
          bottom={0}
          height="72px"
          width="279px"
          borderTop={`1px solid ${alpha(theme.palette.grey[700], 0.4)}`}
          bgcolor={alpha(theme.palette.grey[900], 0.5)}
          spacing={1.5}
          paddingY={2}
          paddingX={3}
          sx={{ backdropFilter: 'blur(10px)', ...sx }}
        >
          <Box height={'40px'}>
            <UserMenuPopover />
          </Box>
          <Stack>
            <Box>
              <Typography variant="subtitle2" color={theme.palette.common.white}>
                {user.name}
              </Typography>
            </Box>
            <Box>
              <Typography fontSize={12} lineHeight={1} color={theme.palette.text.disabled}>
                {user.email}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default UserBadge;
