import { Menu, popoverClasses } from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import AttendeeCard from '../../../sections/core/deal/attendees/AttendeeCard';
import People from 'src/@types/api/people';
import Users from 'src/@types/api/users';

const PersonCard = forwardRef(
  (
    {
      atMentions,
      editable,
    }: {
      atMentions: (People | Users)[];
      editable: boolean;
    },
    ref: any
  ) => {
    const [openAttendeeCard, setOpenAttendeeCard] = useState(null);
    const [hoveredMeetingAttendee, setHoveredMeetingAttendee] = useState<People | Users>();

    const refForCard = useRef<any>(null);

    const handleHover = () => {
      if (refForCard.current) {
        clearTimeout(refForCard.current);
      }
    };

    const handleClose = () => {
      setOpenAttendeeCard(null);
    };

    const handleCloseHover = useCallback(() => {
      clearTimeout(refForCard.current);
      refForCard.current = setTimeout(() => {
        handleClose();
      }, 50);
    }, []);

    const handleMouseOverListener = useCallback(
      (e: any) => {
        if (e.buttons === 0 && e.target.dataset.id) {
          handleHover();
          // @ts-ignore
          setOpenAttendeeCard(e.target);
          setHoveredMeetingAttendee(
            atMentions?.find((person) => person.id === e.target.dataset.id)
          );
        }
      },
      [atMentions]
    );

    const handleMouseLeaveListener = useCallback(() => {
      handleCloseHover();
    }, [handleCloseHover]);

    useEffect(() => {
      if (!ref?.current) return;
      const mentionItems = ref?.current.querySelectorAll('[data-id]');
      ref?.current.addEventListener('mouseover', handleMouseOverListener);
      ref?.current.addEventListener('mouseout', handleMouseLeaveListener);
      mentionItems.forEach((item: any) => {
        // item.addEventListener('mouseover', handleMouseOverListener);
        // item.addEventListener('mouseout', handleMouseLeaveListener);
        window.document.addEventListener('scroll', () => {
          handleCloseHover();
        });
      });

      return () => {
        mentionItems.forEach((item: any) => {
          // item.removeEventListener('mouseover', handleMouseOverListener);
          // item.removeEventListener('mouseout', handleMouseLeaveListener);
          window.document.removeEventListener('scroll', () => {
            handleCloseHover();
          });
        });
      };
    }, [
      handleCloseHover,
      handleMouseLeaveListener,
      handleMouseOverListener,
      ref,
      ref?.current?.innerText,
    ]);

    return (
      <>
        {hoveredMeetingAttendee && (
          <>
            <Menu
              anchorEl={openAttendeeCard}
              open={Boolean(openAttendeeCard)}
              anchorOrigin={{ vertical: -275, horizontal: 100 }}
              transformOrigin={{ vertical: -200, horizontal: 100 }}
              disableScrollLock
              onClose={handleClose}
              MenuListProps={{
                onMouseEnter: handleHover,
                onMouseLeave: handleCloseHover,
                style: { pointerEvents: 'auto', padding: 0 },
              }}
              sx={{
                marginTop: 1,

                [`&.${popoverClasses.root}`]: {
                  pointerEvents: 'none',
                },
              }}
              PaperProps={{
                sx: {
                  backgroundColor: 'inherit',
                },
              }}
              TransitionProps={{
                timeout: 0,
              }}
            >
              <AttendeeCard meetingAttendee={hoveredMeetingAttendee} editable={editable} />
            </Menu>
          </>
        )}
      </>
    );
  }
);
export default PersonCard;
