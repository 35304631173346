import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { NotesSource } from '../../../../@types/api/notes';
import { useAuthContext } from '../../../../auth/useAuthContext';
import CreateNote from '../../../../components/create-note/CreateNote';
import Scrollbar from '../../../../components/scrollbar';
import { EMPTY_NOTE_TITLE_PARAGRAPH_BINARY } from '../../../../components/tip-tap-editor/TipTapEditorUtils';
import { NOTES_PATH } from '../../../../config';
import { useCreateNote } from '../../../../hooks/useQueries';
import { getNoteTree } from './DashboardNavNotesUtils';
import NotesNavSectionItem from './NotesNavSectionItem';
import { useNotesContext } from 'src/hooks/useNotesContext';

const NotesNavSection = () => {
  const theme = useTheme();

  const { notes, refresh, isLoading, isError } = useNotesContext();
  useEffect(() => {
    refresh(NotesSource.User);
  }, [refresh]);

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuthContext();
  const createNote = useCreateNote(NotesSource.User, user?.id);
  const navigate = useNavigate();

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading notes.</div>;

  const handleCreateNote = () => {
    createNote
      .mutateAsync({ valueBinary: EMPTY_NOTE_TITLE_PARAGRAPH_BINARY })
      .then(async (res) => {
        await refresh(NotesSource.User);
        navigate(`/${NOTES_PATH}/${res.id}`);
        enqueueSnackbar('Note created!', { variant: 'success' });
      })
      .catch(() => enqueueSnackbar('Error creating note!', { variant: 'error' }));
  };

  const noteTree = getNoteTree(notes);
  return (
    <Stack sx={{ height: '100%', paddingBottom: '420px' }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: '8px 36px',
          cursor: 'pointer',
          position: 'sticky',
          top: 0,
        }}
      >
        <Typography
          variant="overline"
          color={theme.palette.grey[500]}
          sx={{ textTransform: 'uppercase' }}
        >
          My Notes
        </Typography>
        <CreateNote title={'Create Note'} handleClick={handleCreateNote} />
      </Stack>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            position: 'relative',
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Stack sx={{ padding: '0 24px' }}>
          {noteTree.map((note) => (
            <NotesNavSectionItem key={note.id} noteTree={noteTree} thisNote={note} />
          ))}
        </Stack>
      </Scrollbar>
    </Stack>
  );
};

export default React.memo(NotesNavSection);
