import { Button, Stack } from '@mui/material';
import React from 'react';

type SaveAndCancelProps = {
  handleSave: () => void;
  handleClose: () => void;
  isDisabled?: boolean;
};

const SaveAndCancel = ({ handleSave, handleClose, isDisabled }: SaveAndCancelProps) => (
  <Stack spacing={1.5} sx={{ p: 3 }} display="flex" direction="row" justifyContent="flex-end">
    <Button variant="outlined" color={'inherit'} size="medium" onClick={handleClose}>
      Cancel
    </Button>
    <Button
      variant="contained"
      disabled={isDisabled}
      color={'primary'}
      size="medium"
      onClick={handleSave}
    >
      Save
    </Button>
  </Stack>
);

export default SaveAndCancel;
