import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  alpha,
  useTheme,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { forwardRef, useCallback, useMemo, useState } from 'react';

import { SalesforceInputComponentProps } from './SalesforceInputComponent';

const MultiPickComponent = forwardRef(
  ({ node, disabled, handleChangeValue, initialValue }: SalesforceInputComponentProps, ref) => {
    const { picklistValues } = node.attrs;
    const theme = useTheme();

    const [value, setValue] = useState<string[]>(() => initialValue?.split(';') || []);

    const handleChange = useCallback(
      (event: SelectChangeEvent<typeof value>) => {
        const { value: targetValue } = event.target;
        const newValue = Array.isArray(targetValue) ? targetValue : targetValue.split(',');
        setValue(newValue);
        handleChangeValue(newValue.join(';'), node.attrs.salesforceFieldName, true);
      },
      [handleChangeValue, node.attrs.salesforceFieldName]
    );

    const outlinedInputStyles = useMemo(
      () => ({
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.grey[300]}!important`,
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.grey[300]}!important`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.grey[300]}!important`,
        },
      }),
      [theme.palette.grey]
    );

    const chipStyles = useMemo(
      () => ({
        borderRadius: '8px',
        height: '24px',
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
        color: theme.palette.primary.dark,
        fontWeight: '500',
        '& .MuiChip-label': {
          padding: '3px 8px',
        },
      }),
      [theme.palette.primary]
    );

    const menuItemStyles = useMemo(
      () => ({
        color: theme.palette.common.black,
        borderRadius: 1,
        paddingLeft: '0',
        '&.Mui-selected': {
          backgroundColor: 'transparent',
        },
      }),
      [theme.palette.common.black]
    );

    return (
      <FormControl size="small" sx={{ marginTop: 1 }}>
        <Select
          multiple
          value={value}
          onChange={handleChange}
          disabled={disabled}
          input={<OutlinedInput fullWidth inputRef={ref} sx={outlinedInputStyles} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
              {selected.map((val) => (
                <Chip key={val} label={val} sx={chipStyles} />
              ))}
            </Box>
          )}
          MenuProps={{
            PaperProps: {
              sx: {
                padding: '0 8px',
                maxHeight: '268px',
                backgroundColor: theme.palette.common.white,
                marginTop: '8px',
              },
            },
          }}
        >
          {picklistValues.map((el) => (
            <MenuItem key={el.value} value={el.value} sx={menuItemStyles}>
              <Checkbox checked={value.indexOf(el.value) > -1} />
              <ListItemText primary={el.value} sx={{ marginLeft: '8px' }} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);

export default MultiPickComponent;
