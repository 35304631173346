import { Editor } from '@tiptap/core';
import React from 'react';

import { ReactComponent as StrikeIcon } from '../../../assets/icons/tiptapIcons/strikethrough.svg';
import Button from './Button';

const StrikeButton = ({ editor }: { editor: Editor }) => {
  const handleToggle = (editor: Editor) => {
    editor.chain().focus().toggleStrike().run();
  };

  return (
    <Button
      editor={editor}
      shortcut={'mod-Shift-X'}
      icon={<StrikeIcon />}
      tooltip={'Strikethrough'}
      isActiveName={'strike'}
      clickCommand={handleToggle}
    />
  );
};

export default StrikeButton;
