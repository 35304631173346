import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';

// import backgroundImage from '../../assets/backgroundImage.jpg';
import { useAuthContext } from '../../auth/useAuthContext';
import { LOCAL_STORAGE } from '../../config';
import ActiveSectionContext, { useActiveSectionState } from '../../hooks/useActiveNavSection';
import SavingContext, { useSavingContextState } from '../../hooks/useSavingContext';
import Main from './Main';
import NavVertical from './nav/NavVertical';
import { getNavConfig } from './nav/nav-config';
import LoadingScreen from 'src/components/loading-screen/LoadingScreen';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { NoteTemplatesProvider } from 'src/hooks/useNoteTemplatesContext';
import { NotesProvider } from 'src/hooks/useNotesContext';
import { useGetDealStages, useGetDealsList } from 'src/hooks/useQueries';
import useResponsive from 'src/hooks/useResponsive';

export default function DashboardLayout() {
  const { isSaving, setIsSaving } = useSavingContextState();
  const { activeSection, setActiveSection } = useActiveSectionState();
  const { user } = useAuthContext();
  const [open, setOpen] = useLocalStorage(LOCAL_STORAGE.LEFT_MENU, true);

  // TODO: Move deal stages to context provider
  const { data: stages, isLoading: isLoadingStages, isError: isErrorStages } = useGetDealStages();
  // TODO: Move deals list to context provider
  const { data: deals, isLoading: isLoadingDeals, isError: isErrorDeals } = useGetDealsList();

  const isDesktop = useResponsive('up', 1118);
  const openOnDesktop = useRef(false);

  useEffect(() => {
    if (!isDesktop) {
      openOnDesktop.current = open;
      setOpen(false);
      return;
    }
    if (openOnDesktop.current) return setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  // TODO: None of this should be loading on page load, only the section you're on should load
  if (isLoadingDeals || isLoadingStages) {
    return <LoadingScreen />;
  }

  if (isErrorDeals || isErrorStages) {
    console.error('Error fetching data!');
  }

  if (stages?.length === 0) {
    console.error('Stages are empty!');
  }

  const favoriteDeals = deals?.data?.filter(
    (deal) => deal?.dealUsers?.find((dealUser) => dealUser.userId === user?.id)?.pinnedAt
  );

  // const isDealPage = deal_id && !company_id && !note_id;

  const navConfig = getNavConfig(deals?.data ?? [], user);

  const renderNavVertical = (
    <NavVertical
      open={open}
      setOpen={setOpen}
      data={navConfig}
      deals={deals?.data ?? []}
      favoriteDeals={getNavConfig(favoriteDeals ?? [], user)}
      stages={stages ?? []}
    />
  );

  return (
    <SavingContext.Provider value={{ isSaving, setIsSaving }}>
      <ActiveSectionContext.Provider value={{ activeSection, setActiveSection }}>
        <NotesProvider>
          <NoteTemplatesProvider>
            <Box sx={{ height: '100%' }}>
              {/*<Box*/}
              {/*  sx={{*/}
              {/*    // backgroundImage: `url(${backgroundImage})`,*/}
              {/*    backgroundRepeat: 'no-repeat',*/}
              {/*    backgroundAttachment: 'fixed',*/}
              {/*    backgroundSize: 'cover',*/}
              {/*    position: 'fixed',*/}
              {/*    height: '100%',*/}
              {/*    width: '100vw',*/}
              {/*  }}*/}
              {/*/>*/}
              <Box
                sx={{
                  display: 'flex',
                  minHeight: 'fit-content',
                  height: '100%',
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                {renderNavVertical}

                <Main open={open}>
                  <Outlet />
                </Main>
              </Box>
            </Box>
          </NoteTemplatesProvider>
        </NotesProvider>
      </ActiveSectionContext.Provider>
    </SavingContext.Provider>
  );
}
