import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import TodayIcon from '@mui/icons-material/Today';
import { InputAdornment } from '@mui/material';
import { Slice } from 'prosemirror-model';

import { SalesforceField } from '../../../@types/api/types/configTypes';
import { ReactComponent as AddressIcon } from '../../../assets/icons/navIcons/business.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/tiptapIcons/link-m.svg';
import { apiQuery } from '../../../utils/apiQuery';
import { ISlashNodeInterface } from '../extensions/ContextSalesforceExtension';
import { SalesforceFieldTypes } from './types';
import { BASE_URL } from 'src/config';

type DropImageProps = {
  view: any;
  event: DragEvent;
  slice: Slice;
  moved: boolean;
  noteId: string;
};

export const getSalesforceIcon = ({
  node,
  isDisableEditing = false,
}: {
  node: ISlashNodeInterface;
  isDisableEditing?: boolean;
}) => {
  if (node.attrs.type === SalesforceFieldTypes.CURRENCY) {
    return (
      <InputAdornment
        position="start"
        contentEditable={!isDisableEditing}
        suppressContentEditableWarning={true}
      >
        <AttachMoneyIcon />
      </InputAdornment>
    );
  }

  if (node.attrs.type === SalesforceFieldTypes.ADDRESS) {
    return (
      <InputAdornment
        position="start"
        contentEditable={!isDisableEditing}
        suppressContentEditableWarning={true}
      >
        <AddressIcon />
      </InputAdornment>
    );
  }

  if (
    node.attrs.type === SalesforceFieldTypes.DATE ||
    node.attrs.type === SalesforceFieldTypes.DATETIME
  ) {
    return (
      <InputAdornment
        position="start"
        contentEditable={!isDisableEditing}
        suppressContentEditableWarning={true}
      >
        <TodayIcon />
      </InputAdornment>
    );
  }

  if (node.attrs.type === SalesforceFieldTypes.PERCENT) {
    return (
      <InputAdornment
        position="start"
        contentEditable={!isDisableEditing}
        suppressContentEditableWarning={true}
      >
        <PercentIcon />
      </InputAdornment>
    );
  }

  if (node.attrs.type === SalesforceFieldTypes.URL) {
    return (
      <InputAdornment
        position="start"
        contentEditable={!isDisableEditing}
        suppressContentEditableWarning={true}
      >
        <LinkIcon />
      </InputAdornment>
    );
  }

  // if (node.attrs.type === SlashFieldType.T) {
  //   return <LinkIcon />;
  // }

  return <></>;
};

export const isCompoundParent = ({
  item,
  fields,
}: {
  item: SalesforceField;
  fields: SalesforceField[];
}) => {
  const { compoundFieldName, name } = item;
  let isCompound = false;
  if (compoundFieldName === null) {
    const existField = fields
      .filter((field) => field.id !== item.id)
      .find((field) => field.compoundFieldName === name);
    if (existField?.id) {
      isCompound = true;
    }
  }
  return isCompound;
};

const uploadImage = (file: File, noteId: string) => {
  const formData = new FormData();
  formData.append('file', file);

  // TODO: make additional API query method
  return apiQuery<{ href: string }>(
    `/notes/${noteId}/upload`,
    {
      method: 'POST',
      body: formData,
    },
    null
  ).catch((error) => console.error('Error Uploading Image!', error));
};

export function handleDropImage({ view, event, moved, noteId }: DropImageProps) {
  if (!moved && event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) {
    let file = event.dataTransfer.files[0];

    let filesize = +(file.size / 1024 / 1024).toFixed(4); // get the filesize in MB
    if ((file.type === 'image/jpeg' || file.type === 'image/png') && filesize < 10) {
      let _URL = window.URL || window.webkitURL;
      let img = new Image();
      img.src = _URL.createObjectURL(file);

      const MAX_HEIGHT = 500;
      const MAX_WIDTH = 720;

      img.onload = function () {
        const originalHeight = img.height;
        const originalWidth = img.width;

        let newWidth = originalWidth;
        let newHeight = originalHeight;
        let scaleRatio = 1;

        if (originalHeight > MAX_HEIGHT) {
          scaleRatio = originalHeight / MAX_HEIGHT;
          newHeight /= scaleRatio;
          newWidth /= scaleRatio;
        }

        if (newWidth > MAX_WIDTH) {
          scaleRatio = newWidth / MAX_WIDTH;
          newHeight /= scaleRatio;
          newWidth /= scaleRatio;
        }

        // preview image
        const { schema } = view.state;
        const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
        const node = schema.nodes.image.create({
          src: _URL.createObjectURL(file),
          width: newWidth,
          height: newHeight,
          originalWidth,
          originalHeight,
        });
        const transaction = view.state.tr.insert(coordinates.pos, node);
        const savedPos = coordinates.pos;
        view.dispatch(transaction);

        uploadImage(file, noteId)
          .then((response) => {
            // change blob src to real S3 url in node

            const { editor } = view.dom;
            editor.commands.command(({ tr }: { tr: any }) => {
              tr.doc.descendants((n: any, position: any) => {
                if (n.attrs.src === node.attrs.src) {
                  tr.setNodeMarkup(position, undefined, {
                    ...n.attrs,
                    src: `${BASE_URL}/images/${response?.href}`,
                  });
                }
              });

              return true;
            });
          })
          .catch((error) => {
            if (error) {
              const deletedTr = view.state.tr.delete(savedPos, coordinates.pos + 2);
              view.dispatch(deletedTr);
              alert(
                'There was a problem uploading your image, please refresh the page and try again.'
              );
            }
          });
      };
    } else {
      window.alert('Images need to be in jpg or png format and less than 10Mb in size.');
    }
    return true;
  }
  return false;
}
