import { Editor } from '@tiptap/core';
import React from 'react';

import { ReactComponent as BulletListIcon } from '../../../assets/icons/tiptapIcons/list-bulleted.svg';
import Button from './Button';

const BulletListButton = ({ editor }: { editor: Editor }) => {
  const handleToggle = (editor: Editor) => {
    editor.chain().focus().toggleBulletList().run();
  };

  return (
    <Button
      editor={editor}
      shortcut={'mod-Shift-8'}
      icon={<BulletListIcon />}
      tooltip={'Bullet List'}
      isActiveName={'bulletList'}
      clickCommand={handleToggle}
    />
  );
};

export default BulletListButton;
