import React, { forwardRef } from 'react';

import { ISlashNodeInterface } from '../../extensions/ContextSalesforceExtension';
import { SalesforceFieldTypes } from '../types';
import DatePickerComponent from './DatePickerComponent';
import DateTimePickerComponent from './DateTimePickerComponent';
import DropDownComponent from './DropDownComponent';
import MultiPickComponent from './MultiPickComponent';
import NumberInputComponent from './NumberInputComponent';
import PhoneInputComponent from './PhoneInputComponent';
import SwitchComponent from './SwitchComponent';
import TextInputComponent from './TextInputComponent';
import UrlInputComponent from './UrlInputComponent';

export type SalesforceInputComponentProps = {
  node: ISlashNodeInterface;
  disabled: boolean;
  handleChangeValue: (
    newValue: any,
    salesforceFieldName: string,
    saveImmediately?: boolean
  ) => void;
  initialValue: any;
};

type Props = SalesforceInputComponentProps & {
  nodeType: SalesforceFieldTypes;
};

// Map nodeType to corresponding component
const ComponentMap: Record<
  SalesforceFieldTypes,
  React.ComponentType<SalesforceInputComponentProps>
> = {
  [SalesforceFieldTypes.BOOLEAN]: SwitchComponent,
  [SalesforceFieldTypes.DATE]: DatePickerComponent,
  [SalesforceFieldTypes.DATETIME]: DateTimePickerComponent,
  [SalesforceFieldTypes.PICKLIST]: DropDownComponent,
  [SalesforceFieldTypes.MULTIPICKLIST]: MultiPickComponent,
  [SalesforceFieldTypes.URL]: UrlInputComponent,
  [SalesforceFieldTypes.PHONE]: PhoneInputComponent,
  [SalesforceFieldTypes.INT]: NumberInputComponent,
  [SalesforceFieldTypes.DOUBLE]: NumberInputComponent,
  [SalesforceFieldTypes.CURRENCY]: NumberInputComponent,
  [SalesforceFieldTypes.PERCENT]: NumberInputComponent,
  [SalesforceFieldTypes.TEXTAREA]: TextInputComponent,
  [SalesforceFieldTypes.ID]: TextInputComponent,
  [SalesforceFieldTypes.STRING]: TextInputComponent,
  [SalesforceFieldTypes.ADDRESS]: TextInputComponent,
  [SalesforceFieldTypes.REFERENCE]: TextInputComponent,
};

const SalesforceInputComponent = forwardRef<HTMLDivElement, Props>(
  ({ nodeType, node, disabled, initialValue, handleChangeValue }, ref) => {
    const Component = ComponentMap[nodeType];

    const key = `${node.attrs.salesforceKey}${node.attrs.salesforceFieldName}`;

    return (
      <Component
        key={key}
        node={node}
        disabled={disabled}
        initialValue={initialValue}
        handleChangeValue={handleChangeValue}
      />
    );
  }
);

export default SalesforceInputComponent;
