import { Link, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useDebounce } from 'usehooks-ts';

import { ReactComponent as DotIcon } from '../../../../assets/icons/tiptapIcons/slashmenu/dotIcon.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/tiptapIcons/slashmenu/warningIcon.svg';
import { BASE_URL, LOCAL_STORAGE } from '../../../../config';
import { useDealContext } from '../../../../hooks/useDealContext';
import SpinnerIcon from '../../../spinner-icon/SpinnerIcon';
import { ISlashNodeInterface } from '../../extensions/ContextSalesforceExtension';
import SalesforceInputComponent from '../InputComponents/SalesforceInputComponent';
import { SalesforceFieldTypes } from '../types';
import { useEditAndSaveSalesforce } from '../useEditAndSaveSalesforce';
import NodeViewComponent from './NodeViewComponent';
import SalesforceComponentHeader, { IFieldsForType } from './SalesforceComponentHeader';
import { useSalesorceComponent, withMyContext } from './withSalesforce';

export interface ISalesforceComponentProps {
  node: ISlashNodeInterface;
  updateAttributes: (attributes: Record<string, any>) => void;
  editor: any;
  decorations: any;
  getPos: () => number;
}

const SalesforceComponent = ({
  node,
  editor,
  updateAttributes,
  getPos,
}: ISalesforceComponentProps) => {
  const theme = useTheme();
  const { template_id, deal_id, note_id, account_id } = useParams();

  const { deal } = useDealContext();
  const { salesforceKey } = useSalesorceComponent();
  const ref = useRef<any>(null);
  const isInitialLoad = useRef(true);

  const { salesforceFieldName, salesforceType } = node.attrs;
  const nodeType = node.attrs.type;
  const isIdField = salesforceFieldName === 'Id';

  const data = deal && Object.keys(deal).length !== 0 ? deal?.getSalesforceDataStructure() : null;
  const fieldsForType = data?.find((field) => field.type === salesforceType);

  const isValueExist = isIdField
    ? !!fieldsForType?.fields.hasOwnProperty('salesforceKey')
    : !!fieldsForType?.fields.hasOwnProperty(salesforceFieldName);

  // don't remove Boolean, its give warnings!!!
  const isDisableEditing = template_id
    ? true
    : nodeType === SalesforceFieldTypes.ID ||
      nodeType === SalesforceFieldTypes.REFERENCE ||
      Boolean(node.attrs.calculatedFormula) ||
      Boolean(node.attrs.isCompoundParent) ||
      !node.attrs.updateable ||
      !editor.isEditable ||
      !salesforceKey;

  const isDisabled = !salesforceKey && (!deal_id || !account_id);

  const {
    handleLoadValueFromSalesforce,
    handleEditSalesforce,
    SFValue,
    isLoading,
    isError,
    isSaving,
    isTokenError,
  } = useEditAndSaveSalesforce({
    node,
    updateAttributes,
    salesforceKey: salesforceKey,
    dealId: deal_id,
    isDisabled: isDisabled,
  });

  const initialValue =
    !isValueExist && SFValue[salesforceFieldName]
      ? SFValue[salesforceFieldName]
      : isIdField
        ? fieldsForType?.fields.salesforceKey
        : fieldsForType?.fields[salesforceFieldName];

  const [inputValue, setValue] = useState(initialValue ?? '');
  const debouncedValue = useDebounce(inputValue, 3000);

  useEffect(() => {
    const updateData = async () => {
      if (salesforceKey) {
        const res: any = await handleLoadValueFromSalesforce();
        if (!res) return;
        const attributes = JSON.parse(JSON.stringify(res ?? ''), (key, value) => {
          if (value == null) return '';
          return value;
        });
        const newObj = { ...(res?.meta ?? {}), ...res };
        const inputValue = newObj[salesforceFieldName] ?? '';
        try {
          const pos = getPos();
          setTimeout(() => pos && updateAttributes({ ...attributes, value: inputValue }));
        } catch (e) {
          console.error(e);
        }
        setValue(inputValue);
      }
    };

    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesforceKey]);

  // get the change and always set the value, if immediate save is true then save the value now
  const handleChangeValue = (
    value: any,
    salesforceFieldName: string,
    saveImmediately?: boolean
  ) => {
    setValue(value);
    if (saveImmediately) {
      handleEditSalesforce(value, salesforceFieldName);
    }
  };

  useEffect(() => {
    if (debouncedValue && !isInitialLoad.current) {
      handleEditSalesforce(debouncedValue, salesforceFieldName);
    }
    // eslint-disable-next-line
  }, [debouncedValue]);

  // Run this effect only on initial load to avoid using debouncedValue on load
  useEffect(() => {
    isInitialLoad.current = false;
  }, []);

  useEffect(() => {
    if (!isValueExist) {
      handleLoadValueFromSalesforce();
    }
    // eslint-disable-next-line
  }, [isValueExist]);

  const handleSalesforceUpdate = (salesforceField: any) => {
    localStorage.setItem(LOCAL_STORAGE.SALESFORCE_LAST_ACTIVE_SLASH_NAME, salesforceField);
  };

  const handleTrySaveAgain = useCallback(() => {
    const slashName = localStorage.getItem(LOCAL_STORAGE.SALESFORCE_LAST_ACTIVE_SLASH_NAME) ?? '';

    if (LOCAL_STORAGE.SALESFORCE_TOKEN_UPDATED && ref.current?.node?.id === slashName) {
      handleChangeValue(ref.current.node, slashName);
      localStorage.removeItem(LOCAL_STORAGE.SALESFORCE_TOKEN_UPDATED);
      localStorage.removeItem(LOCAL_STORAGE.SALESFORCE_LAST_ACTIVE_SLASH_NAME);
      return;
    }

    if (LOCAL_STORAGE.SALESFORCE_TOKEN_UPDATED && ref.current?.id === slashName) {
      handleChangeValue(ref.current, slashName);
      localStorage.removeItem(LOCAL_STORAGE.SALESFORCE_TOKEN_UPDATED);
      localStorage.removeItem(LOCAL_STORAGE.SALESFORCE_LAST_ACTIVE_SLASH_NAME);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('storage', handleTrySaveAgain);
    return () => window.removeEventListener('storage', handleTrySaveAgain);
    // eslint-disable-next-line
  }, []);

  const tokenErrorHeader = (
    <Stack spacing={0.5} direction="row" alignItems="center" style={{ marginLeft: 'auto' }}>
      <WarningIcon />
      <Typography color={theme.palette.grey[600]} style={{ fontSize: '12px' }}>
        Invalid Salesforce Credentials
      </Typography>
      <DotIcon />
      <Link href={`${BASE_URL}/providers/auth/salesforce`} target={'_blank'}>
        <Typography
          onClick={() => handleSalesforceUpdate(salesforceFieldName)}
          color={theme.palette.primary.main}
          style={{ fontSize: '12px', cursor: 'pointer' }}
        >
          Update
        </Typography>
      </Link>
    </Stack>
  );

  if (isLoading) {
    return (
      <NodeViewComponent
        node={node}
        header={
          <Stack spacing={0.5} direction="row" alignItems="center" style={{ marginLeft: 'auto' }}>
            <SpinnerIcon text={'Loading...'} />
          </Stack>
        }
      />
    );
  }

  const isNotePage = note_id && !account_id && !deal_id;

  if (isError && !template_id && !isNotePage) {
    return (
      <NodeViewComponent
        node={node}
        header={
          <Stack spacing={0.5} direction="row" alignItems="center" style={{ marginLeft: 'auto' }}>
            <WarningIcon />
            <Typography color={theme.palette.grey[600]} style={{ fontSize: '12px' }}>
              Unable to load
            </Typography>
            <DotIcon />
            <Typography
              onClick={() => handleLoadValueFromSalesforce()}
              color={theme.palette.primary.main}
              style={{ fontSize: '12px', cursor: 'pointer' }}
            >
              Retry
            </Typography>
          </Stack>
        }
      />
    );
  }

  return (
    <NodeViewComponent
      node={node}
      fieldsForType={fieldsForType as IFieldsForType}
      sfValue={SFValue}
      updateAttributes={updateAttributes}
      header={
        isTokenError ? (
          tokenErrorHeader
        ) : (
          <SalesforceComponentHeader isSaving={isSaving} node={node} />
        )
      }
      content={
        <SalesforceInputComponent
          node={node}
          nodeType={nodeType}
          disabled={isDisableEditing}
          initialValue={inputValue}
          handleChangeValue={handleChangeValue}
        />
      }
    />
  );
};

export default withMyContext(SalesforceComponent);
