import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import MenuPopover from '../../menu-popover';

type Props = {
  colors: { name: string; color: string; palletteColor?: string }[];
  openPopover: HTMLElement | null;
  handleClosePopover: () => void;
  handleChooseColor: (color: string) => void;
};

const PaletteMenu = ({ colors, openPopover, handleClosePopover, handleChooseColor }: Props) => {
  const theme = useTheme();
  return (
    <MenuPopover
      open={openPopover}
      onClose={handleClosePopover}
      arrow={'top-center'}
      sx={{
        width: 'fit-contents',
        marginTop: '16px',
        borderRadius: 0.75,
        border: `1px solid ${theme.palette.grey[300]}`,
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.customShadows.dropdown,
        padding: 0,
        zIndex: '1000',
      }}
      disabledArrow
      sxRoot={{
        zIndex: '5000',
      }}
    >
      <Stack direction="row" spacing={1.5} sx={{ padding: 1 }}>
        {colors.map((color) => (
          <Box
            key={color.name}
            onClick={() => handleChooseColor(color.color)}
            sx={{
              bgcolor: color?.palletteColor ?? color.color,
              color: 'primary.contrastText',
              p: 1.25,
              borderRadius: '50%',
              cursor: 'pointer',
              transition: 'ease 0.5s',
              '&:hover': {
                transform: 'scale(1.25)',
              },
            }}
          />
        ))}
      </Stack>
    </MenuPopover>
  );
};

export default PaletteMenu;
