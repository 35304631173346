import { BASE_URL } from '../config';

export async function refreshAccessToken(): Promise<string> {
  const result = await fetch(`${BASE_URL}/auth/jwt/refresh`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });
  const { accessToken } = await result.json();
  if (accessToken) {
    return accessToken;
  }
  return '';
}
