import { Typography, useTheme } from '@mui/material';
import React from 'react';

import Iconify from '../iconify';

const SpinnerIcon = ({
  text,
  width = 20,
  height = 20,
}: {
  text: string;
  width?: number;
  height?: number;
}) => {
  const theme = useTheme();
  return (
    <>
      <Iconify
        icon={'material-symbols:cached'}
        width={width}
        height={height}
        sx={{
          color: theme.palette.grey[500],
          animation: 'spin 2s linear infinite',
          '@keyframes spin': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
          },
        }}
      />
      <Typography
        variant="caption"
        sx={{ color: theme.palette.grey[500], cursor: 'default', userSelect: 'none' }}
      >
        {text}
      </Typography>
    </>
  );
};

export default SpinnerIcon;
