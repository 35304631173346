export const scrollIntoView = (
  title: string,
  block: ScrollLogicalPosition = 'end',
  timeout: number = 750,
  behavior: ScrollBehavior = 'smooth'
) => {
  const anchorTarget = document.getElementById(title);
  setTimeout(() => {
    anchorTarget && anchorTarget.scrollIntoView({ behavior, block });
  }, timeout);
};
