import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';

import { sortByNameAndEmail } from '../../../utils/sortByNameAndEmail';
import { MentionList } from './MentionList';
import People from 'src/@types/api/people';
import Users from 'src/@types/api/users';

export const Suggestions = ({ atMentions }: { atMentions?: (People | Users)[] }) => ({
  items: (props: { query: string }) =>
    atMentions
      ?.filter((item: People | Users) => {
        if (item.name) {
          return item.name?.toLowerCase()?.includes(props.query.toLowerCase());
        }

        return item.email?.toLowerCase()?.includes(props.query.toLowerCase());
      })
      .sort(sortByNameAndEmail((item) => item))
      .slice(0, 5) ?? [],

  render: () => {
    let reactRenderer: any;
    let popup: any;

    return {
      onStart: (props: any) => {
        reactRenderer = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,

          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props: any) {
        reactRenderer.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props: any) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return reactRenderer?.ref?.onKeyDown(props);
      },

      onExit() {
        popup?.[0].destroy();
        reactRenderer?.destroy();
      },
    };
  },
});
