import { ElementType, Suspense, lazy } from 'react';

import LoadingScreen from '../components/loading-screen';

const Loadable = (Component: ElementType) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Auth
export const AuthPage = Loadable(lazy(() => import('../pages/auth/AuthPage')));
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));

// Core
export const AccountPage = Loadable(lazy(() => import('../pages/core/account/AccountPage')));
export const AccountsPage = Loadable(lazy(() => import('../pages/core/account/AccountsPage')));
export const AccountPageDeals = Loadable(
  lazy(() => import('../pages/core/account/AccountPageDeals'))
);
export const AccountPageNotes = Loadable(
  lazy(() => import('../pages/core/account/AccountPageNotes'))
);
export const AIPage = Loadable(lazy(() => import('../pages/core/ai/AIPage')));
export const DealPage = Loadable(lazy(() => import('../pages/core/deal/DealPage')));
export const DealPageNotes = Loadable(lazy(() => import('../pages/core/deal/DealPageNotes')));
export const DealPageMeetings = Loadable(lazy(() => import('../pages/core/deal/DealPageMeetings')));
export const DealPageContextAI = Loadable(
  lazy(() => import('../pages/core/deal/DealPageContextAI'))
);
export const DealPagePeople = Loadable(lazy(() => import('../pages/core/deal/DealPagePeople')));
export const DealsPage = Loadable(lazy(() => import('../pages/core/deal/DealsPage')));
export const GongPage = Loadable(lazy(() => import('../pages/auth/GongPage')));
export const GongAuthPage = Loadable(lazy(() => import('../pages/auth/GongAuthPage')));
export const MeetingsPage = Loadable(lazy(() => import('../pages/core/meeting/MeetingsPage')));
export const NotePage = Loadable(lazy(() => import('../pages/core/note/NotePage')));
export const NotesPage = Loadable(lazy(() => import('../pages/core/note/NotesPage')));
export const TemplatePage = Loadable(lazy(() => import('../pages/core/template/TemplatePage')));
export const TemplatesPage = Loadable(lazy(() => import('../pages/core/template/TemplatesPage')));
export const SalesforceAuthPage = Loadable(lazy(() => import('../pages/auth/SalesforceAuthPage')));
export const SalesforcePage = Loadable(lazy(() => import('../pages/auth/SalesforcePage')));
export const SalesforceLoadingPage = Loadable(
  lazy(() => import('../components/salesforce-loading/SalesforceLoading'))
);

//MessagesPage
export const PageNotFoundMessagePage = Loadable(
  lazy(() => import('../pages/core/messages/PageNotFoundMessagePage'))
);
