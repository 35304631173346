import type ClientUsers from './clientUsers';
import DealUsers from './dealUsers';
import type Deals from './deals';
import BaseEntity from './extends/baseEntity';
import type Logins from './logins';
import type MeetingAttendees from './meetingAttendees';
import type Meetings from './meetings';
import Notes from './notes';
import type SalesforceOpportunities from './salesforceOpportunities';
import { PrimaryId } from './types/id';
import { Role } from './types/userAuthTypes';

export enum UserSource {
  AutoGenerated = 'AutoGenerated',
  Invited = 'Invited',
  Registered = 'Registered',
}
export default class Users extends BaseEntity {
  name!: string;

  email!: string;

  role!: Role;

  linkedIn?: string;

  image?: string;

  title?: string;

  source!: UserSource;

  userDealStats?: IUserDealStats;

  // Relationships

  clientUsers?: ClientUsers[];

  deals?: Deals[];

  notes?: Notes[];

  logins?: Logins[];

  meetingAttendees?: MeetingAttendees[];

  meetings?: Meetings[];

  salesforceOpportunities?: SalesforceOpportunities[];

  dealUsers?: DealUsers[];

  constructor(data: Partial<Users>) {
    super();
    Object.assign(this, data);
  }
}

interface IUserDealStats {
  dealId: PrimaryId;
  meetingCount: number;
}
