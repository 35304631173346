import { NodeViewProps } from '@tiptap/core';
import { NodeViewWrapper } from '@tiptap/react';

import { convertAndSanitize, getHtmlResult } from '../openai-menu/OpenAIMenuUtils';

export const CustomParagraphNodeView = ({ node }: NodeViewProps) => (
  <NodeViewWrapper>
    <div
      dangerouslySetInnerHTML={{
        __html: getHtmlResult(convertAndSanitize(node.attrs.textContent || '')),
      }}
    />
  </NodeViewWrapper>
);
