import { OutlinedInput, useTheme } from '@mui/material';
import React, { useEffect, useRef } from 'react';

export const DelayAutoFocusInput = ({ autoFocus, setIsEditing, ...rest }: any) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();

  useEffect(() => {
    if (!autoFocus) {
      return;
    }

    const frame = window.requestAnimationFrame(() => {
      inputRef.current?.focus();
    });

    return () => {
      window.cancelAnimationFrame(frame);
    };
  }, [autoFocus]);

  return (
    <OutlinedInput
      style={{ height: '30px', zIndex: '9999' }}
      variant={'outlined'}
      fullWidth
      // onBlur={() => setIsEditing(false)}
      sx={{
        width: '260px!important',
        border: 'none!important',
        borderRadius: '8px',
        color: theme.palette.success.dark,
        '.MuiOutlinedInput-input': {
          padding: '0!important',
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none!important',
        },
        '&:fieldset': {
          border: 'none!important',
        },
        '&:hover fieldset': {
          border: 'none!important',
        },
        '&.Mui-focused fieldset': {
          border: 'none!important',
        },
      }}
      inputRef={inputRef}
      {...rest}
    />
  );
};
