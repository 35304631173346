import { AnyExtension } from '@tiptap/core';
import { TableRow } from '@tiptap/extension-table-row';

import { TableCell } from './TableCell';
import { Table } from './TableExtension';
import { TableHeader } from './TableHeader';

export const TableExtensionsPack: AnyExtension[] = [
  Table.configure({
    resizable: true,
    allowTableNodeSelection: true,
    lastColumnResizable: false,
    cellMinWidth: 50,
  }),
  TableCell,
  TableHeader,
  TableRow,
];
