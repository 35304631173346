import { ClickAwayListener, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { Editor } from '@tiptap/core';
import React, { useCallback, useState } from 'react';

import { ReactComponent as ArrowIcon } from '../../../../assets/icons/dealIcons/expand_icon.svg';
import MenuPopper from '../../../menu-popper/MenuPopper';
import { OptionsType } from './TableBubbleMenu';

type OptionsMenuProps = {
  title: string;
  options: OptionsType[];
  editor: Editor;
};

const OptionsMenu = ({ options, title, editor }: OptionsMenuProps) => {
  const theme = useTheme();
  const [openOptionsMenu, setOpenOptionsMenu] = useState<HTMLElement | null>(null);

  const handleOpenOptionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    // if we want see where our cursor after opening popover
    // editor.commands.focus();
    if (openOptionsMenu) return setOpenOptionsMenu(null);

    setOpenOptionsMenu(event.currentTarget);
  };

  const handleCloseOptionsMenu = useCallback(() => {
    setOpenOptionsMenu(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!editor) return null;

  return (
    <ClickAwayListener
      onClickAway={handleCloseOptionsMenu}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <div>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          onClick={handleOpenOptionsMenu}
          onBlur={handleCloseOptionsMenu}
          sx={{ cursor: 'pointer' }}
        >
          <Typography
            variant="subtitle2"
            sx={{ color: openOptionsMenu ? theme.palette.primary.main : theme.palette.grey[600] }}
          >
            {title}
          </Typography>
          <ArrowIcon
            style={{
              color: openOptionsMenu ? theme.palette.primary.main : theme.palette.grey[600],
            }}
          />
        </Stack>

        <MenuPopper
          anchorEl={openOptionsMenu}
          open={Boolean(openOptionsMenu)}
          disablePortal
          sx={{
            marginTop: `12px!important`,
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.label} onClick={option.action}>
              {option.label}
            </MenuItem>
          ))}
        </MenuPopper>
      </div>
    </ClickAwayListener>
  );
};

export default OptionsMenu;
