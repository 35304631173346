import { Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { NodeViewWrapper } from '@tiptap/react';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import { ReactComponent as SalesforceIcon } from '../../../../assets/icons/tiptapIcons/slashmenu/salesforceCloudIcon.svg';
import { ISlashNodeInterface } from '../../extensions/ContextSalesforceExtension';
import LinkFieldModal from './LinkFieldModal';
import { IFieldsForType } from './SalesforceComponentHeader';
import { SalesforceType } from 'src/@types/api/types/salesforce';
import Deal from 'src/@types/core/deal';
import { useGetSalesforceOptions } from 'src/hooks/useQueries';

interface INodeViewComponentProps {
  node: ISlashNodeInterface;
  content?: React.ReactNode;
  header?: React.ReactNode;
  editable?: boolean;
  sfValue?: any;
  deal?: Deal;
  fieldsForType?: IFieldsForType;
  updateAttributes?: (attributes: Record<string, any>) => void;
}

const NodeViewComponent = ({
  node,
  content,
  header,
  sfValue,
  deal,
  fieldsForType,
  updateAttributes,
}: INodeViewComponentProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { template_id } = useParams();

  const handleCloseModal = () => {
    setOpen(false);
  };

  const {
    data: options,
    isLoading,
    isFetching,
  } = useGetSalesforceOptions(node.attrs.salesforceType, open);

  const isKeySet = node.attrs.salesforceKey.length > 5;

  return (
    <NodeViewWrapper
      className="salesforce-component"
      id={`${node.attrs.salesforceFieldName}-${crypto.randomUUID()}`}
    >
      <Stack
        sx={{
          width: '100%',
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: 1,
          padding: '12px',
        }}
      >
        <Stack direction="row" alignItems="center" contentEditable={false}>
          <SalesforceIcon style={{ marginRight: '8px' }} />
          <Typography
            variant="caption"
            sx={{ color: theme.palette.grey[600], lineHeight: '18px', minWidth: 'fit-content' }}
          >
            {node.attrs.salesforceFieldLabel}{' '}
          </Typography>

          {!template_id ? (
            <Tooltip title={isKeySet ? `Change ${node.attrs.salesforceType}` : ``} placement="top">
              <Typography
                sx={{
                  fontSize: '12px !important',
                  lineHeight: '12px !important',
                  fontWeight: '600 !important',
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  marginLeft: '5px',
                  marginRight: '3px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(true);
                }}
              >
                <Typography variant="caption" sx={{ color: theme.palette.grey[600] }}>
                  (
                </Typography>
                {!isKeySet && `Link to ${node.attrs.salesforceType}`}
                {(isKeySet && sfValue?.Name) || sfValue?.name}
                <Typography variant="caption" sx={{ color: theme.palette.grey[600] }}>
                  )
                </Typography>
              </Typography>
            </Tooltip>
          ) : (
            <Tooltip title={"Can't link to Salesforce in a template"} placement="top">
              <Typography
                color={theme.palette.grey[600]}
                sx={{ marginLeft: '5px' }}
                style={{ fontSize: '12px' }}
              >
                (Unlinked)
              </Typography>
            </Tooltip>
          )}
          {!isFetching && !isLoading && (
            <LinkFieldModal
              open={open}
              setOpen={setOpen}
              handleCloseModal={handleCloseModal}
              fieldType={fieldsForType?.type ?? (node.attrs.salesforceType as SalesforceType)}
              node={node}
              options={options ?? []}
              updateAttributes={updateAttributes}
            />
          )}

          {header}
        </Stack>
        {content}
      </Stack>
    </NodeViewWrapper>
  );
};

export default NodeViewComponent;
