import { forEach } from 'lodash';

import { AuthUserType } from '../../../auth/types';
import { CONTEXT_HOME, DEALS_PATH } from '../../../config';
import Deal from 'src/@types/core/deal';
import { NavListProps } from 'src/components/nav-section/types';

interface NavListConfig {
  subheader: string;
  items: NavListProps[];
}

export function getNavConfig(data: Deal[], user: AuthUserType): NavListConfig[] {
  const navListProps: NavListProps[] = [];

  forEach(data, (deal) => {
    const deal_id = deal.id;
    const dpath = CONTEXT_HOME + `/${DEALS_PATH}/` + deal_id;

    const navListProp = {
      id: deal.id,
      title: deal.name,
      path: dpath,
      attributes: [
        { name: 'Stage', value: deal.salesforceOpportunity?.StageName },
        {
          name: 'ForecastCategory',
          value: deal.salesforceOpportunity?.ForecastCategory,
        },
        { name: 'CloseDate', value: deal.salesforceOpportunity?.CloseDate },
        { name: 'DealOwner', value: deal.user?.name },
        { name: 'Company', value: deal.company?.name },
      ],
      isFavorite: !!deal.dealUsers?.find((u) => u.userId === user?.id)?.pinnedAt,
    };

    navListProps.push(navListProp);
  });

  navListProps.sort((a, b) => (a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() ? 1 : -1));

  const navSectionProps = [
    // { subheader: 'Home', items: [{ title: 'Home', path: CONTEXT_HOME + '/home' }] },
    { subheader: 'Deals', items: navListProps },
  ];

  return navSectionProps;
}
