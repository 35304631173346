export const isAccessTokenExpired = (accessToken?: string, gracePeriodSeconds = 30): boolean => {
  try {
    if (!accessToken) {
      return true;
    }
    const { exp } = JSON.parse(atob(accessToken.split('.')[1]));
    return Date.now() >= exp * 1000 - gracePeriodSeconds * 1000;
  } catch (e) {
    return true;
  }
};
