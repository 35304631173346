import { Dispatch, createContext, useContext, useState } from 'react';

interface ISavingContext {
  isSaving: boolean;
  setIsSaving: Dispatch<any>;
}

const SavingContext = createContext<ISavingContext>({
  isSaving: false,
  setIsSaving: () => {},
});

export function useSavingContext() {
  return useContext(SavingContext);
}

export const useSavingContextState = () => {
  const [isSaving, setIsSaving] = useState<boolean>(false);

  return { isSaving, setIsSaving };
};

export default SavingContext;
