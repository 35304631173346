import { forEach } from 'lodash';
import { NavigateFunction } from 'react-router-dom';

import Notes, { NotesSource } from '../../../../@types/api/notes';
import { PrimaryId } from '../../../../@types/api/types/id';
import { NOTES_PATH } from '../../../../config';
import { sortAlphabetically } from '../../../../utils/sortAlphabetically';
import NoteTemplates from 'src/@types/api/noteTemplates';

export interface INoteTreeNode extends Notes {
  children: INoteTreeNode[];
  depth: number;
}

export const getNoteTree = (
  notes: Notes[],
  id: string | null = null,
  link = 'parentNoteId',
  depth = 0
): INoteTreeNode[] =>
  notes
    .filter((note) => note[link as keyof Notes] === id)
    .sort(sortAlphabetically((item) => item.title))
    .map((note) => {
      let counter = depth;
      return {
        ...note,
        children: getNoteTree(notes, note.id, 'parentNoteId', counter + 1),
        depth: counter,
      } as INoteTreeNode;
    });

export function findNote(
  noteId: PrimaryId,
  noteTree: INoteTreeNode[] | null
): INoteTreeNode | null {
  if (!noteTree) return null;
  for (let i = 0; i < noteTree.length; i++) {
    if (noteTree[i].id === noteId) {
      return noteTree[i];
    }
    if (noteTree[i].children.length > 0) {
      const note = findNote(noteId, noteTree[i].children);
      if (note) {
        return note;
      }
    }
  }
  return null;
}

export const getNoteTitleFromJson = (note: Notes | NoteTemplates): string => {
  if (note.id === undefined) return 'My Notes';
  // Check if there is a title set, if not, use the value to extract the title
  if (note.hasTitle && note.title) return note.title;
  if ('source' in note && note.source === NotesSource.AI)
    return note.value?.content?.[0]?.attrs?.text;

  const noteContent = note?.value?.default || note?.value;
  return (
    noteContent?.content?.find((item: any) => item.type === 'title')?.content?.[0]?.text ??
    note.title ??
    'Untitled Note'
  );
};

export const countChildNotes = (noteId: PrimaryId, noteTree: INoteTreeNode[]): number => {
  const note = findNote(noteId, noteTree);
  if (!note) return 0;
  return (
    note.children.length +
    note.children.reduce((acc, item) => acc + countChildNotes(item.id, noteTree), 0)
  );
};

export function getNoteTreeArray(
  noteTree: INoteTreeNode[],
  thisNote: INoteTreeNode
): INoteTreeNode[] {
  let notes: INoteTreeNode[] = [];
  forEach(noteTree, (note) => {
    // if (note.id !== thisNote.id && note.id !== thisNote.parentNoteId) {
    notes.push(note);
    if (note.children.length > 0) {
      notes.push(...getNoteTreeArray(note.children, thisNote));
    }
    // }
  });
  return notes;
}

export const navigateToExistingNote = (
  noteTree: INoteTreeNode[],
  thisNoteId: string,
  navigate: NavigateFunction
) => {
  const existingNotes = noteTree.filter((item) => item.id !== thisNoteId);
  navigate(`/${NOTES_PATH}/${existingNotes[0].id}`, { replace: true });
};
