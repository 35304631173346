import { SalesforceFieldTypes } from '../../../components/tip-tap-editor/slash-menu/types';

export interface ClientSalesforceConfig {
  Objects: any;
}

export interface ClientAzureConfig {
  Events: any;
}

export interface AiPrompt {
  name: string;
  icon: string;
  promptText: string;
  standalone: boolean;
}

export interface AiConfig {
  noteConfig: {
    prompts: AiPrompt[];
  };
}

export type ClientConfig = ClientSalesforceConfig | ClientAzureConfig | AiConfig;

export enum ClientConfigType {
  // Salesforce company URL
  SALESFORCE_URL = 'salesforceUrl',

  // Salesforce objects that exist
  SALESFORCE_OBJECTS = 'salesforceObjects',
  // Salesforce objects that are chosen
  SALESFORCE_OBJECTS_CHOSEN = 'salesforceObjectsChosen',

  // Salesforce fields that exist
  SALESFORCE_FIELDS_ACCOUNT = 'salesforceFieldsAccount',
  SALESFORCE_FIELDS_CONTACT = 'salesforceFieldsContact',
  SALESFORCE_FIELDS_OPPORTUNITY = 'salesforceFieldsOpportunity',
  SALESFORCE_FIELDS_OPPORTUNITY_CONTACT_ROLE = 'salesforceFieldsOpportunityContactRole',
  SALESFORCE_FIELDS_OPPORTUNITY_STAGE = 'salesforceFieldsOpportunityStage',
  SALESFORCE_FIELDS_USER = 'salesforceFieldsUser',
  SALESFORCE_FIELDS_USER_ROLE = 'salesforceFieldsUserRole',

  SALESFORCE_FIELDS_GONG_CALL = 'salesforceFieldsGongCall',

  // Salesforce fields that are chosen
  SALESFORCE_FIELDS_CHOSEN_ACCOUNT = 'salesforceFieldsChosenAccount',
  SALESFORCE_FIELDS_CHOSEN_CONTACT = 'salesforceFieldsChosenContact',
  SALESFORCE_FIELDS_CHOSEN_OPPORTUNITY = 'salesforceFieldsChosenOpportunity',
  SALESFORCE_FIELDS_CHOSEN_OPPORTUNITY_CONTACT_ROLE = 'salesforceFieldsChosenOpportunityContactRole',
  SALESFORCE_FIELDS_CHOSEN_OPPORTUNITY_STAGE = 'salesforceFieldsChosenOpportunityStage',
  SALESFORCE_FIELDS_CHOSEN_USER = 'salesforceFieldsChosenUser',
  SALESFORCE_FIELDS_CHOSEN_USER_ROLE = 'salesforceFieldsChosenUserRole',

  SALESFORCE_FIELDS_CHOSEN_GONG_CALL = 'salesforceFieldsChosenGongCall',

  // Core client fields
  CORE_CLIENT_CONFIG = 'coreClientConfig',

  AI_CONFIG = 'aiConfig',
}

export enum SalesforceType {
  ACCOUNT = 'Account',
  OPPORTUNITY = 'Opportunity',
  OPPORTUNITY_CONTACT_ROLE = 'OpportunityContactRole',
  OPPORTUNITY_STAGE = 'OpportunityStage',
  CONTACT = 'Contact',
  USER = 'User',
  USER_ROLE = 'UserRole',
  CUSTOM = 'Custom',
  GONG_CALL = 'Gong__Gong_Call__c',
  GONG_RELATED_OPPORTUNITY = 'Gong__Related_Opportunity__c',
}

export type SalesforceField = {
  id: string;
  calculatedFormula: any;
  custom: boolean;
  label: string;
  name: string;
  type: SalesforceFieldTypes;
  compoundFieldName: string | null;
  key: string;
  salesforceType: SalesforceType;
  updateable: boolean;
  picklistValues: PicklistValues[];
  value: any;
};

export type PicklistValues = {
  active: boolean;
  defaultValue: boolean;
  label: string;
  validFor: any;
  value: string;
};
