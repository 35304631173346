import Companies from '../@types/api/companies';
import SalesforceOpportunityStages from '../@types/api/salesforceOpportunityStages';
import { sortAlphabetically } from '../utils/sortAlphabetically';
import Deal from 'src/@types/core/deal';

export function getDealById({
  data,
  dealId,
}: {
  data: Deal[] | undefined;
  dealId: string | undefined;
}): Deal {
  let thisDeal = data?.find((deal) => deal.id === dealId);
  return new Deal(thisDeal!);
}

export function getDealStages(stages: SalesforceOpportunityStages[]): string[] {
  return Array.from(
    new Set(
      stages
        .sort((a: SalesforceOpportunityStages, b: SalesforceOpportunityStages) => {
          if (!a.SortOrder) return -1;
          if (!b.SortOrder) return -1;
          return a?.SortOrder - b?.SortOrder;
        })
        .map((item) => item.ApiName)
    )
  ) as string[];
}

export function getDealOwners(deals: Deal[]): string[] {
  let dealOwners: string[] = [];

  deals?.forEach((deal) => {
    let dealOwner = deal.user?.name;
    if (!dealOwner || dealOwners.includes(dealOwner)) return;
    dealOwners.push(dealOwner);
  });

  return dealOwners.sort();
}

export function getDealCompanies(deals: Deal[]): Companies[] {
  let stages = [...new Map(deals.map((item) => [item.company.id, item])).values()].map(
    (item) => item.company
  );

  return stages.sort(sortAlphabetically((account) => account.name));
}

export function getDealCompaniesName(deals: Deal[]): string[] {
  let stages = [...new Map(deals.map((item) => [item.company.id, item])).values()].map(
    (item) => item.company.name
  );

  return stages.sort();
}

export function getDealForecastCategories(deals: Deal[]): string[] {
  let forecastCategories: string[] = [];

  deals?.forEach((deal) => {
    if (
      deal.salesforceOpportunity?.ForecastCategory !== undefined &&
      !forecastCategories.includes(deal.salesforceOpportunity?.ForecastCategory)
    ) {
      forecastCategories.push(deal.salesforceOpportunity?.ForecastCategory);
    }
  });

  return forecastCategories.sort();
}

export function getDealCloseDates(deals: Deal[]): string[] {
  let closeDates: string[] = [];
  deals?.forEach((deal) => {
    if (deal.salesforceOpportunity?.CloseDate !== undefined) {
      let closeDate = new Date(deal.salesforceOpportunity?.CloseDate);
      let closeDateFormatted = closeDate.toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      });
      if (!closeDates.includes(closeDateFormatted)) {
        closeDates.push(closeDateFormatted);
      }
    }
  });

  //sort the dates
  closeDates.sort((a, b) => {
    let dateA = new Date(a);
    let dateB = new Date(b);
    return dateA.getTime() - dateB.getTime();
  });

  return closeDates;
}
