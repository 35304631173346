import { Editor } from '@tiptap/core';
import React from 'react';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/tiptapIcons/cross.svg';
import { useLink } from '../link-input/useLink';
import '../styles.scss';
import Button from './Button';

const RemoveLinkButton = ({ editor, setIsEditing }: { editor: Editor; setIsEditing: any }) => {
  const { removeLink } = useLink({ editor, setIsEditing });

  const handleToggle = () => {
    removeLink();
  };

  return (
    <Button
      editor={editor}
      icon={<DeleteIcon />}
      tooltip={'Remove Link'}
      isActiveName={'removeLink'}
      clickCommand={handleToggle}
    />
  );
};

export default RemoveLinkButton;
