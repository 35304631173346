import { Editor } from '@tiptap/core';
import React from 'react';

import { ReactComponent as BoldIcon } from '../../../assets/icons/tiptapIcons/bold.svg';
import '../styles.scss';
import Button from './Button';

const BoldButton = ({ editor }: { editor: Editor }) => {
  const handleToggle = (editor: Editor) => {
    editor.chain().focus().toggleBold().run();
  };

  return (
    <Button
      editor={editor}
      shortcut={'mod-b'}
      icon={<BoldIcon />}
      tooltip={'Bold'}
      isActiveName={'bold'}
      clickCommand={handleToggle}
    />
  );
};

export default BoldButton;
