import { MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import NoteTemplates from '../../../../../@types/api/noteTemplates';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/navIcons/delete.svg';
import { Buttons } from '../../../../../components/modal/Buttons';
import { GeneralModalComponent } from '../../../../../components/modal/GeneralModalComponent';
import { TEMPLATES_PATH } from '../../../../../config';
import { useDeleteTemplate } from '../../../../../hooks/useQueries';
import { useNoteTemplatesContext } from 'src/hooks/useNoteTemplatesContext';

const DeleteTemplate = ({
  templateId,
  handleClose,
  template,
}: {
  templateId: string;
  handleClose: () => void;
  template: NoteTemplates;
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { refresh } = useNoteTemplatesContext();

  const [open, setOpen] = React.useState(false);

  const deleteTemplate = useDeleteTemplate(templateId);

  const handleOpenModal = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleCloseModal = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleDelete = () => {
    deleteTemplate
      .mutateAsync()
      .then(async () => {
        enqueueSnackbar('Template deleted', { variant: 'success' });
        await refresh();
        navigate(`/${TEMPLATES_PATH}`, { replace: true });
      })
      .catch(() => enqueueSnackbar('Template not deleted!', { variant: 'error' }))
      .finally(() => {
        handleClose();
      });
  };

  return (
    <>
      <MenuItem onClick={handleOpenModal} sx={{ color: theme.palette.common.white }}>
        <DeleteIcon style={{ marginRight: '8px' }} />
        Delete
      </MenuItem>
      <GeneralModalComponent
        handleCloseModal={handleCloseModal}
        buttons={
          <Buttons
            activeButtonName={'Delete'}
            buttonColor="error"
            handleClick={handleDelete}
            handleCloseModal={handleCloseModal}
          />
        }
        content={
          <Typography variant="body1" color={theme.palette.grey[800]} sx={{ padding: '0 24px' }}>
            {`Are you sure you want to delete "${template.title || template.name}"?`}
          </Typography>
        }
        open={open}
        headerText={'Delete Template'}
      />
    </>
  );
};

export default DeleteTemplate;
