import { forEach } from 'lodash';

import { sortAlphabetically } from '../../utils/sortAlphabetically';
import Meetings from '../api/meetings';
import { GoogleConferenceData } from '../api/types/calendar';
import MeetingAttendee, { MeetingAttendeeType } from './meetingAttendee';

export default class Meeting extends Meetings {
  getPeopleByType(type?: MeetingAttendeeType): MeetingAttendee[] {
    const attendees: MeetingAttendee[] = [];
    forEach(this.meetingAttendees, (meetingAttendee) => {
      const attendee = new MeetingAttendee(meetingAttendee);
      if (!type || attendee.type === type) {
        if (
          attendee.name === this.calendarEvents[0]?.organizer?.emailAddress.name &&
          attendee.email === this.calendarEvents[0]?.organizer?.emailAddress.address.toLowerCase()
        ) {
          attendee.status = 'accepted';
        }
        attendees.push(attendee);
      }
    });
    return attendees.sort(sortAlphabetically((person) => person.name ?? person.email));
  }

  checkCalendarEvents() {
    if (this.calendarEvents?.length === 0) {
      console.error(`Meeting ${this.id} has no calendar events`);
    }
  }

  isExternal(): boolean {
    return !!this.getPeopleByType(MeetingAttendeeType.External).length;
  }

  get start(): Date | undefined {
    this.checkCalendarEvents();

    if (!this.calendarEvents?.[0]?.startUtc) {
      console.error(`Meeting ${this.id} has no start date`);
    }

    return this.calendarEvents?.[0]?.startUtc;
  }

  get end(): Date | undefined {
    this.checkCalendarEvents();

    if (!this.calendarEvents?.[0]?.endUtc) {
      console.error(`Meeting ${this.id} has no end date`);
    }

    return this.calendarEvents?.[0]?.endUtc;
  }

  get name(): string | undefined {
    this.checkCalendarEvents();

    if (!this.calendarEvents?.[0]?.subject && !this.meta?.startUtc) {
      console.error(`Meeting ${this.id} has no subject`);
    }

    return this.calendarEvents?.[0]?.subject;
  }

  get location(): string {
    if (
      this.calendarEvents?.[0]?.location?.displayName &&
      this.calendarEvents?.[0]?.location?.displayName !== ''
    ) {
      return this.calendarEvents?.[0]?.location?.displayName || '';
    }

    const onlineMeeting = this.calendarEvents?.[0]?.onlineMeeting as GoogleConferenceData;
    if (onlineMeeting && onlineMeeting.entryPoints?.length) {
      return onlineMeeting.entryPoints?.[0]?.uri || '';
    }

    if (onlineMeeting && onlineMeeting.joinUrl) {
      return onlineMeeting.joinUrl || '';
    }

    return '';
  }

  constructor(m: Meetings) {
    super();
    Object.assign(this, m);
  }
}
