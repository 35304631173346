import { Mixin } from 'ts-mixer';

import Deals from './deals';
import BaseEntity from './extends/baseEntity';
import MeetingAttendees from './meetingAttendees';
import type Meetings from './meetings';
import NoteUsers from './noteUsers';
import People from './people';
import { PrimaryId } from './types/id';
import type Users from './users';

export enum NotesSource {
  MeetingAttendee = 'meeting_attendees',
  Deal = 'deals',
  Meeting = 'meetings',
  Company = 'companies',
  People = 'people',
  Agenda = 'agendas',
  User = 'users',
  AI = 'ai',
}

export default class Notes extends Mixin(BaseEntity) {
  constructor(note: Notes) {
    super();
    Object.assign(this, note);
  }

  clientId!: PrimaryId;

  userId!: PrimaryId;

  source!: NotesSource;

  sourceId!: PrimaryId;

  _title?: string;

  value!: any;

  valueBinary?: any;

  hasBinaryData?: any;

  private!: boolean;

  parentNoteId?: PrimaryId;

  // Relationships

  noteUsers?: NoteUsers[];

  parent?: Notes;

  children?: Notes[];

  user!: Users;

  meetings?: Meetings;

  deal?: Deals;

  meetingAttendees?: MeetingAttendees;

  person?: People;

  get hasTitle(): boolean {
    return !!this._title;
  }

  get title() {
    return this._title ?? 'Untitled Note';
  }

  set title(value: string) {
    this._title = value;
  }
}
