import Clients from './clients';
import BaseEntity from './extends/baseEntity';
import TemplateUsers from './templateUsers';
import { AccessLevel } from './types/accessLevel';
import { PrimaryId } from './types/id';
import Users from './users';

export enum TemplateType {
  User = 'User',
  Client = 'Client',
}

export default class NoteTemplates extends BaseEntity {
  clientId!: PrimaryId;

  userId!: PrimaryId;

  name!: string;

  value!: any;

  valueBinary?: any;

  hasBinaryData?: any;

  type!: TemplateType;

  title?: string;

  accessLevel?: AccessLevel;

  // Relationships
  client!: Clients;

  noteTemplateUsers?: TemplateUsers[];

  user!: Users;

  get hasTitle(): boolean {
    return !!this.title;
  }
}
