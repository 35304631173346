import React from 'react';

import { ReactComponent as ActionIcon } from '../../../../assets/icons/tiptapIcons/slashmenu/actionItem.svg';
import { SLASH_MENU, SlashCommandType } from '../types';

const ActionCommand = () => {
  const actionCommand = {
    name: SLASH_MENU.ACTION,
    value: [],
    icon: <ActionIcon />,
    subtitle: 'Create and assign action items',
    command: ({ editor, range }: SlashCommandType<null>) => {
      // first line remove /
      editor?.commands.deleteRange({ from: range.from, to: range.to + 1 });
      editor?.chain().focus().toggleTaskList().run();
    },
  };
  return { ...actionCommand };
};

export default ActionCommand;
