import { Editor } from '@tiptap/core';
import React from 'react';

import { ReactComponent as UnderlineIcon } from '../../../assets/icons/tiptapIcons/underline.svg';
import Button from './Button';

const UnderlineButton = ({ editor }: { editor: Editor }) => {
  const handleToggle = (editor: Editor) => {
    editor.chain().focus().toggleUnderline().run();
  };

  return (
    <Button
      editor={editor}
      shortcut={'mod-U'}
      icon={<UnderlineIcon />}
      tooltip={'Underline'}
      isActiveName={'underline'}
      clickCommand={handleToggle}
    />
  );
};

export default UnderlineButton;
