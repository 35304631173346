import { MenuItem, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { NotesSource } from '../../../../../@types/api/notes';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/navIcons/closeIcon.svg';
import { useAuthContext } from '../../../../../auth/useAuthContext';
import { Buttons } from '../../../../../components/modal/Buttons';
import { GeneralModalComponent } from '../../../../../components/modal/GeneralModalComponent';
import { useRemoveNoteAccess } from '../../../../../hooks/useQueries';
import { INoteTreeNode, navigateToExistingNote } from '../DashboardNavNotesUtils';

type Props = {
  thisNote: INoteTreeNode;
  noteTree: INoteTreeNode[];
  handleClose?: () => void;
  isNotePage?: boolean;
};

const successMessage = 'Access Updated';
const failureMessage = 'Error Updating Access';
const RemoveSharedNote = ({ thisNote, noteTree, handleClose, isNotePage }: Props) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const removeNoteAccess = useRemoveNoteAccess(thisNote.id, user?.id, NotesSource.User);

  const handleOpenModal = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleCloseModal = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleRemoveAccess = () => {
    removeNoteAccess
      .mutateAsync()
      .then(() => {
        enqueueSnackbar(successMessage, { variant: 'success' });
        navigateToExistingNote(noteTree, thisNote.id, navigate);
      })
      .catch(() => enqueueSnackbar(failureMessage, { variant: 'error' }))
      .finally(() => handleClose && handleClose());
  };

  return (
    <>
      <MenuItem
        onClick={handleOpenModal}
        sx={{ color: isNotePage ? theme.palette.common.black : theme.palette.common.white }}
      >
        <CloseIcon color={theme.palette.grey[600]} style={{ marginRight: '8px' }} />
        Remove
      </MenuItem>
      <GeneralModalComponent
        handleCloseModal={handleCloseModal}
        buttons={
          <Buttons
            activeButtonName={'Remove'}
            buttonColor="error"
            handleClick={handleRemoveAccess}
            handleCloseModal={handleCloseModal}
          />
        }
        content={
          <Typography variant="body1" color={theme.palette.grey[800]} sx={{ padding: '0 24px' }}>
            Remove note from your list
          </Typography>
        }
        open={open}
        headerText={'Remove Note'}
      />
    </>
  );
};

export default RemoveSharedNote;
