import { Box, alpha } from '@mui/material';
import React from 'react';

import { ReactComponent as OpenAIIcon } from '../../../../assets/icons/tiptapIcons/bublemenu/openAI.svg';
import { ISlashCommandProps, SLASH_MENU, SlashCommandType } from '../types';
import { scrollIntoView } from 'src/utils/scrollIntoView';

const AICommand = ({
  setIsEditingOpenAI,
}: {
  setIsEditingOpenAI: (open: boolean) => void;
}): ISlashCommandProps => {
  const aiCommand = {
    name: SLASH_MENU.AI,
    value: [],
    icon: (
      <Box
        width={40}
        height={40}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid #DFE3E8`,
          borderRadius: '6px',
        }}
      >
        <Box
          sx={{
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            backgroundColor: alpha('#8E33FF', 0.12),
            padding: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <OpenAIIcon
            style={{
              width: '12.8px',
              height: '12.8px',
              color: '#C684FF',
            }}
          />
        </Box>
      </Box>
    ),
    subtitle: 'Ask AI anything',
    command: ({ editor, range }: SlashCommandType<null>) => {
      editor
        ?.chain()
        .insertContentAt({ from: range.from - 1, to: range.to }, [{ type: 'paragraph' }])
        .run();
      setTimeout(() => {
        const el = document.getElementById('inputAI');
        el?.focus();
        scrollIntoView('inputAI', 'center', 100, 'smooth');
      }, 50);
      setIsEditingOpenAI(true);
    },
  };

  return { ...aiCommand };
};

export default AICommand;
