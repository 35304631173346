import { createSlice } from '@reduxjs/toolkit';

import { ISalesforceInitialState } from '../types/salesforce';

const initialState: ISalesforceInitialState = {
  url: '',
  accountFields: [],
  opportunityFields: [],
};

const slice = createSlice({
  name: 'salesforce',
  initialState,
  reducers: {
    saveUserSalesforceUrl: (state, { payload }) => {
      state.url = payload;
    },
    deleteUserSalesforceUrl: (state) => {
      state.url = '';
    },
    saveUserSalesforceAccountFields: (state, { payload }) => {
      state.accountFields = payload;
    },
    deleteUserSalesforceAccountFields: (state) => {
      state.accountFields = [];
    },
    saveUserSalesforceOpportunityFields: (state, { payload }) => {
      state.opportunityFields = payload;
    },
    deleteUserSalesforceOpportunityFields: (state) => {
      state.opportunityFields = [];
    },
  },
});

export default slice.reducer;

export const { actions: salesforceActions } = slice;
