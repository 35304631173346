import { MenuItem, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';

import { ReactComponent as CopyIcon } from '../../../../assets/icons/tiptapIcons/link-m.svg';
import { NOTES_PATH } from '../../../../config';
import { useNotesMenuContext } from '../../../../hooks/useNotesMenuContext';
import { copyToClipboard } from '../../../../utils/copyToClipboard';

const CopyElement = ({ id, isNote }: { id: string; isNote?: boolean }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const { setOpenMenu } = useNotesMenuContext();

  const handleCopyToClipboard = (copiedId: string) => {
    const url = window.location.href.replace(window.location.hash, '');
    setOpenMenu(null);
    isNote
      ? copyToClipboard(`${window.location.origin}/${NOTES_PATH}/${copiedId}`)
      : copyToClipboard(url);
    enqueueSnackbar('Link copied to clipboard', { variant: 'success' });
  };

  return (
    <MenuItem onClick={() => handleCopyToClipboard(id)}>
      <CopyIcon style={{ cursor: 'pointer', marginRight: '8px' }} />
      <Typography variant="body2" sx={{ color: theme.palette.grey[800] }}>
        Copy Link
      </Typography>
    </MenuItem>
  );
};

export default CopyElement;
