import { Button, Divider, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrowIcon } from '../../../assets/icons/navIcons/arrow_forward.svg';
import { ReactComponent as AccountsIcon } from '../../../assets/icons/navIcons/business.svg';
import { ReactComponent as UpcomingIcon } from '../../../assets/icons/navIcons/ic_calendar.svg';
import { ReactComponent as DealsIcon } from '../../../assets/icons/navIcons/ic_deals.svg';
import { ReactComponent as NotesIcon } from '../../../assets/icons/navIcons/ic_notes.svg';
import { ReactComponent as TemplateIcon } from '../../../assets/icons/navIcons/template.svg';
import { LOCAL_STORAGE, SESSION_STORAGE } from '../../../config';
import NavSectionItem from './NavSectionItem';
import { NavSection } from './NavVertical';
import { useNotesContext } from 'src/hooks/useNotesContext';

const NavSectionItems = ({
  activeSection,
  setActiveSection,
}: {
  activeSection: NavSection | null;
  setActiveSection: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { notes } = useNotesContext();

  const handleSetActiveSection = (navActiveSection: NavSection) => {
    setActiveSection(navActiveSection);
  };

  const handleNavigateBack = () => {
    const prevUrl = localStorage.getItem(LOCAL_STORAGE.PREV_PAGE_TEMPLATE);
    if (prevUrl) {
      localStorage.removeItem(LOCAL_STORAGE.PREV_PAGE_TEMPLATE);
      return navigate(prevUrl);
    }
    navigate(-1);
  };

  useEffect(() => {
    if (!activeSection) {
      sessionStorage.setItem(SESSION_STORAGE.SELECTED_SECTION, NavSection.MEETINGS);
      setActiveSection(NavSection.MEETINGS);
    }
  }, [activeSection, setActiveSection]);

  return (
    <Stack spacing={1} sx={{ paddingX: 3, marginBottom: 2 }}>
      {!(activeSection === NavSection.TEMPLATES) && (
        <>
          <NavSectionItem
            icon={<UpcomingIcon />}
            section={NavSection.MEETINGS}
            activeSection={activeSection}
            handleSetActiveSection={handleSetActiveSection}
            notes={notes}
          />
          <NavSectionItem
            icon={<AccountsIcon />}
            section={NavSection.ACCOUNTS}
            activeSection={activeSection}
            handleSetActiveSection={handleSetActiveSection}
            notes={notes}
          />
          <NavSectionItem
            icon={<DealsIcon />}
            section={NavSection.DEALS}
            activeSection={activeSection}
            handleSetActiveSection={handleSetActiveSection}
            notes={notes}
          />
          <NavSectionItem
            icon={<NotesIcon />}
            section={NavSection.MY_NOTES}
            activeSection={activeSection}
            handleSetActiveSection={handleSetActiveSection}
            notes={notes}
          />
        </>
      )}
      {activeSection === NavSection.TEMPLATES && (
        <Stack spacing={1}>
          <Button
            onClick={handleNavigateBack}
            color="inherit"
            startIcon={
              <BackArrowIcon
                style={{
                  transform: 'rotate(180deg)',
                  width: '24px',
                  height: '24px',
                }}
              />
            }
            sx={{
              justifyContent: 'flex-start',
              padding: '8px 8px 8px 12px',
              width: '100%',
              '.MuiButton-startIcon': {
                marginRight: '8px',
                marginLeft: '0px',
              },
            }}
          >
            <Typography variant="body2" color={theme.palette.grey[500]}>
              Back
            </Typography>
          </Button>
          <NavSectionItem
            icon={<TemplateIcon />}
            section={NavSection.TEMPLATES}
            activeSection={activeSection}
            handleSetActiveSection={handleSetActiveSection}
            notes={notes}
          />
        </Stack>
      )}
      <Divider style={{ margin: '20px 12px 0px' }} />
    </Stack>
  );
};

export default NavSectionItems;
