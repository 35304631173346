import { AI_ENABLED } from '../../../config';
import AICommand from './SlashCommands/AICommand';
import ActionCommand from './SlashCommands/ActionCommand';
import EmojiCommand from './SlashCommands/EmojiCommand';
import GongCommand from './SlashCommands/GongCommand';
import MentionCommand from './SlashCommands/MentionCommand';
import SalesforceCommand from './SlashCommands/SalesforceCommand';
import TableCommand from './SlashCommands/TableCommand';
import TemplateCommand from './SlashCommands/TemplateCommand';
import { Suggestions as SlashMenuSuggestions } from './Suggestion';

const COMMANDS = {
  salesforce: true,
  action: true,
  mention: true,
  emoji: true,
  template: true,
  gong: true,
  table: true,
  ai: true,
};

type CommandKeys = keyof typeof COMMANDS;
export type ICommands = {
  [key in CommandKeys]?: boolean;
};

type Props = {
  commands?: ICommands;
  setIsEditingOpenAI: (value: boolean) => void;
};

export function useSlashMenuSuggestions({ commands, setIsEditingOpenAI }: Props) {
  const activeCommands: ICommands = { ...COMMANDS, ...commands };

  return {
    slashMenu: SlashMenuSuggestions({
      commands: [
        ...(activeCommands.salesforce ? [SalesforceCommand()] : []),
        ...(activeCommands.ai && AI_ENABLED ? [AICommand({ setIsEditingOpenAI })] : []),
        ...(activeCommands.template ? [TemplateCommand()] : []),
        ...(activeCommands.gong ? [GongCommand()] : []),
        ...(activeCommands.action ? [ActionCommand()] : []),
        ...(activeCommands.table ? [TableCommand()] : []),
        ...(activeCommands.mention ? [MentionCommand()] : []),
        ...(activeCommands.emoji ? [EmojiCommand()] : []),
      ],
    }),
  };
}
