import React from 'react';
import { Helmet } from 'react-helmet-async';

import GongAuthCallback from 'src/sections/auth/GongAuthCallback';

export default function GongAuthPage() {
  return (
    <>
      <Helmet>
        <title>Context | Gong</title>
      </Helmet>

      <GongAuthCallback />
    </>
  );
}
