import { Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import NoteTemplates from '../../../../@types/api/noteTemplates';
import { ReactComponent as LockIcon } from '../../../../assets/icons/navIcons/lock.svg';
import { TEMPLATES_PATH } from '../../../../config';
import TemplateActionsNavItem from './TemplateActionsNavItem';
import { AccessLevel, AccessLevelValues } from 'src/@types/api/types/accessLevel';

const TemplatesNavSectionItem = ({ template }: { template: NoteTemplates }) => {
  const theme = useTheme();
  const { template_id } = useParams();

  const navigate = useNavigate();

  const [showActionIcon, setShowActionIcon] = useState(false);

  const activeTemplate = useMemo(() => template.id === template_id, [template_id, template.id]);

  const accessLevel = template?.accessLevel || AccessLevel.Read;

  const editable = AccessLevelValues[accessLevel] >= 200;

  const handleNavigateToTemplate = (templateId: string) => {
    navigate(`/${TEMPLATES_PATH}/${templateId}`);
  };

  return (
    <Stack
      onClick={() => handleNavigateToTemplate(template.id)}
      onMouseOver={() => setShowActionIcon(true)}
      onMouseLeave={() => setShowActionIcon(false)}
      direction="row"
      alignItems="center"
      sx={{
        height: '40px',
        padding: '8px 12px',
        marginBottom: 0.5,
        backgroundColor: activeTemplate ? alpha(theme.palette.grey[700], 0.3) : 'transparent',
        borderRadius: 1,
        textTransform: 'none',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: activeTemplate
            ? alpha(theme.palette.grey[700], 0.6)
            : alpha(theme.palette.grey[700], 0.3),
        },
      }}
    >
      {!editable && (
        <LockIcon
          style={{
            color: activeTemplate ? theme.palette.common.white : theme.palette.grey[700],
            marginRight: '8px',
            minWidth: '16px',
          }}
        />
      )}
      <Typography
        variant={activeTemplate ? 'subtitle2' : 'body2'}
        color={activeTemplate ? theme.palette.common.white : theme.palette.grey[500]}
        noWrap
      >
        {template.title || template.name}
      </Typography>
      {showActionIcon && (
        <TemplateActionsNavItem
          template={template}
          templateId={template.id}
          isActive={activeTemplate}
          isEditor={editable}
          hideButtons={() => setShowActionIcon(false)}
        />
      )}
    </Stack>
  );
};

export default TemplatesNavSectionItem;
