import { Box, Divider } from '@mui/material';
import { Editor } from '@tiptap/core';
import React, { ChangeEvent, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import RemoveLinkButton from '../buttons/RemoveLinkButton';
import { DelayAutoFocusInput } from './DelayAutoFocusInput';
import { useLink } from './useLink';

interface IEnterLinkProps {
  editor: Editor;
  setIsEditing: any;
}

const EnterLinkToolbar = ({ editor, setIsEditing }: IEnterLinkProps) => {
  const { href, setHref, submitHref, cancelHref } = useLink({ editor, setIsEditing });
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = () => {
    setIsEditing(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <Box
      boxSizing={'border-box'}
      display="flex"
      alignItems="center"
      ref={ref}
      onClick={handleClickOutside}
      sx={{ overflow: 'hidden', width: '260px', position: 'relative', padding: '0 8px 0 12px' }}
    >
      <DelayAutoFocusInput
        autoFocus
        placeholder="Enter url..."
        onChange={(event: ChangeEvent<HTMLInputElement>) => setHref(event.target.value)}
        value={href}
        setIsEditing={setIsEditing}
        onKeyDown={(event: any) => {
          const { code } = event;

          if (code === 'Enter' || code === 'NumpadEnter') {
            submitHref();
          }

          if (code === 'Escape') {
            cancelHref();
          }
        }}
        onClick={(event: any) => {
          event.stopPropagation();
        }}
      />
      <Divider orientation={'vertical'} sx={{ marginRight: '8px' }} flexItem />
      <RemoveLinkButton editor={editor} setIsEditing={setIsEditing} />
    </Box>
  );
};

export default EnterLinkToolbar;
