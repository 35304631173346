import { Checkbox, FormControlLabel, Stack, TextField, Typography, useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState } from 'react';

import { sortAlphabetically } from '../../../../../utils/sortAlphabetically';
import { INoteTreeNode } from '../DashboardNavNotesUtils';
import Deal from 'src/@types/core/deal';
import { Buttons } from 'src/components/modal/Buttons';
import ModalComponent from 'src/components/modal/Modal';

export interface IMoveUserNoteToDealNotesProps {
  open: boolean;
  handleCloseModal: any;
  handleClick: any;
  allDeals: Deal[];
  noteTree: INoteTreeNode[];
  thisNote: INoteTreeNode;
  noteTitle: string;
}

const MoveUserNoteToDealNotes = ({
  open,
  handleCloseModal,
  allDeals,
  noteTitle,
  handleClick,
}: IMoveUserNoteToDealNotesProps) => {
  const theme = useTheme();
  const [selectDeal, setSelectDeal] = React.useState<Deal | null>(null);
  const [inputDeal, setInputDeal] = useState('');
  const [openOnDeal, setOpenOnDeal] = useState(false);

  const handleChangeDeal = (event: any, deal: Deal | null) => {
    setSelectDeal(deal);
  };

  const handleChangeOpenonDeal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpenOnDeal(event.target.checked);
  };
  return (
    <ModalComponent
      open={open}
      handleClose={handleCloseModal}
      header={<Typography variant={'h6'}>Move {noteTitle} to Deal</Typography>}
      buttons={
        <>
          <Buttons
            activeButtonName={'Move'}
            handleCloseModal={handleCloseModal}
            handleClick={() => handleClick(selectDeal?.id, openOnDeal)}
            disabled={selectDeal === null}
            content={
              <FormControlLabel
                label="Open note on deal when done"
                control={
                  <Checkbox sx={{ marginRight: '10px' }} onChange={handleChangeOpenonDeal} />
                }
                sx={{ marginRight: 'auto !important' }}
              />
            }
          />
        </>
      }
    >
      <Stack sx={{ padding: '0 24px', width: '100%' }}>
        <Autocomplete
          size={'small'}
          value={selectDeal}
          onChange={(event, value) => handleChangeDeal(event, value)}
          inputValue={inputDeal}
          isOptionEqualToValue={(option, value) => option.id === value.id || value.id === ''}
          onInputChange={(event, newInputValue) => {
            setInputDeal(newInputValue);
          }}
          options={allDeals.sort(sortAlphabetically((deal) => deal.name))}
          getOptionLabel={(option) => option.name || ''}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          componentsProps={{
            popper: {
              sx: {
                zIndex: '9999',
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Select Deal"
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.grey[300]}!important`,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.grey[300]}!important`,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.grey[300]}!important`,
                },
              }}
            />
          )}
        />
      </Stack>
    </ModalComponent>
  );
};

export default MoveUserNoteToDealNotes;
