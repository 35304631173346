import { MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React from 'react';

import { NotesSource } from '../../../../../@types/api/notes';
import { ReactComponent as MoveArrowIcon } from '../../../../../assets/icons/navIcons/arrow_forward.svg';
import { useNotesMenuContext } from '../../../../../hooks/useNotesMenuContext';
import { useSetNoteId } from '../../../../../hooks/useQueries';
import { INoteTreeNode } from '../DashboardNavNotesUtils';
import MoveNotes from './MoveNotes';
import { useNotesContext } from 'src/hooks/useNotesContext';

interface Props {
  thisNote: INoteTreeNode;
  noteTree: INoteTreeNode[];
  handleClose?: () => void;
  isNotePage?: boolean;
}

const MoveNote = ({ thisNote, noteTree, handleClose, isNotePage }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { handleClose: handleCloseNote } = useNotesMenuContext();
  const moveNote = useSetNoteId(NotesSource.User, thisNote.id);
  const { refresh } = useNotesContext();
  const handleOpenModal = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleCloseModal = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleMoveNote = (value: INoteTreeNode) => {
    moveNote
      .mutateAsync({ parentNoteId: value.id ?? null })
      .then(async () => {
        enqueueSnackbar('Moved!', { variant: 'success' });
        await refresh(NotesSource.User);
      })
      .catch(() => enqueueSnackbar('Error moving note!', { variant: 'error' }))
      .finally(() => {
        setOpen(false);
        handleClose && handleClose();
        handleCloseNote && handleCloseNote();
      });
  };

  return (
    <>
      <MenuItem
        onClick={handleOpenModal}
        sx={{ color: isNotePage ? theme.palette.common.black : theme.palette.common.white }}
      >
        <MoveArrowIcon style={{ marginRight: '8px', transform: 'rotate(270deg)' }} />
        Reorder Note
      </MenuItem>
      <MoveNotes
        open={open}
        handleCloseModal={handleCloseModal}
        handleClick={handleMoveNote}
        noteTree={noteTree}
        thisNote={thisNote}
      />
    </>
  );
};

export default MoveNote;
