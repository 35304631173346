import { ReactNode, createContext, useCallback, useContext, useState } from 'react';

import NoteTemplates from 'src/@types/api/noteTemplates';
import { apiQuery } from 'src/utils/apiQuery';

// Define the shape of the note data
interface INoteTemplatesContext {
  noteTemplates: NoteTemplates[];
  isLoading: boolean;
  isError: boolean;
  error?: string | null;
  update: (newNoteTemplates: NoteTemplates[]) => void;
  refresh: () => Promise<void>;
}

// Create the context with a default value
const NoteTemplatesContext = createContext<INoteTemplatesContext | undefined>(undefined);

// Define the provider component
export const NoteTemplatesProvider = ({ children }: { children: ReactNode }) => {
  const [noteTemplates, setNoteTemplates] = useState<NoteTemplates[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const update = (newNoteTemplates: NoteTemplates[]) => {
    setNoteTemplates(newNoteTemplates);
  };

  // Function to refresh (fetch) notes
  const refresh = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    setError(null);
    try {
      const newNoteTemplates = await apiQuery<NoteTemplates[]>(`/notes/templates`);
      newNoteTemplates.sort(
        (a, b) =>
          (a.title ?? a.name)?.toLowerCase().localeCompare((b.title ?? b.name).toLowerCase()) ?? 0
      );
      setNoteTemplates(newNoteTemplates);
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch note templates:', error);
      setError(error.message);
      setIsError(true);
      setIsLoading(false);
    }
  }, []);

  return (
    <NoteTemplatesContext.Provider
      value={{ noteTemplates, update, refresh, isLoading, isError, error }}
    >
      {children}
    </NoteTemplatesContext.Provider>
  );
};

// Hook to use the context
export function useNoteTemplatesContext() {
  const context = useContext(NoteTemplatesContext);
  if (context === undefined) {
    throw new Error('useNoteTemplatesContext must be used within a NoteTemplatesProvider');
  }
  return context;
}
