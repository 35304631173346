import { Theme } from '@mui/material/styles';

import { ModifiedSearchResult, SearchType } from '../../../@types/api/search';
import { ReactComponent as DealIcon } from '../../../assets/icons/homeIcons/crown.svg';
import { ReactComponent as CompanyIcon } from '../../../assets/icons/navIcons/business.svg';
import { ReactComponent as MeetingIcon } from '../../../assets/icons/navIcons/ic_calendar.svg';

export const getSearchItemIcon = (option: ModifiedSearchResult, theme: Theme) => {
  const { type } = option;
  if (type === SearchType.COMPANIES) {
    return (
      <CompanyIcon
        style={{
          minWidth: '24px',
          marginRight: '8px',
          color: theme.palette.grey[500],
        }}
      />
    );
  }
  if (type === SearchType.DEALS) {
    return (
      <DealIcon
        style={{
          minWidth: '24px',
          marginRight: '8px',
          color: theme.palette.grey[500],
        }}
      />
    );
  }
  if (type === SearchType.MEETINGS) {
    return (
      <MeetingIcon
        style={{
          minWidth: '24px',
          marginRight: '8px',
          color: theme.palette.grey[500],
        }}
      />
    );
  }
};
