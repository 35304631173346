import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import salesforceReducer from './slices/salesforce';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  salesforce: salesforceReducer,
});

export { rootPersistConfig, rootReducer };
