import { Avatar, AvatarGroup, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';

import { useAuthContext } from '../../auth/useAuthContext';
import { CustomAvatar } from '../custom-avatar';
import { generateAccessRoleText, isSameUser } from '../share/ShareUtils';

const AvatarGroupComponent = ({
  users,
  setOpen,
}: {
  users: any[];
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  const { user: currentUser } = useAuthContext();

  const totalUsers = users.length;
  const otherUsers = totalUsers > 4 ? users.slice(3) : [];

  return (
    <AvatarGroup
      sx={{
        '.MuiAvatarGroup-avatar': {
          width: '24px',
          height: '24px',
          border: `3px solid ${theme.palette.common.white}`,
          color: theme.palette.common.white,
        },
      }}
    >
      {totalUsers <= 4
        ? users.slice(0, 4).map((user) => (
            <Tooltip
              key={user?.id ?? user?.userId}
              title={
                <Stack alignItems="center" key={user.userId}>
                  <Typography variant="caption">
                    {isSameUser(user, currentUser) ? `${user.user?.name} (You)` : user.user?.name}
                  </Typography>
                  <Typography variant="caption">
                    {generateAccessRoleText(user.accessLevel)}
                  </Typography>
                </Stack>
              }
            >
              <CustomAvatar
                name={user.user?.name}
                src={user.user?.image}
                style={{ border: `3px solid ${theme.palette.common.white}` }}
                sx={{
                  width: '24px',
                  height: '24px',
                  backgroundColor: '#826AF9',
                  color: theme.palette.common.white,
                }}
              />
            </Tooltip>
          ))
        : null}
      {totalUsers > 4
        ? users.slice(0, 3).map((user) => (
            <Tooltip
              key={user?.id ?? user.userId}
              title={
                <Stack alignItems="center" key={user.userId}>
                  {isSameUser(user, currentUser) ? `${user.user.name} (You)` : user.user.name}
                  <Typography variant="caption">
                    {generateAccessRoleText(user.accessLevel)}
                  </Typography>
                </Stack>
              }
            >
              <CustomAvatar
                name={user.user.name}
                src={user.user.image}
                style={{ border: `3px solid ${theme.palette.common.white}` }}
                sx={{
                  width: '24px',
                  height: '24px',
                  backgroundColor: '#826AF9',
                  color: theme.palette.common.white,
                }}
              />
            </Tooltip>
          ))
        : null}
      {totalUsers > 4 ? (
        <Tooltip
          title={
            <Stack alignItems="center">
              <Typography variant="caption">Also with access:</Typography>
              {otherUsers.slice(0, 3).map((user) => (
                <Typography
                  variant="caption"
                  key={user?.id ?? user?.userId}
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {user?.user?.name} <br />
                </Typography>
              ))}
              {totalUsers > 6 && (
                <Typography
                  variant="caption"
                  sx={{ backgroundColor: theme.palette.grey[500] }}
                >{`+${users.length - 6} more`}</Typography>
              )}
            </Stack>
          }
        >
          <Avatar
            onClick={() => setOpen && setOpen(true)}
            sx={{ backgroundColor: theme.palette.grey[500] }}
          >{`+${users.length - 3}`}</Avatar>
        </Tooltip>
      ) : null}
      {/*{users.map((user) => (*/}
      {/*  <Tooltip*/}
      {/*    key={user.id}*/}
      {/*    title={`${user.user.name}*/}
      {/*   ${user.user.email}*/}
      {/*    ${user.permission}`}*/}
      {/*  >*/}
      {/*    <CustomAvatar*/}
      {/*      name={user.user.name}*/}
      {/*      src={user.user.image}*/}
      {/*      style={{ border: `3px solid ${theme.palette.grey[900]}` }}*/}
      {/*      sx={{*/}
      {/*        width: '24px',*/}
      {/*        height: '24px',*/}
      {/*        backgroundColor: '#826AF9',*/}
      {/*        color: theme.palette.common.white,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </Tooltip>*/}
      {/*))}*/}
    </AvatarGroup>
  );
};

export default AvatarGroupComponent;
