import { Box, BoxProps } from '@mui/material';

import { NAV } from '../../config';
import useResponsive from 'src/hooks/useResponsive';

// const SPACING = 8;

interface MainProps {
  open: boolean;
}
export default function Main({ open, children, sx, ...other }: BoxProps & MainProps) {
  // const isNavHorizontal = themeLayout === 'horizontal';

  // const isNavMini = themeLayout === 'mini';
  //
  // const isDesktop = useResponsive('up', 'lg');
  const isDesktop = useResponsive('up', 1118);
  //
  // if (isNavHorizontal) {
  //   return (
  //     <Box
  //       component="main"
  //       sx={{
  //         // pt: `${HEADER.H_MOBILE + SPACING}px`,
  //         // pb: `${HEADER.H_MOBILE + SPACING}px`,
  //         // ...(isDesktop && {
  //         px: 2,
  //         pt: `${HEADER.H_DASHBOARD_DESKTOP + 80}px`,
  //         pb: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
  //         // }),
  //       }}
  //     >
  //       {children}
  //     </Box>
  //   );
  // }

  const mainWidth = () => {
    if (open) {
      if (!isDesktop) {
        return '100%';
      }
      return `calc(100% - ${NAV.W_DASHBOARD}px)`;
    }
    return 'calc(100% - 20px)';
  };
  return (
    <Box
      component="main"
      display={'flex'}
      sx={{
        // py: `${HEADER.H_MOBILE + SPACING}px`,
        // ...(isDesktop && {
        padding: 0,
        overflow: 'hidden',
        height: '100%',
        // pb: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
        width: mainWidth(),
        //   ...(isNavMini && {
        //     width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
        //   }),
        // }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
