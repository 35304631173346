import { Box, CircularProgress } from '@mui/material';
import { SxProps, useTheme } from '@mui/material/styles';

type LoadingScreenProps = {
  color?: string;
  sx?: SxProps;
  containerSx?: SxProps;
};

export default function LoadingScreen({ color, sx, containerSx }: LoadingScreenProps) {
  const theme = useTheme();
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      sx={{ display: 'flex', height: '100%', width: '100%', ...containerSx }}
    >
      <CircularProgress sx={{ color: color ? color : theme.palette.common.white, ...sx }} />
    </Box>
  );
}
