import DealUsers from '../../@types/api/dealUsers';
import NoteUsers from '../../@types/api/noteUsers';
import { AccessLevel } from '../../@types/api/types/accessLevel';
import { AuthUserType } from '../../auth/types';
import NoteTemplateUsers from 'src/@types/api/templateUsers';

export const getOrderedUsers = (users: any[], currentUser: AuthUserType) => {
  let orderedArray: any[];
  const currentUserInArr = users.find((user) => user.userId === currentUser?.id);
  const ownerInArr = users.find((user) => user.accessLevel === AccessLevel.Owner);
  orderedArray = Array.from(new Set([ownerInArr, currentUserInArr, ...users])).filter(
    (el) => el !== undefined
  );

  return orderedArray;
};

export const isSameUser = (user: any, currentUser: AuthUserType) => {
  if (user?.user?.id) {
    return user.user?.id === currentUser?.id;
  }
  return user?.userId === currentUser?.id;
};

export const getCurrentUser = (
  users: (NoteUsers | DealUsers | NoteTemplateUsers)[] | undefined,
  user: AuthUserType
) => users?.find((u) => u?.userId === user?.id);

export const isViewer = (user: NoteUsers | DealUsers | NoteTemplateUsers | undefined) =>
  user?.accessLevel === AccessLevel.Read;

export const isWriter = (user: NoteUsers | DealUsers | NoteTemplateUsers | undefined) =>
  user?.accessLevel === AccessLevel.Write;

export const isOwner = (user: NoteUsers | DealUsers | NoteTemplateUsers | undefined) =>
  user?.accessLevel === AccessLevel.Owner;

export const generateAccessRoleText = (accessLevel: AccessLevel) => {
  if (accessLevel === AccessLevel.Read) return 'Viewer';
  if (accessLevel === AccessLevel.Write) return 'Editor';
  return accessLevel.charAt(0).toUpperCase() + accessLevel.slice(1);
};
