import { Stack, Switch } from '@mui/material';
import React, { forwardRef } from 'react';

import { SalesforceInputComponentProps } from './SalesforceInputComponent';

const SwitchComponent = forwardRef(
  ({ node, disabled, handleChangeValue, initialValue }: SalesforceInputComponentProps, ref) => {
    const [checked, setChecked] = React.useState(initialValue === 'true');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newChecked = event.target.checked;
      setChecked(newChecked);
      handleChangeValue(newChecked, node.attrs.salesforceFieldName, true);
    };

    return (
      <Stack direction="row" alignItems="center">
        <Switch
          inputRef={ref}
          disabled={disabled}
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          sx={{ marginLeft: '-12px' }}
        />
      </Stack>
    );
  }
);

export default SwitchComponent;
