import { Tooltip } from '@mui/material';
import { Editor } from '@tiptap/core';
import React from 'react';

import { getShortcutString } from '../environment/keyboardUtils';

interface IButtonProps {
  editor: Editor | null;
  shortcut?: string;
  icon: React.ReactNode;
  tooltip: string;
  isActiveName: string;
  activeNameAttr?: {};
  clickCommand: (editor: Editor, event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isClassname?: boolean;
  expandMoreIcon?: React.ReactNode;
  text?: React.ReactNode;
  width?: string;
  className?: string;
}

const Button = ({
  editor,
  shortcut,
  icon,
  tooltip,
  isActiveName,
  clickCommand,
  activeNameAttr,
  isClassname = true,
  expandMoreIcon,
  text,
  width = '20px',
  className = 'button',
}: IButtonProps) => {
  if (!editor) return null;

  return (
    <Tooltip title={`${tooltip} ${getShortcutString(shortcut)}`} placement={'top'}>
      <button
        onClick={(event) => clickCommand(editor, event)}
        style={{ width: expandMoreIcon ? '28px' : width }}
        className={
          isClassname
            ? editor.isActive(isActiveName, activeNameAttr)
              ? 'button-is-active'
              : className
            : className
        }
      >
        {icon}
        {expandMoreIcon}
        {text}
      </button>
    </Tooltip>
  );
};

export default Button;
