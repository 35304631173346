import { Button, Tooltip, useTheme } from '@mui/material';
import React from 'react';

import { ReactComponent as AddIcon } from '../../assets/icons/navIcons/add.svg';

type CreateNoteProps = {
  title: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  handleClick: any;
};

const CreateNote = ({ title, placement = 'top', handleClick }: CreateNoteProps) => {
  const theme = useTheme();
  return (
    <Button color="inherit" style={{ minWidth: '24px', height: '24px', padding: '4px' }}>
      <Tooltip title={title} placement={placement}>
        <AddIcon onClick={handleClick} style={{ color: theme.palette.grey[400] }} />
      </Tooltip>
    </Button>
  );
};

export default CreateNote;
