import { Box } from '@mui/material';
import { Editor } from '@tiptap/core';

import { AI_ENABLED, LOCAL_STORAGE } from '../../config';
import BulletListButton from './buttons/BulletListButton';
import HeadingButton from './buttons/HeadingButton';
import OpenAIButton from './buttons/OpenAIButton';
import OrderedListButton from './buttons/OrderedListButton';
import TasklistButton from './buttons/TasklistButton';
import useResponsive from 'src/hooks/useResponsive';

const FloatingToolbar = ({
  editor,
  setIsEditingOpenAI,
}: {
  editor: Editor | null;
  setIsEditingOpenAI: (open: boolean) => void;
}) => {
  const open = JSON.parse(localStorage.getItem(LOCAL_STORAGE.LEFT_MENU) ?? 'false');
  const shouldShow = editor?.state?.selection?.empty;
  const isDesktop = useResponsive('up', 1118);
  if (!editor) return null;
  return (
    <div>
      {shouldShow && (
        <Box
          className="floating-menu"
          sx={{
            left: `calc(50% + ${open ? (isDesktop ? 140 : 0) : 0}px)`,
          }}
        >
          {AI_ENABLED && <OpenAIButton editor={editor} setIsEditingOpenAI={setIsEditingOpenAI} />}
          <HeadingButton editor={editor} level={1} />
          <HeadingButton editor={editor} level={2} />
          <OrderedListButton editor={editor} />
          <BulletListButton editor={editor} />
          <TasklistButton editor={editor} />
        </Box>
      )}
    </div>
  );
};

export default FloatingToolbar;
