import CalendarEvents from './calendarEvents';
import type Clients from './clients';
import type Deals from './deals';
import BaseEntity from './extends/baseEntity';
import GongCalls from './gongCalls';
import type MeetingAttendees from './meetingAttendees';
import type MeetingTypes from './meetingTypes';
import Notes from './notes';
import { PrimaryId } from './types/id';
import { MatchDetails, MatchMessage, MeetingsMeta } from './types/meetings';

export default class Meetings extends BaseEntity {
  clientId!: PrimaryId;

  meetingTypeId!: PrimaryId;

  dealId!: PrimaryId;

  public!: boolean;

  matchAttempts!: number;

  matchMessage?: MatchMessage;

  matchDetails!: MatchDetails;

  meta!: MeetingsMeta;

  gongId?: PrimaryId;

  // Relationships

  client!: Clients;

  deal!: Deals;

  gongCall?: GongCalls;

  meetingType!: MeetingTypes;

  calendarEvents!: CalendarEvents[];

  meetingAttendees?: MeetingAttendees[];

  notes?: Notes[];
}
