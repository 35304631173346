import { Divider } from '@mui/material';
import { Editor, isTextSelection } from '@tiptap/core';
import { BubbleMenu } from '@tiptap/react';
import { useState } from 'react';

import { AI_ENABLED } from '../../config';
import BoldButton from './buttons/BoldButton';
import BulletListButton from './buttons/BulletListButton';
import FontColorButton from './buttons/FontColorButton';
import HeadingButton from './buttons/HeadingButton';
import HighlightButton from './buttons/HighlightButton';
import ItalicButton from './buttons/ItalicButton';
import LinkButton from './buttons/LinkButton';
import OpenAIButton from './buttons/OpenAIButton';
import OrderedListButton from './buttons/OrderedListButton';
import StrikeButton from './buttons/StrikeButton';
import TasklistButton from './buttons/TasklistButton';
import UnderlineButton from './buttons/UnderlineButton';
import { isCellSelection } from './extensions/table/TipTapTableUtils';
import EnterLinkToolbar from './link-input/EnterLinkToolbar';
import './styles.scss';

const BubbleToolbar = ({
  editor,
  setIsEditingOpenAI,
}: {
  editor: Editor | null;
  setIsEditingOpenAI: (open: boolean) => void;
}) => {
  const [isEditingLink, setIsEditingLink] = useState(false);

  const shouldShow = ({ view, state, from, to }: { view: any; state: any; from: any; to: any }) => {
    const { doc, selection } = state;
    const { empty } = selection;
    const isChildOfMenu = view.dom.contains(document.activeElement);
    const hasEditorFocus = view.hasFocus() || isChildOfMenu;

    const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection);

    const isCellSel = isCellSelection(editor?.state.selection);

    const isTitle = editor!.isActive('title');

    const isSalesforceComponent = editor?.isActive('contextSalesforce');

    const isImage = editor?.isActive('image');
    return (
      hasEditorFocus &&
      editor!.isEditable &&
      !empty &&
      !isEmptyTextBlock &&
      !isCellSel &&
      !isTitle &&
      !isSalesforceComponent &&
      !isImage
    );
  };

  if (!editor) return null;

  return (
    <div>
      <BubbleMenu
        className={`bubble-menu-default ${isEditingLink && 'link-toolbar '}`}
        pluginKey={'bubbleMenuDefault'}
        tippyOptions={{
          duration: 100,
          maxWidth: 'fit-content',
          offset: [0, 32],
        }}
        editor={editor}
        shouldShow={shouldShow}
      >
        {!isEditingLink ? (
          <>
            {AI_ENABLED && (
              <>
                <OpenAIButton editor={editor} setIsEditingOpenAI={setIsEditingOpenAI} />
                <Divider flexItem orientation={'vertical'} sx={{ paddingY: 1 }} />
              </>
            )}
            <BoldButton editor={editor} />
            <ItalicButton editor={editor} />
            <UnderlineButton editor={editor} />
            <StrikeButton editor={editor} />
            <FontColorButton editor={editor} />
            <HighlightButton editor={editor} />
            <HeadingButton editor={editor} level={1} />
            <HeadingButton editor={editor} level={2} />
            <Divider flexItem orientation={'vertical'} sx={{ paddingY: 1 }} />
            <OrderedListButton editor={editor} />
            <BulletListButton editor={editor} />
            <TasklistButton editor={editor} />
            <Divider flexItem orientation={'vertical'} sx={{ paddingY: 1 }} />
            <LinkButton editor={editor} setIsEditing={setIsEditingLink} />
          </>
        ) : (
          <EnterLinkToolbar editor={editor} setIsEditing={setIsEditingLink} />
        )}
      </BubbleMenu>
    </div>
  );
};

export default BubbleToolbar;
