import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Editor } from '@tiptap/core';

import { ReactComponent as OpenAIIcon } from '../../../assets/icons/tiptapIcons/bublemenu/openAI.svg';
import '../styles.scss';
import Button from './Button';
import { scrollIntoView } from 'src/utils/scrollIntoView';

const OpenAIButton = ({
  editor,
  setIsEditingOpenAI,
}: {
  editor: Editor;
  setIsEditingOpenAI: (open: boolean) => void;
}) => {
  const theme = useTheme();

  const handleClick = () => {
    setIsEditingOpenAI(true);
    setTimeout(() => {
      const el = document.getElementById('inputAI');
      el?.focus();
      scrollIntoView('inputAI', 'center', 100, 'smooth');
    }, 50);
    editor?.chain().focus().run();
  };

  return (
    <Button
      editor={editor}
      icon={<OpenAIIcon style={{ color: theme.palette.secondary.light }} />}
      tooltip={'Ask AI to edit, review, or generate text'}
      isActiveName={'navigate'}
      clickCommand={handleClick}
      width="40px"
      className="open-ai-button"
      text={
        <Typography
          sx={{
            marginLeft: '5px',
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '24px',
            color: theme.palette.secondary.light,
          }}
        >
          AI
        </Typography>
      }
    />
  );
};

export default OpenAIButton;
