export const environment = {
  get isBrowser(): boolean {
    return !!(
      typeof window !== 'undefined' &&
      typeof window.document !== 'undefined' &&
      window.navigator &&
      window.navigator.userAgent
    );
  },

  get isMac(): boolean {
    return environment.isBrowser && /Mac|iPod|iPhone|iPad/.test(navigator.platform);
  },

  // get isJSDOM(): boolean {
  //   return environment.isBrowser && window.navigator.userAgent.includes('jsdom');
  // },

  // get isNode(): boolean {
  //   return (
  //     typeof process !== 'undefined' && process.versions != null && process.versions.node != null
  //   );
  // },

  // get isIos(): boolean {
  //   return environment.isBrowser && /iPod|iPhone|iPad/.test(navigator.platform);
  // },

  // get isApple(): boolean {
  //   return environment.isNode
  //     ? process.platform === 'darwin'
  //     : environment.isBrowser
  //     ? /Mac|iPod|iPhone|iPad/.test(window.navigator.platform)
  //     : false;
  // },

  // get isDevelopment(): boolean {
  //   return process.env.NODE_ENV === 'development';
  // },
  //
  // get isTest(): boolean {
  //   return process.env.NODE_ENV === 'test';
  // },
  //
  // get isProduction(): boolean {
  //   return process.env.NODE_ENV === 'production';
  // },
};
