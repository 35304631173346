import { Box, Card, Link, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import Deal from '../../../../@types/core/deal';
import { ReactComponent as LinkedInIcon } from '../../../../assets/icons/dealIcons/linkedin.svg';
import { getPersonRole } from './AttendeesUtils';
import People from 'src/@types/api/people';
import Users from 'src/@types/api/users';
import { CustomAvatar } from 'src/components/custom-avatar';
import Label from 'src/components/label';

interface AttendeeCardProps {
  meetingAttendee: People | Users;
  handleOpenModal?: () => void;
  editable: boolean;
  sx?: SxProps;
  numberOfMeetings?: number;
  deal?: Deal;
}

const AttendeeCard = ({
  meetingAttendee,
  handleOpenModal,
  editable,
  sx,
  numberOfMeetings,
  deal,
}: AttendeeCardProps) => {
  const theme = useTheme();
  // const { enqueueSnackbar } = useSnackbar();
  // const { deal_id } = useParams();

  // get the person in case this meeting attendee is an external person
  // const person = meetingAttendee as Person;

  // const personNote = getPersonNote(person);

  // const isNoteEmpty = getIsNoteEmpty(personNote);

  // TODO: remove manual attendee functionality

  // const removeAttendee = useRemoveAttendee(meetingAttendee.meetingId, meetingAttendee.id, deal_id);

  // const handleRemoveAttendee = () => {
  //   removeAttendee
  //     .mutateAsync({
  //       meetingAttendeeId: meetingAttendee.id,
  //       meetingId: meetingAttendee.meetingId,
  //     })
  //     .then(() => {
  //       enqueueSnackbar('Deleted!', { variant: 'success' });
  //     })
  //     .catch(() => enqueueSnackbar('Not deleted!', { variant: 'error' }));
  // };

  const meetingAttendeeRole = getPersonRole(meetingAttendee, deal!);

  const src = meetingAttendee instanceof People ? meetingAttendee?.photoUrl : meetingAttendee.image;
  return (
    <>
      <Card
        sx={{
          borderRadius: '10px',
          boxShadow: theme.customShadows.card,
          width: 'fit-content',
          boxSizing: 'border-box',
          cursor: 'default',
          height: '62px',
          ...sx,
        }}
      >
        <Box display="flex" sx={{ padding: '8px', alignItems: 'center', height: '100%' }}>
          <CustomAvatar
            src={src ? src : meetingAttendee.linkedIn}
            name={meetingAttendee.name ?? meetingAttendee.email}
            sx={{
              fontSize: '16px',
              width: '40px',
              height: '40px',
              backgroundColor: theme.palette.secondary.dark,
              color: theme.palette.common.white,
            }}
          />
          <Box sx={{ paddingLeft: '8px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '22px',
                  color: theme.palette.text.primary,
                }}
              >
                {meetingAttendee?.name || meetingAttendee?.email}
              </Typography>

              {meetingAttendee.linkedIn && (
                <Link
                  href={meetingAttendee?.linkedIn}
                  target="_blank"
                  sx={{ height: '18px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <LinkedInIcon
                    color={'primary'}
                    style={{
                      width: '14px',
                      height: '14px',
                      marginLeft: '8px',
                    }}
                  />
                </Link>
              )}
              {meetingAttendeeRole && (
                <Label
                  variant={'soft'}
                  color={meetingAttendeeRole === 'Unknown' ? 'default' : 'primary'}
                  sx={{
                    marginLeft: '8px',
                    minWidth: 'fit-content',
                    alignSelf: 'flex-start',
                    // cursor: 'pointer',
                  }}
                >
                  {meetingAttendeeRole}
                </Label>
              )}
            </Box>
            {meetingAttendee?.title && (
              <Box>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {meetingAttendee?.title}
                </Typography>
              </Box>
            )}
          </Box>
          {/* <AttendeeCardHeader
            meetingAttendee={meetingAttendee}
            numberOfMeetings={numberOfMeetings}
            deal={deal}
          />
          {person && (
            <>
              {!isNoteEmpty && (
                <Box
                  sx={{
                    marginTop: 1,
                    maxHeight: '398px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    'div p': {
                      margin: '0!important',
                    },
                  }}
                >
                  <TiptapEditor
                    editable={false}
                    dealId={deal_id!}
                    customClassName={'tiptap-editor-card'}
                    noteId={personNote.id}
                  />
                </Box>
              )}
              {meetingAttendee instanceof People && (
                <Stack
                  display={'flex'}
                  direction={'row'}
                  spacing={1.5}
                  sx={{ height: '36px', marginTop: 2.5 }}
                >
                  {meetingAttendee.isManual() && (
                  <Button
                    color={'inherit'}
                    size="medium"
                    variant="contained"
                    fullWidth
                    onClick={handleRemoveAttendee}
                    sx={{ fontSize: '14px' }}
                  >
                    Remove attendee
                  </Button>
                )}
                  {editable && (
                    <Button
                      variant="outlined"
                      color={'inherit'}
                      size="medium"
                      fullWidth
                      onClick={handleOpenModal}
                      sx={{ fontSize: '14px' }}
                    >
                      {'Open Note'}
                    </Button>
                  )}
                </Stack>
              )}
            </>
          )} */}
        </Box>
      </Card>
    </>
  );
};

export default AttendeeCard;
