import { IconifyIcon } from '@iconify/react';
import { styled } from '@mui/material/styles';

import Iconify from './Iconify';

export type IconifyProps = IconifyIcon | string;
export const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 0,
  flexShrink: 0,
  marginRight: theme.spacing(0),
}));
