import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { TemplateType } from '../../../../@types/api/noteTemplates';
import { useAuthContext } from '../../../../auth/useAuthContext';
import CreateNote from '../../../../components/create-note/CreateNote';
import Scrollbar from '../../../../components/scrollbar';
import { PLACEHOLDERS, TEMPLATES_PATH } from '../../../../config';
import { useCreateTemplate } from '../../../../hooks/useQueries';
import TemplatesNavSectionItem from './TemplatesNavSectionItem';
import { useNoteTemplatesContext } from 'src/hooks/useNoteTemplatesContext';

const TemplatesNavSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const { noteTemplates: templates, refresh } = useNoteTemplatesContext();

  const createNote = useCreateTemplate();
  const groupedTemplates = [
    {
      group: 'Account Templates',
      templates: [...templates.filter((template) => template.type === TemplateType.Client)],
    },
    {
      group: 'My Templates',
      templates: [...templates.filter((template) => template.type === TemplateType.User)],
    },
  ];
  const handleCreateTemplate = () => {
    const isOwner =
      templates.find((template) => template.type === TemplateType.Client)?.userId === user?.id;
    createNote
      .mutateAsync({
        name: PLACEHOLDERS.TITLE_TEMPLATE,
        value: '',
        type: isOwner ? TemplateType.Client : TemplateType.User,
      })
      .then((res) => {
        navigate(`/${TEMPLATES_PATH}/${res.id}`);
        enqueueSnackbar('Template created!', { variant: 'success' });
        refresh();
      })
      .catch(() => enqueueSnackbar('Error creating template!', { variant: 'error' }));
  };

  return (
    <Stack sx={{ height: '100%', paddingBottom: '280px' }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: '8px 36px',
          marginBottom: 0.5,
          position: 'sticky',
          top: 0,
        }}
      >
        <Typography
          variant="overline"
          sx={{
            fontSize: '12px',
            fontWeight: 700,
            color: theme.palette.grey[500],
            textTransform: 'uppercase',
          }}
        >
          Templates
        </Typography>
        <CreateNote title={'Create Template'} handleClick={handleCreateTemplate} />
      </Stack>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            position: 'relative',
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Stack sx={{ padding: '0 24px' }}>
          {groupedTemplates?.map(({ group, templates: groupTemplates }) => (
            <Stack key={group}>
              {groupTemplates.length > 0 && (
                <Typography
                  variant="overline"
                  color={theme.palette.common.white}
                  marginLeft={1.5}
                  marginBottom={1}
                  marginTop={1}
                >
                  {group}
                </Typography>
              )}
              {groupTemplates.map((template) => (
                <TemplatesNavSectionItem key={template.id} template={template} />
              ))}
            </Stack>
          ))}
        </Stack>
      </Scrollbar>
    </Stack>
  );
};

export default TemplatesNavSection;
