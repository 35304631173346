import { Editor } from '@tiptap/core';
import { useCallback, useMemo, useState } from 'react';

export const useLink = ({ editor, setIsEditing }: { editor: Editor | null; setIsEditing: any }) => {
  const previousUrl = editor?.getAttributes('link').href;
  const [href, setHref] = useState<string>(previousUrl ?? '');

  const navigateHref = useCallback(() => {
    window.open(href, '_blank');

    return true;
  }, [href]);

  const submitHref = useCallback(() => {
    setIsEditing(false);

    if (href === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    } else {
      if (href.includes('https://')) {
        editor?.chain().focus().extendMarkRange('link').setLink({ href }).run();
        setHref(href);
      } else {
        editor
          ?.chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: `https://${href}` })
          .run();
        setHref(`https://${href}`);
      }
    }
  }, [setIsEditing, href, editor]);

  const removeLink = useCallback(() => {
    editor?.chain().focus().unsetLink().run();
    setHref('');
    setIsEditing(false);
  }, [setIsEditing, editor]);

  const cancelHref = useCallback(() => {
    editor?.chain().focus().run();
    setIsEditing(false);
  }, [editor, setIsEditing]);

  return useMemo(
    () => ({
      href,
      setHref,
      submitHref,
      cancelHref,
      navigateHref,
      removeLink,
    }),
    [href, setHref, navigateHref, removeLink, submitHref, cancelHref]
  );
};
