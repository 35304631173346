import { GlobalStyles as MUIGlobalStyles, useTheme } from '@mui/material';

import backgroundImage from '../assets/backgroundImage.jpg';

export default function GlobalStyles() {
  const theme = useTheme();
  return (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        '&::-webkit-scrollbar': {
          position: 'absolute',
          left: '2px',
          width: '10px',
          height: '10px',
          display: 'block',
        },
        '&::-webkit-scrollbar-corner': {
          display: 'none',
        },
        '::-webkit-scrollbar-track': {
          opacity: 0,
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.grey[600],
          border: '2px solid transparent',
          borderRadius: '15px',
          backgroundClip: 'content-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          scrollBehavior: 'smooth',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.grey[900],
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${backgroundImage})`,
          backgroundAttachment: 'fixed',
          minHeight: '100%',
          //create bugs with sticky position of our sidebars on deal page
          // overflowX: 'hidden',
          // '& > div': {
          //   height: '100vh!important',
          // },
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
      }}
    />
  );
}
