import React from 'react';

import { ReactComponent as TableIcon } from '../../../../assets/icons/tiptapIcons/slashmenu/tableIcon.svg';
import { SLASH_MENU, SlashCommandType } from '../types';

export type TemplateAttributesType = {
  templateValue: any;
};

const TableCommand = () => {
  const tableCommand = {
    name: SLASH_MENU.TABLE,
    value: [],
    icon: <TableIcon />,
    subtitle: 'Insert Table',
    command: ({ editor, range, props }: SlashCommandType<TemplateAttributesType>) => {
      editor?.commands.deleteRange({ from: range.from, to: range.to + 1 });
      editor?.chain().insertTable({ rows: 2, cols: 3, withHeaderRow: true }).focus().run();
    },
  };

  return { ...tableCommand };
};

export default TableCommand;
