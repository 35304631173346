import EnvironmentOverlay from './components/environment-overlay/EnvironmentOverlay';
import Router from './routes';

export default function App() {
  return (
    <EnvironmentOverlay>
      <Router />
    </EnvironmentOverlay>
  );
}
