import { ReactComponent as GongIcon } from '../../../../assets/icons/tiptapIcons/slashmenu/gongIcon.svg';
import { GongAttributesType } from '../../extensions/GongNode';
import { SLASH_MENU, SlashCommandType } from '../types';

const GongCommand = () => {
  const gongCommand = {
    name: SLASH_MENU.GONG,
    value: [],
    icon: <GongIcon />,
    subtitle: 'Insert a Gong call recording',
    command: ({ editor, range, props }: SlashCommandType<GongAttributesType>) => {
      editor
        ?.chain()
        .focus()
        .insertContentAt({ from: range.from, to: range.to + 1 }, [
          {
            type: 'contextGong',
            attrs: {
              id: props.attrs.id,
              name: props.attrs.name,
              callId: props.attrs.callId,
              start: props.attrs.start,
              end: props.attrs.end,
            },
          },
          { type: 'text', text: ' ' },
        ])
        .run();
    },
  };
  return { ...gongCommand };
};

export default GongCommand;
