import { Stack, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { NotesSource } from '../../../../@types/api/notes';
import { ReactComponent as AddIcon } from '../../../../assets/icons/navIcons/add.svg';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/navIcons/ic_dots.svg';
import { useAuthContext } from '../../../../auth/useAuthContext';
import MenuPopover from '../../../../components/menu-popover';
import NotesMenuGroupedActions from '../../../../components/notes-menu/NotesMenuGroupedActions';
import { isViewer } from '../../../../components/share/ShareUtils';
import { EMPTY_NOTE_TITLE_PARAGRAPH_BINARY } from '../../../../components/tip-tap-editor/TipTapEditorUtils';
import { NOTES_PATH } from '../../../../config';
import { useCreateNote } from '../../../../hooks/useQueries';
import { INoteTreeNode } from './DashboardNavNotesUtils';
import { useNotesContext } from 'src/hooks/useNotesContext';

export const NoteActionsNavItem = ({
  thisNote,
  noteTree,
  activeColor,
  handleHideActiveButtons,
}: {
  thisNote: INoteTreeNode;
  noteTree: INoteTreeNode[];
  activeColor: string;
  handleHideActiveButtons: () => void;
}) => {
  const theme = useTheme();
  const { user } = useAuthContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const currentUser = thisNote.noteUsers?.find((u) => u.userId === user?.id);
  const createNote = useCreateNote(NotesSource.User, user?.id);
  const { refresh } = useNotesContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleHideActiveButtons();
  };

  const handleAddChildNote = () => {
    createNote
      .mutateAsync({
        valueBinary: EMPTY_NOTE_TITLE_PARAGRAPH_BINARY,
        parentNoteId: thisNote.id,
      })
      .then(async (res) => {
        await refresh(NotesSource.User);
        navigate(`/${NOTES_PATH}/${res.id}`);
        enqueueSnackbar('Note created!', { variant: 'success' });
      })
      .catch(() => enqueueSnackbar('Error creating note!', { variant: 'error' }));
  };

  return (
    <Stack direction="row" sx={{ marginLeft: 'auto' }}>
      <Typography
        style={{
          height: '24px',
          width: '24px',
          padding: '4px',
        }}
        sx={{
          '&:hover': { borderRadius: '8px', backgroundColor: theme.palette.grey[700] },
        }}
        onClick={handleClick}
      >
        <Tooltip title={'More Actions'} placement={'top'}>
          <DotsIcon style={{ color: activeColor }} />
        </Tooltip>
      </Typography>
      {!isViewer(currentUser) && (
        <Typography
          style={{
            height: '24px',
            width: '24px',
            padding: '4px',
          }}
          sx={{
            '&:hover': { borderRadius: '8px', backgroundColor: theme.palette.grey[700] },
          }}
          onClick={handleAddChildNote}
        >
          <Tooltip title={'Create child note'} placement={'top'}>
            <AddIcon style={{ color: activeColor, verticalAlign: 'top' }} />
          </Tooltip>
        </Typography>
      )}
      <MenuPopover
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          handleClose();
        }}
        disabledArrow
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 1.5,
          color: theme.palette.common.white,
          backgroundColor: theme.palette.grey[900],
          boxShadow: theme.customShadows.dropdownDark,
        }}
      >
        <NotesMenuGroupedActions note={thisNote} noteTree={noteTree} handleClose={handleClose} />
      </MenuPopover>
    </Stack>
  );
};
