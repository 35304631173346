import _, { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';

import SalesforceOpportunityStages from '../../../@types/api/salesforceOpportunityStages';
import { AuthUserType } from '../../../auth/types';
import { IFilterCheckboxes } from '../../../components/nav-section';
import { LOCAL_STORAGE } from '../../../config';
import useLocalStorage from '../../../hooks/useLocalStorage';

export function useFilters({
  data,
  stages,
  user,
  filter,
  paths,
}: {
  data?: any[];
  stages?: SalesforceOpportunityStages[];
  user?: AuthUserType;
  filter: string;
  paths?: string[];
}) {
  const [filteredData, setFilteredData] = useState(data);

  const defaultValues = {
    [LOCAL_STORAGE.DEALS_FILTERS]: {
      Stage: stages?.filter((item) => item.IsClosed !== 'true').map((item) => item.ApiName),
      'Deal Owner': [user?.name],
    },
    [LOCAL_STORAGE.PEOPLE_FILTERS]: {
      Name: [],
      Role: [],
    },
  };
  const DEFAULT_FILTER_VALUE = localStorage.getItem(filter) ?? defaultValues[filter];

  const [localStorageValue, setLocalStorageValue] = useLocalStorage(filter, DEFAULT_FILTER_VALUE);
  const [localDataFilters, setLocalDataFilters] = useState<IFilterCheckboxes>(localStorageValue);

  const handleCheckbox = (event: any, stage: string) => {
    const chosenTarget = event.target.name ? event.target.name : event.target.textContent;
    setLocalDataFilters((prev: IFilterCheckboxes) => {
      if (prev[stage]?.includes(chosenTarget)) {
        const filteredArray = prev[stage].filter((item) => item !== chosenTarget);
        return { ...prev, [stage]: filteredArray };
      }

      if (prev[stage]) {
        prev[stage] = [chosenTarget, ...prev[stage]];
        return { ...prev };
      }

      prev[stage] = [chosenTarget];
      return { ...prev };
    });
  };

  const handleSelectAll = (filtersName: string[], stage: string, isSelectedAll: boolean) => {
    if (isSelectedAll) {
      setLocalDataFilters((prev: IFilterCheckboxes) => {
        prev[stage] = [];
        return { ...prev };
      });
      return;
    }

    setLocalDataFilters((prev: IFilterCheckboxes) => {
      prev[stage] = [...filtersName];
      return { ...prev };
    });
  };

  const filterData = () => {
    const duplicatedData = cloneDeep(data);

    if (Object.values(localDataFilters).every((item) => item.length === 0)) {
      // all filters empty, return all deals
      setFilteredData(duplicatedData);
      return;
    }

    const filteredData = duplicatedData?.filter((item) =>
      Object.values(localDataFilters).reduce((acc, curr, idx) => {
        let res = !curr.length;
        curr.forEach((filter) => {
          const filterValue = filter === 'None' ? null : filter;
          if (_.get(item, paths![idx]) === filterValue) {
            res = true;
          }
        });

        return acc && res;
      }, true)
    );
    setFilteredData(filteredData);
  };

  const filterByStages = () => {
    const duplicatedData = structuredClone(data);
    const allDeals = duplicatedData?.find((deal) => deal.subheader === 'Deals');

    if (Object.values(localDataFilters).every((item) => item.length === 0)) {
      // all filters empty, return all deals
      setFilteredData(duplicatedData);
      return;
    }

    if (allDeals) {
      const tempFilteredArray = allDeals?.items.filter((deal: { attributes: { value: any }[] }) =>
        Object.values(localDataFilters)?.reduce((acc: any, currentFilters) => {
          let res = !currentFilters.length;
          currentFilters.forEach((filter: any) => {
            deal.attributes?.forEach((attr: { value: any }) => {
              if (attr.value === filter) {
                res = true;
              }
            });
          });
          return acc && res;
        }, true)
      );

      setFilteredData(() =>
        duplicatedData?.map((deal) => {
          if (deal.subheader === 'Deals') {
            deal.items = tempFilteredArray;
            return deal;
          }
          return deal;
        })
      );
    }
  };

  useEffect(() => {
    setLocalStorageValue(localDataFilters);
    if (filter === LOCAL_STORAGE.DEALS_FILTERS) {
      return filterByStages();
    }
    filterData();

    // eslint-disable-next-line
  }, [localDataFilters, stages, data]);

  return {
    handleSelectAll,
    handleCheckbox,
    filteredData,
    localDataFilters,
  };
}
