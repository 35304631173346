import {
  Alert,
  Box,
  Button,
  ClickAwayListener,
  Stack,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { Editor } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import { type NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import { ReactComponent as CopyIcon } from '../../../assets/icons/copyIcon.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/navIcons/add.svg';
import { ReactComponent as RegenerateIcon } from '../../../assets/icons/regenerateIcon.svg';
import { ReactComponent as OpenAiIcon } from '../../../assets/icons/tiptapIcons/bublemenu/openAI.svg';
import { getValueBinary, insertNodeAtEnd } from '../TipTapEditorUtils';
import { DEFAULT_EXTENSIONS } from '../TiptapEditor';
import { convertAndSanitize, getHtmlResult } from '../openai-menu/OpenAIMenuUtils';
import { NotesSource } from 'src/@types/api/notes';
import { useAuthContext } from 'src/auth/useAuthContext';
import Label from 'src/components/label';
import LoadingScreen from 'src/components/loading-screen/LoadingScreen';
import { DEALS_PATH, NOTES_PATH } from 'src/config';
import { useDealContext } from 'src/hooks/useDealContext';
import { useCreateNote } from 'src/hooks/useQueries';

const AIResponseNodeView = ({ node, editor, updateAttributes }: NodeViewProps) => {
  const theme = useTheme();
  const { user } = useAuthContext();
  const { deal } = useDealContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const notesSaver = useCreateNote(NotesSource.Deal, deal!.id);

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const handleNavigate = () => {
    navigate(`/${DEALS_PATH}/${deal!.id}/${NOTES_PATH}/${node.attrs.noteId}`);
  };
  const parentNote = deal?.notes
    ?.filter((note) => note.source === NotesSource.Deal)
    .find((note) => note.parentNoteId === null);

  const createNote = (id: string, value?: any) => {
    notesSaver
      .mutateAsync({ valueBinary: getValueBinary(value), parentNoteId: id, value })
      .then((res) => {
        updateAttributes({ noteId: res.id });
      })
      .catch(() => enqueueSnackbar('Error creating note!', { variant: 'error' }));
  };

  const handleCreateNote = (value?: any) => {
    if (!parentNote) {
      notesSaver
        .mutateAsync({ valueBinary: getValueBinary(value), value })
        .then((res) => {
          createNote(res.id, value);
          updateAttributes({ noteId: res.id });
        })
        .catch(() => enqueueSnackbar('Error creating note!', { variant: 'error' }));
      return;
    }
    return createNote(parentNote?.id, value);
  };

  interface Button {
    name: string;
    icon: JSX.Element;
    onClick: () => void;
    disabled?: boolean;
  }

  const copyToClip = (str: string) => {
    function listener(e: any) {
      e.clipboardData.setData('text/html', str);
      e.clipboardData.setData('text/plain', str);
      e.preventDefault();
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  };

  const BUTTONS: Button[] = [
    {
      name: 'Add to Notes',
      icon: <AddIcon style={{ marginRight: '6px' }} />,
      onClick: () => {
        const value = getHtmlResult(convertAndSanitize(node?.attrs?.text));
        const title = getHtmlResult(convertAndSanitize(node.attrs.text.substring(0, 30)));
        const editor = new Editor({
          extensions: [
            ...DEFAULT_EXTENSIONS,
            Document.extend({
              content: 'title block+',
            }),
          ],
          content: `${title}  ${value}`,
        });
        handleCreateNote(editor.getJSON());
      },
      disabled: !!node.attrs.noteId || !node.attrs.text,
    },
    {
      name: 'Copy',
      icon: (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleTooltipClose}
        >
          <Tooltip
            placement={'top'}
            onClose={handleTooltipClose}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied to clipboard"
          >
            <CopyIcon style={{ cursor: 'pointer', marginRight: '6px' }} />
          </Tooltip>
        </ClickAwayListener>
      ),
      onClick: () => {
        handleTooltipOpen();
        const value = getHtmlResult(convertAndSanitize(node?.attrs?.text));
        const editor = new Editor({
          extensions: [
            ...DEFAULT_EXTENSIONS,
            Document.extend({
              content: 'block+',
            }),
          ],
          content: `${value}`,
        });

        copyToClip(editor.getHTML());
      },
      disabled: !node.attrs.text,
    },
    ...(node.attrs.isLast
      ? [
          {
            name: 'Regenerate',
            icon: <RegenerateIcon style={{ marginRight: '6px' }} />,
            onClick: () => {
              const { attrs } = editor.state.doc.content.child(editor.state.doc.childCount - 2);
              const { text, promptText, promptInstructions } = attrs;

              const newAIMessage = editor.schema.nodes.AIRequest.create({
                userId: user?.id,
                timestamp: new Date().getTime(),
                text,
                promptText,
                promptInstructions,
              });
              insertNodeAtEnd(editor, newAIMessage);
            },
          },
        ]
      : []),
  ];
  const infoItems = node.attrs.loadingInfo.split(',');
  return (
    <NodeViewWrapper
      as="div"
      style={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Box sx={{ width: 1 }}>
        <Box
          sx={{
            display: 'flex',
            marginBottom: '10px',
            width: '100%',
          }}
        >
          <Box sx={{ marginRight: '12px' }}>
            <Box
              sx={{
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                backgroundColor: alpha(theme.palette.secondary.light, 0.12),
                padding: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <OpenAiIcon
                style={{
                  width: '16px',
                  height: '16px',
                  color: theme.palette.secondary.light,
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{ fontSize: '15px', fontWeight: '700', lineHeight: '26px', userSelect: 'none' }}
            >
              Context AI
            </Typography>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
              }}
            >
              {node.attrs.text || !node.attrs.isLoadingResponse || !node.attrs.isLast ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: getHtmlResult(convertAndSanitize(node.attrs.text)),
                  }}
                />
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                  <Box>
                    {infoItems.map((line: string, index: number) => (
                      <Stack direction={'row'} alignItems={'center'} key={index}>
                        {index === infoItems.length - 1 && (
                          <LoadingScreen
                            color={theme.palette.secondary.main}
                            sx={{
                              height: '18px !important',
                              width: '18px !important',
                              marginRight: '8px',
                            }}
                            containerSx={{
                              width: 'fit-content',
                            }}
                          />
                        )}
                        <Typography
                          variant={'subtitle2'}
                          sx={{
                            color: theme.palette.secondary.main,
                          }}
                        >
                          {line}
                        </Typography>
                      </Stack>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            justifyContent: 'flex-start',
            paddingLeft: '36px',
            width: '100%',
            marginBottom: '32px',
          }}
        >
          {node.attrs.isLoadingResponse === false &&
            BUTTONS.map(({ icon, disabled, name, onClick }) => (
              <Label
                key={name}
                sx={{
                  fontSize: '12px',
                  color: disabled ? theme.palette.grey[600] : theme.palette.secondary.dark,
                  backgroundColor: disabled
                    ? theme.palette.grey[300]
                    : alpha(theme.palette.secondary.main, 0.16),
                  pointerEvents: disabled ? 'none' : 'auto',
                  cursor: disabled ? 'default' : 'pointer',
                  '&:hover': {
                    backgroundColor: disabled
                      ? theme.palette.grey[300]
                      : alpha(theme.palette.secondary.main, 0.24),
                  },
                  userSelect: 'none',
                }}
                {...(!disabled && { onClick })}
              >
                {icon}
                {name}
              </Label>
            ))}
        </Box>
        {node.attrs.noteId && (
          <Alert
            variant="outlined"
            severity="success"
            color="success"
            action={
              <Button variant="contained" color="success" onClick={handleNavigate} size="small">
                View Note
              </Button>
            }
            sx={{
              marginBottom: '25px',
              marginLeft: '36px',
              display: 'flex',
              alignItems: 'center',
              '& .MuiAlert-action': {
                paddingTop: '0',
              },
              backgroundColor: alpha(theme.palette.success.main, 0.08),
              borderColor: alpha(theme.palette.success.main, 0.16),
              userSelect: 'none',
            }}
          >
            Response added to new deal note
          </Alert>
        )}
      </Box>
    </NodeViewWrapper>
  );
};

export default AIResponseNodeView;
