import { Box, Divider, Stack, SxProps } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import Iconify from '../iconify';
import Status from '../status/Status';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 480,
  width: 'fit-content',
  outline: 'none',
  bgcolor: 'background.paper',
  borderRadius: 2,
};

type ModalProps = {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  header: React.ReactNode;
  customHeader?: boolean;
  buttons?: React.ReactNode;
  tabs?: React.ReactNode;
  status?: boolean;
  disablePortal?: boolean;
  sx?: SxProps;
  dividers?: boolean;
  titleSx?: SxProps;
};

const ModalComponent = ({
  open,
  handleClose,
  header,
  customHeader,
  children,
  buttons,
  tabs,
  status,
  disablePortal = false,
  sx,
  dividers = true,
  titleSx,
}: ModalProps) => {
  const theme = useTheme();
  return (
    <Modal
      open={open}
      onClick={(event) => event.stopPropagation()}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disablePortal={disablePortal}
      disableRestoreFocus
      sx={{ zIndex: '5000' }}
    >
      <Box sx={{ ...style, ...sx }}>
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          sx={{ padding: '24px 24px 0', ...titleSx }}
        >
          {header}
          <Iconify
            onClick={handleClose}
            icon={'ic:round-close'}
            color={theme.palette.grey[600]}
            sx={{ cursor: 'pointer', minWidth: '20px' }}
          />
        </Stack>
        {tabs}
        {(customHeader || buttons) && dividers && (
          <Divider sx={{ marginTop: tabs ? 0 : 3, marginBottom: tabs ? 0 : 3 }} />
        )}
        <Stack alignItems={'flex-start'}>{children}</Stack>
        {buttons && dividers && <Divider sx={{ marginTop: tabs ? 0 : 3 }} />}
        {buttons}
        {status && (
          <Status sx={{ position: 'absolute', right: '24px', bottom: '16px' }} editable={true} />
        )}
      </Box>
    </Modal>
  );
};

export default ModalComponent;
