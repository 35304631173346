import { createContext, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import People from '../@types/api/people';
import Users from '../@types/api/users';
import Deal from '../@types/core/deal';
import { useGetDeal, useGetDealPeople, useGetDealUsers } from './useQueries';
import Notes from 'src/@types/api/notes';

export enum DealTimelineTypes {
  meetings = 'meetings',
  people = 'people',
  deal = 'deal',
  company = 'company',
  dealNote = 'dealNote',
}

interface IDealContext {
  deal?: Deal;
  people: People[];
  users: Users[];
  allPeople?: (People | Users)[];
  dealIsLoading: boolean;
  dealIsRefetching: boolean;
  locationState?: any;
  dealIsFetching: boolean;
  peopleIsLoading: boolean;
  usersIsLoading: boolean;
  notes: Notes[];
  setNotes: (notes: Notes[]) => void;
  refetchDeal: () => void;
  refetchPeople: () => void;
}

const DealContext = createContext<IDealContext>({
  deal: {} as Deal,
  dealIsLoading: true,
  dealIsRefetching: false,
  dealIsFetching: true,

  allPeople: [],
  people: [],
  peopleIsLoading: true,

  users: [],
  usersIsLoading: true,

  notes: [],

  setNotes: (notes: Notes[]) => {},
  refetchDeal: () => {},
  refetchPeople: () => {},
});

export function useDealContext() {
  return useContext(DealContext);
}

export const useDealContextState = () => {
  const { deal_id } = useParams();
  const {
    data: dealPeople,
    isLoading: isLoadingPeople,
    refetch: refetchPeople,
  } = useGetDealPeople(deal_id!);
  const { data: dealUsers, isLoading: isLoadingUsers } = useGetDealUsers(deal_id!);
  const [notes, setNotes] = useState<Notes[]>([]);

  const {
    data: dealData,
    isLoading: dealIsLoading,
    isError: dealIsError,
    error: dealError,
    isRefetching: dealIsRefetching,
    isFetching: dealIsFetching,
    refetch: refetchDeal,
  } = useGetDeal(deal_id!);

  const deal = useMemo(() => {
    if (dealData) {
      setNotes(dealData.notes?.map((n) => new Notes(n)) || []);
      return new Deal(dealData);
    }
  }, [dealData]);

  const people = useMemo(
    () => (dealPeople ? dealPeople.map((el) => new People(el)) : []),
    [dealPeople]
  );

  const users = useMemo(() => (dealUsers ? dealUsers.map((el) => new Users(el)) : []), [dealUsers]);

  const allPeople = useMemo(() => (people && users ? [...people, ...users] : []), [people, users]);

  return {
    allPeople,
    deal,
    dealIsLoading,
    dealIsRefetching,
    dealIsFetching,
    dealIsError,
    dealError,
    people,
    isLoadingPeople,
    users,
    isLoadingUsers,
    notes,
    setNotes,
    refetchDeal,
    refetchPeople,
  };
};

export default DealContext;
