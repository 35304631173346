import { Box, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import React, { useCallback, useState } from 'react';

import { ReactComponent as PersonIcon } from '../../assets/icons/ic_person.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/tiptapIcons/cross.svg';
import { sortByNameAndEmail } from '../../utils/sortByNameAndEmail';
import { IconButtonAnimate } from '../animate';
import { CustomAvatar } from '../custom-avatar';
import MenuPopover from '../menu-popover';
import People from 'src/@types/api/people';
import Users from 'src/@types/api/users';

export interface IAvatarPopoverProps {
  selectedPerson: People | Users | null;
  setSelectedPerson: (person: People | Users | null) => void;
  onSelected: (option: any) => void;
  onRemoveAttendee?: (assignee: Users | People) => void;
  people: (People | Users)[];
  disabled?: boolean;
}

export default function AvatarPopover({
  selectedPerson,
  setSelectedPerson,
  onSelected,
  onRemoveAttendee,
  people,
  disabled,
}: IAvatarPopoverProps) {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const theme = useTheme();

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = useCallback(() => {
    setOpenPopover(null);
  }, [setOpenPopover]);

  const handleSelectPerson = useCallback(
    (person: Users | People) => {
      onSelected(person);
      setSelectedPerson(person);
      handleClosePopover();
    },
    [onSelected, setSelectedPerson, handleClosePopover]
  );

  const handleRemoveAttendee = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    person: Users | People
  ) => {
    event.stopPropagation();
    if (onRemoveAttendee) {
      onRemoveAttendee(person);
    }
    setSelectedPerson(null);
    handleClosePopover();
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{
          width: '16px',
          height: '16px',
          p: 0,
          opacity: disabled ? '0.5' : '1',
        }}
      >
        <Tooltip
          title={selectedPerson ? 'Change Assigned Person' : 'Assign Person'}
          placement={'top'}
          PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [0, 0] } }] }}
        >
          {selectedPerson ? (
            <Box>
              <CustomAvatar
                src={selectedPerson?.image}
                sx={{ height: 20, width: 20, fontSize: '12px', fontWeight: '700' }}
                name={selectedPerson?.email}
              />
            </Box>
          ) : (
            <PersonIcon
              style={{
                color: openPopover ? theme.palette.primary.main : theme.palette.grey[500],
                marginTop: '4px',
              }}
            />
          )}
        </Tooltip>
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        disabledArrow
        PaperProps={{
          sx: {
            marginTop: 1,
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            padding: 1.25,
            maxHeight: '190px',
            overflow: 'auto',
            zIndex: '9000',
          },
        }}
        sxRoot={{ zIndex: '5000' }}
        sx={{ width: 'fit-contents' }}
      >
        <Stack spacing={0.5}>
          {people.length > 0 ? (
            people.sort(sortByNameAndEmail((person) => person)).map((person) => (
              <MenuItem
                key={person.id}
                selected={person.id === selectedPerson?.id}
                onClick={(event) => {
                  event.stopPropagation();
                  handleSelectPerson(person);
                }}
                style={{ padding: '5px 8px' }}
                sx={{
                  minWidth: '142px',
                  borderRadius: 0.75,
                  '&.Mui-selected': {
                    color: theme.palette.primary.dark,
                    backgroundColor: alpha(theme.palette.success.lighter, 0.4),
                  },
                }}
              >
                <Typography variant={'body2'} style={{ fontSize: '14px' }}>
                  {person.name ?? person.email}
                </Typography>
                {person.id === selectedPerson?.id && (
                  <CrossIcon
                    onClick={(event) => handleRemoveAttendee(event, person)}
                    style={{
                      marginLeft: 'auto',
                    }}
                  />
                )}
              </MenuItem>
            ))
          ) : (
            <Typography variant="body2" style={{ fontSize: '14px' }} sx={{ padding: '8px' }}>
              No results
            </Typography>
          )}
        </Stack>
      </MenuPopover>
    </>
  );
}
