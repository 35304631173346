import { WritableDraft, setAutoFreeze } from 'immer';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import { ReconnectableWebSocket, WebSocketOptions } from '../../utils/websocket';

setAutoFreeze(false);

export interface WebSocketState {
  sockets: Record<string, ReconnectableWebSocket>;
  initialize: (
    path: string | (() => string) | (() => Promise<string>),
    key: string,
    options?: WebSocketOptions
  ) => void;
  disconnect: (key: string) => void;
}

export const useWebsocket = create<WebSocketState>()(
  immer((set) => ({
    sockets: {} as Record<string, ReconnectableWebSocket>,
    disconnect(key: string) {
      set((state: WritableDraft<WebSocketState>) => {
        if (state.sockets[key]) {
          state.sockets[key].close();
          delete state.sockets[key];
        }
      });
    },
    initialize(
      path: string | (() => string) | (() => Promise<string>),
      key: string,
      options?: WebSocketOptions
    ) {
      // Check if a socket for the given path already exists
      set((state: WritableDraft<WebSocketState>) => {
        if (!state.sockets[key]) {
          // If it doesn't exist, create a new WebSocket connection
          const ws = new ReconnectableWebSocket(path, options);

          // Store the new WebSocket connection in the state
          state.sockets[key] = ws;
        }
      });

      // Now, return the WebSocket (existing or new)
      set((state: WritableDraft<WebSocketState>) => state.sockets[key]);
    },
    // handleError() {},
  }))
);
