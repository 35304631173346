import { findParentNode } from '@tiptap/core';
import { Node } from 'prosemirror-model';
import { Selection, Transaction } from 'prosemirror-state';
import { CellSelection, TableMap } from 'prosemirror-tables';
import { EditorView } from 'prosemirror-view';

export const isRectSelected = (rect: any) => (selection: CellSelection) => {
  const map = TableMap.get(selection.$anchorCell.node(-1));
  const start = selection.$anchorCell.start(-1);
  const cells = map.cellsInRect(rect);
  const selectedCells = map.cellsInRect(
    map.rectBetween(selection.$anchorCell.pos - start, selection.$headCell.pos - start)
  );

  for (let i = 0, count = cells.length; i < count; i++) {
    if (selectedCells.indexOf(cells[i]) === -1) {
      return false;
    }
  }

  return true;
};

export const findTable = (selection: Selection) =>
  findParentNode(
    (node) => node.type.spec.tableRole && node.type.spec.tableRole === 'table'
    // @ts-ignore
  )(selection);

export const isCellSelection = (selection: any) => selection instanceof CellSelection;

export const isColumnSelected = (columnIndex: number) => (selection: any) => {
  if (isCellSelection(selection)) {
    const map = TableMap.get(selection.$anchorCell.node(-1));
    return isRectSelected({
      left: columnIndex,
      right: columnIndex + 1,
      top: 0,
      bottom: map.height,
    })(selection);
  }

  return false;
};

export const isRowSelected = (rowIndex: number) => (selection: any) => {
  if (isCellSelection(selection)) {
    const map = TableMap.get(selection.$anchorCell.node(-1));
    return isRectSelected({
      left: 0,
      right: map.width,
      top: rowIndex,
      bottom: rowIndex + 1,
    })(selection);
  }

  return false;
};

export const isTableSelected = (selection: any) => {
  if (isCellSelection(selection)) {
    const map = TableMap.get(selection.$anchorCell.node(-1));
    return isRectSelected({
      left: 0,
      right: map.width,
      top: 0,
      bottom: map.height,
    })(selection);
  }

  return false;
};

export const getCellsInColumn = (columnIndex: number | number[]) => (selection: Selection) => {
  const table = findTable(selection);
  if (table) {
    const map = TableMap.get(table.node);
    const indexes = Array.isArray(columnIndex) ? columnIndex : Array.from([columnIndex]);
    return indexes.reduce(
      (acc, index) => {
        if (index >= 0 && index <= map.width - 1) {
          const cells = map.cellsInRect({
            left: index,
            right: index + 1,
            top: 0,
            bottom: map.height,
          });
          return acc.concat(
            cells.map((nodePos) => {
              const node = table.node.nodeAt(nodePos);
              const pos = nodePos + table.start;
              return { pos, start: pos + 1, node };
            })
          );
        }
        return acc;
      },
      [] as { pos: number; start: number; node: Node | null | undefined }[]
    );
  }
};

export const getCellsInRow = (rowIndex: number | number[]) => (selection: Selection) => {
  const table = findTable(selection);
  if (table) {
    const map = TableMap.get(table.node);
    const indexes = Array.isArray(rowIndex) ? rowIndex : Array.from([rowIndex]);
    return indexes.reduce(
      (acc, index) => {
        if (index >= 0 && index <= map.height - 1) {
          const cells = map.cellsInRect({
            left: 0,
            right: map.width,
            top: index,
            bottom: index + 1,
          });
          return acc.concat(
            cells.map((nodePos) => {
              const node = table.node.nodeAt(nodePos);
              const pos = nodePos + table.start;
              return { pos, start: pos + 1, node };
            })
          );
        }
        return acc;
      },
      [] as { pos: number; start: number; node: Node | null | undefined }[]
    );
  }
};

export const getCellsInTable = (selection: Selection) => {
  const table = findTable(selection);
  if (table) {
    const map = TableMap.get(table.node);
    const cells = map.cellsInRect({
      left: 0,
      right: map.width,
      top: 0,
      bottom: map.height,
    });
    return cells.map((nodePos) => {
      const node = table.node.nodeAt(nodePos);
      const pos = nodePos + table.start;
      return { pos, start: pos + 1, node };
    });
  }
};

// export const findParentNodeClosestToPos = (
//   $pos: ResolvedPos,
//   predicate: (node: Node) => boolean
// ) => {
//   for (let i = $pos.depth; i > 0; i--) {
//     const node = $pos.node(i);
//     if (predicate(node)) {
//       return {
//         pos: i > 0 ? $pos.before(i) : 0,
//         start: $pos.start(i),
//         depth: i,
//         node,
//       };
//     }
//   }
// };

// export const findCellClosestToPos = ($pos: ResolvedPos) => {
//   const predicate = (node: Node) =>
//     node.type.spec.tableRole && /cell/i.test(node.type.spec.tableRole);
//   return findParentNodeClosestToPos($pos, predicate);
// };

const select = (type: 'row' | 'column') => (index: number) => (tr: Transaction) => {
  const table = findTable(tr.selection);
  const isRowSelection = type === 'row';
  if (table) {
    const map = TableMap.get(table.node);

    // toggle selection
    // if (isRowSelection && isRowSelected(index)(tr.selection)) {
    //   const $anchor = tr.doc.resolve(0);
    //   const $head = tr.doc.resolve(0);
    //   return tr.setSelection(new CellSelection($anchor, $head));
    // }
    //
    // if (!isRowSelection && isColumnSelected(index)(tr.selection)) {
    //   const $anchor = tr.doc.resolve(0);
    //   const $head = tr.doc.resolve(0);
    //   return tr.setSelection(new CellSelection($anchor, $head));
    // }

    // Check if the index is valid
    if (index >= 0 && index < (isRowSelection ? map.height : map.width)) {
      const left = isRowSelection ? 0 : index;
      const top = isRowSelection ? index : 0;
      const right = isRowSelection ? map.width : index + 1;
      const bottom = isRowSelection ? index + 1 : map.height;

      const cellsInFirstRow = map.cellsInRect({
        left,
        top,
        right: isRowSelection ? right : left + 1,
        bottom: isRowSelection ? top + 1 : bottom,
      });

      const cellsInLastRow =
        bottom - top === 1
          ? cellsInFirstRow
          : map.cellsInRect({
              left: isRowSelection ? left : right - 1,
              top: isRowSelection ? bottom - 1 : top,
              right,
              bottom,
            });

      const head = table.start + cellsInFirstRow[0];
      const anchor = table.start + cellsInLastRow[cellsInLastRow.length - 1];
      const $head = tr.doc.resolve(head);
      const $anchor = tr.doc.resolve(anchor);

      // @ts-ignore
      return tr.setSelection(new CellSelection($anchor, $head));
    }
  }
  return tr;
};

export const selectColumn = select('column');

export const selectRow = select('row');

export const selectTable = (tr: Transaction) => {
  const table = findTable(tr.selection);
  if (table) {
    const { map } = TableMap.get(table.node);
    if (map && map.length) {
      const head = table.start + map[0];
      const anchor = table.start + map[map.length - 1];
      const $head = tr.doc.resolve(head);
      const $anchor = tr.doc.resolve(anchor);

      // @ts-ignore
      return tr.setSelection(new CellSelection($anchor, $head));
    }
  }
  return tr;
};

// @ts-ignore
function dropPoint(doc, pos, slice) {
  const $pos = doc.resolve(pos);
  if (!slice.content.size) {
    return pos;
  }
  let { content } = slice;
  for (let i = 0; i < slice.openStart; i++) {
    // eslint-disable-next-line prefer-destructuring
    content = content.firstChild.content;
  }
  for (let pass = 1; pass <= (slice.openStart === 0 && slice.size ? 2 : 1); pass++) {
    for (let d = $pos.depth; d >= 0; d--) {
      const bias =
        d === $pos.depth ? 0 : $pos.pos <= ($pos.start(d + 1) + $pos.end(d + 1)) / 2 ? -1 : 1;
      const insertPos = $pos.index(d) + (bias > 0 ? 1 : 0);
      const parent = $pos.node(d);
      let fits = false;
      if (pass === 1) {
        fits = parent.canReplace(insertPos, insertPos, content);
      } else {
        const wrapping = parent.contentMatchAt(insertPos).findWrapping(content.firstChild.type);
        fits = wrapping && parent.canReplaceWith(insertPos, insertPos, wrapping[0]);
      }
      if (fits) {
        return bias === 0 ? $pos.pos : bias < 0 ? $pos.before(d + 1) : $pos.after(d + 1);
      }
    }
  }
  return null;
}

export const removePossibleTable = (view: EditorView, event: DragEvent): Transaction | null => {
  const { state } = view;

  const $pos = state.selection.$anchor;
  for (let d = $pos.depth; d > 0; d--) {
    const node = $pos.node(d);
    if (node.type.spec.tableRole === 'table') {
      const eventPos = view.posAtCoords({
        left: event.clientX,
        top: event.clientY,
      });
      if (!eventPos) return null;
      const slice = view.dragging?.slice;
      if (!slice) return null;

      const $mouse = view.state.doc.resolve(eventPos.pos);
      const insertPos = dropPoint(view.state.doc, $mouse.pos, slice);
      if (!insertPos) return null;

      let { tr } = state;
      tr = tr.delete($pos.before(d), $pos.after(d));

      const pos = tr.mapping.map(insertPos);

      tr = tr.replaceRange(pos, pos, slice).scrollIntoView();

      return tr;
    }
  }

  return null;
};

export default function isTableNode(node: Node): boolean {
  const name = node instanceof Node ? node.type.name : null;
  return name === 'table' || name === 'tableRow' || name === 'tableHeader' || name === 'tableCell';
}
