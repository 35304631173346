import React from 'react';

import { ReactComponent as EmojiIcon } from '../../../../assets/icons/tiptapIcons/slashmenu/emojiIcon.svg';
import { SLASH_MENU, SlashCommandType } from '../types';

const EmojiCommand = () => {
  const emojiCommand = {
    name: SLASH_MENU.EMOJI,
    value: [],
    icon: <EmojiIcon />,
    subtitle: 'Use emojis to express ideas',
    command: ({ editor, range }: SlashCommandType<null>) => {
      editor
        ?.chain()
        .focus()
        .insertContentAt({ from: range.from, to: range.to + 1 }, [
          {
            type: 'text',
            text: ':',
          },
        ])
        .run();
    },
  };

  return { ...emojiCommand };
};

export default EmojiCommand;
