import { Box, Typography, useTheme } from '@mui/material';

import { ReactComponent as OpenAIIcon } from '../../../assets/icons/tiptapIcons/bublemenu/openAI.svg';
import Iconify from 'src/components/iconify';

const InputStartAdornment = ({
  isLoading,
  startAdormentPlaceholder,
  loadingSpinner,
}: {
  isLoading: boolean;
  startAdormentPlaceholder: string;
  loadingSpinner: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        minWidth: isLoading && startAdormentPlaceholder ? '137px' : 'fit-content',
        width: 'fit-content',
        alignItems: 'center',
      }}
    >
      <OpenAIIcon
        style={{
          marginRight: '5px',
          color: theme.palette.secondary.light,
        }}
      />
      {isLoading && (
        <>
          {startAdormentPlaceholder && (
            <Typography
              color={theme.palette.secondary.light}
              sx={{ lineHeight: '22px', fontSize: '14px' }}
            >
              {startAdormentPlaceholder}
            </Typography>
          )}
          {loadingSpinner && (
            <Iconify
              icon={'material-symbols:cached'}
              width={16}
              height={16}
              sx={{
                color: theme.palette.secondary.light,
                marginLeft: '3px',
                animation: 'spin 2s linear infinite',
                '@keyframes spin': {
                  '0%': { transform: 'rotate(0deg)' },
                  '100%': { transform: 'rotate(360deg)' },
                },
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default InputStartAdornment;
