import { Editor, getHTMLFromFragment } from '@tiptap/core';
import DOMPurify from 'dompurify';
import { marked } from 'marked';

export const SUMMARIZE = `Summarise the following text:`;
export const FIX_SPELLING_AND_GRAMMAR = `Fix the spelling and grammar for the following text:`;

export const isWhitespaceString = (str: string) => !str.replace(/\s/g, '').length;

export const removeCitation = (input: string): string => input.replace(/【.*?】/g, '');

export const convertAndSanitize = (markdownText: string): string => {
  const noCitations = removeCitation(markdownText);
  const rawHtml = marked.parse(noCitations);
  return DOMPurify.sanitize(rawHtml as string);
};

export function getHtmlResult(content: string): string {
  let html = marked.parse(content) as string;
  html = html.replace(/<h3>/g, '<h1>');
  html = html.replace(/<\/h3>/g, '</h1>');
  html = html.replace(/<h4>/g, '<h2>');
  html = html.replace(/<\/h4>/g, '</h2>');
  return html;
}

export const getPrompt = ({
  editor,
  specialPrompt,
  inputValue,
  isEmptySelection,
  prevPrompt,
}: {
  editor: Editor;
  specialPrompt?: string;
  inputValue?: string;
  isEmptySelection: boolean;
  prevPrompt?: string;
}) => {
  const { state } = editor;
  const { from, to } = state.selection;
  const selectedContent = editor.state.doc.slice(from, to);
  const formattingSelectedContent = ` ${getHTMLFromFragment(
    selectedContent.content,
    editor.schema
  )}`;
  const isEmptyString = isWhitespaceString(formattingSelectedContent);
  const request = specialPrompt ? specialPrompt : inputValue;
  const fullRequest =
    !isEmptySelection && !isEmptyString ? request + formattingSelectedContent : request;
  return prevPrompt ? prevPrompt : fullRequest;
};
