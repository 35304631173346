import { MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CopyIcon } from '../../../../../assets/icons/dealIcons/copy.svg';
import { useDuplicateTemplate } from '../../../../../hooks/useQueries';
import { useNoteTemplatesContext } from 'src/hooks/useNoteTemplatesContext';

const DuplicateTemplate = ({
  templateId,
  handleClose,
}: {
  templateId: string;
  handleClose: () => void;
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { refresh } = useNoteTemplatesContext();

  const duplicateTemplate = useDuplicateTemplate(templateId);

  const handleDuplicateTemplate = (event: any) => {
    event.stopPropagation();
    duplicateTemplate
      .mutateAsync()
      .then((res) => {
        navigate(`/t/${res.id}`);
        enqueueSnackbar('Template Duplicated!', { variant: 'success' });
        refresh();
      })
      .catch(() => enqueueSnackbar('Error Duplicating Template!', { variant: 'error' }))
      .finally(() => handleClose());
  };

  return (
    <MenuItem onClick={handleDuplicateTemplate} sx={{ color: theme.palette.common.white }}>
      <CopyIcon style={{ marginRight: '8px' }} />
      Duplicate
    </MenuItem>
  );
};

export default DuplicateTemplate;
