import { MenuItem, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';

import { ReactComponent as ExportNotes } from '../../../../assets/icons/export.svg';
import { useNotesMenuContext } from '../../../../hooks/useNotesMenuContext';
import { useSnackbar } from 'src/components/snackbar';
import { ACCOUNTS_PATH, DEALS_PATH, NOTES_PATH } from 'src/config';
import { useGeneratePdfFromNote } from 'src/hooks/useQueries';

const ExportElement = ({ noteId }: { noteId?: string }): React.JSX.Element => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const generatePdf = useGeneratePdfFromNote();
  const location = useLocation();
  const { meeting_id } = useParams();
  const pageType = location.pathname[1];

  const title = useMemo(() => {
    if (meeting_id) return 'Deal Meeting Note';
    if (pageType === DEALS_PATH) return 'Deal Note';
    if (pageType === NOTES_PATH) return 'My Note';
    if (pageType === ACCOUNTS_PATH) return 'Account Note';
    return 'Note';
  }, [pageType, meeting_id]);

  const { setOpenMenu } = useNotesMenuContext();
  const handleCopyToClipboard = async () => {
    const html = getFullHTMLWithStyles(title, noteId, meeting_id);
    generatePdf
      .mutateAsync({ html })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'context_exported_document.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setOpenMenu(null);
        enqueueSnackbar('Note exported to PDF', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Failed to convert', { variant: 'error' });
      });
  };

  return (
    <MenuItem onClick={handleCopyToClipboard}>
      <ExportNotes
        style={{ cursor: 'pointer', marginRight: '8px', color: theme.palette.grey[500] }}
      />
      <Typography variant="body2" sx={{ color: theme.palette.grey[800] }}>
        Export to PDF
      </Typography>
    </MenuItem>
  );
};

export default ExportElement;

function getFullHTMLWithStyles(defaultTitle: string, noteId?: string, meeting_id?: string) {
  const htmlContent = meeting_id
    ? document.querySelector(`#meeting-info`)
    : document.querySelector(`#tiptap-editor-${noteId}`);
  const title = htmlContent?.querySelector('.title-extension')?.textContent;
  const cloneElement = document.createElement('div');
  cloneElement.innerHTML = htmlContent?.outerHTML || '';
  cloneElement.querySelector('#meeting-notes-menu')?.remove();
  cloneElement.querySelector('.floating-menu')?.remove();
  const styles = Array.from(document.styleSheets)
    .map((styleSheet) => {
      try {
        return Array.from(styleSheet.cssRules)
          .map((rule) => rule.cssText)
          .join('');
      } catch (e) {
        return ''; // Handle CORS errors if any
      }
    })
    .join('');

  return `
    <html>
      <head>
        <title>${title ? title : defaultTitle}</title>
        <style>
          ${styles}
        </style>
      </head>
      <body>${cloneElement?.outerHTML}</body>
    </html>
  `;
}
