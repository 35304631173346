import { Editor } from '@tiptap/core';
import React from 'react';

import { ReactComponent as ItalicIcon } from '../../../assets/icons/tiptapIcons/italic.svg';
import Button from './Button';

const ItalicButton = ({ editor }: { editor: Editor }) => {
  const handleToggle = (editor: Editor) => {
    editor.chain().focus().toggleItalic().run();
  };

  return (
    <Button
      editor={editor}
      shortcut={'mod-I'}
      icon={<ItalicIcon />}
      tooltip={'Italic'}
      isActiveName={'italic'}
      clickCommand={handleToggle}
    />
  );
};

export default ItalicButton;
