import { ReactComponent as SalesforceIcon } from '../../../../assets/icons/tiptapIcons/slashmenu/salesforceIcon.svg';
import { ISlashNodeInterface } from '../../extensions/ContextSalesforceExtension';
import { SLASH_MENU, SlashCommandType } from '../types';

const SalesforceCommand = () => {
  const salesforceCommand = {
    name: SLASH_MENU.SALESFORCE,
    value: [],
    icon: <SalesforceIcon />,
    subtitle: 'Add and update Salesforce data',
    command: ({ editor, range, props }: SlashCommandType<ISlashNodeInterface['attrs']>) => {
      editor
        ?.chain()
        .focus()
        .insertContentAt(
          { from: range.from - 1, to: range.to },
          [
            {
              type: 'contextSalesforce',
              attrs: {
                salesforceFieldLabel: props.attrs.salesforceFieldLabel,
                salesforceFieldName: props.attrs.salesforceFieldName,
                salesforceKey: props.attrs.salesforceKey,
                salesforceType: props.attrs.salesforceType,
                calculatedFormula: props.attrs.calculatedFormula,
                picklistValues: props.attrs.picklistValues,
                compoundFieldName: props.attrs.compoundFieldName,
                isCompoundParent: props.attrs.isCompoundParent,
                updateable: props.attrs.updateable,
                type: props.attrs.type,
              },
            },
            { type: 'paragraph' },
          ],
          {
            parseOptions: {
              preserveWhitespace: 'full',
            },
          }
        )
        .run();
    },
  };
  return { ...salesforceCommand };
};

export default SalesforceCommand;
