import { Box, Checkbox, Popper, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import React from 'react';

import { ReactComponent as DotIcon } from '../../../assets/icons/navIcons/ic_dot.svg';
import Iconify from '../../iconify';
import { IFiltersState } from './FiltersAndGroups';
import {
  checkIsFilterItemSelected,
  checkIsFilterNameHasSelected,
  checkIsSelectedAll,
} from './NavSectionVerticalUtils';
import { StyledMenuItem } from './styles';

interface ISecondLevelMenuItem {
  filterItem: { name: string; values: string[] };
  handleCheckbox: (event: any, value: string) => void;
  localDataFilters: any;
  openSecondLevel: IFiltersState;
  handleOpenSecondLevel: (event: any, filterName: string, filterValues: string[]) => void;
  filterValues: string[];
  anchorEl: HTMLElement | null;
  handleSelectAll: (filtersName: string[], stage: string, isSelectedAll: boolean) => void;
  filterPage: string;
  styles?: any;
  selected: string;
  //deals: Deal[];
  //values: string[];
}

const SecondLevelMenuItem = ({
  filterItem,
  handleCheckbox,
  handleSelectAll,
  localDataFilters,
  //deals,
  filterValues,
  openSecondLevel,
  handleOpenSecondLevel,
  anchorEl,
  filterPage,
  styles,
  selected,
}: //values,
ISecondLevelMenuItem) => {
  const theme = useTheme();

  const isSelectedFilterName = checkIsFilterNameHasSelected(filterItem.name, filterPage);

  const isSelectedAll = checkIsSelectedAll(filterItem.name, filterValues.length, filterPage);
  const defaultStyle = {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 1.25,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[900],
    boxShadow: theme.customShadows.dropdownDark,
    width: '275px',
    maxHeight: '396px',
    padding: '8px 5px 8px 8px',
    zIndex: '10000',
  };
  const style = { ...defaultStyle, ...styles };

  return (
    <>
      <StyledMenuItem
        key={filterItem.name}
        onMouseEnter={(event) => {
          handleOpenSecondLevel(event, filterItem.name, filterItem.values);
        }}
        selected={isSelectedFilterName && selected === 'background'}
        sx={{
          padding: '4px 4px 4px 8px !important',
          marginBottom: '4px',
          '&:last-of-type': {
            marginBottom: '0px',
          },
        }}
      >
        <Typography
          variant="body2"
          sx={{
            marginRight: 1,
            position: 'relative',
            fontWeight: isSelectedFilterName ? 600 : 400,
          }}
        >
          {filterItem.name}
          {isSelectedFilterName && selected === 'dot' && (
            <DotIcon
              style={{
                position: 'absolute',
                right: '-12px',
                top: '-4px',
                color: theme.palette.primary.main,
                marginRight: 0,
              }}
            />
          )}
        </Typography>
        <Iconify
          icon={'ic:outline-chevron-right'}
          sx={{ marginLeft: 'auto', marginRight: `0!important` }}
        />
      </StyledMenuItem>
      <Popper
        id="popper"
        anchorEl={anchorEl}
        open={openSecondLevel?.[filterItem.name.replace(/ /g, '') as keyof IFiltersState]}
        placement={'right-start'}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-8, 0],
            },
          },
        ]}
        sx={style}
      >
        {filterValues.length > 0 && (
          <Box sx={{ overflow: 'auto', paddingRight: '5px' }}>
            {filterValues.map((value) => (
              <StyledMenuItem
                id="popper-item"
                key={value}
                onClick={(event) => handleCheckbox(event, filterItem.name)}
                sx={{ padding: '4px 8px 4px 2px', borderRadius: '8px', marginBottom: 0.5 }}
                selected={
                  checkIsFilterItemSelected(filterItem.name, filterPage, value) &&
                  selected === 'background'
                }
              >
                <Checkbox
                  checked={!!localDataFilters?.[filterItem.name]?.includes(value)}
                  // onChange={(event) => handleCheckbox(event, filterItem.name)}
                  disableRipple={true}
                  name={value}
                  sx={{ padding: '3px' }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    marginLeft: '4px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: 'fit-content',
                    fontWeight: checkIsFilterItemSelected(filterItem.name, filterPage, value)
                      ? 600
                      : 400,
                  }}
                >
                  {value}
                </Typography>

                {/*<FormControlLabel*/}
                {/*  label={value}*/}
                {/*  control={*/}
                {/*    <Checkbox*/}
                {/*      checked={!!localDataFilters[filterItem.name]?.includes(value)}*/}
                {/*      onChange={(event) => handleCheckbox(event, filterItem.name)}*/}
                {/*      disableRipple={true}*/}
                {/*      name={value}*/}
                {/*    />*/}
                {/*  }*/}
                {/*/>*/}
              </StyledMenuItem>
            ))}
          </Box>
        )}
        <Typography
          id="select-deselect"
          color={theme.palette.primary.main}
          variant="subtitle2"
          onClick={() => handleSelectAll(filterValues, filterItem.name, isSelectedAll)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            padding: '4px 8px',
            borderRadius: '8px',
            height: '38px',
            cursor: 'pointer',
            position: 'sticky',
            '&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: alpha(
                theme.palette.primary.main,
                theme.palette.action.selectedOpacity
              ),
            },
          }}
        >
          {isSelectedAll ? 'Deselect All' : 'Select All'}
        </Typography>
      </Popper>
    </>
  );
};

export default SecondLevelMenuItem;
