import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useState } from 'react';

import FilterButton from '../../../assets/icons/FilterButton';
import FilterGroup from '../../../assets/icons/GroupButton';
import MenuPopover from '../../menu-popover';
import { checkIsFilterSelected, checkIsGroupSelected } from './NavSectionVerticalUtils';
import SecondLevelMenuItem from './SecondLevelMenuItem';
import { IconsGroup, StyledMenuItem } from './styles';

interface IGroup {
  name: string;
  value: string;
}

interface IFilter {
  name: string;
  values: string[];
}

type Props = {
  localDataFilters: any;
  handleCheckbox: (event: any, stage: any) => void;
  handleOnClickGroupItem?: (item: any) => void;
  localStorageGroup?: string;
  handleSelectAll: (filtersName: string[], stage: string, isSelectedAll: boolean) => void;
  filters: IFilter[];
  groups?: IGroup[];
  filterPage: string;
  filterIcon?: any;
  groupIcon?: any;
  styles?: any;
  grouping?: boolean;
  selected?: string;
};

export interface IFiltersState {
  [key: string]: boolean;
}

const FiltersAndGroups = ({
  localDataFilters,
  handleCheckbox,
  handleSelectAll,
  handleOnClickGroupItem = () => {},
  localStorageGroup,
  filters,
  groups = [],
  filterPage,
  filterIcon: FilterIcon,
  groupIcon: GropupIcon,
  styles,
  grouping = true,
  selected = 'dot',
}: Props): JSX.Element => {
  const initFilterState = filters.reduce((acc, filter) => {
    acc[filter.name.replace(/ /g, '')] = false;
    return acc;
  }, {} as IFiltersState);

  const [openFilterPopover, setOpenFilterPopover] = useState<HTMLElement | null>(null);
  const [openGroupPopover, setOpenGroupPopover] = useState<HTMLElement | null>(null);
  const [openSecondLevel, setOpenSecondLevel] = useState<IFiltersState>(initFilterState);
  const [filterValues, setFilterValues] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const theme = useTheme();

  const handleOpenFilterPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenFilterPopover(event.currentTarget);
  };

  const handleClosePopover = useCallback(() => {
    setOpenFilterPopover(null);
    handleCloseSecondLevel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenGroupPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenGroupPopover(event.currentTarget);
  };

  const handleCloseGroupPopover = useCallback(() => {
    setOpenGroupPopover(null);
  }, []);

  const handleCloseSecondLevel = () => {
    setOpenSecondLevel(initFilterState);
    setAnchorEl(null);
  };

  const handleOpenSecondLevel = (event: any, filterName: string, filterValues: string[]) => {
    setOpenSecondLevel(() => ({
      ...initFilterState,
      [filterName.replace(/ /g, '')]: true,
    }));
    setAnchorEl(event.target);
    setFilterValues(filterValues);
  };

  const isFilterSelected = checkIsFilterSelected();
  const isGroupSelected = checkIsGroupSelected();

  const filtersCount = (Object.values(localDataFilters) as any[][]).reduce(
    (acc: number, curr: any[]) => (acc += curr.length),
    0
  );
  const filterButton = FilterIcon ? (
    <FilterIcon
      isFilterSelected={isFilterSelected}
      filtersCount={filtersCount}
      position={'relative'}
      {...(openFilterPopover && {
        border: `1.5px solid ${theme.palette.text.primary}!important`,
      })}
    />
  ) : (
    <FilterButton
      height={24}
      isFilterSelected={isFilterSelected}
      onClick={handleOpenFilterPopover}
      sx={{ position: 'relative' }}
    />
  );

  const groupButton = GropupIcon ? (
    <GropupIcon isGroupSelected={isGroupSelected} sx={{ position: 'relative' }} />
  ) : (
    <FilterGroup height={24} isGroupSelected={isGroupSelected} sx={{ position: 'relative' }} />
  );
  const defaultStyle = {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 1.5,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[900],
    boxShadow: theme.customShadows.dropdownDark,
  };
  const style = { ...defaultStyle, ...styles };
  return (
    <IconsGroup>
      <Box onClick={handleOpenFilterPopover}>{filterButton}</Box>
      {grouping && <Box onClick={handleOpenGroupPopover}> {groupButton}</Box>}

      <MenuPopover
        open={openFilterPopover}
        onClose={handleClosePopover}
        disabledArrow={true}
        sx={style}
      >
        {filters.map((filterItem) => (
          <SecondLevelMenuItem
            key={filterItem.name}
            filterItem={filterItem}
            handleCheckbox={handleCheckbox}
            handleSelectAll={handleSelectAll}
            localDataFilters={localDataFilters}
            openSecondLevel={openSecondLevel}
            handleOpenSecondLevel={handleOpenSecondLevel}
            filterValues={filterValues}
            anchorEl={anchorEl}
            filterPage={filterPage}
            styles={styles}
            selected={selected}
          />
        ))}
      </MenuPopover>
      <MenuPopover
        open={openGroupPopover}
        onClose={handleCloseGroupPopover}
        disabledArrow={true}
        sx={style}
      >
        {groups &&
          groups?.map((item) => (
            <StyledMenuItem
              key={item.value}
              selected={item.value === localStorageGroup}
              onClick={() => handleOnClickGroupItem(item.value)}
            >
              {item.name}
            </StyledMenuItem>
          ))}
      </MenuPopover>
    </IconsGroup>
  );
};

export default FiltersAndGroups;
