import { Box, Divider, List, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

import { FilterStageType, NavSectionProps } from '../../../../components/nav-section';
import FiltersAndGroups from '../../../../components/nav-section/vertical/FiltersAndGroups';
import NavList from '../../../../components/nav-section/vertical/NavList';
import { FiltersGroup } from '../../../../components/nav-section/vertical/styles';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { LOCAL_STORAGE } from 'src/config';
import { getDealCompaniesName, getDealOwners, getDealStages } from 'src/main/DealUtils';

function DealsNavSection({
  data,
  items,
  deals,
  favoriteDeals,
  localDataFilters,
  localStorageGroup,
  handleOnClickGroupItem,
  handleCheckbox,
  handleSelectAll,
  stages,
  sx,
}: NavSectionProps) {
  const theme = useTheme();
  const filters = [
    {
      name: 'Company',
      values: getDealCompaniesName(deals),
    },
    {
      name: 'Deal Owner',
      values: getDealOwners(deals),
    },
    {
      name: 'Stage',
      values: getDealStages(stages),
    },
  ];

  const groups = [
    {
      name: 'Company',
      value: 'Company',
    },
    {
      name: 'Close Date',
      value: 'CloseDate',
    },
    {
      name: 'Deal Owner',
      value: 'DealOwner',
    },
    {
      name: 'Forecast Category',
      value: 'ForecastCategory',
    },
    {
      name: 'Stage',
      value: 'Stage',
    },
    {
      name: 'None',
      value: 'None',
    },
  ];
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: '8px 36px 12px 36px',
          top: '0',
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 700,
            color: theme.palette.grey[500],
            textTransform: 'uppercase',
          }}
        >
          Deals
        </Typography>
        {items?.map((group) => (
          <React.Fragment key={group.subheader}>
            {group.subheader && (
              <FiltersGroup>
                {group.subheader === 'Deals' && (
                  <FiltersAndGroups
                    localDataFilters={localDataFilters}
                    localStorageGroup={localStorageGroup}
                    handleCheckbox={handleCheckbox}
                    handleSelectAll={handleSelectAll}
                    handleOnClickGroupItem={handleOnClickGroupItem}
                    filters={filters}
                    groups={groups}
                    filterPage={LOCAL_STORAGE.DEALS_FILTERS}
                  />
                )}
              </FiltersGroup>
            )}
          </React.Fragment>
        ))}
      </Stack>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            position: 'relative',
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Stack sx={sx}>
          {favoriteDeals[0].items.length > 0 && (
            <Stack px={3}>
              <Typography
                variant="overline"
                color={theme.palette.common.white}
                marginLeft={1.5}
                sx={{ display: 'block', paddingTop: '8px' }}
              >
                Favorites
              </Typography>
              <Stack sx={{ marginTop: 1 }}>
                {favoriteDeals[0].items.map((item) => (
                  <NavList key={item.title + item.path} data={item} depth={1} />
                ))}
              </Stack>
              <Divider sx={{ marginY: 1 }} />
            </Stack>
          )}

          {data.map((group) => {
            const key = group.subheader || group.items[0].title;
            return (
              <List key={key} disablePadding sx={{ px: 3 }}>
                {group.items.map((list, index) =>
                  list.items ? (
                    <Box key={list.stageName + index.toString()}>
                      <Typography
                        variant="overline"
                        color={theme.palette.common.white}
                        marginLeft={1.5}
                        sx={{ display: 'block', paddingTop: '8px' }}
                      >
                        {list.stageName}
                      </Typography>
                      {list.items.map((item: FilterStageType) => (
                        <NavList key={item.title + item.path} data={item} depth={1} />
                      ))}
                    </Box>
                  ) : (
                    <NavList key={list.title + list.path} data={list} depth={1} deep={false} />
                  )
                )}
              </List>
            );
          })}
        </Stack>
      </Scrollbar>
    </>
  );
}

export default React.memo(DealsNavSection);
