import { Collapse, Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CaretIcon } from '../../../../assets/icons/navIcons/ic_caret_down.svg';
import { ReactComponent as DotIcon } from '../../../../assets/icons/navIcons/ic_dot.svg';
import { ReactComponent as PeopleGroupIcon } from '../../../../assets/icons/navIcons/personGroup.svg';
import { NOTES_PATH } from '../../../../config';
import { scrollIntoView } from '../../../../utils/scrollIntoView';
import { INoteTreeNode } from './DashboardNavNotesUtils';
import { NoteActionsNavItem } from './NoteActionsNavItem';

const NotesNavSectionItem = ({
  noteTree,
  thisNote,
}: {
  noteTree: INoteTreeNode[];
  thisNote: INoteTreeNode;
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    setIsVisible((prev) => !prev);
  };

  const hasChildren = thisNote.children && thisNote?.children?.length > 0;

  return (
    <>
      <SectionItem
        isVisible={isVisible}
        noteTree={noteTree}
        thisNote={thisNote}
        handleClick={handleClick}
      />
      {hasChildren && (
        <Collapse in={isVisible} unmountOnExit style={{ minHeight: 'none' }}>
          <NavSubListSectionItems noteTree={noteTree} thisNote={thisNote} />
        </Collapse>
      )}
    </>
  );
};

export default NotesNavSectionItem;

type SectionItemProps = {
  noteTree: INoteTreeNode[];
  thisNote: INoteTreeNode;
  handleClick: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  isVisible: boolean;
};

const SectionItem = ({ thisNote, noteTree, handleClick, isVisible }: SectionItemProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { note_id } = useParams();

  const [showActionButtons, setShowActionButtons] = useState(false);

  const isActiveNote = thisNote.id === note_id;
  const activeColor = isActiveNote ? theme.palette.common.white : theme.palette.grey[500];

  const hasChildren = thisNote.children && thisNote?.children?.length > 0;

  const isNoteShared = thisNote.noteUsers && thisNote.noteUsers?.length > 1;

  const handleNavigate = () => {
    if (isActiveNote) return;
    navigate(`/${NOTES_PATH}/${thisNote.id}`);
  };

  const handleHideActiveButtons = () => {
    setShowActionButtons(false);
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActiveNote) {
      scrollIntoView(thisNote.id, 'center', 100, 'auto');
    }
  }, [isActiveNote, thisNote.id]);

  return (
    <Stack
      id={thisNote.id}
      direction="row"
      onClick={handleNavigate}
      onMouseOver={() => setShowActionButtons(true)}
      onMouseLeave={handleHideActiveButtons}
      ref={ref}
      sx={{
        padding: '8px 12px',
        alignItems: 'center',
        backgroundColor: isActiveNote ? alpha(theme.palette.grey[700], 0.3) : 'transparent',
        borderRadius: 1,
        textTransform: 'none',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: isActiveNote
            ? alpha(theme.palette.grey[700], 0.6)
            : alpha(theme.palette.grey[700], 0.3),
        },
        marginTop: 0.5,
        marginLeft: thisNote.depth > 0 ? theme.spacing(thisNote.depth * 2.5) : 0,
      }}
    >
      {hasChildren ? (
        <CaretIcon
          onClick={(e) => handleClick(e)}
          style={{
            minWidth: '12px',
            color: activeColor,
            transform: `rotate(${isVisible ? '0deg' : '-90deg'})`,
          }}
        />
      ) : (
        <DotIcon style={{ minWidth: '12px', color: theme.palette.grey[400] }} />
      )}
      {isNoteShared && <PeopleGroupIcon style={{ marginLeft: '8px', minWidth: '16px' }} />}
      <Typography
        variant={isActiveNote ? 'subtitle2' : 'body2'}
        color={activeColor}
        sx={{
          marginLeft: '8px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {thisNote.title || 'Untitled Note'}
      </Typography>
      {showActionButtons && (
        <NoteActionsNavItem
          thisNote={thisNote}
          noteTree={noteTree}
          activeColor={activeColor}
          handleHideActiveButtons={handleHideActiveButtons}
        />
      )}
    </Stack>
  );
};

type NavSubListSectionItemsProps = {
  thisNote: INoteTreeNode;
  noteTree: INoteTreeNode[];
};

function NavSubListSectionItems({ thisNote, noteTree }: NavSubListSectionItemsProps) {
  return (
    <>
      {thisNote.children.map((note) => (
        <NotesNavSectionItem key={note.id} noteTree={noteTree} thisNote={note} />
      ))}
    </>
  );
}
