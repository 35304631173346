import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ClickAwayListener } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Editor } from '@tiptap/core';
import React, { useCallback, useState } from 'react';

import { ReactComponent as FontColorIcon } from '../../../assets/icons/tiptapIcons/font-color.svg';
import useLocalStorage from '../../../hooks/useLocalStorage';
import PaletteMenu from '../react-components/PaletteMenu';
import Button from './Button';

const FontColorButton = ({ editor }: { editor: Editor }) => {
  const theme = useTheme();

  const COLORS = [
    {
      name: 'black',
      color: theme.palette.common.black,
    },
    {
      name: 'red',
      color: theme.palette.error.main,
    },
    {
      name: 'orange',
      color: theme.palette.warning.main,
    },
    {
      name: 'green',
      color: theme.palette.primary.main,
    },
    {
      name: 'blue',
      color: theme.palette.info.main,
    },
    {
      name: 'purple',
      color: theme.palette.secondary.main,
    },
  ];

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [fontColor, setFontColor] = useLocalStorage(
    'fontColor',
    localStorage.getItem('fontColor') ?? theme.palette.common.black
  );

  const handleOpenPopover = (event: any) => {
    event.stopPropagation();
    if (openPopover) return setOpenPopover(null);
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = useCallback(() => {
    setOpenPopover(null);
  }, []);

  const handleApplyColorToText = (editor: Editor) => {
    // editor.commands.setColor(fontColor);
    editor.chain().setColor(fontColor).focus().run();
  };

  const handleChooseColor = (color: string) => {
    setFontColor(color);
    editor.chain().setColor(color).focus().run();
    handleClosePopover();
  };

  return (
    <ClickAwayListener onClickAway={handleClosePopover}>
      <div>
        <Button
          editor={editor}
          icon={<FontColorIcon style={{ color: fontColor }} />}
          tooltip={`Font Color`}
          isActiveName={'textStyle'}
          clickCommand={handleApplyColorToText}
          isClassname={false}
          expandMoreIcon={
            <ExpandMoreIcon
              sx={{ width: '12px', height: '16px', color: theme.palette.grey[600] }}
              onClick={handleOpenPopover}
            />
          }
        />
        <PaletteMenu
          colors={COLORS}
          openPopover={openPopover}
          handleClosePopover={handleClosePopover}
          handleChooseColor={handleChooseColor}
        />
      </div>
    </ClickAwayListener>
  );
};

export default FontColorButton;
