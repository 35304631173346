import BaseEntity from './extends/baseEntity';
import type Meetings from './meetings';
import Notes from './notes';
import type People from './people';
import { PrimaryId } from './types/id';
import type Users from './users';

export enum MeetingAttendeeSource {
  Calendar = 'Calendar',
  Manual = 'Manual',
}

export default class MeetingAttendees extends BaseEntity {
  meetingId!: PrimaryId;

  userId?: PrimaryId;

  peopleId?: PrimaryId;

  optional!: boolean;

  status!: string;

  notes?: Notes[];

  source!: MeetingAttendeeSource;

  // Relationships

  meeting!: Meetings;

  user?: Users;

  person?: People;
}
