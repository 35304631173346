import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import { APP_ENV } from '../../config';

const EnvironmentOverlay = ({ children }: { children: React.ReactNode }) => {
  const hostname = window.location.hostname.split('.')[0];
  return (
    <>
      {APP_ENV === 'development' && (
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
          <Box
            position={'fixed'}
            sx={{
              top: '0px',
              zIndex: '10000',
              backgroundColor: '#FFF',
              padding: '0 5px',
              border: '2px solid red',
            }}
          >
            <Typography variant={'body1'}>{hostname}</Typography>
          </Box>
        </Stack>
      )}
      {children}
    </>
  );
};

export default EnvironmentOverlay;
