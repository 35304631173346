import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useActiveLink from '../../../hooks/useActiveLink';
import { NavListProps } from '../types';
import NavItem from './NavItem';

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  // hasChild: boolean;
  deep?: boolean;
};

export default function NavList({ data, depth, deep }: NavListRootProps) {
  const { pathname } = useLocation();
  const { active, isExternalLink } = useActiveLink(data.path, deep);

  const [open, setOpen] = useState(active);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <NavItem
      item={data}
      depth={depth}
      open={open}
      active={active}
      isExternalLink={isExternalLink}
      onClick={handleToggle}
    />
  );
}
