import { Box, SxProps } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import React from 'react';

import Scrollbar from '../../../components/scrollbar';
import { HEADER } from '../../../config';

export const scrollStylesShow = {
  '&:hover': {
    '&::-webkit-scrollbar': {
      display: 'none',
      width: '0',
    },
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  // '&::-webkit-scrollbar-corner': {
  //   display: 'flex',
  // },
  '::-webkit-scrollbar-track': {
    opacity: 0,
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: alpha('#637381', 0.48),
    border: '2px solid transparent',
    borderRadius: '15px',
    backgroundClip: 'content-box',
  },
};

export const scrollStyles = {
  // '&:hover': {
  '&::-webkit-scrollbar': {
    display: 'none',
    width: '0',
  },
  // },
  // '&::-webkit-scrollbar': {
  //   // display: 'none',
  //
  // },
  '&::-webkit-scrollbar-corner': {
    display: 'none',
  },
  '::-webkit-scrollbar-track': {
    opacity: 0,
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: alpha('#637381', 0.48),
    border: '2px solid transparent',
    borderRadius: '15px',
    backgroundClip: 'content-box',
  },
};

const Sidebar = ({ children, sx }: { children: React.ReactNode; sx?: SxProps }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: alpha(theme.palette.grey[900], 0.8),
        maxHeight: `calc(96vh - ${HEADER.H_MAIN_DESKTOP + 11}px)`,
        height: 'fit-content ',
        overflow: 'auto',
        ...scrollStyles,
        pb: '12px',
        ...sx,
      }}
    >
      <Scrollbar>{children}</Scrollbar>
    </Box>
  );
};

export default Sidebar;
