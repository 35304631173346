import MeetingAttendees from '../../../../@types/api/meetingAttendees';
import People from 'src/@types/api/people';
import Users from 'src/@types/api/users';
import Deal from 'src/@types/core/deal';
import MeetingAttendee, { MeetingStatus } from 'src/@types/core/meetingAttendee';

export const getPersonRole = (
  meetingAttendee: People | Users | MeetingAttendee,
  deal: Deal | null
) => {
  let role = '';

  // if we have a user, then the only role is a deal owner, user.role is something else
  if (meetingAttendee instanceof Users) {
    return meetingAttendee.id === deal?.userId ? 'Deal Owner' : '';
  }

  // if we have a meeting attendee, then we can get the role from the meeting attendee
  if (meetingAttendee.role) {
    return meetingAttendee.role;
  }

  //if we have a people, we need to get the role from the deal
  if (meetingAttendee instanceof People) {
    const roles = meetingAttendee.salesforceContact?.salesforceOpportunityContactRoles?.sort(
      (a, b) => (a.CreatedDate > b.CreatedDate ? -1 : 1)
    );
    role = roles && roles.length > 0 && roles[0].Role ? roles[0].Role : 'Unknown';
  }

  return checkForCreatedByPeopleAi(role);
};

export const checkForCreatedByPeopleAi = (role: string) =>
  role.toLocaleLowerCase() === 'created by people.ai' ? 'Unknown' : role;

export const getMeetingAttendeesRow = (meetingAttendees: MeetingAttendees[]): string => {
  // for all the meetingAttendees, create a map where the key is the status and the value is the number of attendees with that status
  const statusMap = new Map<MeetingStatus, number>();
  meetingAttendees?.forEach((meetingAttendee) => {
    const attendee = new MeetingAttendee(meetingAttendee);
    const status = attendee.getStatus();
    if (statusMap.has(status)) {
      const count = statusMap.get(status) as number;
      statusMap.set(status, count + 1);
    } else {
      statusMap.set(status, 1);
    }
  });

  // create a string with the status and the number of attendees with that status
  let statusString = '';
  statusMap.forEach((value, key) => {
    statusString += `${value} ${key}, `;
  });
  statusString = statusString.slice(0, -2);

  return statusString;
};
