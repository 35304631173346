import { Mixin } from 'ts-mixer';

import type Clients from './clients';
import type Companies from './companies';
import DealUsers from './dealUsers';
import BaseEntity from './extends/baseEntity';
import { SalesforceRequired } from './extends/salesforce';
import type Meetings from './meetings';
import Notes from './notes';
import SalesforceOpportunities from './salesforceOpportunities';
import { PrimaryId } from './types/id';
import type Users from './users';

export default class Deals extends Mixin(BaseEntity, SalesforceRequired) {
  clientId!: PrimaryId;

  companyId!: PrimaryId;

  userId!: PrimaryId;

  name!: string;

  notes?: Notes[];

  // Relationships

  client!: Clients;

  company!: Companies;

  user!: Users;

  meetings?: Meetings[];

  dealUsers?: DealUsers[];

  salesforceOpportunity!: SalesforceOpportunities;
}
