import { Stack, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { useSavingContext } from '../../hooks/useSavingContext';
import Iconify from '../iconify';
import SpinnerIcon from '../spinner-icon/SpinnerIcon';

const Status = ({ editable, sx }: { editable: boolean; sx?: SxProps }) => {
  const theme = useTheme();

  const { isSaving } = useSavingContext();

  return (
    <Stack
      spacing={0.5}
      display="flex"
      direction="row"
      alignItems="center"
      sx={{ width: 'fit-content', ...sx }}
    >
      {!editable ? (
        <>
          <Iconify
            icon={'material-symbols:lock'}
            sx={{ color: theme.palette.common.white, width: '16px', height: '16px' }}
          />
          <Typography
            variant="caption"
            sx={{ color: theme.palette.common.white, cursor: 'default', userSelect: 'none' }}
          >
            Read-only
          </Typography>
        </>
      ) : !isSaving ? (
        <>
          <Iconify icon={'material-symbols:check'} sx={{ color: theme.palette.grey[500] }} />
          <Typography
            variant="caption"
            sx={{ color: theme.palette.grey[500], cursor: 'default', userSelect: 'none' }}
          >
            Saved
          </Typography>
        </>
      ) : (
        <SpinnerIcon text={'Saving...'} />
      )}
    </Stack>
  );
};

export default Status;
