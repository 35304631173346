import { ReactNode, createContext, useCallback, useContext, useState } from 'react';

import Notes, { NotesSource } from 'src/@types/api/notes';
import { apiQuery } from 'src/utils/apiQuery';

// Define the shape of the note data
interface INotesContext {
  notes: Notes[];
  slimNotes: Omit<Notes, 'noteUsers'>[];
  isLoading: boolean;
  isError: boolean;
  error?: string | null;
  updateNotes: (newNotes: Notes[]) => void;
  refresh: (noteSource: NotesSource) => Promise<void>;
  updateNoteTitle: (noteId: string, title: string) => void;
}

// Create the context with a default value
const NotesContext = createContext<INotesContext | undefined>(undefined);

// Define the provider component
export const NotesProvider = ({
  children,
  initialNotes = [],
}: {
  children: ReactNode;
  initialNotes?: Notes[];
}) => {
  const [notes, setNotes] = useState<Notes[]>(initialNotes);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const updateNotes = (newNotes: Notes[]) => {
    setNotes(newNotes);
  };
  const updateNoteTitle = (noteId: string, title: string) => {
    setNotes((prev) => {
      const newNote = prev.find((note) => note.id === noteId);
      if (newNote) {
        newNote.title = title;
      }
      return [...prev];
    });
  };

  const slimNotes = notes.map((note) => {
    const slimNote = { ...note };
    delete slimNote.noteUsers;
    return slimNote;
  }) as Omit<Notes, 'noteUsers'>[];
  // Function to refresh (fetch) notes
  const refresh = useCallback(async (source: NotesSource = NotesSource.User) => {
    setIsLoading(true);
    setIsError(false);
    setError(null);
    try {
      const newNotes = await apiQuery<Notes[]>(`/notes?source=${source}`);
      setNotes(newNotes);
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch notes:', error);
      setError(error.message);
      setIsError(true);
      setIsLoading(false);
    }
  }, []);

  return (
    <NotesContext.Provider
      value={{ notes, updateNotes, refresh, isLoading, isError, error, slimNotes, updateNoteTitle }}
    >
      {children}
    </NotesContext.Provider>
  );
};

// Hook to use the context
export function useNotesContext() {
  const context = useContext(NotesContext);
  if (context === undefined) {
    throw new Error('useNotesContext must be used within a NotesProvider');
  }
  return context;
}
