import { useEffect, useState } from 'react';

import { IDataNavSection } from '../../../components/nav-section';
import { groupByFilterName } from '../../../components/nav-section/vertical/NavSectionVerticalUtils';
import { LOCAL_STORAGE } from '../../../config';
import useLocalStorage from '../../../hooks/useLocalStorage';

export function useFiltersGroup({ filteredDeals }: { filteredDeals: IDataNavSection[] }) {
  const [groupedItems, setGroupedItems] = useState<IDataNavSection[]>(filteredDeals);
  const [localStorageGroup, setLocalStorageGroup] = useLocalStorage(
    LOCAL_STORAGE.GROUPING,
    localStorage.getItem(LOCAL_STORAGE.GROUPING) ?? 'CloseDate'
  );
  const [localDataGroup, setLocalDataGroup] = useState<string>(localStorageGroup);

  useEffect(() => {
    if (!localDataGroup || localDataGroup === 'None') {
      setGroupedItems(filteredDeals);
      return;
    }
    const groupFiltered = groupByFilterName(filteredDeals, localDataGroup);
    setGroupedItems(groupFiltered);
  }, [filteredDeals, localDataGroup]);

  const handleOnClickGroupItem = (value: string) => {
    setLocalStorageGroup(value);
    setLocalDataGroup(value);
    const groupFiltered = groupByFilterName(filteredDeals, localDataGroup);
    setGroupedItems(groupFiltered);
  };

  useEffect(() => {
    setLocalStorageGroup(localDataGroup);
    // eslint-disable-next-line
  }, []);

  return { groupedItems, localStorageGroup, handleOnClickGroupItem };
}
