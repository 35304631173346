import { Box, Link, Typography, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { useAuthContext } from '../../../auth/useAuthContext';
import AvatarGroupComponent from '../../../components/avatar-group/AvatarGroup';
import { getOrderedUsers } from '../../../components/share/ShareUtils';

interface IStickyHeaderProps {
  title: string;
  secTitle?: string;
  users?: any[];
}

const StickyHeader = ({ title, secTitle, users }: IStickyHeaderProps) => {
  const { user: currentUser } = useAuthContext();

  const orderedUsers = getOrderedUsers(users || [], currentUser);

  const theme = useTheme();

  return (
    <>
      <Box gridColumn="span 7" sx={{ position: 'relative' }}>
        <Box sx={{ padding: '24px 0 16px 24px ' }}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '36px',
              color: theme.palette.text.primary,
              textDecoration: 'none',
            }}
            id="title"
          >
            {title}
          </Typography>

          {secTitle && (
            <Typography sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '22px' }}>
              <Link
                component={RouterLink}
                to={`/`}
                sx={{ color: theme.palette.text.secondary, width: 'fit-content' }}
              >
                {secTitle}
              </Link>
            </Typography>
          )}
        </Box>
      </Box>
      {orderedUsers.length > 0 && (
        <Box
          gridColumn="span 3"
          sx={{
            position: 'sticky',
            top: '0px',
            zIndex: '1199',
            marginTop: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'sticky',
              top: '0px',
              marginLeft: 'auto',
              width: 'fit-content',
              padding: '9px 24px 0 0',
              gap: '16px',
            }}
          >
            <AvatarGroupComponent users={orderedUsers ?? []} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default StickyHeader;
