import { useSnackbar } from 'notistack';
import { useReducer } from 'react';
import { useParams } from 'react-router';

import { useEditSalesforceField, useGetFieldValueFromSalesforce } from '../../../hooks/useQueries';
import { ISlashNodeInterface } from '../extensions/ContextSalesforceExtension';

type State = {
  isError: boolean;
  isTokenError: boolean;
  isLoading: boolean;
  isSaving: boolean;
  salesforceValue: any;
};

type Action =
  | {
      type: 'update';
      payload: {
        key: string;
        value: any;
      };
    }
  | { type: 'reset' };

const initialValue: State = {
  isError: false,
  isTokenError: false,
  isSaving: false,
  isLoading: true,
  salesforceValue: {},
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'update':
      return { ...state, [action.payload.key]: action.payload.value };
    case 'reset':
      return initialValue;
  }
};

type Props = {
  node: ISlashNodeInterface;
  updateAttributes: (attributes: Record<string, any>) => void;
  salesforceKey: string;
  dealId?: string;
  isDisabled?: boolean;
};

export const useEditAndSaveSalesforce = ({
  node,
  updateAttributes,
  salesforceKey,
  dealId,
  isDisabled = false,
}: Props) => {
  const [salesforceValue, dispatch] = useReducer(reducer, initialValue);
  const { enqueueSnackbar } = useSnackbar();
  const { template_id } = useParams();

  const editSalesforceFields = useEditSalesforceField(
    node.attrs.salesforceType,
    salesforceKey,
    dealId!
  );
  const getSalesforceValue = useGetFieldValueFromSalesforce(
    node.attrs.salesforceType,
    salesforceKey
  );

  const handleLoadValueFromSalesforce = () => {
    if (isDisabled) return;
    if (salesforceKey === 'none' || template_id)
      return dispatch({ type: 'update', payload: { key: 'isLoading', value: false } });
    dispatch({
      type: 'update',
      payload: { key: 'isLoading', value: true },
    });
    dispatch({
      type: 'update',
      payload: { key: 'isError', value: false },
    });
    dispatch({
      type: 'update',
      payload: { key: 'isTokenError', value: false },
    });
    return getSalesforceValue
      .mutateAsync({ additionalField: node.attrs.salesforceFieldName })
      .then((res) => {
        dispatch({
          type: 'update',
          payload: { key: 'salesforceValue', value: res },
        });
        return res;
      })
      .catch((reason) => {
        const json = JSON.parse(reason);
        if (json.message === 'No oAuth found') {
          return dispatch({
            type: 'update',
            payload: { key: 'isTokenError', value: true },
          });
        }
        dispatch({
          type: 'update',
          payload: { key: 'isError', value: true },
        });
      })
      .finally(() => {
        dispatch({
          type: 'update',
          payload: { key: 'isLoading', value: false },
        });
      });
  };

  const handleEditSalesforce = (currentValue: any, activeSlashName: string) => {
    dispatch({
      type: 'update',
      payload: { key: 'isSaving', value: true },
    });
    dispatch({
      type: 'update',
      payload: { key: 'isError', value: false },
    });
    dispatch({
      type: 'update',
      payload: { key: 'isTokenError', value: false },
    });
    editSalesforceFields
      .mutateAsync({ [activeSlashName]: currentValue })
      .then((res: any) => {
        dispatch({
          type: 'update',
          payload: { key: 'salesforceValue', value: res },
        });
        updateAttributes({ lastUpdated: new Date().toISOString(), value: currentValue });
      })
      .catch((reason) => {
        const json = JSON.parse(reason);
        if (json.message === 'No oAuth found') {
          return dispatch({
            type: 'update',
            payload: { key: 'isTokenError', value: true },
          });
        }
        dispatch({
          type: 'update',
          payload: { key: 'isError', value: true },
        });
        return enqueueSnackbar('Error Updating Salesforce!', { variant: 'error' });
      })
      .finally(() => {
        dispatch({
          type: 'update',
          payload: { key: 'isSaving', value: false },
        });
      });
  };

  const spreadSalesforceFields = {
    ...salesforceValue.salesforceValue?.meta,
    ...salesforceValue.salesforceValue,
  };

  const { isLoading, isError, isSaving, isTokenError } = salesforceValue;

  return {
    handleLoadValueFromSalesforce,
    handleEditSalesforce,
    isLoading,
    isError,
    isTokenError,
    isSaving,
    SFValue: spreadSalesforceFields,
  };
};
