import { Theme, alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Avatar(theme: Theme) {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: { fontSize: '1rem', lineHeight: 0, fontWeight: '600' },
        colorDefault: {
          color: theme.palette.text.secondary,
          backgroundColor: alpha(theme.palette.grey[500], 0.24),
        },
        img: {
          width: '100%',
          height: '100%',
        },
      },
    },
    MuiAvatarGroup: {
      defaultProps: {
        max: 4,
      },
      styleOverrides: {
        root: {
          justifyContent: 'flex-end',
        },
        avatar: {
          fontSize: 12,
          fontWeight: theme.typography.fontWeightMedium,
          /*  '&:first-of-type': {
                                                              fontSize: 12,
                                                              color: theme.palette.primary.main,
                                                              backgroundColor: theme.palette.primary.lighter,
                                                            }, */
        },
      },
    },
  };
}
