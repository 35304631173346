import { TextField } from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import { getSalesforceIcon } from '../SlashMenuUtils';
import { SalesforceInputComponentProps } from './SalesforceInputComponent';

const formatPhoneNumber = (value: string) => {
  if (!value) return value;

  // Remove all non-numeric characters
  const cleaned = value.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

  if (match) {
    const part1 = match[1];
    const part2 = match[2] ? `.${match[2]}` : '';
    const part3 = match[3] ? `.${match[3]}` : '';
    return `${part1}${part2}${part3}`;
  }

  return value;
};

const PhoneInputComponent = forwardRef(
  ({ node, disabled, handleChangeValue, initialValue }: SalesforceInputComponentProps, ref) => {
    const [inputValue, setInputValue] = useState<string>(formatPhoneNumber(initialValue));

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const rawValue = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
        const formattedValue = formatPhoneNumber(rawValue);
        setInputValue(formattedValue);
        handleChangeValue(rawValue, node.attrs.salesforceFieldName); // Pass the raw numeric value
      },
      [handleChangeValue, node.attrs.salesforceFieldName]
    );

    useEffect(() => {
      setInputValue(formatPhoneNumber(initialValue));
    }, [initialValue]);

    const inputProps = useMemo(
      () => ({
        startAdornment: getSalesforceIcon({ node }),
        sx: { minHeight: '24px' },
        inputRef: ref,
        type: 'tel',
        disableUnderline: true,
      }),
      [node, ref]
    );

    return (
      <TextField
        id={node.attrs.salesforceFieldName}
        fullWidth
        hiddenLabel
        disabled={disabled}
        value={inputValue}
        onChange={handleChange}
        placeholder="No data"
        variant="standard"
        InputProps={inputProps}
      />
    );
  }
);

export default PhoneInputComponent;
