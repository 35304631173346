import { Box, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CalendarIcon, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isPast, isToday } from 'date-fns';
import { useEffect, useState } from 'react';

import { DatePickerIcon } from './styles';
import { fDateMonthAndDay } from 'src/utils/formatTime';

export interface ICalendarPopoverProps {
  initialDate: string;
  disabled?: boolean;
  changeCalendar: (date: string) => void;
}

export default function CalendarPopover({
  //allowBlank,
  initialDate,
  disabled,
  changeCalendar,
}: ICalendarPopoverProps) {
  const theme = useTheme();
  const [date, setDate] = useState<string>(initialDate);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setDate(initialDate);
  }, [initialDate]);

  const handleChange = (date: Date) => {
    changeCalendar(date.toISOString());
    setDate(date.toISOString());
  };

  const handleOpenCalendar = (event: any) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getColor = (date: Date) => {
    if (open) return theme.palette.primary.main;
    if (disabled) return theme.palette.grey[600];
    if (isToday(date)) return theme.palette.grey[800];
    if (isPast(date)) return theme.palette.error.darker;
    return theme.palette.grey[800];
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={new Date(date)}
          onChange={(date) => handleChange(date ?? new Date())}
          open={open}
          onClose={handleClose}
          slotProps={{
            popper: {
              placement: 'bottom-start',
              sx: {
                zIndex: 5000,
              },
              disablePortal: true,
            },
            textField: {
              fullWidth: true,
              variant: 'standard',
              onClick: handleOpenCalendar,
              sx: {
                width: 'auto',
                '& .MuiInputBase-root': {
                  padding: 0,
                },
              },
              InputProps: {
                disableUnderline: true,
                sx: {
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  '& .MuiInputBase-input': {
                    display: 'none',
                  },
                  '& .MuiInputAdornment-root': {
                    marginLeft: 0,
                    '& .MuiIconButton-root': {
                      padding: 0,
                    },
                  },
                },
                endAdornment: (
                  <Box sx={{ width: 'fit-content' }} onClick={handleOpenCalendar}>
                    <Box>
                      {!date ? (
                        <Tooltip
                          title={'Set Due Date'}
                          placement={'top'}
                          PopperProps={{
                            modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
                          }}
                        >
                          <DatePickerIcon
                            sx={{
                              '& svg': {
                                width: '20px',
                                height: '24px',
                                '& path': {
                                  color: open
                                    ? theme.palette.primary.main
                                    : theme.palette.grey[500],
                                },
                              },
                              height: '24px',
                              color: `${theme.palette.grey[500]}!important`,
                              opacity: disabled ? '0.5' : '1',
                              zIndex: '9999',
                            }}
                          >
                            <CalendarIcon />
                          </DatePickerIcon>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={'Change Due Date'}
                          placement={'top'}
                          PopperProps={{
                            modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
                          }}
                        >
                          <Typography
                            variant={'body2'}
                            style={{ fontSize: '14px' }}
                            sx={{
                              marginLeft: '10px',
                              marginRight: '10px',
                              opacity: disabled ? '0.5' : '1',
                              cursor: disabled ? 'default' : 'pointer',
                              // date return 00:00Z and isPast return true for current day
                              color: getColor(new Date(date)),
                            }}
                          >
                            {fDateMonthAndDay(date)}
                          </Typography>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                ),
              },
            },
          }}
          slots={{}}
        />
      </LocalizationProvider>
    </>
  );
}
