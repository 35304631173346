import { Input, SxProps, useTheme } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';

import InputEndAdornment from './InputEndAdornment';
import InputStartAdornment from './InputStartAdornment';
import { isWhitespaceString } from './OpenAIMenuUtils';

const OpenAIInput = ({
  handleSubmit,

  isLoading = false,
  isResponse = false,

  abortStream,

  sx,
  placeholder = 'Ask AI to edit or generate...',
  responsePlaceholder = 'Tell AI what to do next...',
  loadingPlaceholder = '',
  startAdornmentPlaceholder = '',
  multiline = false,
  loadingSpinner = false,
  result,
  coloredPlaceholder = true,
}: {
  handleSubmit: any;

  isLoading?: boolean;
  isResponse?: boolean;

  abortStream?: () => void;

  sx?: SxProps;
  placeholder?: string;
  responsePlaceholder?: string;
  loadingPlaceholder?: string;
  startAdornmentPlaceholder?: string;
  multiline?: boolean;
  loadingSpinner?: boolean;
  result?: string;
  coloredPlaceholder?: boolean;
}) => {
  const [inputValue, setInputValue] = useState('');
  const handleChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const theme = useTheme();

  const handleKeyDown = (e: any) => {
    // if the input is empty, do nothing
    if (isWhitespaceString(inputValue)) return;
    // if the user presses enter with shift, add a new line
    if (e.key === 'Enter' && e.shiftKey === true) {
      e.preventDefault();
      handleChangeInputValue({
        target: { value: inputValue + '\n' },
      } as React.ChangeEvent<HTMLInputElement>);
    }

    // if the user presses enter without shift, submit the message
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(inputValue);
      setInputValue('');
    }
  };

  return (
    <Input
      id="inputAI"
      multiline={multiline}
      maxRows={multiline ? 4 : 0}
      startAdornment={
        <InputStartAdornment
          isLoading={isLoading}
          startAdormentPlaceholder={startAdornmentPlaceholder}
          loadingSpinner={loadingSpinner}
        />
      }
      placeholder={isLoading ? loadingPlaceholder : isResponse ? responsePlaceholder : placeholder}
      disableUnderline={true}
      // disabled={isLoading}
      onKeyDown={handleKeyDown}
      endAdornment={
        <InputEndAdornment
          isLoading={isLoading}
          inputValue={inputValue}
          sendClick={handleSubmit}
          abortStream={abortStream}
          setInputValue={setInputValue}
        />
      }
      fullWidth
      autoComplete="off"
      value={inputValue}
      onChange={handleChangeInputValue}
      sx={{
        backgroundColor: theme.palette.common.white,
        paddingTop: result ? '4px' : 0,
        borderRadius: '12px',
        ' .MuiInput-input': {
          '&::placeholder': {
            color:
              isLoading && coloredPlaceholder
                ? theme.palette.secondary.light
                : theme.palette.text.disabled,
          },
        },
        ...sx,
      }}
    />
  );
};

export default OpenAIInput;
