import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import { forwardRef } from 'react';

import { IconifyProps } from './types';

interface Props extends React.ComponentProps<typeof Icon> {
  icon: IconifyProps;
  width?: number;
  sx?: any;
}

const Iconify = forwardRef<SVGSVGElement, Props>(({ icon, width = 20, sx, ...other }, ref) => (
  <Box sx={{ width, height: width, display: 'inline-flex', ...sx }}>
    <Icon ref={ref} icon={icon} width="100%" height="100%" {...other} />
  </Box>
));

export default Iconify;
