import { Mixin } from 'ts-mixer';

import { BASE_URL } from '../../config';
import type Clients from './clients';
import Companies from './companies';
import BaseEntity from './extends/baseEntity';
import { SalesforceRequired } from './extends/salesforce';
import type MeetingAttendees from './meetingAttendees';
import Notes from './notes';
import SalesforceContacts from './salesforceContacts';
import { PrimaryId } from './types/id';
import { getPersonRole } from 'src/sections/core/deal/attendees/AttendeesUtils';

export default class People extends Mixin(BaseEntity, SalesforceRequired) {
  clientId!: PrimaryId;

  companyId!: string;

  name!: string;

  email!: string;

  title?: string;

  _image?: string;

  _photoUrl?: string;

  phone?: string;

  linkedIn?: string;

  role?: string;

  notes?: Notes[];

  peopleDealStats?: IPeopleDealStats;

  get photoUrl() {
    return !this._photoUrl
      ? this._photoUrl
      : this._photoUrl?.startsWith('blob')
        ? this._photoUrl
        : `${BASE_URL}/images/${this._photoUrl?.replace('https://context-app-prod.s3.us-east-1.amazonaws.com', '')}`;
  }

  set photoUrl(value: string | undefined) {
    this._photoUrl = value;
  }

  get roleName() {
    return getPersonRole(this, null);
  }

  get image(): string | undefined {
    return !this._image
      ? this._image
      : this._image?.startsWith('blob')
        ? this._image
        : `${BASE_URL}/images/${this._image?.replace('https://context-app-prod.s3.us-east-1.amazonaws.com', '')}`;
  }

  set image(value: string | undefined) {
    this._image = value;
  }

  // Relationships

  meetingAttendees?: MeetingAttendees[];

  client!: Clients;

  company?: Companies;

  salesforceContact?: SalesforceContacts;

  constructor(data: Partial<People>) {
    super();
    Object.assign(this, data);
  }
}

interface IPeopleDealStats {
  dealId: PrimaryId;
  meetingCount: number;
  lastMeetingId?: PrimaryId;
  lastMeetingDate?: Date;
  nextMeetingId?: PrimaryId;
  nextMeetingDate?: Date;
}
