import { Divider } from '@mui/material';

import { useAuthContext } from '../../auth/useAuthContext';
import { INoteTreeNode } from '../../layouts/dashboard/nav/notes/DashboardNavNotesUtils';
import DeleteNote from '../../layouts/dashboard/nav/notes/actions/DeleteNote';
import DuplicateNote from '../../layouts/dashboard/nav/notes/actions/DuplicateNote';
import MoveNote from '../../layouts/dashboard/nav/notes/actions/MoveNote';
import RemoveSharedNote from '../../layouts/dashboard/nav/notes/actions/RemoveSharedNote';
import CopyElement from '../../sections/core/deal/menu-sections/CopyElement';
import { getCurrentUser, isOwner, isViewer, isWriter } from '../share/ShareUtils';
import MoveToDeal from 'src/layouts/dashboard/nav/notes/actions/MoveToDeal';
import ExportElement from 'src/sections/core/deal/menu-sections/ExportToPdf';
import { NoteTitle } from 'src/utils/tiptapExtractTitles';

type Props = {
  note?: NoteTitle;
  noteTree: INoteTreeNode[];
  handleClose?: () => void;
  isNotePage?: boolean;
};

const NotesMenuGroupedActions = ({ note, noteTree, handleClose, isNotePage }: Props) => {
  const { user } = useAuthContext();
  const currentUser = getCurrentUser(note?.noteUsers, user);

  return (
    <>
      {isNotePage && (
        <>
          <CopyElement id={note?.id!} isNote />
          <ExportElement noteId={note?.id} />
          <Divider />
        </>
      )}
      {!isViewer(currentUser) && (
        <MoveNote
          thisNote={note as INoteTreeNode}
          noteTree={noteTree}
          handleClose={handleClose}
          isNotePage={isNotePage}
        />
      )}
      {(isOwner(currentUser) || isWriter(currentUser)) && (
        <MoveToDeal
          noteTree={noteTree}
          thisNote={note as INoteTreeNode}
          handleClose={handleClose}
          isNotePage={isNotePage}
        />
      )}
      <DuplicateNote
        note={note as INoteTreeNode}
        handleClose={handleClose}
        isNotePage={isNotePage}
      />
      {(isOwner(currentUser) || isWriter(currentUser)) && (
        <DeleteNote
          noteTree={noteTree}
          thisNote={note as INoteTreeNode}
          handleClose={handleClose}
          isNotePage={isNotePage}
        />
      )}
      {!isOwner(currentUser) && (
        <RemoveSharedNote
          noteTree={noteTree}
          thisNote={note as INoteTreeNode}
          handleClose={handleClose}
          isNotePage={isNotePage}
        />
      )}
    </>
  );
};

export default NotesMenuGroupedActions;
