import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { CustomParagraphNodeView } from '../react-components/AIMenuNodeView';

export const CustomParagraphAIMenu = Node.create({
  name: 'customAIMenuNode',
  addAttributes() {
    return {
      textContent: {
        default: '',
      },
      isComplete: {
        default: false,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'custom-ai-menu-node',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['custom-ai-menu-node', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(CustomParagraphNodeView);
  },
});
