import { Box } from '@mui/material';
import { Editor } from '@tiptap/core';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef } from 'react';
import useResizeObserver from 'use-resize-observer';

import Notes from 'src/@types/api/notes';
import TiptapEditor from 'src/components/tip-tap-editor/TiptapEditor';
import { ReconnectableWebSocket } from 'src/utils/websocket';

const OpenAIMenuNote = ({
  aiSocket,
  handleLoading,
  handleResponse,
  handleResult,
}: {
  aiSocket: ReconnectableWebSocket;
  handleLoading: (loading: boolean) => void;
  handleResponse: (loading: boolean) => void;
  handleResult: (result: string) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const tiptapEditorRef = useRef<Editor | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const { height } = useResizeObserver<HTMLElement>({
    ref: ref,
  });

  const handleUpdate = (editor: Editor) => {
    tiptapEditorRef.current = editor;
  };

  const handleError = useCallback(
    (error: string) => {
      enqueueSnackbar(error, { variant: 'error' });
    },
    [enqueueSnackbar]
  );

  const updateAIResponse = useCallback(
    ({ value, complete }: { value?: string; complete?: boolean }) => {
      const editor = tiptapEditorRef.current;
      if (!editor) return;
      const { tr } = editor.state;
      const lastNodeResponseValue = editor.state.doc.lastChild?.attrs.textContent || '';
      const isComplete = editor.state.doc.lastChild?.attrs.isComplete || false;
      if (complete) {
        tr.setNodeAttribute(0, 'isComplete', true);
        editor.view.dispatch(tr);
        return handleResult(editor.state.doc.lastChild?.attrs.textContent);
      }
      if (isComplete && lastNodeResponseValue) {
        tr.setNodeAttribute(0, 'isComplete', false);
        tr.setNodeAttribute(0, 'textContent', value);
        return editor.view.dispatch(tr);
      }
      tr.setNodeAttribute(0, 'textContent', lastNodeResponseValue + value);
      editor.view.dispatch(tr);
    },
    [handleResult]
  );

  useEffect(() => {
    if (aiSocket) {
      const onMessage = (e: { data: string }) => {
        const data = JSON.parse(e.data);
        if (data.r) {
          updateAIResponse({ value: data.r });
        }

        if (data.error) {
          handleLoading(false);
          handleResponse(true);
          handleError(data.error);
        }
        if (data.complete) {
          updateAIResponse({ complete: true });
          handleLoading(false);
          handleResponse(true);
        }
      };

      aiSocket.on('message', onMessage);

      const onError = () => {
        handleError('Error: OpenAI websocket error');
      };
      const onClose = () => {
        handleLoading(false);
      };
      aiSocket.on('error', onError);
      return () => {
        aiSocket.off('message', onMessage);
        aiSocket.off('close', onClose);
        aiSocket.off('error', onError);
      };
    }
  }, [aiSocket, handleError, handleLoading, handleResponse, updateAIResponse]);

  useEffect(() => {
    const elem = document.getElementById('AIResponse');
    if (elem) {
      elem.scrollTop = elem.scrollHeight;
    }
  }, [height]);

  return (
    <Box
      sx={{
        maxHeight: '250px',
        overflow: 'auto',
        lineHeight: '22px',
        display: 'flex',
        flexDirection: 'column',
        '& table, th, td': {
          border: '1px solid black',
        },
      }}
      id="AIResponse"
    >
      <Box sx={{ width: '100%', height: '100%' }} ref={ref}>
        <TiptapEditor
          atMentions={[]}
          editable={false}
          noteId={''}
          customClassName={'tiptap-deal-ai-note'}
          autofocus={false}
          customSchema="customAIMenuNode"
          collabCursor={false}
          disableConnection={true}
          offlineEditing={true}
          key={'deal-review-editor'}
          onUpdate={handleUpdate}
          onCreate={handleUpdate}
          showFloatingToolbar={false}
          note={{} as Notes}
        />
      </Box>
    </Box>
  );
};

export default OpenAIMenuNote;
