import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import AIRequestNodeView from '../react-components/AIRequestNodeView';
import AIResponseNodeView from '../react-components/AIResponseNodeView';

export const AIMessage = Node.create({
  name: 'AIMessage',

  group: 'block',

  content: 'block*',

  selectable: false,

  atom: false,

  addAttributes() {
    return {
      timestamp: {
        default: '',
      },
      text: {
        default: '',
      },
      error: {
        default: '',
      },
    };
  },
});

export const AIRequest = AIMessage.extend({
  name: 'AIRequest',

  addOptions() {
    return {
      ...this.parent?.(),
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      promptText: {
        default: '',
      },
      promptInstructions: {
        default: '',
      },
      userId: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-userId'),
        renderHTML: (attributes) => ({
          'data-userId': attributes.userId,
        }),
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'ai-request',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['ai-request', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(AIRequestNodeView);
  },
});

export const AIResponse = AIMessage.extend({
  name: 'AIResponse',

  addOptions() {
    return {
      ...this.parent?.(),
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      isLast: {
        default: false,
      },
      noteId: {
        default: '',
      },
      isLoadingResponse: {
        default: true,
      },
      threadId: {
        default: '',
      },
      loadingInfo: {
        default: '',
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'ai-response',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['ai-response', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(AIResponseNodeView);
  },
});
