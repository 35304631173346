import { useTheme } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import React, { forwardRef, useCallback, useState } from 'react';

import { SalesforceInputComponentProps } from './SalesforceInputComponent';

const today = new Date();
const minDate = new Date(today.getFullYear() - 20, today.getMonth(), today.getDate());
const maxDate = new Date(today.getFullYear() + 20, today.getMonth(), today.getDate());

// Helper function to create a date without timezone offset
const parseDateStringWithoutTimezone = (dateString: string): Date => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day); // month - 1 because Date() month is 0-indexed
};

const DatePickerComponent = forwardRef(
  ({ node, disabled, handleChangeValue, initialValue }: SalesforceInputComponentProps, ref) => {
    const theme = useTheme();

    // Initialize the date state, parsing the date string without timezone adjustments
    const initialDate = initialValue ? parseDateStringWithoutTimezone(initialValue) : null;

    const [value, setValue] = useState<Date | null>(initialDate);
    const [open, setOpen] = useState(false);

    // Handle date change
    const handleChangeDate = useCallback(
      (newValue: Date | null) => {
        if (!newValue) return;
        setValue(newValue);
        handleChangeValue(format(newValue, 'yyyy-MM-dd'), node.attrs.salesforceFieldName, true);
      },
      [handleChangeValue, node.attrs.salesforceFieldName]
    );

    // Toggle date picker open/close
    const handleOpen = useCallback(() => {
      if (!disabled) setOpen(true);
    }, [disabled]);

    const handleClose = useCallback(() => setOpen(false), []);

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disabled={disabled}
          open={open}
          onClose={handleClose}
          value={value}
          ref={ref as React.RefObject<HTMLDivElement>}
          format="MM/dd/yyyy"
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleChangeDate}
          slotProps={{
            popper: {
              placement: 'bottom-start',
              sx: { zIndex: 9999 },
              modifiers: [{ name: 'offset', options: { offset: [-30, 10] } }],
            },
            field: { readOnly: true },
            textField: {
              variant: 'standard',
              fullWidth: true,
              onClick: handleOpen,
              sx: { marginTop: '8px' },
              InputProps: {
                id: node.attrs.salesforceFieldName,
                disableUnderline: true,
                startAdornment: null,
                sx: {
                  color: theme.palette.common.black,
                  flexDirection: 'row-reverse',
                  '& .MuiInputBase-input': {
                    marginLeft: '20px',
                    cursor: disabled ? 'default' : 'pointer',
                  },
                  '& .MuiInputAdornment-root': {
                    marginLeft: '-3px',
                    '& .MuiButtonBase-root': {
                      padding: '0',
                    },
                  },
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    );
  }
);

export default DatePickerComponent;
