import { Box, Button, Paper, Stack, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import NoteTemplates from '../../../../@types/api/noteTemplates';
import { ReactComponent as GongIcon } from '../../../../assets/icons/tiptapIcons/slashmenu/gongIcon.svg';
import { ReactComponent as SalesforceIcon } from '../../../../assets/icons/tiptapIcons/slashmenu/salesforceIcon.svg';
import { fDateMeeting, fDateTimeOnly } from '../../../../utils/formatTime';
import { ISlashCommandProps, SLASH_MENU } from '../types';
import {
  getAvailbaleSFFields,
  getOptionGroupByType,
  getOptionLabelByType,
  getOptionPlaceHolderByType,
  isGong,
  selectFieldByType,
} from './SlashSecondMenuUtils';
import { SecondMenuValue } from './types';
import { useAuthContext } from 'src/auth/useAuthContext';
import LoadingScreen from 'src/components/loading-screen/LoadingScreen';
import { TEMPLATES_PATH } from 'src/config';
import { useDealContext } from 'src/hooks/useDealContext';
import { useNoteTemplatesContext } from 'src/hooks/useNoteTemplatesContext';
import { useGetDealGongCalls, useGetSalesforceFields } from 'src/hooks/useQueries';
import { useAccountOutletContext } from 'src/sections/core/account/Account';

type Props = {
  selectedMenu: string;
  items: ISlashCommandProps[];
  command: any;
  handleCloseMenu: (reason: string) => void;
};

type SecondMenuValueWithGroup = SecondMenuValue & { group: string };

const SecondMenu = ({ selectedMenu, command, handleCloseMenu, items }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<SecondMenuValue>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(true);
  const { clientName } = useAuthContext();
  const { deal } = useDealContext();
  const accountData = useAccountOutletContext();
  const {
    data: gongCalls,
    isLoading: gongCallsIsLoading,
    isRefetching: gongCallsIsRefetching,
  } = useGetDealGongCalls(deal ? deal.id : '', selectedMenu === SLASH_MENU.GONG);
  const {
    data: fields,
    isLoading: fieldsIsLoading,
    isRefetching: fieldsIsRefetching,
  } = useGetSalesforceFields(selectedMenu === SLASH_MENU.SALESFORCE);
  const { noteTemplates: templates, isLoading: templatesIsLoding } = useNoteTemplatesContext();
  const loadingState = {
    [SLASH_MENU.SALESFORCE]: fieldsIsLoading || fieldsIsRefetching,
    [SLASH_MENU.GONG]: gongCallsIsLoading || gongCallsIsRefetching,
    [SLASH_MENU.TEMPLATE]: templatesIsLoding,
  };

  const availableSfFields = useMemo(
    () => getAvailbaleSFFields(fields || [], deal, accountData?.account),
    [accountData?.account, deal, fields]
  );
  const loadingItems = {
    [SLASH_MENU.SALESFORCE]: availableSfFields,
    [SLASH_MENU.GONG]: gongCalls,
    [SLASH_MENU.TEMPLATE]: templates,
  };
  const withIcon = selectedMenu === SLASH_MENU.SALESFORCE || selectedMenu === SLASH_MENU.GONG;

  const options: SecondMenuValue[] = (loadingItems[selectedMenu] as SecondMenuValue[]) || [];

  const sortTemplates = (a: string, b: string) => {
    if (a === 'My Templates' && b !== 'My Templates') {
      return -1;
    } else if (a !== 'My Templates' && b === 'My Templates') {
      return 1;
    } else {
      return 0;
    }
  };

  const sortedOptionsByGroup = options
    .map((option) => {
      const type = getOptionGroupByType(option, clientName);
      (option as SecondMenuValueWithGroup).group = type;
      return option;
    })
    .sort((a, b) => {
      if (selectedMenu === SLASH_MENU.TEMPLATE) {
        return sortTemplates(
          (a as SecondMenuValueWithGroup).group,
          (b as SecondMenuValueWithGroup).group
        );
      }
      return (a as SecondMenuValueWithGroup).group.localeCompare(
        (b as SecondMenuValueWithGroup).group
      );
    });

  const isEmpty = !options.length;

  const noOptionText = (
    <>
      {selectedMenu === SLASH_MENU.GONG ? (
        <Typography>No Gong call recordings were found for this deal.</Typography>
      ) : selectedMenu === SLASH_MENU.TEMPLATE ? (
        <Typography>No Templates exist, add one with the buttton below.</Typography>
      ) : (
        <Typography>No options</Typography>
      )}
    </>
  );

  const uniqueSubheaders = Array.from(
    new Set(options.map((option: any) => option?.salesforceType ?? option?.type))
  );

  const selectField = (chosenField: SecondMenuValue) => {
    const item = items.find((item: ISlashCommandProps) => item.name === selectedMenu);

    selectFieldByType({ itemCommand: item?.command, chosenField, command, values: options });
  };

  const handleTemplatePage = () => {
    if (selectedMenu === SLASH_MENU.TEMPLATE) {
      const firstTemplate =
        (options as NoteTemplates[])?.find((template: NoteTemplates) => template?.type === 'Client')
          ?.id ?? options?.[0]?.id;
      if (firstTemplate) return navigate(`/${TEMPLATES_PATH}/${firstTemplate}`);
      navigate(`/${TEMPLATES_PATH}`);
    }
  };

  const handleChangeSearchValue = (newValue: SecondMenuValue) => {
    if (newValue) {
      setSearchValue(newValue);
      selectField(newValue);
      return;
    }
  };
  const getHeight = () => {
    const subheadersHeight = uniqueSubheaders.length * 42;
    const inputAndPaddings = 120;
    const heightOfItem = selectedMenu === SLASH_MENU.GONG ? 56 : 40;
    if (options.length === 0) return 180;
    if (options.length * heightOfItem + subheadersHeight + inputAndPaddings > 410) return 410;

    return options.length * heightOfItem + subheadersHeight + inputAndPaddings;
  };

  return (
    <Stack
      direction="column"
      sx={{
        position: 'relative',
        background: theme.palette.common.white,
        width: '299px',
        height: getHeight() + 'px',
        maxHeight: '410px',
        boxShadow:
          '0 0 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24)',
        borderRadius: '12px',
        overflow: 'auto',
        overflowX: 'hidden',
      }}
    >
      {loadingState[selectedMenu] ? (
        <LoadingScreen color={theme.palette.primary.dark} />
      ) : (
        <Autocomplete
          open={open}
          onOpen={() => setOpen(true)}
          onClose={(event: any, reason: string) => {
            if (event?.relatedTarget?.className === 'tippy-box') return;
            if (event?.relatedTarget?.innerText === 'Manage Templates') return;
            handleCloseMenu(reason);
          }}
          value={searchValue}
          onChange={(event, newValue) => handleChangeSearchValue(newValue)}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          disablePortal
          forcePopupIcon={false}
          size="small"
          sx={{
            padding: 2,
            width: '299px',
            visibility: isEmpty ? 'hidden' : 'visible',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: `${theme.palette.grey[300]}!important`,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: `${theme.palette.grey[300]}!important`,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: `${theme.palette.grey[300]}!important`,
            },
            '& + .MuiAutocomplete-popper': {
              top: isEmpty ? `-40px!important` : '0',
            },
          }}
          PaperComponent={(props) => CustomPaper(props, getHeight())}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              hiddenLabel
              placeholder={getOptionPlaceHolderByType(selectedMenu)}
              sx={{
                '.MuiInputBase-root': {
                  fontSize: '14px',
                  fontWeight: '400',
                },
              }}
            />
          )}
          renderOption={(props, option) => (
            <Typography
              {...props}
              component="span"
              key={option?.id ?? crypto.randomUUID()}
              style={{
                paddingLeft: '8px',
                height: selectedMenu === SLASH_MENU.GONG ? '56px' : '38px',
              }}
            >
              {withIcon && (
                <Box
                  width={24}
                  height={24}
                  marginRight="8px"
                  sx={{ '& svg': { width: '24px', height: '24px' } }}
                >
                  {selectedMenu === SLASH_MENU.SALESFORCE && <SalesforceIcon />}
                  {selectedMenu === SLASH_MENU.GONG && <GongIcon />}
                </Box>
              )}
              {SLASH_MENU.GONG && isGong(option) ? (
                <Stack direction="column" sx={{ width: '210px' }}>
                  <Typography
                    variant="body2"
                    color={theme.palette.grey[800]}
                    noWrap
                    sx={{ fontWeight: 400 }}
                  >
                    {getOptionLabelByType(option)}
                  </Typography>
                  <Typography
                    variant="caption"
                    color={theme.palette.grey[600]}
                    noWrap
                    sx={{ fontWeight: 400 }}
                  >
                    {option?.started ? fDateMeeting(option.started) : ''} -{' '}
                    {option?.ended ? fDateTimeOnly(option.ended) : ''}
                  </Typography>
                </Stack>
              ) : (
                <Typography
                  variant="body2"
                  color={theme.palette.grey[800]}
                  noWrap
                  sx={{ fontWeight: 500 }}
                >
                  {getOptionLabelByType(option)}
                </Typography>
              )}
            </Typography>
          )}
          options={sortedOptionsByGroup}
          noOptionsText={noOptionText}
          getOptionLabel={(option) => getOptionLabelByType(option)}
          groupBy={(option) => getOptionGroupByType(option, clientName)}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
        />
      )}

      {selectedMenu === SLASH_MENU.TEMPLATE ? (
        <Button
          color="inherit"
          size="small"
          variant="outlined"
          onClick={handleTemplatePage}
          sx={{
            position: 'absolute',
            left: '16px',
            right: '16px',
            bottom: '16px',
          }}
        >
          Manage Templates
        </Button>
      ) : (
        <Button
          color="inherit"
          size="small"
          variant="contained"
          sx={{ marginLeft: 'auto', position: 'absolute', right: '16px', bottom: '16px' }}
          onClick={() => handleCloseMenu('cancel')}
        >
          Cancel
        </Button>
      )}
    </Stack>
  );
};

export default SecondMenu;

const CustomPaper = (props: any, height: number) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={8}
      sx={{
        position: 'relative',
        zIndex: '9999',
        height: height - 100 + 'px',
        boxShadow: 'none!important',
        '.MuiAutocomplete-listbox': {
          padding: '0',
          maxHeight: '100%',
          '.MuiListSubheader-root': {
            height: '34px',
            // marginTop: '8px',
            padding: '16px 0 8px',
            fontWeight: '700',
            lineHeight: '18px',
            textTransform: 'uppercase',
            fontSize: '12px',
            top: '0',
          },
          '.MuiAutocomplete-option': {
            color: theme.palette.grey[800],
          },
        },
      }}
      {...props}
    />
  );
};
