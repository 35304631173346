import { Editor } from '@tiptap/core';
import React from 'react';

import { ReactComponent as LinkIcon } from '../../../assets/icons/tiptapIcons/link-m.svg';
import Button from './Button';

const LinkButton = ({ editor, setIsEditing }: { editor: Editor; setIsEditing: any }) => (
  <Button
    editor={editor}
    icon={<LinkIcon />}
    tooltip={'Create Link'}
    isActiveName={'link'}
    clickCommand={() => setIsEditing(true)}
  />
);

export default LinkButton;
