import { Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import React from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import SalesforceOpportunityStages from '../../../../@types/api/salesforceOpportunityStages';
import Deal from '../../../../@types/core/deal';
import { IDataNavSection } from '../../../../components/nav-section';
import FiltersAndGroups from '../../../../components/nav-section/vertical/FiltersAndGroups';
import { FiltersGroup } from '../../../../components/nav-section/vertical/styles';
import Scrollbar from '../../../../components/scrollbar';
import { useLocales } from '../../../../locales';
import {
  getDealCompanies,
  getDealCompaniesName,
  getDealOwners,
  getDealStages,
} from '../../../../main/DealUtils';
import { ACCOUNTS_PATH, DEALS_PATH, LOCAL_STORAGE } from 'src/config';

type NavAccountsSectionProps = {
  stages: SalesforceOpportunityStages[];
  items: IDataNavSection[];
  filteredDeals: IDataNavSection[];
  deals: Deal[];
  localDataFilters: any;
  localStorageGroup: string;
  handleOnClickGroupItem: (item: any) => void;
  handleCheckbox: (event: any, stage: any) => void;
  handleSelectAll: (filtersName: string[], stage: string, isSelectedAll: boolean) => void;
};

const AccountsNavSection = ({
  deals,
  filteredDeals,
  items,
  localDataFilters,
  localStorageGroup,
  handleOnClickGroupItem,
  handleCheckbox,
  handleSelectAll,
  stages,
}: NavAccountsSectionProps) => {
  const theme = useTheme();
  const filteredDealsForAccounts = deals.filter((deal) =>
    filteredDeals[0].items.find((item) => item.id === deal.id)
  );
  const accounts = getDealCompanies(filteredDealsForAccounts);

  const { translate } = useLocales();

  const { account_id } = useParams();

  const navigate = useNavigate();
  const handleNavigateToCompany = (accountId: string) => {
    navigate(`/${ACCOUNTS_PATH}/${accountId}/${DEALS_PATH}     `);
  };

  const filters = [
    {
      name: 'Company',
      values: getDealCompaniesName(deals),
    },
    {
      name: 'Deal Owner',
      values: getDealOwners(deals),
    },
    {
      name: 'Stage',
      values: getDealStages(stages),
    },
  ];

  const groups = [
    {
      name: 'Company',
      value: 'Company',
    },
    {
      name: 'Close Date',
      value: 'CloseDate',
    },
    {
      name: 'Deal Owner',
      value: 'DealOwner',
    },
    {
      name: 'Forecast Category',
      value: 'ForecastCategory',
    },
    {
      name: 'Stage',
      value: 'Stage',
    },
    {
      name: 'None',
      value: 'None',
    },
  ];

  return (
    <Stack sx={{ height: '100%', paddingBottom: '420px' }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: '8px 36px 12px 36px',
          position: 'sticky',
          top: 0,
        }}
      >
        <Typography
          variant="overline"
          sx={{
            fontSize: '12px',
            fontWeight: 700,
            color: theme.palette.grey[500],
            textTransform: 'uppercase',
          }}
        >
          Accounts
        </Typography>
        {items?.map((group) => (
          <React.Fragment key={group.subheader}>
            {group.subheader && (
              <FiltersGroup>
                {translate(group.subheader) === 'Deals' && (
                  <FiltersAndGroups
                    localDataFilters={localDataFilters}
                    localStorageGroup={localStorageGroup}
                    handleCheckbox={handleCheckbox}
                    handleSelectAll={handleSelectAll}
                    handleOnClickGroupItem={handleOnClickGroupItem}
                    filters={filters}
                    groups={groups}
                    filterPage={LOCAL_STORAGE.DEALS_FILTERS}
                    grouping={false}
                  />
                )}
              </FiltersGroup>
            )}
          </React.Fragment>
        ))}
      </Stack>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            position: 'relative',
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <Stack sx={{ padding: '0 24px' }}>
          {accounts.map((account) => {
            const isActiveAccount = account.id === account_id;
            return (
              <Typography
                key={account.id}
                onClick={() => handleNavigateToCompany(account.id)}
                variant={isActiveAccount ? 'subtitle2' : 'body2'}
                color={isActiveAccount ? theme.palette.common.white : theme.palette.grey[500]}
                sx={{
                  padding: '8px 12px',
                  marginBottom: 0.5,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  backgroundColor: isActiveAccount
                    ? alpha(theme.palette.grey[700], 0.3)
                    : 'transparent',
                  borderRadius: 1,
                  textTransform: 'none',
                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: isActiveAccount
                      ? alpha(theme.palette.grey[700], 0.6)
                      : alpha(theme.palette.grey[700], 0.3),
                  },
                }}
              >
                {account.name}
              </Typography>
            );
          })}
        </Stack>
      </Scrollbar>
    </Stack>
  );
};

export default AccountsNavSection;
