import { Box, Typography, useTheme } from '@mui/material';
import { type NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import { useAuthContext } from 'src/auth/useAuthContext';
import { CustomAvatar } from 'src/components/custom-avatar';

const AIRequestNodeView = ({ node }: NodeViewProps) => {
  const { user } = useAuthContext();
  const theme = useTheme();
  return (
    <NodeViewWrapper
      as="div"
      style={{
        display: 'flex',
        width: '100%',
      }}
    >
      <Box sx={{ width: 1, marginBottom: '32px' }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <Box sx={{ marginRight: '12px' }}>
            {
              <CustomAvatar
                name={user?.name}
                sx={{
                  width: '24px',
                  height: '24px',
                  backgroundColor: '#826AF9',
                  color: theme.palette.common.white,
                  fontSize: '12px',
                  fontWeight: '600px',
                }}
                src={user?.image}
              />
            }
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{ fontSize: '15px', fontWeight: '700', lineHeight: '26px', userSelect: 'none' }}
            >
              You
            </Typography>
            <Box
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
              }}
            >
              {node.attrs.text}
            </Box>
          </Box>
        </Box>
      </Box>
    </NodeViewWrapper>
  );
};

export default AIRequestNodeView;
