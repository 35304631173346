import { TextField } from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

import { getSalesforceIcon } from '../SlashMenuUtils';
import { SalesforceFieldTypes } from '../types';
import { SalesforceInputComponentProps } from './SalesforceInputComponent';

const NumberInputComponent = forwardRef(
  ({ node, disabled, handleChangeValue, initialValue }: SalesforceInputComponentProps, ref) => {
    // Initialize state with the default value of 0
    const [inputValue, setInputValue] = useState<string>(initialValue || '0');

    const handleChangeTextFieldNumber = useCallback(
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let { value } = event.target;

        // If the input is cleared, set it to "0"
        if (value === '') {
          value = '0';
        }

        let formattedValue = value;

        if (node.attrs.type === SalesforceFieldTypes.CURRENCY) {
          formattedValue = (Math.round(+value * 100) / 100).toString();
        } else if (node.attrs.type === SalesforceFieldTypes.PERCENT) {
          formattedValue = Math.round(+value).toString();
        }

        setInputValue(formattedValue); // Update the input value in state
        handleChangeValue(formattedValue, node.attrs.salesforceFieldName);
      },
      [handleChangeValue, node.attrs.salesforceFieldName, node.attrs.type]
    );

    useEffect(() => {
      // Ensure the input value is correctly initialized or reset to 0
      setInputValue(initialValue || '0');
    }, [initialValue]);

    const inputProps = useMemo(
      () => ({
        inputComponent: NumericFormatCustom as any,
        startAdornment: getSalesforceIcon({ node }),
        sx: { minHeight: '24px' },
        inputRef: ref,
        disableUnderline: true,
      }),
      [node, ref]
    );

    return (
      <TextField
        id={node.attrs.salesforceFieldName}
        fullWidth
        hiddenLabel
        disabled={disabled}
        value={inputValue}
        placeholder="0"
        onChange={handleChangeTextFieldNumber}
        variant="standard"
        InputProps={inputProps}
      />
    );
  }
);

export default NumberInputComponent;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        decimalScale={2}
      />
    );
  }
);
