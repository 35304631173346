import NoteTemplates from '../../@types/api/noteTemplates';
import Notes from '../../@types/api/notes';
import { AccessLevel, AccessLevelValues } from '../../@types/api/types/accessLevel';
import { AuthUserType } from '../../auth/types';
import { getCurrentUser } from '../share/ShareUtils';

export const isNoteEditable = (user: AuthUserType, note?: Notes | NoteTemplates) => {
  // Define a type guard for NoteTemplates
  const isNoteTemplate = (note: Notes | NoteTemplates): note is NoteTemplates =>
    'accessLevel' in note || 'noteTemplateUsers' in note;

  if (note) {
    if (isNoteTemplate(note)) {
      // Handle NoteTemplates
      return AccessLevelValues[note?.accessLevel || AccessLevel.Read] >= 200;
    } else {
      // Handle Notes
      const currentUser = getCurrentUser(note.noteUsers, user);
      return AccessLevelValues[currentUser?.accessLevel || AccessLevel.Read] >= 200;
    }
  }

  // Return a default value (e.g., false) if no note is provided
  return false;
};
