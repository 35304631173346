import { Box, Tab, Tabs, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { DEALS_PATH, NOTES_PATH } from 'src/config';

export const accountTabConfiguration = [
  {
    path: DEALS_PATH,
    label: 'Deals',
  },
  {
    path: NOTES_PATH,
    label: 'Notes',
  },
];

function AccountTabs() {
  const location = useLocation();

  const [value, setValue] = useState(accountTabConfiguration[0].path);

  useEffect(() => {
    const activeTab = location.pathname.split('/')[3];
    if (activeTab) {
      setValue(activeTab);
    }
  }, [location.pathname]);

  const theme = useTheme();

  const TabStyles = {
    marginRight: '40px',
    color: theme.palette.text.primary,
    fontSize: '14px',
    '&.MuiTab-root:not(.Mui-selected)': {
      color: theme.palette.text.secondary,
      backgroundColor: '#fff',
    },
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
        zIndex: '1000',
        position: 'sticky',
        backgroundColor: '#fff',
        top: '0px',
        padding: '0px 24px',
        borderRadius: '16px',
      }}
      gridColumn="span 10"
    >
      <Tabs
        value={value}
        onChange={handleChangeTab}
        sx={{
          color: '#fff',
          '& .MuiTabs-indicator': {
            height: '3px',
            bottom: '-2px',
            backgroundColor: theme.palette.text.primary,
          },
          '& .MuiTabs-scroller': {
            overflowX: 'visible',
            overflowY: 'visible',
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
          },
        }}
      >
        {accountTabConfiguration.map((tab) => (
          <Tab
            key={tab.path}
            sx={TabStyles}
            value={tab.path}
            label={tab.label}
            component={Link}
            to={tab.path}
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default AccountTabs;
