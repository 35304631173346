import { Button, Stack } from '@mui/material';
import React from 'react';

export const Buttons = ({
  activeButtonName,
  handleClick,
  buttonColor = 'success',
  handleCloseModal,
  disabled = false,
  content,
}: {
  activeButtonName: string;
  handleClick: any;
  buttonColor?: 'success' | 'error' | 'inherit' | 'primary' | 'secondary' | 'info' | 'warning';
  handleCloseModal: (e: any) => void;
  disabled?: boolean;
  content?: any;
}) => (
  <Stack spacing={1.5} sx={{ p: 3 }} display="flex" direction="row" justifyContent="flex-end">
    {content}
    <Button variant="outlined" color={'inherit'} size="medium" onClick={handleCloseModal}>
      Cancel
    </Button>
    <Button
      variant="contained"
      disabled={disabled}
      color={buttonColor}
      size="medium"
      onClick={handleClick}
    >
      {activeButtonName}
    </Button>
  </Stack>
);
