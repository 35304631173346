import Image from '@tiptap/extension-image';
import { ReactNodeViewRenderer } from '@tiptap/react';

import ContextImageComponent from '../react-components/ContextImageComponent';

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  // interface Commands<ReturnType> {
  // Custom commands go here. Leaving this snippet for any devs needing to make their own extension
  // }
}

export type ContextImageNode = {
  attrs: {
    src: string;
    title: string;
    alt: string;
    width: number;
    height: number;
    originalWidth: number;
    originalHeight: number;
  };
};

export const ContextImage = Image.extend({
  addOptions() {
    return {
      ...this.parent?.(),
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      width: { renderHTML: ({ width }) => ({ width }) },
      height: { renderHTML: ({ height }) => ({ height }) },
      originalWidth: { default: null },
      originalHeight: { default: null },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(ContextImageComponent);
  },
});
