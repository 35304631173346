import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import React, { forwardRef, useCallback, useState } from 'react';

import { SalesforceInputComponentProps } from './SalesforceInputComponent';

const DateTimePickerComponent = forwardRef(
  ({ node, disabled, handleChangeValue, initialValue }: SalesforceInputComponentProps, ref) => {
    const [value, setValue] = useState<Date | null>(() => {
      if (initialValue) {
        return typeof initialValue === 'string' ? parseISO(initialValue) : initialValue;
      }
      return null;
    });

    const handleChangeTime = useCallback(
      (newValue: Date | null) => {
        setValue(newValue);
        handleChangeValue(newValue, node.attrs.salesforceFieldName, true);
      },
      [handleChangeValue, node.attrs.salesforceFieldName]
    );

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          disabled={disabled}
          value={value}
          ref={ref as React.RefObject<HTMLDivElement>}
          onChange={handleChangeTime}
          slotProps={{
            popper: {
              placement: 'bottom-start',
              sx: { zIndex: 9999 },
              modifiers: [{ name: 'offset', options: { offset: [-30, 10] } }],
            },
            field: { readOnly: true },
            textField: {
              variant: 'standard',
              fullWidth: true,
              InputProps: {
                id: node.attrs.salesforceFieldName,
                type: 'datetime',
                disableUnderline: true,
                sx: {
                  flexDirection: 'row-reverse',
                  '& .MuiInputBase-input': {
                    marginLeft: '12px',
                  },
                  '& .MuiInputAdornment-root': {
                    marginLeft: '-3px',
                    '& .MuiButtonBase-root': {
                      paddingLeft: '0',
                    },
                  },
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    );
  }
);

export default DateTimePickerComponent;
