import { Box } from '@mui/material';
import { Outlet, useOutletContext } from 'react-router';

import AccountTabs from './AccountTabs';
import Companies from 'src/@types/api/companies';
import DealUsers from 'src/@types/api/dealUsers';
import StickyHeader from 'src/layouts/dashboard/header/StickyHeader';

export default function Account({
  account,
  accountIsRefetching,
  accountUsers,
}: {
  account: Companies;
  accountIsRefetching: boolean;
  accountUsers: DealUsers[];
}) {
  return (
    <Box
      sx={{
        position: 'relative',
        height: 'fit-content',
        minHeight: '100%',
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
      }}
    >
      <StickyHeader title={account.name} />
      <AccountTabs />
      <Outlet context={{ account, accountIsRefetching, accountUsers } satisfies IContext} />
    </Box>
  );
}

interface IContext {
  account: Companies;
  accountIsRefetching: boolean;
  accountUsers: DealUsers[];
}

export function useAccountOutletContext() {
  return useOutletContext<IContext>();
}
