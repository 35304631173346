import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';

import { ReactComponent as SendIcon } from '../../../assets/icons/tiptapIcons/bublemenu/sendIcon.svg';
import { ReactComponent as StopIcon } from '../../../assets/icons/tiptapIcons/bublemenu/stopIcon.svg';

const InputEndAdornment = ({
  isLoading,
  inputValue,
  sendClick,
  abortStream,
  setInputValue,
}: {
  isLoading: boolean;
  inputValue: string;
  sendClick: (inputValue: string) => void;
  abortStream?: () => void;
  setInputValue: (inputValue: string) => void;
}) => {
  const theme = useTheme();
  return isLoading ? (
    <Box
      sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={() => {
        abortStream && abortStream();
        setInputValue('');
      }}
    >
      <StopIcon color={theme.palette.text.secondary} />
      <Typography
        color={theme.palette.text.secondary}
        sx={{ lineHeight: '22px', fontSize: '14px', marginLeft: '3px' }}
      >
        Stop
      </Typography>
    </Box>
  ) : (
    <Button
      sx={{
        padding: '0px',
        minWidth: '20px',
      }}
      disabled={inputValue.length === 0}
      onClick={() => {
        sendClick(inputValue);
        setInputValue('');
      }}
    >
      {inputValue.length > 0 ? (
        <Tooltip title="Send" placement="top">
          <SendIcon style={{ color: theme.palette.primary.main }} />
        </Tooltip>
      ) : (
        <SendIcon style={{ color: theme.palette.grey[500] }} />
      )}
    </Button>
  );
};

export default InputEndAdornment;
