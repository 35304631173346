import { FormControl, MenuItem, OutlinedInput, Select, useTheme } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';

import { SalesforceInputComponentProps } from './SalesforceInputComponent';

const DropDownComponent = forwardRef(
  ({ node, disabled, handleChangeValue, initialValue }: SalesforceInputComponentProps, ref) => {
    const { picklistValues } = node.attrs;
    const theme = useTheme();

    const [value, setValue] = useState(initialValue ?? '');

    const handleChange = useCallback(
      (event: SelectChangeEvent) => {
        const newValue = event.target.value;
        setValue(newValue);
        handleChangeValue(newValue, node.attrs.salesforceFieldName, true);
      },
      [handleChangeValue, node.attrs.salesforceFieldName]
    );

    const commonInputProps = useMemo(
      () => ({
        id: node.attrs.salesforceFieldName,
        inputRef: ref,
        fullWidth: true,
        sx: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.grey[300]}!important`,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.grey[300]}!important`,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.grey[300]}!important`,
          },
          WebkitUserModify: disabled ? 'read-only' : 'read-write',
        },
      }),
      [theme, disabled, ref, node.attrs.salesforceFieldName]
    );

    return (
      <FormControl size="small" sx={{ marginTop: 1 }}>
        <Select
          disabled={disabled}
          value={disabled ? initialValue : value}
          onChange={!disabled ? handleChange : undefined}
          MenuProps={{
            PaperProps: {
              sx: {
                padding: '0 8px',
                maxHeight: '190px',
                backgroundColor: theme.palette.common.white,
              },
            },
          }}
          input={<OutlinedInput {...commonInputProps} />}
        >
          {disabled ? (
            <MenuItem value={initialValue}>{initialValue}</MenuItem>
          ) : (
            picklistValues.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                sx={{ color: theme.palette.common.black, borderRadius: 1 }}
              >
                {item.value}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    );
  }
);

export default DropDownComponent;
