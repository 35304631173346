import { Dispatch, createContext, useContext, useState } from 'react';

import { SESSION_STORAGE } from '../config';
import { NavSection } from '../layouts/dashboard/nav/NavVertical';

interface IActiveSectionContext {
  activeSection: NavSection | null;
  setActiveSection: Dispatch<any>;
}

const ActiveSectionContext = createContext<IActiveSectionContext>({
  activeSection: null,
  setActiveSection: () => {},
});

export function useActiveSectionContext() {
  return useContext(ActiveSectionContext);
}

export const useActiveSectionState = () => {
  const [activeSection, setActiveSection] = useState<NavSection | null>(
    (sessionStorage.getItem(SESSION_STORAGE.SELECTED_SECTION) as NavSection) ?? null
  );

  return { activeSection, setActiveSection };
};

export default ActiveSectionContext;
