import { Editor } from '@tiptap/core';
import React from 'react';

import { ReactComponent as TasklistIcon } from '../../../assets/icons/tiptapIcons/list-taskedlist.svg';
import Button from './Button';

const TasklistButton = ({ editor }: { editor: Editor }) => {
  const handleToggle = (editor: Editor) => {
    editor.chain().focus().toggleTaskList().run();
  };

  return (
    <Button
      editor={editor}
      shortcut={'mod-Shift-9'}
      icon={<TasklistIcon />}
      tooltip={'Task List'}
      isActiveName={'taskList'}
      clickCommand={handleToggle}
    />
  );
};

export default TasklistButton;
