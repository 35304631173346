import React, { createContext, useContext } from 'react';

interface INotesMenuContext {
  openMenu: HTMLButtonElement | null;
  setOpenMenu: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  handleClose: () => void;
}

export const NotesMenuContext = createContext<INotesMenuContext>({
  openMenu: null,
  setOpenMenu: () => {},
  handleClose: () => {},
});

export const useNotesMenuContextState = () => {
  const [openMenu, setOpenMenu] = React.useState<HTMLButtonElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  return {
    openMenu,
    setOpenMenu,
    handleOpenMenu,
    handleCloseMenu,
  };
};

export function useNotesMenuContext() {
  return useContext(NotesMenuContext);
}
