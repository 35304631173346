import { MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React from 'react';

import { NotesSource } from '../../../../../@types/api/notes';
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/dealIcons/copy.svg';
import { useNotesMenuContext } from '../../../../../hooks/useNotesMenuContext';
import { useDuplicateNote } from '../../../../../hooks/useQueries';
import { INoteTreeNode } from '../DashboardNavNotesUtils';
import { useNotesContext } from 'src/hooks/useNotesContext';

interface Props {
  note: INoteTreeNode;
  handleClose?: () => void;
  isNotePage?: boolean;
}

const DuplicateNote = ({ note, handleClose, isNotePage }: Props) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const { handleClose: handleCloseNote } = useNotesMenuContext();
  const { refresh } = useNotesContext();
  const duplicateNote = useDuplicateNote(NotesSource.User, note.id);

  const handleDuplicateNote = () => {
    duplicateNote
      .mutateAsync({ sourceId: crypto.randomUUID() })
      .then(async () => {
        await refresh(NotesSource.User);
        enqueueSnackbar('Note Duplicated!', { variant: 'success' });
      })
      .catch(() => enqueueSnackbar('Note Not Duplicated!', { variant: 'error' }))
      .finally(() => {
        handleClose && handleClose();
        handleCloseNote && handleCloseNote();
      });
  };

  return (
    <>
      <MenuItem
        onClick={handleDuplicateNote}
        sx={{ color: isNotePage ? theme.palette.common.black : theme.palette.common.white }}
      >
        <CopyIcon style={{ marginRight: '8px' }} />
        Duplicate
      </MenuItem>
      {/*<GeneralModalComponent*/}
      {/*  handleCloseModal={handleCloseModal}*/}
      {/*  buttons={*/}
      {/*    <Buttons*/}
      {/*      activeButtonName="Duplicate"*/}
      {/*      handleClick={handleDuplicateNote}*/}
      {/*      handleCloseModal={handleCloseModal}*/}
      {/*    />*/}
      {/*  }*/}
      {/*  content={*/}
      {/*    <Typography variant="body1" color={theme.palette.grey[800]} sx={{ padding: '0 24px' }}>*/}
      {/*      {`Are you sure you want to duplicate "${noteTitle}"?`}*/}
      {/*    </Typography>*/}
      {/*  }*/}
      {/*  open={open}*/}
      {/*  headerText={'Duplicate Note'}*/}
      {/*/>*/}
    </>
  );
};

export default DuplicateNote;
