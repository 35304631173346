import { Editor } from '@tiptap/core';
import { Level } from '@tiptap/extension-heading';
import React from 'react';

import { ReactComponent as HeadingLevelOneIcon } from '../../../assets/icons/tiptapIcons/h-1.svg';
import { ReactComponent as HeadingLevelTwoIcon } from '../../../assets/icons/tiptapIcons/h-2.svg';
import Button from './Button';

const HeadingButton = ({ editor, level }: { editor: Editor; level: Level }) => {
  const handleToggle = (editor: Editor) => {
    editor.chain().focus().toggleHeading({ level }).run();
  };

  return (
    <Button
      editor={editor}
      shortcut={`mod-Alt-${level}`}
      icon={level === 1 ? <HeadingLevelOneIcon /> : <HeadingLevelTwoIcon />}
      tooltip={`Heading ${level}`}
      isActiveName={'heading'}
      activeNameAttr={{ level }}
      clickCommand={handleToggle}
    />
  );
};

export default HeadingButton;
