import { Link, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';

import { ReactComponent as GongIcon } from '../../assets/icons/dealIcons/ic-gong.svg';
import { fDateMeeting, fDateTimeOnly } from '../../utils/formatTime';
import { IconStyle } from '../iconify';
import { GeneralModalComponent } from '../modal/GeneralModalComponent';

type GongModalProps = {
  open: boolean;
  handleCloseModal: (e: any) => void;
  name: string;
  callId: string;
  start: Date;
  end: Date;
};

const GongModal = ({ open, handleCloseModal, name, callId, end, start }: GongModalProps) => {
  const theme = useTheme();

  return (
    <GeneralModalComponent
      handleCloseModal={handleCloseModal}
      content={
        <Stack padding={3} paddingTop={0}>
          <iframe
            title="gong call"
            src={`https://app.gong.io/embedded-call?call-id=${callId}`}
            width={600}
            height={523}
            style={{ border: 'none' }}
          />
        </Stack>
      }
      open={open}
      header={
        <Stack direction="row" spacing={1}>
          <GongIcon />
          <Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="h6">{name}</Typography>
              <Link
                height={'16px'}
                href={`https://app.gong.io/embedded-call?call-id=${callId}`}
                target="_blank"
                alignSelf={'flex-start'}
                style={{ marginTop: '6px' }}
              >
                <Tooltip
                  title={'Open Call in Gong'}
                  sx={{ cursor: 'pointer' }}
                  placement={'top'}
                  PopperProps={{
                    disablePortal: true,
                  }}
                >
                  <IconStyle
                    sx={{ height: '16px' }}
                    icon={'mdi:external-link'}
                    color={theme.palette.grey[500]}
                  />
                </Tooltip>
              </Link>
            </Stack>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              {start ? fDateMeeting(start) : ''} - {end ? fDateTimeOnly(end) : ''}
            </Typography>
          </Stack>
        </Stack>
      }
      headerText={'Gong meeting'}
    />
  );
};

export default GongModal;
