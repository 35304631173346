import { ReactNode } from 'react';

// import { Navigate } from 'react-router-dom';
//
// import { GONG_PATH, LOCAL_STORAGE } from '../config';
// import { useGetGongToken } from '../hooks/useQueries';
import LoadingScreen from 'src/components/loading-screen';

type GongGuardProps = {
  children: ReactNode;
};

export default function GongGuard({ children }: GongGuardProps) {
  // const { data, isLoading } = useGetGongToken();

  const isLoading = false;

  // const isSkipGongPermanently = useMemo(() => localStorage.getItem(LOCAL_STORAGE.GONG_SKIP), []);
  // const isSkipGongSession = useMemo(() => sessionStorage.getItem(LOCAL_STORAGE.GONG_SKIP), []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  // if (!isSkipGongPermanently && !isSkipGongSession && data?.accessToken !== 'ok') {
  //   return <Navigate to={`/${GONG_PATH}`} replace />;
  // }

  return <>{children}</>;
}
