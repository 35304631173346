import { Stack } from '@mui/material';
import React from 'react';

import Logo from '../../../components/logo';

const NavHeader = () => (
  <Stack
    spacing={3}
    sx={{
      pt: 3,
      pb: 2.5,
      pl: 3,
      pr: 3,
      flexShrink: 0,
    }}
  >
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Logo path={'/logo/context_full_white.svg'} sx={{ width: 92, height: 24 }} />
    </Stack>
  </Stack>
);

export default NavHeader;
