import { Stack, TextField, Typography, useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState } from 'react';

import { ISlashNodeInterface } from '../../extensions/ContextSalesforceExtension';
import { useSalesorceComponent } from './withSalesforce';
import { SalesforceType } from 'src/@types/api/types/salesforce';
import ModalComponent from 'src/components/modal/Modal';
import SaveAndCancel from 'src/components/modal/SaveAndCancel';
import { sortAlphabetically } from 'src/utils/sortAlphabetically';

export interface ISaveAndEditDealProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseModal: () => void;
  handleCloseMenu?: () => void;
  fieldType: SalesforceType;
  node: ISlashNodeInterface;
  options: ILinkField[];
  updateAttributes?: (attributes: Record<string, any>) => void;
}
export interface ILinkField {
  salesforceType: string;
  name: string;
  salesforceKey: string;
}

const LinkFieldModal = ({
  open,
  setOpen,
  handleCloseModal,
  handleCloseMenu,
  fieldType,
  options,
  updateAttributes,
}: ISaveAndEditDealProps) => {
  const theme = useTheme();
  const { setSalesforceKey, salesforceKey } = useSalesorceComponent();

  const [selectSource, setSelectSource] = useState<ILinkField | null>(
    options?.find((company) => salesforceKey === company.salesforceKey) ?? null
  );

  const [inputDeal, setInputDeal] = useState('');

  const handleSave = () => {
    handleCloseMenu && handleCloseMenu();
    setOpen(false);
    setSalesforceKey(selectSource?.salesforceKey);
    setTimeout(() => updateAttributes && updateAttributes({ lastUpdated: '' }));
  };

  const handleChangeDeal = (_: any, company: ILinkField | null) => {
    setSelectSource(company);
  };

  return (
    <ModalComponent
      open={open}
      handleClose={handleCloseModal}
      header={<Typography variant={'h6'}>Link Field to Salesforce {fieldType}</Typography>}
      buttons={
        <SaveAndCancel
          handleClose={handleCloseModal}
          handleSave={handleSave}
          isDisabled={!selectSource}
        />
      }
    >
      <Stack sx={{ padding: '0 24px', width: '100%' }}>
        <Autocomplete
          size={'small'}
          value={selectSource}
          onChange={(event, value) => handleChangeDeal(event, value)}
          inputValue={inputDeal}
          isOptionEqualToValue={(option, value) =>
            option.salesforceKey === value.salesforceKey || value.salesforceKey === ''
          }
          onInputChange={(event, newInputValue) => {
            setInputDeal(newInputValue);
          }}
          options={options?.sort(sortAlphabetically((option) => option.name)) ?? []}
          getOptionLabel={(option) => option?.name || ''}
          getOptionDisabled={(option) => option.salesforceKey === selectSource?.salesforceKey}
          renderOption={(props, option, idx) => (
            <li {...props} key={option?.salesforceKey + idx.index}>
              {option?.name}
            </li>
          )}
          componentsProps={{
            popper: {
              sx: {
                zIndex: '9999',
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={`Select ${fieldType}`}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.grey[300]}!important`,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.grey[300]}!important`,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.grey[300]}!important`,
                },
              }}
            />
          )}
        />
      </Stack>
    </ModalComponent>
  );
};

export default LinkFieldModal;
