import { ListItemAvatar, ListItemText, MenuItem, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import User from '../../../@types/core/user';
import { useAuthContext } from '../../../auth/useAuthContext';
import { CustomAvatar } from '../../../components/custom-avatar';
import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover';
import { CONTEXT_AUTH, LOCAL_STORAGE, SESSION_STORAGE } from '../../../config';
import { useGetSalesforceToken, useGetUsers } from '../../../hooks/useQueries';
import { scrollStyles } from '../../../sections/core/deal/Sidebar';
import { apiQuery } from '../../../utils/apiQuery';
import LoadingScreen from 'src/components/loading-screen';

function getEmails(data: User[]) {
  return data.map((user: any) => user.email);
}

function getUserId(users: User[], email: string) {
  const user = users.find((user) => user.email === email);
  if (user === undefined) return '';
  return user.id;
}

function sortEmails(emails: string[]): string[] {
  return emails.sort((a, b) => {
    const [aUsername, aDomain] = a.split('@');
    const [bUsername, bDomain] = b.split('@');

    if (aDomain === bDomain) {
      return aUsername.localeCompare(bUsername);
    }

    return aDomain.localeCompare(bDomain);
  });
}

export default function SwitchUserPopover() {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { data, isLoading, isError, error } = useGetUsers();
  const users = data ? data : [];
  const { enqueueSnackbar } = useSnackbar();
  const { auth } = useAuthContext();
  const { refetch } = useGetSalesforceToken();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    //TODO: add an error message
    return <h1>{JSON.stringify(error)}</h1>;
  }

  const userList = sortEmails(getEmails(users));

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const clearStorageOnImpersonate = () => {
    localStorage.removeItem(LOCAL_STORAGE.DEALS_FILTERS);
    localStorage.removeItem(LOCAL_STORAGE.GROUPING);
    localStorage.removeItem(LOCAL_STORAGE.VIEW_USER);
    sessionStorage.removeItem(SESSION_STORAGE.SELECTED_SECTION);
  };

  const updateData = async () => {
    await queryClient.refetchQueries({ queryKey: ['stages'] });
    await queryClient.invalidateQueries({ queryKey: ['deals'] });
    await queryClient.invalidateQueries({ queryKey: ['noteTemplates'] });
  };

  function handleSelectPerson(user: User[], email: string) {
    const userId = getUserId(user, email);
    apiQuery('/admin/users/impersonate', {
      method: 'POST',
      body: JSON.stringify({ userId }),
      credentials: 'include',
      resetAccessToken: true,
    })
      .then(async () => {
        await auth();
        await updateData();
        await refetch();
      })
      .finally(() => {
        clearStorageOnImpersonate();
        handleClosePopover();
        enqueueSnackbar(`Impersonating ${email}`, { variant: 'success' });
        navigate(CONTEXT_AUTH.login);
      });
  }

  return (
    <>
      <MenuItem
        onClick={handleOpenPopover}
        sx={{
          //m: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          ':hover': { cursor: 'pointer' },
        }}
      >
        <Typography variant="body2">Impersonate User</Typography>
        <Iconify
          icon={'ic:baseline-arrow-right'}
          sx={{
            marginRight: '0!important',
          }}
        />
      </MenuItem>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        disabledArrow
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        sx={{
          maxHeight: '440px',
          overflow: 'auto',
          ...scrollStyles,
          p: 0,
        }}
      >
        {userList.map((email: string) => (
          <MenuItem
            key={email}
            onClick={() => {
              handleSelectPerson(users, email);
            }}
            sx={{ m: 0.5 }}
          >
            <ListItemAvatar>
              <CustomAvatar
                sx={{ height: 20, width: 20, m: 0 }}
                //src={user?.image}
                name={email}
              />
            </ListItemAvatar>

            <ListItemText
              primary={email}
              primaryTypographyProps={{ typography: 'body2', sx: { mb: 0.25 } }}
            />
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}
