import GongCalls from '../../../../@types/api/gongCalls';
import NoteTemplates, { TemplateType } from '../../../../@types/api/noteTemplates';
import { ClientConfigType, SalesforceField } from '../../../../@types/api/types/configTypes';
import { ISlashNodeInterface } from '../../extensions/ContextSalesforceExtension';
import { GongAttributesType } from '../../extensions/GongNode';
import { TemplateAttributesType } from '../SlashCommands/TemplateCommand';
import { isCompoundParent } from '../SlashMenuUtils';
import { SLASH_MENU } from '../types';
import { NewItemProps, SecondMenuValue } from './types';
import Companies from 'src/@types/api/companies';
import { SalesforceType } from 'src/@types/api/types/salesforce';
import Deal from 'src/@types/core/deal';
import { sortAlphabetically } from 'src/utils/sortAlphabetically';

export const isSalesforce = (option: SecondMenuValue): option is SalesforceField =>
  (option as SalesforceField)?.picklistValues !== undefined;

export const isGong = (option: SecondMenuValue): option is GongCalls =>
  (option as GongCalls)?.accountIds !== undefined;

export const isTemplate = (option: SecondMenuValue): option is NoteTemplates =>
  (option as NoteTemplates)?.value !== undefined;

export const getOptionLabelByType = (option: SecondMenuValue) => {
  if (!option) return 'No Label';
  if (isSalesforce(option)) {
    const { updateable, calculatedFormula, label } = option;
    const details = !updateable || calculatedFormula ? ' (Read-Only)' : '';
    return `${label}${details}`;
  }
  if (isGong(option)) {
    return option.title ?? 'No Name';
  }
  if (option.type === TemplateType.Client || option.type === TemplateType.User) {
    return option.title ?? option.name;
  }
  return option.name;
};

export const getOptionGroupByType = (option: SecondMenuValue, clientName?: string) => {
  if (!option) return 'No Group';
  if (isSalesforce(option)) {
    return option.salesforceType;
  }

  if (isGong(option)) {
    return 'Gong Calls';
  }
  if (option.type === TemplateType.Client) return `${clientName || 'Account'} Templates`;
  return 'My Templates';
};

export const getOptionPlaceHolderByType = (selectedMenu: string) => {
  if (!selectedMenu) return 'Type Something';
  if (selectedMenu === SLASH_MENU.GONG) return 'Search for a Gong call to insert';
  if (selectedMenu === SLASH_MENU.SALESFORCE) {
    return 'Search for a Salesforce field to add';
  }
  return 'Search templates';
};

const selectSalesforceField = ({
  itemCommand,
  chosenField,
  command,
  values,
}: {
  itemCommand: any;
  chosenField: SalesforceField;
  command: any;
  values: any[];
}) => {
  const isCompoundFieldName =
    values &&
    isCompoundParent({
      item: chosenField,
      fields: values as SalesforceField[],
    });

  //
  const newItem: NewItemProps<ISlashNodeInterface['attrs']> = {
    command: itemCommand,
    attrs: {
      salesforceFieldLabel: chosenField.label,
      salesforceFieldName: chosenField.name,
      salesforceType: chosenField.salesforceType,
      salesforceKey: chosenField.key,
      calculatedFormula: chosenField.calculatedFormula,
      picklistValues: chosenField.picklistValues,
      compoundFieldName: chosenField.compoundFieldName,
      isCompoundParent: Boolean(isCompoundFieldName),
      updateable: chosenField.updateable,
      type: chosenField.type,
      lastUpdated: new Date(),
      value: chosenField.value,
    },
  };

  return command(newItem);
};

const selectTemplateField = ({
  itemCommand,
  chosenField,
  command,
}: {
  itemCommand?: any;
  chosenField: NoteTemplates;
  command: any;
}) => {
  const newItem: NewItemProps<TemplateAttributesType> = {
    command: itemCommand,
    attrs: {
      templateValue: chosenField.value,
    },
  };

  return command(newItem);
};

const selectGongField = ({
  itemCommand,
  chosenField,
  command,
}: {
  itemCommand?: any;
  chosenField: GongCalls;
  command: any;
}) => {
  const newItem: NewItemProps<GongAttributesType> = {
    command: itemCommand,
    attrs: {
      id: chosenField?.id,
      name: chosenField?.title ?? 'No name',
      callId: chosenField?.callId,
      start: chosenField?.started,
      end: chosenField?.started,
    },
  };

  return command(newItem);
};

export const selectFieldByType = ({
  itemCommand,
  chosenField,
  command,
  values,
}: {
  itemCommand?: any;
  chosenField: SecondMenuValue;
  command: any;
  values: any[];
}) => {
  if (chosenField && isSalesforce(chosenField)) {
    return selectSalesforceField({ itemCommand, chosenField, command, values });
  }
  if (chosenField && isTemplate(chosenField)) {
    return selectTemplateField({ itemCommand, chosenField, command });
  }
  if (chosenField && isGong(chosenField)) {
    return selectGongField({ itemCommand, chosenField, command });
  }
};

export const getAvailbaleSFFields = (fields: any[], thisDeal?: Deal, account?: Companies) => {
  const data = thisDeal?.id ? thisDeal?.getSalesforceDataStructure() : [];

  const salesforceKeyAccount =
    data?.find((field) => field.type === SalesforceType.ACCOUNT)?.fields.salesforceKey ||
    account?.salesforceKey ||
    '';
  const salesforceKeyOpportunity =
    data?.find((field) => field.type === SalesforceType.OPPORTUNITY)?.fields.salesforceKey || '';

  const salesforceFieldsOpportunity =
    fields
      ?.filter((field) => field.type === ClientConfigType.SALESFORCE_FIELDS_OPPORTUNITY)
      .flatMap((item) => item.value.availableFields)
      .map((field) => ({
        ...field,
        id: crypto.randomUUID(),
        salesforceType: SalesforceType.OPPORTUNITY,
        key: salesforceKeyOpportunity,
      }))
      .sort(sortAlphabetically((item) => item.label.toLowerCase())) ?? [];

  const availableFields: SalesforceField[] = [
    ...(fields
      ?.filter((field) => field.type === ClientConfigType.SALESFORCE_FIELDS_ACCOUNT)
      .flatMap((item) => item.value.availableFields)
      .map((field) => ({
        ...field,
        id: crypto.randomUUID(),
        salesforceType: SalesforceType.ACCOUNT,
        key: salesforceKeyAccount,
      }))
      .sort(sortAlphabetically((item) => item.label.toLowerCase())) ?? []),
    ...salesforceFieldsOpportunity,
  ];
  return availableFields;
};
