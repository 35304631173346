import { Box, BoxProps, Tooltip } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { memo } from 'react';

import { ReactComponent as DotIcon } from './navIcons/ic_dot.svg';

type Props = {
  isGroupSelected: boolean;
};

function GroupButton({ isGroupSelected, ...other }: BoxProps & Props) {
  const theme = useTheme();
  return (
    <Box {...other}>
      <Tooltip title="Group" placement={'top'}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="23"
            height="23"
            rx="3.5"
            fill={alpha(theme.palette.grey[900], 0.3)}
          />
          <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#454F5B" />
          <rect
            x="8.98047"
            y="8.98077"
            width="9.26923"
            height="9.26923"
            rx="1.25"
            fill="#161C24"
            stroke="#919EAB"
            strokeWidth="1.5"
          />
          <rect
            x="5.75"
            y="5.75"
            width="9.26923"
            height="9.26923"
            rx="1.25"
            fill="#161C24"
            stroke="#919EAB"
            strokeWidth="1.5"
          />
        </svg>
      </Tooltip>
      {isGroupSelected && (
        <DotIcon
          style={{
            position: 'absolute',
            right: '-5px',
            top: '-11px',
            color: theme.palette.primary.main,
          }}
        />
      )}
    </Box>
  );
}

export default memo(GroupButton);
