import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ACCOUNTS_PATH,
  DEALS_PATH,
  MEETINGS_PATH,
  NOTES_PATH,
  SESSION_STORAGE,
  TEMPLATES_PATH,
} from '../../../config';
import { NavSection } from './NavVertical';
import { NoteTitle } from 'src/utils/tiptapExtractTitles';

type ISectionItemProps = {
  icon: React.ReactNode;
  section: NavSection;
  activeSection: NavSection | null;
  handleSetActiveSection: (navActiveSection: NavSection) => void;
  // TODD this is mostly neither a note nor a title
  notes: NoteTitle[];
};

const NavSectionItem = ({
  icon,
  section,
  activeSection,
  handleSetActiveSection,
}: ISectionItemProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleActiveSection = () => {
    sessionStorage.setItem(SESSION_STORAGE.SELECTED_SECTION, section);
    handleSetActiveSection(section);

    switch (section) {
      case NavSection.MEETINGS:
        return navigate(`${MEETINGS_PATH}`);
      case NavSection.ACCOUNTS:
        return navigate(`/${ACCOUNTS_PATH}`);
      case NavSection.DEALS:
        return navigate(`/${DEALS_PATH}`);
      case NavSection.MY_NOTES:
        return navigate(`/${NOTES_PATH}`);
      case NavSection.TEMPLATES:
        return navigate(`/${TEMPLATES_PATH}`);
    }
  };

  const isActive = activeSection === section;

  return (
    <Button
      color={'inherit'}
      sx={{
        width: '100%',
        display: 'flex',
        direction: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '8px 8px 8px 12px',
        cursor: 'pointer',
        '& svg path': {
          fill: isActive ? theme.palette.primary.main : theme.palette.grey[600],
        },
      }}
      onClick={handleActiveSection}
    >
      {icon}
      <Typography
        variant="body2"
        color={isActive ? theme.palette.common.white : theme.palette.grey[500]}
        sx={{ marginLeft: 1 }}
      >
        {section}
      </Typography>
    </Button>
  );
};

export default NavSectionItem;
