import { Divider, Stack, Tooltip, useTheme } from '@mui/material';
import { Editor, findParentNode, posToDOMRect } from '@tiptap/core';
import { BubbleMenu } from '@tiptap/react';
import { Node as PMNode } from 'prosemirror-model';
import React, { useCallback } from 'react';

import { ReactComponent as DeleteTableIcon } from '../../../../assets/icons/tiptapIcons/deleteTable.svg';
import OptionsMenu from './OptionsMenu';
import { Table } from './TableExtension';

export type OptionsType = {
  label: string;
  action: () => void;
};

export const TableBubbleMenu: React.FC<{ editor: Editor; editable: boolean }> = ({
  editor,
  editable,
}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const toggleHeaderColumn = useCallback(
    () => editor.chain().focus().toggleHeaderColumn().run(),
    [editor]
  );
  const toggleHeaderRow = useCallback(
    () => editor.chain().focus().toggleHeaderRow().run(),
    [editor]
  );
  const addColumnAfter = useCallback(() => editor.chain().focus().addColumnAfter().run(), [editor]);
  const deleteColumn = useCallback(() => editor.chain().focus().deleteColumn().run(), [editor]);
  const addRowAfter = useCallback(() => editor.chain().focus().addRowAfter().run(), [editor]);
  const deleteRow = useCallback(() => editor.chain().focus().deleteRow().run(), [editor]);
  const deleteTable = useCallback(() => {
    handleClose();
    editor.chain().focus().deleteTable().run();
  }, [editor]);
  const mergeCells = useCallback(() => editor.chain().focus().mergeCells().run(), [editor]);
  const splitCell = useCallback(() => editor.chain().focus().splitCell().run(), [editor]);
  const TABLE_OPTIONS: OptionsType[] = [
    { label: 'Header row', action: toggleHeaderRow },
    { label: 'Header column', action: toggleHeaderColumn },
  ];

  const CELL_OPTIONS: OptionsType[] = [
    {
      label: 'Insert column right',
      action: addColumnAfter,
    },
    { label: 'Insert row below', action: addRowAfter },
    { label: 'Merge cells', action: mergeCells },
    { label: 'Split cell', action: splitCell },
    { label: 'Delete column', action: deleteColumn },
    { label: 'Delete row', action: deleteRow },
  ];

  const shouldShow = useCallback<any['shouldShow']>(
    () => editable && editor.isActive(Table.name),
    [editable, editor]
  );

  const getReferenceClientRect = useCallback(() => {
    const { selection } = editor.state;
    const predicate = (node: PMNode) => node.type.name === Table.name;
    const parent = findParentNode(predicate)(selection);

    if (parent) {
      const dom = editor.view.nodeDOM(parent?.pos) as HTMLElement;
      return dom.getBoundingClientRect();
    }

    return posToDOMRect(editor.view, selection.from, selection.to);
  }, [editor]);

  const handleHoverTable = () => {
    // put delete class for whole table
  };

  if (!editor) return null;

  return (
    <div>
      {' '}
      <BubbleMenu
        className={'bubble-menu bubble-menu-table'}
        editor={editor}
        pluginKey="table-bubble-menu"
        shouldShow={shouldShow}
        tippyOptions={{
          getReferenceClientRect,
          offset: [0, 10],
          placement: 'bottom',
          maxWidth: 'fit-content',
          zIndex: 4000,
        }}
      >
        <Stack spacing={1.5} direction="row" alignItems={'center'}>
          <OptionsMenu title="Table Options" options={TABLE_OPTIONS} editor={editor} />
          <Divider flexItem orientation="vertical" />
          <OptionsMenu title="Cell Options" options={CELL_OPTIONS} editor={editor} />
          <Divider flexItem orientation="vertical" />

          <Tooltip
            title={'Delete table'}
            placement={'top'}
            onOpen={handleOpen}
            onClose={handleClose}
            open={open}
            PopperProps={{
              disablePortal: true,
            }}
          >
            <button
              onClick={deleteTable}
              style={{ color: theme.palette.grey[500] }}
              className="button"
              onMouseOver={handleHoverTable}
            >
              <DeleteTableIcon />
            </button>
          </Tooltip>
        </Stack>
      </BubbleMenu>
    </div>
  );
};
