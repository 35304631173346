import { Editor } from '@tiptap/core';
import React from 'react';

import { ReactComponent as OrderedListIcon } from '../../../assets/icons/tiptapIcons/list-ordered.svg';
import Button from './Button';

const OrderedListButton = ({ editor }: { editor: Editor }) => {
  const handleToggle = (editor: Editor) => {
    editor.chain().focus().toggleOrderedList().run();
  };

  return (
    <Button
      editor={editor}
      shortcut={'mod-Shift-7'}
      icon={<OrderedListIcon />}
      tooltip={'Ordered List'}
      isActiveName={'orderedList'}
      clickCommand={handleToggle}
    />
  );
};

export default OrderedListButton;
