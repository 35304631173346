import { format } from 'date-fns';

import { LOCAL_STORAGE } from '../../../config';
import { sortAlphabetically } from '../../../utils/sortAlphabetically';
import { sortByDate } from '../../../utils/sortByDate';
import { IDataNavSection, INewDealsItems, NavListProps } from '../types';

export const duplicateArray = (data: IDataNavSection[], groupName: string) => {
  const duplicatedData = structuredClone(data);
  const stagedData = new Map();
  const newArr = duplicatedData.find((item) => item.subheader === 'Deals');

  newArr?.items.forEach((item) => {
    const value = item.attributes?.find((item) => item.name === groupName)?.value as string;
    return stagedData.set(value, stagedData.get(value) ? [...stagedData.get(value), item] : [item]);
  });

  let newDealsItems: INewDealsItems[] = [];

  stagedData.forEach((value, key) => {
    newDealsItems.push({
      stageName: key,
      items: value,
    });
  });

  const deals = duplicatedData.find((item) => item.subheader === 'Deals');

  const closeDateFiltered: INewDealsItems[] = [];
  if (groupName === 'CloseDate') {
    newDealsItems.sort(sortByDate((stage) => stage.stageName));

    newDealsItems.forEach((item) => {
      item.stageName = format(item.stageName ? new Date(item.stageName) : new Date(), 'MMMM yyyy');
      item.items = item.items.sort(sortAlphabetically((deal) => deal.title));
      let existing = closeDateFiltered.filter((el) => el.stageName === item.stageName);
      if (existing.length > 0) {
        let existingIndex = closeDateFiltered.indexOf(existing[0]);
        closeDateFiltered[existingIndex].items = closeDateFiltered[existingIndex].items.concat(
          item.items
        );
      } else {
        closeDateFiltered.push(item);
      }
    });
    closeDateFiltered.forEach((item) => {
      item.items = item.items.sort(sortAlphabetically((deal) => deal.title));
    });

    newDealsItems = closeDateFiltered;
  }

  if (deals?.items) {
    if (groupName !== 'CloseDate') {
      newDealsItems.sort(sortAlphabetically((deal) => deal.stageName));
    }
    deals.items = newDealsItems as NavListProps[];
  }

  return {
    duplicatedData,
  };
};

export const groupByFilterName = (filteredData: IDataNavSection[], groupName: string) => {
  const { duplicatedData } = duplicateArray(filteredData, groupName);

  return duplicatedData;
};
export const checkIsGroupSelected = (): boolean => {
  const group = localStorage.getItem(LOCAL_STORAGE.GROUPING);
  if (!group) {
    return false;
  }
  return group !== '"None"';
};
export const checkIsFilterSelected = (): boolean => {
  const filtersData = localStorage.getItem(LOCAL_STORAGE.DEALS_FILTERS);

  if (!filtersData) {
    return false;
  }

  const parsedFilters = JSON.parse(filtersData);

  return Object.values(parsedFilters).some((item: any) => item.length > 0);
};

export const checkIsFilterNameHasSelected = (filterName: string, filterPage: string) => {
  const filtersData = localStorage.getItem(filterPage);

  if (!filtersData) {
    return false;
  }

  const parsedFilters = JSON.parse(filtersData);
  const selectedFilter = parsedFilters[filterName] || [];

  return selectedFilter.length > 0;
};

export const checkIsFilterItemSelected = (
  filterName: string,
  filterPage: string,
  filterItem: string
) => {
  const filtersData = localStorage.getItem(filterPage);

  if (!filtersData) {
    return false;
  }
  const parsedFilters = JSON.parse(filtersData);
  const selectedFilter = parsedFilters[filterName] || [];

  return selectedFilter.includes(filterItem);
};

export const checkIsSelectedAll = (
  filterName: string,
  filterLength: number,
  filterPage: string
) => {
  const filtersData = localStorage.getItem(filterPage);

  if (!filtersData) {
    return false;
  }

  const parsedFilters = JSON.parse(filtersData);
  const selectedFilter = parsedFilters[filterName] || [];

  return selectedFilter.length === filterLength;
};
