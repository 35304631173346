import React from 'react';
import TagManager from 'react-gtm-module';

import { useAuthContext } from '../../auth/useAuthContext';
import { tagManagerArgs } from '../../index';

type Props = {
  children: React.ReactNode;
};
export default function GoogleTagManager({ children }: Props) {
  const { user } = useAuthContext();
  const dataLayerArgs = {
    dataLayer: {
      user_id: user?.id,
      event: 'login',
    },
  };

  if (user?.id) {
    TagManager.initialize({ ...tagManagerArgs, ...dataLayerArgs });
    TagManager.dataLayer({ dataLayer: { user_id: user?.id } });
  }

  return <>{children}</>;
}
