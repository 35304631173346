import { MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { NotesSource } from '../../../../../@types/api/notes';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/navIcons/delete.svg';
import { useAuthContext } from '../../../../../auth/useAuthContext';
import { Buttons } from '../../../../../components/modal/Buttons';
import { GeneralModalComponent } from '../../../../../components/modal/GeneralModalComponent';
import { NOTES_PATH } from '../../../../../config';
import { useNotesMenuContext } from '../../../../../hooks/useNotesMenuContext';
import { useDeleteNote } from '../../../../../hooks/useQueries';
import { INoteTreeNode, countChildNotes } from '../DashboardNavNotesUtils';
import { useNotesContext } from 'src/hooks/useNotesContext';

interface Props {
  thisNote: INoteTreeNode;
  noteTree: INoteTreeNode[];
  handleClose?: () => void;
  isNotePage?: boolean;
}

const failureMessage = 'Error Deleting Note';
const DeleteNote = ({ thisNote, noteTree, handleClose, isNotePage }: Props) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [open, setOpen] = React.useState(false);
  const { handleClose: handleCloseNote } = useNotesMenuContext();
  const { refresh } = useNotesContext();

  const noteTitle = thisNote.title ?? 'Untitled Note';
  let deleteNoteMessage = `Are you sure you want to delete "${noteTitle}"`;
  const noteChildren = countChildNotes(thisNote.id, noteTree);
  switch (noteChildren) {
    case 0:
      deleteNoteMessage += '?';
      break;
    case 1:
      deleteNoteMessage += ` and the 1 note under it?`;
      break;
    default:
      deleteNoteMessage += ` and the ${noteChildren} notes under it?`;
  }

  const handleOpenModal = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleCloseModal = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  const deleteNote = useDeleteNote(NotesSource.User, thisNote.id, user?.id);

  const handleDelete = () => {
    deleteNote
      .mutateAsync({ sourceId: thisNote.id })
      .then(async () => {
        await refresh(NotesSource.User);
        const parentId = thisNote.parentNoteId ?? '';
        navigate(`/${NOTES_PATH}/${parentId}`, { replace: true });
      })
      .catch(() => enqueueSnackbar(failureMessage, { variant: 'error' }))
      .finally(() => {
        handleClose && handleClose();
        handleCloseNote && handleCloseNote();
      });
  };

  return (
    <>
      <MenuItem
        onClick={handleOpenModal}
        sx={{ color: isNotePage ? theme.palette.common.black : theme.palette.common.white }}
      >
        <DeleteIcon style={{ marginRight: '8px' }} />
        Delete
      </MenuItem>
      <GeneralModalComponent
        handleCloseModal={handleCloseModal}
        buttons={
          <Buttons
            activeButtonName={'Delete'}
            buttonColor="error"
            handleClick={handleDelete}
            handleCloseModal={handleCloseModal}
          />
        }
        content={
          <Typography variant="body1" color={theme.palette.grey[800]} sx={{ padding: '0 24px' }}>
            {deleteNoteMessage}
          </Typography>
        }
        open={open}
        headerText={'Delete Note'}
      />
    </>
  );
};

export default DeleteNote;
