import React from 'react';

import { ReactComponent as MentionIcon } from '../../../../assets/icons/tiptapIcons/slashmenu/mentionIcon.svg';
import { SLASH_MENU, SlashCommandType } from '../types';

const MentionCommand = () => {
  const mentionCommand = {
    name: SLASH_MENU.MENTION,
    value: [],
    icon: <MentionIcon />,
    subtitle: 'Mention someone in this note',
    command: ({ editor, range }: SlashCommandType<null>) => {
      editor
        ?.chain()
        .focus()
        .insertContentAt({ from: range.from, to: range.to + 1 }, [
          {
            type: 'text',
            text: '@',
          },
        ])
        .run();
    },
  };
  return { ...mentionCommand };
};

export default MentionCommand;
