import MeetingAttendees from '../api/meetingAttendees';
import People from '../api/people';

export enum MeetingAttendeeType {
  'Internal' = 'Internal',
  'External' = 'External',
}

export enum MeetingStatus {
  Yes = 'yes',
  No = 'no',
  Maybe = 'maybe',
  Waiting = 'waiting',
  Error = 'error',
}

export default class MeetingAttendee extends MeetingAttendees {
  get email(): string {
    if (this.user) {
      return this.user.email;
    }
    if (this.person) {
      return this.person.email ?? '';
    }
    return '';
  }

  get name(): string {
    if (this.user) {
      if (!this.user.name && this.user.email) {
        return this.user.email;
      }
      return this.user.name;
    }
    if (this.person) {
      if (!this.person.name && this.person.email) {
        return this.person.email;
      }
      return this.person.name;
    }

    return '';
  }

  get imageUrl(): string {
    if (this.user) {
      return this.user.image ?? '';
    }
    if (this.person) {
      return this.person.image ?? '';
    }
    return '';
  }

  get title(): string {
    if (this.user) {
      return this.user.title ?? '';
    }
    if (this.person) {
      return this.person.title ?? '';
    }
    return '';
  }

  get linkedIn(): string {
    if (this.user) {
      return this.user.linkedIn ?? '';
    }
    if (this.person) {
      return this.person.salesforceContact?.meta?.LinkedIn_URL__c ?? '';
    }
    return '';
  }

  get role(): string {
    if (this.person) {
      const peopleInstance = new People(this.person);
      return peopleInstance.roleName;
    }

    return '';
  }

  getStatus(): MeetingStatus {
    switch (this.status) {
      case 'accepted':
        return MeetingStatus.Yes;
      case 'declined':
        return MeetingStatus.No;
      case 'tentative':
      case 'tentativelyAccepted':
        return MeetingStatus.Maybe;
      // case 'none':
      // case 'notResponded':
      // case null:
      // case 'needsAction':
      //   return MeetingStatus.Error;
      default:
        return MeetingStatus.Waiting;
    }
  }

  get type(): MeetingAttendeeType {
    if (this.userId) {
      return MeetingAttendeeType.Internal;
    }
    return MeetingAttendeeType.External;
  }

  constructor(data: Partial<MeetingAttendee>) {
    super();
    Object.assign(this, data);
  }

  isExternal() {
    return !!this.person;
  }

  // isManual() {
  //   return this.source === MeetingAttendeeSource.Manual;
  // }
}
