import { ClickAwayListener, Divider, MenuItem, Stack, useTheme } from '@mui/material';
import { Editor } from '@tiptap/core';
import { useMemo } from 'react';

import { ReactComponent as Delete } from '../../../assets/icons/navIcons/delete.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/tiptapIcons/bublemenu/check.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icons/tiptapIcons/bublemenu/editNote.svg';
import { ReactComponent as InsertBelow } from '../../../assets/icons/tiptapIcons/bublemenu/insertBelow.svg';
import { ReactComponent as Replay } from '../../../assets/icons/tiptapIcons/bublemenu/replay.svg';
import { AiPrompt } from 'src/@types/api/types/configTypes';
import { useGetAiConfig } from 'src/hooks/useQueries';

interface IOption {
  name: string;
  onClick: () => void;
  icon?: JSX.Element;
  component?: JSX.Element;
}

interface IMenuIcon {
  [key: string]: JSX.Element;
}

const icons: IMenuIcon = {
  checkIcon: <CheckIcon style={{ marginRight: '8px' }} />,
  filterIcon: <FilterIcon style={{ marginRight: '8px' }} />,
};

const OpenAIButtons = ({
  isEmptySelection,
  editor,
  handleClickSend,
  cleanResult,
  prevInputValue,
  isResponse,
  setIsEditingOpenAI,
  clickAwayHandlerItems,
}: {
  isEmptySelection: boolean;
  editor: Editor;
  handleClickSend: ({
    specialPrompt,
    prevPrompt,
    inputValue,
  }: {
    specialPrompt?: string;
    prevPrompt?: string;
    inputValue?: string;
  }) => void;
  cleanResult: string;
  prevInputValue: string;
  isResponse: boolean;
  setIsEditingOpenAI: (open: boolean) => void;
  clickAwayHandlerItems: (e: MouseEvent | TouchEvent) => void;
}) => {
  const theme = useTheme();
  const { data: configs, isLoading } = useGetAiConfig();

  const initialMenuOptions: IOption[] = useMemo(() => {
    if (!configs?.value?.noteConfig.prompts) {
      return [];
    }
    return configs.value.noteConfig.prompts
      .map((option: AiPrompt) => {
        if (isEmptySelection && option.standalone) return null;
        return {
          icon: icons.filterIcon,
          name: option.name,
          onClick: () => handleClickSend({ specialPrompt: option.promptText }),
        };
      })
      .filter((option: AiPrompt) => option !== null);
  }, [configs?.value?.noteConfig?.prompts, handleClickSend, isEmptySelection]);

  const responseMenuOptions: IOption[] = useMemo(
    () => [
      {
        icon: <CheckIcon style={{ marginRight: '8px', color: theme.palette.text.secondary }} />,
        name: 'Replace selection',
        onClick: () => {
          const { from, to } = editor.state.selection;
          editor
            .chain()
            .focus()
            .deleteRange({ from, to })
            .insertContentAt({ from, to: from }, cleanResult)
            .run();
          setIsEditingOpenAI(false);
        },
      },
      {
        icon: <InsertBelow style={{ marginRight: '8px', color: theme.palette.text.secondary }} />,
        name: 'Insert below',
        onClick: () => {
          const { to } = editor.state.selection;
          editor
            .chain()
            .focus()
            .insertContentAt({ from: to, to: to + 1 }, [{ type: 'hardBreak' }])
            .insertContent(cleanResult)
            .run();
          setIsEditingOpenAI(false);
        },
      },
      {
        name: 'divider',
        onClick: () => {},
        component: <Divider sx={{ margin: '4px 0 !important' }} key={'divider'} />,
      },
      {
        icon: <Replay style={{ marginRight: '8px', color: theme.palette.text.secondary }} />,
        name: 'Try again',
        onClick: () => handleClickSend({ prevPrompt: prevInputValue }),
      },
      {
        icon: (
          <Delete
            style={{
              marginRight: '8px',
              width: '20px',
              height: '20px',
              fill: theme.palette.text.secondary,
            }}
          />
        ),
        name: 'Discard',
        onClick: () => {
          setIsEditingOpenAI(false);
        },
      },
    ],
    [
      theme.palette.text.secondary,
      editor,
      setIsEditingOpenAI,
      handleClickSend,
      prevInputValue,
      cleanResult,
    ]
  );

  const OPTIONS = isResponse ? responseMenuOptions : initialMenuOptions;

  if (isLoading || OPTIONS.length === 0) {
    return null;
  }
  return (
    <ClickAwayListener
      onClickAway={(e) => clickAwayHandlerItems(e)}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      disableReactTree={true}
    >
      <Stack
        sx={{
          justifyContent: 'center',
          backgroundColor: theme.palette.common.white,
          boxShadow: theme.customShadows.dropdown,
          borderRadius: '12px',
          border: `1px solid ${theme.palette.grey[300]}`,
          position: 'sticky',
          bottom: isResponse ? '-186px' : '-101px',
          left: '0',
          padding: '8px',
          width: '250px',
        }}
        id="AIMenu"
      >
        {OPTIONS.map((option) => {
          if (option.name === 'divider') {
            return option.component;
          }
          return (
            <MenuItem
              key={option.name}
              onClick={() => option.onClick()}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                fontSize: '14px',
                lineHeight: '22px',
              }}
              id="AIMenuItem"
            >
              {option.icon}
              {option.name}
            </MenuItem>
          );
        })}
      </Stack>
    </ClickAwayListener>
  );
};
export default OpenAIButtons;
