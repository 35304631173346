import { ReactComponent as TemplateIcon } from '../../../../assets/icons/tiptapIcons/slashmenu/templateIcon.svg';
import { ISlashCommandProps, SLASH_MENU, SlashCommandType } from '../types';

export type TemplateAttributesType = {
  templateValue: any;
};

const templateWithoutTitle = (template: any) =>
  template?.content?.filter((item: any) => item.type !== 'title');

const TemplateCommand = (): ISlashCommandProps => {
  const templateCommand = {
    name: SLASH_MENU.TEMPLATE,
    value: [],
    icon: <TemplateIcon />,
    subtitle: 'Select and insert note templates',
    command: ({ editor, range, props }: SlashCommandType<TemplateAttributesType>) => {
      editor
        ?.chain()
        .focus()
        .insertContentAt(
          { from: range.from - 1, to: range.to },
          templateWithoutTitle(props.attrs.templateValue)
        )
        .run();
    },
  };

  return { ...templateCommand };
};

export default TemplateCommand;
