import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import LoadingScreen from '../../components/loading-screen';
import { CONTEXT_GONG_LOGIN } from '../../config';
import { useGetGongToken } from '../../hooks/useQueries';

export default function GongAuthCallback() {
  const { isSuccess, isError, isLoading } = useGetGongToken();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleTokenSuccess = useCallback(() => {
    const successParam = searchParams.get('success');
    if (successParam) {
      navigate('/', { replace: true });
      enqueueSnackbar('Gong connected!', { variant: 'success' });
    }
  }, [searchParams, navigate, enqueueSnackbar]);

  const handleTokenError = useCallback(() => {
    navigate(CONTEXT_GONG_LOGIN, { replace: true });
    enqueueSnackbar('Gong error!', { variant: 'error' });
  }, [navigate, enqueueSnackbar]);

  useEffect(() => {
    if (isLoading) return; // Guard clause for loading state

    if (isSuccess) {
      handleTokenSuccess();
      return; // Early return to avoid nested conditions
    }

    if (isError) {
      handleTokenError();
    }
  }, [isLoading, isSuccess, isError, handleTokenSuccess, handleTokenError]);

  return <LoadingScreen />;
}
