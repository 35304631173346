import { TaskItem } from '@tiptap/extension-task-item';
import { ReactNodeViewRenderer } from '@tiptap/react';

import DateAndAttendeeComponent from '../react-components/DateAndAttendeeComponent';

export const ContextTaskItemExtension = TaskItem.extend({
  addOptions() {
    return {
      ...this.parent?.(),
    };
  },
  content: 'block*',
  addAttributes() {
    return {
      ...this.parent?.(),
      dueDate: {
        default: '',
        keepOnSplit: false,
        parseHTML: (element) => element.getAttribute('data-dueDate'),
        renderHTML: (attributes) => ({
          'data-dueDate': attributes.dueDate,
        }),
      },
      assigneeId: {
        default: '',
        keepOnSplit: false,
        parseHTML: (element) => element.getAttribute('data-assigneeId'),
        renderHTML: (attributes) => ({
          'data-assigneeId': attributes.assigneeId,
        }),
      },
      taskId: {
        // set the taskId to the epoch in milliseconds when the task is created
        default: new Date().getTime(),
        keepOnSplit: false,
        parseHTML: (element) => element.getAttribute('data-taskId'),
        renderHTML: (attributes) => ({
          'data-taskId': attributes.taskId,
        }),
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem(this.name),
      Tab: () => this.editor.commands.sinkListItem(this.name),
      'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(DateAndAttendeeComponent);
  },
});
