import { Box, Button, Stack, SxProps, Typography, alpha, useTheme } from '@mui/material';
import React from 'react';

export interface IMessagePageProps {
  title: string;
  buttonText: string;
  buttonAction: () => void;
  sx?: SxProps;
}

function MessagePage({ title, buttonText, buttonAction, sx }: IMessagePageProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: '100svh',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        ...sx,
      }}
    >
      <Stack
        spacing={4}
        alignItems="center"
        sx={{
          width: 'fit-content',
          padding: '40px 60px',
          backgroundColor: alpha(theme.palette.grey[900], 0.8),
          borderRadius: '16px',
          border: `1px solid ${alpha(theme.palette.grey[700], 0.4)}`,
          zIndex: '1',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Typography
          variant="h5"
          color={theme.palette.common.white}
          sx={{ zIndex: '1', fontSize: '20px' }}
        >
          {title}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          sx={{ fontSize: '14px' }}
          onClick={buttonAction}
        >
          {buttonText}
        </Button>
      </Stack>
    </Box>
  );
}

export default MessagePage;
