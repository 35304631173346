import { styled } from '@mui/material/styles';

export const DatePickerIcon = styled('div')(({ theme }) => ({
  '& .MuiInputAdornment-root': {
    marginLeft: '0',
    '& .MuiButtonBase-root': {
      padding: '0',
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
}));
