import { useSelector } from './redux/store';

export const LOCAL_STORAGE = {
  CLOSE_DATE_FILTER: 'closeDateFilter',
  DEALS_FILTERS: 'selectedFilters',
  GONG_SKIP: 'skipGong',
  GROUPING: 'selectedGrouping',
  LEFT_MENU: 'leftMenu',
  PEOPLE_FILTERS: 'selectedPeopleFilters',
  PREV_PAGE_TEMPLATE: 'prevPageTemplate',
  SALESFORCE_LAST_ACTIVE_SLASH_NAME: 'salesforceLastActiveSlashName',
  SALESFORCE_SKIP: 'skipSalesforce',
  // SALESFORCE_TOKEN: 'salesforceToken',
  SALESFORCE_TOKEN_UPDATED: 'salesforceTokenUpdated',
  SELECTED_SECTION: 'selectedSection',
  SHOW_INTERNAL_EVENTS: 'showInternalEvents',
  UPDATED_FROM_MODAL: 'updatedFromModal',
  VIEW_USER: 'viewUser',
};

export const SESSION_STORAGE = {
  SELECTED_SECTION: 'selectedSection',
};

// ROUTING PATHS & IDS

export const CONTEXT_AUTH = {
  auth: '/auth',
  login: '/login',
};

export const CONTEXT_HOME = '';
export const CONTEXT_SALESFORCE_LOGIN = '/salesforce';
export const CONTEXT_GONG_LOGIN = '/gong';

export const ACCOUNTS_PATH = 'a';
export const DEALS_PATH = 'd';
export const NOTES_PATH = 'n';
export const MEETINGS_PATH = 'm';
export const PEOPLE_PATH = 'p';
export const TEMPLATES_PATH = 't';
export const AI_PATH = 'ai';
export const HOME_PATH = MEETINGS_PATH;
export const LOGIN_PATH = 'login';
export const AUTH_PATH = 'auth';
export const GONG_PATH = 'gong';
export const GONG_AUTH_PATH = 'gong-auth';
export const SALESFORCE_PATH = 'salesforce';
export const SALESFORCE_AUTH_PATH = 'salesforce-auth';
export const SALESFORCE_LOADING_PATH = 'salesforce-loading';

export const ACCOUNT_ID = 'account_id';
export const DEAL_ID = 'deal_id';
export const MEETING_ID = 'meeting_id';
export const NOTE_ID = 'note_id';
export const PERSON_ID = 'person_id';
export const TEMPLATE_ID = 'template_id';

// API
export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:3004';
export const APP_ENV = process.env.REACT_APP_ENV || 'development';

export const SALESFORCE_INSTANCE_URL = () => {
  const { url } = useSelector((state) => state.salesforce);
  return url + '/';
};

// AI
export const AI_ENABLED = process.env.REACT_APP_AI_ENABLED === 'true';

// LAYOUT

export const DEFAULT_PADDING = 24;
export const DEFAULT_BLUR = 20;

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 48,
  // H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP: 32,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 40,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

export const DELETE_ICON = 'typcn:delete-outline';

export const PLACEHOLDERS = {
  TITLE_NOTE: 'Untitled Note',
  TITLE_MEETING: 'Untitled Meeting',
  TITLE_TEMPLATE: 'Untitled Template',
  PARAGRAPH: 'Type / to discover more actions',
};

export const NOTES = {
  WIDTH: 745,
};
