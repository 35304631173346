import { Editor } from '@tiptap/core';
import React, { useEffect, useState } from 'react';
import tippy, { Placement } from 'tippy.js';

export const Tooltip: React.FC<
  React.PropsWithChildren<{
    editor: Editor;
    title: string;
    placement?: Placement;
  }>
> = ({ editor, title, placement = 'top', children }) => {
  const [element, setElement] = useState<HTMLSpanElement | null>(null);

  const zIndex = 9999;

  useEffect(() => {
    if (!element) return;

    const popup = tippy(element, {
      appendTo: () => editor.options.element,
      theme: 'table-tooltip',
      content: title,
      zIndex,
      placement,
    });

    return () => {
      popup.destroy();
    };
  }, [editor, element, title, zIndex, placement]);

  return (
    <>
      <span ref={setElement}>{children}</span>
    </>
  );
};
