import { createContext, useContext } from 'react';

import NoteTemplates from 'src/@types/api/noteTemplates';
import Notes from 'src/@types/api/notes';

// Define the shape of the note data
interface INoteContext {
  note?: Notes | NoteTemplates;
}

// Create the context
export const NoteContext = createContext<INoteContext>({
  note: undefined,
});

export function useNoteContext() {
  return useContext(NoteContext);
}
