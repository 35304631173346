import { ProsemirrorTransformer } from '@hocuspocus/transformer';
import { Editor, getSchemaByResolvedExtensions } from '@tiptap/core';
import { Buffer } from 'buffer';
import * as Y from 'yjs';

import Deal from '../../@types/core/deal';
import Meeting from '../../@types/core/meeting';
import Person from '../../@types/core/person';
import { ADDITIONAL_EXTENSIONS, DEFAULT_EXTENSIONS } from './TiptapEditor';

export const EMPTY_NOTE_TITLE_PARAGRAPH = {
  type: 'doc',
  content: [{ type: 'title' }, { type: 'paragraph' }],
};

export const EMPTY_NOTE_TITLE_PARAGRAPH_BINARY = getValueBinary(EMPTY_NOTE_TITLE_PARAGRAPH);

export function getValueBinary(tiptapJsonObject: any) {
  const cleanJSON = tiptapJsonObject ? tiptapJsonObject : {};
  const schema = getSchemaByResolvedExtensions([...DEFAULT_EXTENSIONS, ...ADDITIONAL_EXTENSIONS]);
  const yDoc = ProsemirrorTransformer.toYdoc(cleanJSON, 'default', schema);
  return Buffer.from(Y.encodeStateAsUpdate(yDoc)).toString('base64');
}

export type Note = any;
export function getPersonNote(person: Pick<Person, 'notes'>): Note {
  return getNoteOrDefault(person);
}
function getNoteOrDefault(object: Pick<Deal | Meeting | Person, 'notes'>): Note {
  return object && object.notes && object.notes.length > 0 ? object.notes[0].value : '';
}

export function getIsNoteEmpty(note: Note): boolean {
  return getNoteElement(note, 'text') === '';
}

export function getFirstTextElement(note: Note): string {
  return getNoteElement(note, 'text');
}

export function getNoteElement(note: Note, fieldName: string): string {
  if (note?.hasOwnProperty(fieldName) && note?.[fieldName].trim() !== '') {
    return note[fieldName];
  }

  for (let key in note) {
    if (typeof note[key] === 'object') {
      const result = getNoteElement(note[key], fieldName);
      if (result) {
        return result;
      }
    }
  }

  return '';
}

export function insertNodeAtPosition(editor: Editor, lastNodePosition: number, node: any) {
  const tr = editor.view.state.tr.insert(lastNodePosition, [node]);
  editor.view.dispatch(tr);
}

export const insertNodeAtEnd = (editor: Editor, node: any) => {
  const lastNodePosition = editor.view.state?.doc?.content.size;
  insertNodeAtPosition(editor, lastNodePosition, node);
};
