import _ from 'lodash';
import { Node } from 'prosemirror-model';
import slugify from 'slugify';

function safeSlugify(text: string) {
  return `h-${_.escape(
    slugify(text, {
      // eslint-disable-next-line
      remove: /[!"#$%&'\.()*+,\/:;<=>?@\[\]\\^_`{|}~]/g,
      lower: true,
    })
  )}`;
}

function headingToSlug(node: Node, index = 0) {
  const slugified = safeSlugify(node.textContent);
  if (index === 0) return slugified;
  return `${slugified}-${index}`;
}

export function headingToPersistenceKey(node: Node, id?: string) {
  const slug = headingToSlug(node);
  return `rme-${id || window?.location.pathname}–${slug}`;
}
