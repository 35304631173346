import { ReactNode, useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { LOCAL_STORAGE, SALESFORCE_PATH } from '../config';
import { useGetSalesforceToken } from '../hooks/useQueries';
import LoadingScreen from 'src/components/loading-screen';

type SalesforceGuardProps = {
  children: ReactNode;
};

export default function SalesforceGuard({ children }: SalesforceGuardProps) {
  const { data, isLoading } = useGetSalesforceToken();

  const isSkipSalesforcePermanently = useMemo(
    () => localStorage.getItem(LOCAL_STORAGE.SALESFORCE_SKIP),
    []
  );
  const isSkipSalesforceSession = useMemo(
    () => sessionStorage.getItem(LOCAL_STORAGE.SALESFORCE_SKIP),
    []
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isSkipSalesforcePermanently && !isSkipSalesforceSession && data?.accessToken !== 'ok') {
    return <Navigate to={`/${SALESFORCE_PATH}`} replace />;
  }

  return <>{children}</>;
}
