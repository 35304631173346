import { Box, Link, ListItemText, Tooltip, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ReactComponent as StarIcon } from '../../../assets/icons/navIcons/star_border.svg';
import { ReactComponent as StarFavoriteIcon } from '../../../assets/icons/navIcons/star_favorite.svg';
import RoleBasedGuard from '../../../auth/RoleBasedGuard';
import useOnScreen from '../../../hooks/useOnScreen';
import { useTogglePinnedDeal } from '../../../hooks/useQueries';
import { scrollIntoView } from '../../../utils/scrollIntoView';
import { NavItemProps } from '../types';
import { StyledDotIcon, StyledIcon, StyledItem } from './styles';
import { AI_ENABLED } from 'src/config';

export default function NavItem({
  item,
  depth,
  open,
  active,
  isExternalLink,
  ...other
}: NavItemProps) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const { title, path, icon, info, disabled, caption, roles } = item;
  const newPath = AI_ENABLED ? path : `${path}/n`;
  const [showStar, setShowStar] = useState(false);

  const subItem = depth !== 1;

  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  const dealId = item.path.slice(3);
  const setFavoriteDeal = useTogglePinnedDeal(dealId);
  const isFavoriteDeal = item.isFavorite;

  const handleFavorite = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    setFavoriteDeal
      .mutateAsync()
      .catch(() => enqueueSnackbar('Error managing favorites!', { variant: 'error' }));
  };

  useEffect(() => {
    if (active && !isVisible) {
      scrollIntoView(item.title, 'center', 100, 'auto');
    }
    // eslint-disable-next-line
  }, [active]);

  const renderContent = (
    <StyledItem
      depth={depth}
      active={active}
      disabled={disabled}
      caption={!!caption}
      {...other}
      ref={ref}
      id={item.title}
      onMouseOver={() => setShowStar(true)}
      onMouseLeave={() => setShowStar(false)}
    >
      {icon && <StyledIcon>{icon}</StyledIcon>}

      {subItem && (
        <StyledIcon>
          <StyledDotIcon sx={{ ml: 3, mr: 2, flexShrink: 0 }} active={active && subItem} />
        </StyledIcon>
      )}
      {/*{!!children && (*/}
      {/*  <Iconify*/}
      {/*    width={16}*/}
      {/*    icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}*/}
      {/*    sx={{ ml: 0, mr: 1, flexShrink: 0 }}*/}
      {/*  />*/}
      {/*)}*/}
      <Tooltip title={title} enterDelay={750} placement="right">
        <ListItemText
          primary={title}
          secondary={
            caption /* && (
            <Tooltip title={translate(caption)} placement="top-start">
              <span>{translate(caption)}</span>
            </Tooltip> )*/
          }
          primaryTypographyProps={{
            noWrap: true,
            component: 'span',
            variant: active ? 'subtitle2' : 'body2',
          }}
          secondaryTypographyProps={{
            noWrap: true,
            variant: 'caption',
            color: theme.palette.text.disabled,
          }}
          sx={{ textTransform: 'none' }}
        />
      </Tooltip>
      {showStar && isFavoriteDeal && (
        <Tooltip title={'Remove from Favorites'}>
          <StarFavoriteIcon
            style={{ minWidth: '24px' }}
            onClick={(event) => handleFavorite(event)}
          />
        </Tooltip>
      )}
      {showStar && !isFavoriteDeal && (
        <Tooltip title={'Add to Favorites'}>
          <StarIcon
            onClick={(event) => handleFavorite(event)}
            style={{ color: theme.palette.grey[500], minWidth: '24px' }}
          />
        </Tooltip>
      )}
      {info && (
        <Box component="span" sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={newPath} target="_blank" rel="noopener" underline="none">
          {renderContent}
        </Link>
      );

    // Has child
    /* if (children) {
                                              return renderContent;
                                            } */

    // Default
    return (
      <Link component={RouterLink} to={`${newPath}`} underline="none">
        {renderContent}
      </Link>
    );
  };

  return <RoleBasedGuard roles={roles}> {renderItem()} </RoleBasedGuard>;
}
