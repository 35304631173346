import { Dispatch, createContext, useContext, useEffect, useState } from 'react';

import { ISalesforceComponentProps } from './SalesforceComponent';
import { SalesforceType } from 'src/@types/api/types/configTypes';
import { useDealContext } from 'src/hooks/useDealContext';
import { useAccountOutletContext } from 'src/sections/core/account/Account';

export interface ISalesforceComponentContext {
  salesforceKey: string;
  setSalesforceKey: Dispatch<any>;
}

const SalesforceComponentContext = createContext<ISalesforceComponentContext>({
  salesforceKey: '',
  setSalesforceKey: () => {},
});

export function useSalesorceComponent() {
  return useContext(SalesforceComponentContext);
}

export const useSalesforceComponentContext = () => {
  const [salesforceKey, setSalesforceKey] = useState<string>('');
  return { salesforceKey, setSalesforceKey };
};

// export default SalesforceComponentContext;

export const withMyContext = (Component: React.ComponentType<ISalesforceComponentProps>) =>
  function WithMyContext(props: ISalesforceComponentProps) {
    const { setSalesforceKey, salesforceKey } = useSalesforceComponentContext();
    const { deal } = useDealContext();
    const accountData = useAccountOutletContext();

    const data = deal && Object.keys(deal).length !== 0 ? deal?.getSalesforceDataStructure() : null;
    const fieldsForType = data?.find((field) => field.type === props?.node.attrs.salesforceType);

    const salesforceKeyFrom =
      (props.node.attrs.salesforceKey !== 'key' && props.node.attrs.salesforceKey) ||
      fieldsForType?.fields.salesforceKey ||
      (props.node.attrs.salesforceType === SalesforceType.ACCOUNT &&
        accountData?.account?.salesforceKey) ||
      'none';

    useEffect(() => {
      setSalesforceKey(salesforceKeyFrom);
    }, [salesforceKeyFrom, setSalesforceKey]);

    return (
      <SalesforceComponentContext.Provider value={{ salesforceKey, setSalesforceKey }}>
        <Component {...(props as ISalesforceComponentProps)} />
      </SalesforceComponentContext.Provider>
    );
  };
