import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ClickAwayListener } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { Editor } from '@tiptap/core';
import React, { useState } from 'react';

import { ReactComponent as HighlightIcon } from '../../../assets/icons/tiptapIcons/highlight.svg';
import useLocalStorage from '../../../hooks/useLocalStorage';
import PaletteMenu from '../react-components/PaletteMenu';
import Button from './Button';

const HighlightButton = ({ editor }: { editor: Editor }) => {
  const theme = useTheme();

  const COLORS = [
    {
      name: 'red',
      color: alpha(theme.palette.error.main, 0.32),
      palletteColor: theme.palette.error.main,
    },
    {
      name: 'orange',
      color: alpha(theme.palette.warning.main, 0.32),
      palletteColor: theme.palette.warning.main,
    },
    {
      name: 'green',
      color: alpha(theme.palette.primary.main, 0.32),
      palletteColor: theme.palette.primary.main,
    },
    {
      name: 'blue',
      color: alpha(theme.palette.info.main, 0.32),
      palletteColor: theme.palette.info.main,
    },
    {
      name: 'purple',
      color: alpha(theme.palette.secondary.main, 0.32),
      palletteColor: theme.palette.secondary.main,
    },
  ];

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [highlightColor, setHighlightColor] = useLocalStorage(
    'highlightColor',
    localStorage.getItem('highlightColor') ?? theme.palette.common.white
  );

  const handleOpenPopover = (event: any) => {
    event.stopPropagation();
    if (openPopover) return setOpenPopover(null);
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleApplyHighlight = (editor: Editor) => {
    // editor.commands.toggleHighlight({ color: highlightColor });
    handleClosePopover();
    editor.chain().toggleHighlight({ color: highlightColor }).focus().run();
  };

  const handleChooseColor = (color: string) => {
    setHighlightColor(color);
    handleClosePopover();
    editor.chain().toggleHighlight({ color }).focus().run();
  };

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClosePopover}
    >
      <div>
        <Button
          editor={editor}
          shortcut={`mod-Shift-h`}
          icon={<HighlightIcon style={{ color: highlightColor }} />}
          tooltip={`Highlight`}
          isActiveName={'highlight'}
          clickCommand={handleApplyHighlight}
          isClassname={false}
          expandMoreIcon={
            <ExpandMoreIcon
              sx={{
                width: '12px',
                height: '16px',
                color: theme.palette.grey[600],
              }}
              onClick={handleOpenPopover}
            />
          }
        />
        <PaletteMenu
          colors={COLORS}
          openPopover={openPopover}
          handleClosePopover={handleClosePopover}
          handleChooseColor={handleChooseColor}
        />
      </div>
    </ClickAwayListener>
  );
};

export default HighlightButton;
