import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import '../styles.scss';
import SecondMenu from './SecondMenu/SecondMenu';
import { ISlashCommandProps, SLASH_MENU } from './types';

export const CommandsList = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState<string>('');
  const [openSecondMenu, setOpenSecondMenu] = useState(false);

  const theme = useTheme();

  const OPTION_WITH_SECOND_MENU = [SLASH_MENU.SALESFORCE, SLASH_MENU.TEMPLATE, SLASH_MENU.GONG];

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (OPTION_WITH_SECOND_MENU.includes(item?.name)) {
      setSelectedMenu(item?.name);
      setOpenSecondMenu(true);
      return;
    }

    const newItem = { command: item?.command, value: item?.value, name: item?.name };
    return props.command(newItem);
  };

  const upHandler = () => {
    let lengthOfFields = selectedMenu
      ? props.items.find((item: ISlashCommandProps) => item.name === selectedMenu).value.length
      : props.items.length;
    setSelectedIndex((selectedIndex + lengthOfFields - 1) % lengthOfFields);
    let selectedItem: any;
    if (selectedIndex === 0) {
      selectedItem = document.getElementById((selectedIndex + lengthOfFields - 1).toString());
      selectedItem?.scrollIntoView({ block: 'center' });
      return;
    }
    selectedItem = document.getElementById(selectedIndex.toString());
    selectedItem?.scrollIntoView({ block: 'center' });
  };

  const downHandler = () => {
    let lengthOfFields = selectedMenu
      ? props.items.find((item: ISlashCommandProps) => item.name === selectedMenu).value.length
      : props.items.length;
    setSelectedIndex((selectedIndex + 1) % lengthOfFields);
    let selectedItem: any;
    if (selectedIndex === lengthOfFields - 1) {
      selectedItem = document.getElementById((0).toString());
      selectedItem?.scrollIntoView({ block: 'center' });
      return;
    }
    selectedItem = document.getElementById(selectedIndex.toString());
    selectedItem?.scrollIntoView({ block: 'center' });
  };

  const enterHandler = () => {
    if (OPTION_WITH_SECOND_MENU.includes(selectedMenu)) {
      selectItem(selectedIndex);
      setOpenSecondMenu(true);
      return;
    }
    const selectedMenuName = props.items[selectedIndex].name;
    setSelectedMenu(selectedMenuName);
  };

  const handleCloseMenu = (reason: string) => {
    const { editor } = props;
    if (reason === 'toggleInput') return;
    editor?.chain().focus().run();
    setOpenSecondMenu(false);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: any) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        if (!selectedMenu || !OPTION_WITH_SECOND_MENU.includes(selectedMenu)) {
          selectItem(selectedIndex);
          return true;
        }
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  if (selectedMenu && !OPTION_WITH_SECOND_MENU.includes(selectedMenu)) return <></>;

  return (
    <>
      {!selectedMenu && !openSecondMenu && (
        <Stack
          spacing={0.5}
          direction="column"
          sx={{
            position: 'relative',
            background: theme.palette.common.white,
            padding: '16px 6px 16px 16px',
            boxShadow:
              '0 0 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24)',
            borderRadius: '12px',
            overflow: 'auto',
            maxHeight: '324px',
            scrollbarGutter: 'stable',
          }}
        >
          {props.items.length ? (
            props.items.map((item: ISlashCommandProps, index: any) => (
              <Button
                id={index}
                className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
                style={{ display: 'flex', height: '56px', justifyContent: 'start' }}
                sx={{
                  ':hover': {
                    backgroundColor: 'white',
                  },
                }}
                key={index}
                onMouseMoveCapture={() => setSelectedIndex(index)}
                onClick={() => {
                  if (OPTION_WITH_SECOND_MENU.includes(item.name)) {
                    setSelectedMenu(item.name);
                    setOpenSecondMenu(true);
                    return;
                  }
                  setSelectedMenu(item.name);
                  selectItem(index);
                }}
              >
                <Box width={40} height={40} marginRight="12px">
                  {item.icon}
                </Box>
                <Stack>
                  <Typography
                    variant="body2"
                    color={theme.palette.grey[800]}
                    sx={{ fontWeight: 500 }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="caption" color={theme.palette.grey[800]}>
                    {item.subtitle}
                  </Typography>
                </Stack>
              </Button>
            ))
          ) : (
            <div className="item">No results</div>
          )}
        </Stack>
      )}
      {openSecondMenu && (
        <SecondMenu
          selectedMenu={selectedMenu}
          items={props.items}
          command={props.command}
          handleCloseMenu={handleCloseMenu}
        />
      )}
    </>
  );
});
