import { environment } from './environment';

function isCommandKey(key: string): boolean {
  const allowedKeys = ['command', 'cmd', 'meta'];

  if (environment.isMac) {
    allowedKeys.push('mod');
  }

  return allowedKeys.includes(key);
}

function isControlKey(key: string): boolean {
  const allowedKeys = ['control', 'ctrl'];

  if (!environment.isMac) {
    allowedKeys.push('mod');
  }

  return allowedKeys.includes(key);
}

interface BaseKeyboardSymbol {
  key: string;
}

interface ModifierKeyboardSymbol extends BaseKeyboardSymbol {
  type: 'modifier';
  symbol: string;
}

interface NamedKeyboardSymbol extends BaseKeyboardSymbol {
  type: 'named';
  symbol?: string;
}

interface CharKeyboardSymbol extends BaseKeyboardSymbol {
  type: 'char';
}

type KeyboardSymbol = ModifierKeyboardSymbol | NamedKeyboardSymbol | CharKeyboardSymbol;

export function getShortcutSymbols(shortcut: string): KeyboardSymbol[] {
  const symbols: KeyboardSymbol[] = [];

  for (let key of shortcut.split('-')) {
    key = key.toLowerCase();

    if (isCommandKey(key)) {
      symbols.push({ type: 'modifier', symbol: '⌘', key: 'command' });
      continue;
    }

    if (isControlKey(key)) {
      symbols.push({ type: 'modifier', symbol: '⌃', key: 'control' });
      continue;
    }

    switch (key) {
      case 'shift':
        symbols.push({ type: 'modifier', symbol: '⇧', key });
        continue;

      case 'alt':
        symbols.push({ type: 'modifier', symbol: '⌥', key });
        continue;

      case '\n':
      case '\r':
      case 'enter':
        symbols.push({ type: 'named', symbol: '↵', key });
        continue;

      case 'backspace':
        symbols.push({ type: 'named', symbol: '⌫', key });
        continue;

      case 'delete':
        symbols.push({ type: 'named', symbol: '⌦', key });
        continue;

      case 'escape':
        symbols.push({ type: 'named', symbol: '␛', key });
        continue;

      case 'tab':
        symbols.push({ type: 'named', symbol: '⇥', key });
        continue;

      case 'capslock':
        symbols.push({ type: 'named', symbol: '⇪', key });
        continue;

      case 'space':
        symbols.push({ type: 'named', symbol: '␣', key });
        continue;

      case 'pageup':
        symbols.push({ type: 'named', symbol: '⤒', key });
        continue;

      case 'pagedown':
        symbols.push({ type: 'named', symbol: '⤓', key });
        continue;

      case 'home':
        symbols.push({ type: 'named', key });
        continue;

      case 'end':
        symbols.push({ type: 'named', key });
        continue;

      case 'arrowleft':
        symbols.push({ type: 'named', symbol: '←', key });
        continue;

      case 'arrowright':
        symbols.push({ type: 'named', symbol: '→', key });
        continue;

      case 'arrowup':
        symbols.push({ type: 'named', symbol: '→', key });
        continue;

      case 'arrowdown':
        symbols.push({ type: 'named', symbol: '↓', key });
        continue;

      default:
        symbols.push({ type: 'char', key });
    }
  }

  return symbols;
}

export function getShortcutString(shortcut?: string): string {
  if (!shortcut) return '';
  const symbols = getShortcutSymbols(shortcut);
  const stringSymbols: string[] = [];

  for (const sym of symbols) {
    if (sym.type === 'char') {
      stringSymbols.push(sym.key.toUpperCase());
      continue;
    }

    if (sym.type === 'modifier') {
      stringSymbols.push(sym.symbol);
    }
  }

  return `(${stringSymbols.join('')})`;
}
