import { EmojiItem } from '@tiptap-pro/extension-emoji';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import '../styles.scss';

export const EmojiList = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = useCallback(
    (index: number) => {
      const item = props.items[index];

      if (item) {
        props.command({ name: item.name });
      }
    },
    [props]
  );

  const upHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  }, [props.items.length, selectedIndex]);

  const downHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  }, [props.items.length, selectedIndex]);

  const enterHandler = useCallback(() => {
    selectItem(selectedIndex);
  }, [selectItem, selectedIndex]);

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(
    ref,
    () => ({
      onKeyDown: (x: any) => {
        if (x.event.key === 'ArrowUp') {
          upHandler();
          return true;
        }

        if (x.event.key === 'ArrowDown') {
          downHandler();
          return true;
        }

        if (x.event.key === 'Enter') {
          enterHandler();
          return true;
        }

        return false;
      },
    }),
    [upHandler, downHandler, enterHandler]
  );

  return (
    <div className="items">
      {props.items.map((item: EmojiItem, index: number) => (
        <button
          className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
          key={index}
          onClick={() => selectItem(index)}
        >
          {item.fallbackImage ? <img src={item.fallbackImage} alt={'emoji'} /> : item.emoji}:
          {item.name}:
        </button>
      ))}
    </div>
  );
});
