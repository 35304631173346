import { Checkbox, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Editor } from '@tiptap/core';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { useDealContext } from '../../../hooks/useDealContext';
import AvatarPopover from '../../avatar-popover/AvatarPopover';
import CalendarPopover from '../../calendar-popover/CalendarPopover';
import Notes from 'src/@types/api/notes';
// import { useAuthContext } from '../../../auth/useAuthContext';
// import { useParams } from 'react-router';
import People from 'src/@types/api/people';
import Users from 'src/@types/api/users';
import { useNoteContext } from 'src/hooks/useNoteContext';

interface IDateAndAttendeeComponentProps {
  node: any;
  updateAttributes: (attrs: any) => void;
  selected: boolean;
  editor: Editor | null;
  getPos: () => number;
  extension: any;
}

const DateAndAttendeeComponent = ({
  editor,
  node,
  updateAttributes,
  getPos,
}: IDateAndAttendeeComponentProps) => {
  const theme = useTheme();
  const { note_id, deal_id } = useParams();
  const { allPeople: dealPeople } = useDealContext();

  const { note } = useNoteContext() as { note: Notes };

  const isUserNote = !!note_id && !deal_id;
  const atMentions = useMemo(
    () => note?.noteUsers?.map((user) => new Users(user.user)),
    [note?.noteUsers]
  );

  const allPeople = useMemo(() => {
    if (isUserNote) return atMentions;
    return dealPeople;
  }, [atMentions, dealPeople, isUserNote]);

  const [showIcons, setShowIcons] = useState<boolean>(false);
  const [showIconsOnHover, setShowIconsOnHover] = useState<boolean>(false);
  const [selectedPerson, setSelectedPerson] = useState<People | Users | null>(null);
  const personalRef = useRef<any>(null);

  const onSelectionUpdate = useCallback(
    ({ editor }: { editor: Editor }) => {
      if (editor) {
        if (getPos()) {
          const nodeViewPos: any = editor.state.doc.resolve(getPos() + 1);
          const cursorPos = editor.state.selection.$anchor.pos;
          const startOfNode = nodeViewPos.start();
          const endOfNode = nodeViewPos.end();
          const nodeAtCursorPos = editor.state.doc.resolve(cursorPos);
          const startOfSelectedNode = nodeAtCursorPos.start();
          const endOfSelectedNode = nodeAtCursorPos.end();
          const isCurrentNode = startOfNode + 1 === startOfSelectedNode;
          const isCursorInSelectedNode =
            cursorPos >= startOfSelectedNode && cursorPos <= endOfSelectedNode && isCurrentNode;

          if (cursorPos >= startOfNode && cursorPos <= endOfNode && isCursorInSelectedNode) {
            return setShowIcons(true);
          }
          setShowIcons(false);
        }
        setShowIcons(false);
      }
    },
    [setShowIcons, getPos]
  );

  useEffect(() => {
    if (editor) {
      editor.on('selectionUpdate', onSelectionUpdate);
      editor.on('blur', () => setShowIcons(false));
    }
  }, [editor, onSelectionUpdate]);

  const getLengthOfText = (personalRef: any): number => {
    const findTextEl: any = Array.from(personalRef?.current.children)?.find(
      (child: any) => child.className === 'node-view-content'
    );
    const lengthOfMentions = Array.from(findTextEl.querySelectorAll('.person-mention')).reduce(
      (acc, curr: any) => acc + curr.innerText.length,
      0
    );

    const lengthOfElement = findTextEl?.innerText.length;

    return lengthOfElement - (lengthOfMentions as number);
  };

  const handleSelectPerson = (person: People | Users | null) => {
    setSelectedPerson(person);
  };

  const handleOnChangePerson = (assignee: People | Users) => {
    if (!personalRef) return;

    if (selectedPerson) handleRemoveAttendee(selectedPerson);

    let personName;
    let personId = assignee.id;

    if (!assignee?.name) {
      personName = assignee?.email?.split('@')[0];
    } else {
      const fullName = assignee?.name;
      const [firstName, lastName] = fullName!.split(' ');
      personName = lastName ? `${firstName} ${lastName[0]}` : firstName;
    }

    updateAttributes({ assigneeId: personId });

    if (editor) {
      const { selection } = editor.state.tr;

      const isEmptyNode = editor.state.doc.resolve(getPos() + 1).node().textContent === '';
      if (isEmptyNode) {
        editor.commands.insertContentAt({ from: getPos() + 1, to: getPos() + 3 }, [
          { type: 'text', text: ' ' },
          { type: 'mentionAtom', attrs: { id: personId, label: personName } },
        ]);
        return;
      }

      editor.commands.insertContentAt({ from: selection.from, to: selection.to }, [
        { type: 'text', text: ' ' },
        { type: 'mentionAtom', attrs: { id: personId, label: personName } },
      ]);
    }
  };

  const handleRemoveAttendee = (assignee: People | Users) => {
    if (!personalRef) return;

    const lengthOfText = getLengthOfText(personalRef);

    editor?.commands.deleteRange({
      from: getPos() + lengthOfText,
      to: getPos() + lengthOfText + 2,
    });
    updateAttributes({ assigneeId: '' });
  };

  const handleOnChangeDueDate = (date: Date) => {
    updateAttributes({ dueDate: date.toISOString() });
  };

  useEffect(() => {
    if (!personalRef) return;
    const assignPerson = personalRef.current.querySelectorAll("[data-type='mentionAtom']");
    if (assignPerson && assignPerson.length > 0) {
      const chosenPerson = allPeople?.find((person: { id: any }) => {
        let personId = person.id;
        return personId === assignPerson[assignPerson.length - 1].dataset.id;
      });
      if (!chosenPerson) return setSelectedPerson(null);
      let personId = chosenPerson?.id;
      (!node.attrs.assigneeId || node.attrs.assigneeId !== personId) &&
        setTimeout(() => updateAttributes({ assigneeId: personId })); // update attr in useEffect calls flush sync -  error, that is why setTimeout
      setSelectedPerson(chosenPerson);
      return;
    }
    node.attrs.assigneeId && setTimeout(() => updateAttributes({ assigneeId: '' })); // update attr in useEffect calls flush sync -  error, that is why setTimeout
    setSelectedPerson(null);
  }, [allPeople, personalRef?.current?.innerText, updateAttributes, node.attrs.assigneeId]);

  return (
    <NodeViewWrapper
      ref={personalRef}
      onMouseEnter={() => setShowIconsOnHover(true)}
      onMouseLeave={() => setShowIconsOnHover(false)}
      data-duedate={node.attrs.dueDate}
      data-assigneeid={node.attrs.assigneeId}
      data-taskid={node.attrs.taskId}
      data-id={node.attrs.id}
      className={'date-attendee-component'}
    >
      {/*<input type={'checkbox'} data-type="" />*/}
      <Checkbox
        contentEditable={false}
        suppressContentEditableWarning={true}
        disabled={!editor?.isEditable}
        size="small"
        checked={node.attrs.checked}
        sx={{ marginRight: theme.spacing(1), marginTop: '5px' }}
        onChange={(event) => {
          updateAttributes({
            checked: event.target.checked,
          });
        }}
      />
      <NodeViewContent
        contentEditable={editor?.isEditable}
        className={'node-view-content'}
        style={{
          width: '100%',
          color: node.attrs.checked ? theme.palette.grey[600] : theme.palette.grey[800],
          opacity: node.attrs.checked ? 0.5 : 1,
        }}
      />
      <Stack
        contentEditable={false}
        suppressContentEditableWarning={true}
        spacing={1}
        display={'flex'}
        alignItems={'center'}
        direction={'row'}
        marginLeft={'auto'}
        sx={{ position: 'absolute', right: '0', height: '24px', paddingRight: '3px' }}
        onClick={() => {
          const findTextEl: any = Array.from(personalRef?.current.children).find(
            (child: any) => child.className === 'node-view-content'
          );
          const lengthOfMentions = Array.from(
            findTextEl.querySelectorAll('.person-mention')
          ).reduce((acc, curr: any) => acc + curr.innerText.length, 0);
          const countOfMentions = Array.from(findTextEl.querySelectorAll('.person-mention'));
          personalRef.current.focus();
          if (findTextEl) {
            if (node.attrs.dueDate && selectedPerson) {
              editor
                ?.chain()
                .focus(
                  getPos() +
                    2 +
                    findTextEl.innerText.length -
                    (lengthOfMentions as number) +
                    countOfMentions.length -
                    1
                )
                .run();
              return;
            }
            if (!node.attrs.dueDate && selectedPerson) {
              editor
                ?.chain()
                .focus(
                  getPos() +
                    2 -
                    1 +
                    findTextEl.innerText.length -
                    (lengthOfMentions as number) +
                    countOfMentions.length
                )
                .run();
              return;
            }
            editor
              ?.chain()
              .focus(getPos() + 2 + findTextEl.innerText.length)
              .run();
          }
        }}
      >
        {(showIconsOnHover || showIcons || node.attrs.dueDate) && (
          <CalendarPopover
            initialDate={node.attrs.dueDate}
            disabled={!editor?.isEditable}
            changeCalendar={(date: string) => handleOnChangeDueDate(new Date(date))}
          />
        )}
        {(showIconsOnHover || showIcons || node.attrs.assigneeId) && (
          <AvatarPopover
            selectedPerson={selectedPerson}
            setSelectedPerson={handleSelectPerson}
            disabled={!editor?.isEditable}
            onSelected={(assignee) => handleOnChangePerson(assignee)}
            onRemoveAttendee={(assignee) => handleRemoveAttendee(assignee)}
            people={allPeople ?? []}
          />
        )}
      </Stack>
    </NodeViewWrapper>
  );
};

export default DateAndAttendeeComponent;
