export interface WebSocketOptions {
  protocols?: string | string[];
  maxBackoff?: number;
  limitMaxListeners?: number;
}

export class ReconnectableWebSocket {
  private readonly url: string | (() => Promise<string>) | (() => string);

  private readonly protocols?: string | string[];

  private ws: WebSocket | null;

  private attempts: number;

  private readonly maxBackoff: number;

  private readonly maxListeners: number = 0;

  public listeners: Record<string, Array<(...args: any[]) => void>> = {};

  private closeConnection = false;

  constructor(url: string | (() => Promise<string>) | (() => string), options?: WebSocketOptions) {
    this.url = url;
    this.protocols = options?.protocols;
    this.ws = null;
    this.attempts = 1;
    // Set the default maximum backoff to 60 seconds if not provided
    this.maxBackoff = options?.maxBackoff || 60000;
    if (options?.limitMaxListeners) {
      this.maxListeners = options.limitMaxListeners;
    }
  }

  async connect(): Promise<void> {
    // console.debug('Connecting to WebSocket...', this.url);
    this.closeConnection = false;
    this.ws = new WebSocket(await this.getUrl(), this.protocols);

    this.ws.onopen = (): void => {
      // Check for any previously registered listeners and re-register them
      Object.keys(this.listeners).forEach((event) => {
        this.listeners[event].forEach((listener) => {
          this.ws?.addEventListener(event, listener);
        });
      });
    };

    this.ws.onclose = (): void => {
      if (this.closeConnection) {
        return;
      }
      this.reconnect();
    };

    this.ws.onerror = (error: Event): void => {
      console.error('WebSocket error:', error);
      this.ws?.close();
    };
  }

  private async getUrl(): Promise<string> {
    if (typeof this.url === 'function') {
      return this.url();
    }
    return this.url;
  }

  public close(): void {
    // console.debug('Closing WebSocket connection...', this.url);
    this.closeConnection = true;
    this.ws?.close();
  }

  public on(event: string, listener: (...args: any[]) => void): this {
    this.adjustListeners(event, 'add', listener);
    return this;
  }

  public off(event: string, listener: (...args: any[]) => void): this {
    this.adjustListeners(event, 'remove', listener);
    return this;
  }

  public send(data: any): void {
    if (this.ws?.readyState === WebSocket.OPEN) {
      this.ws?.send(data);
    } else {
      console.error('WebSocket not connected, unable to send data', data);
    }
  }

  private adjustListeners(
    event: string,
    state: 'add' | 'remove',
    listener: (...args: any[]) => void
  ): boolean {
    if (!this.listeners.hasOwnProperty(event)) {
      Object.defineProperty(this.listeners, event, {
        value: [],
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }

    if (state === 'add') {
      if (this.maxListeners && this.listeners[event].length + 1 > this.maxListeners) {
        console.error(`Max listeners (${this.maxListeners}) reached for event: ${event}`);
        return false;
      }
      this.listeners[event].push(listener);
      this.ws?.addEventListener(event, listener);
    } else if (state === 'remove') {
      this.ws?.removeEventListener(event, listener);
      const index = this.listeners[event].indexOf(listener);
      if (index > -1) {
        this.listeners[event].splice(index, 1);
      }
    }

    return true;
  }

  private reconnect(): void {
    // console.log(`Reconnecting in ${this.getReconnectDelay()}ms...`);
    setTimeout(async () => {
      // console.log(`Reconnection attempt #${this.attempts}`);
      this.attempts = this.attempts + 1;
      await this.connect();
    }, this.getReconnectDelay());
  }

  private getReconnectDelay(): number {
    return Math.min(this.maxBackoff, 1000 * Math.pow(2, this.attempts - 1));
  }

  /**
   * Proxies the WebSocket's readyState property
   */
  public get readyState(): number {
    return this.ws?.readyState ?? WebSocket.CLOSED;
  }
}
