import { Box } from '@mui/material';
import { Node, mergeAttributes } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { addColumnAfter, deleteColumn } from 'prosemirror-tables';
import { Decoration, DecorationSet } from 'prosemirror-view';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/tiptapIcons/closeIcon.svg';
import { Tooltip } from './TableTooltip';
import { getCellsInRow, isColumnSelected, selectColumn } from './TipTapTableUtils';

export interface TableHeaderOptions {
  HTMLAttributes: Record<string, any>;
}

export const TableHeader = Node.create<TableHeaderOptions, { clearCallbacks: Array<() => void> }>({
  name: 'tableHeader',
  content: 'block+',
  tableRole: 'header_cell',
  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      colspan: {
        default: 1,
      },
      rowspan: {
        default: 1,
      },
      colwidth: {
        default: null,
        parseHTML: (element) => {
          const colwidth = element.getAttribute('colwidth');
          return colwidth ? [parseInt(colwidth, 10)] : null;
        },
      },
      style: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'th' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['th', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addStorage() {
    return {
      clearCallbacks: [],
    };
  },

  onDestroy() {
    this.storage.clearCallbacks.forEach((cb) => cb());
    this.storage.clearCallbacks.length = 0;
  },

  // @ts-ignore
  addProseMirrorPlugins() {
    const { isEditable } = this.editor;

    return [
      new Plugin({
        key: new PluginKey('table-header-control'),
        props: {
          decorations: (state) => {
            if (!isEditable) {
              return DecorationSet.empty;
            }
            const { doc, selection } = state;
            const decorations: Decoration[] = [];
            const cells = getCellsInRow(0)(selection);
            if (cells) {
              this.storage.clearCallbacks.forEach((cb) => cb());
              this.storage.clearCallbacks.length = 0;

              cells.forEach(({ pos }, index) => {
                decorations.push(
                  Decoration.widget(pos + 1, () => {
                    const colSelected = isColumnSelected(index)(selection);
                    let className = 'grip-column';
                    if (colSelected) {
                      className += ' selected';
                    }
                    if (index === 0) {
                      className += ' first';
                    } else if (index === cells.length - 1) {
                      className += ' last';
                    }
                    const grip = document.createElement('a');
                    grip.className = className;

                    const root = createRoot(grip);

                    const handleClearRoot = () => {
                      this.storage.clearCallbacks.push(() => {
                        root.unmount();
                      });
                    };

                    root.render(
                      colSelected ? (
                        <span className="delete">
                          <Box className="deleteBox">
                            <Tooltip editor={this.editor} title={'Delete column'} placement={'top'}>
                              <CloseIcon />
                            </Tooltip>
                          </Box>
                        </span>
                      ) : (
                        <></>
                        // <span className="add">
                        //   <Box className="addBox" id={'add'}>
                        //     <Tooltip
                        //       editor={this.editor}
                        //       title={'Insert column right'}
                        //       placement={'top'}
                        //     >
                        //       <AddIcon id={'add'} />
                        //     </Tooltip>
                        //   </Box>
                        // </span>
                      )
                    );

                    grip.addEventListener('mousedown', (event: any) => {
                      event.preventDefault();
                      event.stopImmediatePropagation();

                      const isAdd = event.target.id === 'add';

                      if (event.target === grip) {
                        this.editor.view.dispatch(selectColumn(index)(this.editor.state.tr));
                      }
                      if (event.target !== grip && colSelected && !isAdd) {
                        deleteColumn(this.editor.state, this.editor.view.dispatch);
                        handleClearRoot();
                      }

                      if (event.target !== grip && !colSelected && isAdd) {
                        addColumnAfter(this.editor.state, this.editor.view.dispatch);
                        handleClearRoot();
                      }
                    });

                    return grip;
                  })
                );
              });
            }
            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});
