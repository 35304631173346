import { Node, mergeAttributes } from '@tiptap/core';

export type Level = 1;

export interface ITitleOptions {
  level: Level;
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  // interface Commands<ReturnType> {
  // Custom commands go here. Leaving this snippet for any devs needing to make their own extension
  // }
}

export const Title = Node.create<ITitleOptions>({
  name: 'title',

  content: 'text*',

  marks: '',

  defining: true,

  selectable: false,

  atom: false,

  addOptions() {
    return {
      level: 1,
      HTMLAttributes: {
        class: 'title-extension',
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    const { level } = this.options;

    return [`h${level}`, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },
});

export default Title;
