import { Editor } from '@tiptap/core';
import { ReactNode } from 'react';

import { SecondMenuValue } from './SecondMenu/types';

export type SlashCommandType<PropsType> = {
  editor: Editor | null;
  range: any;
  props: {
    attrs: PropsType;
  };
};

export interface ISlashCommandProps {
  command: any;
  icon: ReactNode;
  name: string;
  subtitle: string;
  value: SecondMenuValue[];
}

export const SLASH_MENU = {
  SALESFORCE: 'Salesforce Field',
  ACTION: 'Action Item',
  MENTION: 'Mention',
  EMOJI: 'Emoji',
  TEMPLATE: 'Template',
  GONG: 'Gong Call',
  TABLE: 'Table',
  AI: 'Context AI',
};

export enum SalesforceFieldTypes {
  TEXTAREA = 'textarea',
  PHONE = 'phone',
  ID = 'id',
  STRING = 'string',
  PICKLIST = 'picklist',
  MULTIPICKLIST = 'multipicklist',
  CURRENCY = 'currency',
  ADDRESS = 'address',
  DOUBLE = 'double',
  REFERENCE = 'reference',
  DATETIME = 'datetime',
  BOOLEAN = 'boolean',
  INT = 'int',
  DATE = 'date',
  URL = 'url',
  PERCENT = 'percent',
}
