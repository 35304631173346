import { Box, Tooltip } from '@mui/material';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import React from 'react';

import { ReactComponent as GongIcon } from '../../../assets/icons/tiptapIcons/gongNodeIcon.svg';
import GongModal from '../../gong/GongModal';
import { GongAttributesType } from '../extensions/GongNode';

const GongComponent: React.FC<NodeViewProps> = ({ node }) => {
  // Cast node.attrs to GongAttributesType
  const attrs = node.attrs as GongAttributesType;
  const [open, setOpen] = React.useState(false);

  const handleOpenModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleCloseModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <>
      <NodeViewWrapper as="span" className="gong">
        <Tooltip title="View Gong Call">
          <Box
            component="span"
            onClick={handleOpenModal}
            sx={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
          >
            <GongIcon style={{ verticalAlign: 'middle', marginRight: '2px' }} />
            {attrs.name}
          </Box>
        </Tooltip>
      </NodeViewWrapper>
      <GongModal
        open={open}
        handleCloseModal={handleCloseModal}
        name={attrs.name ?? ''}
        callId={attrs.callId}
        start={attrs.start}
        end={attrs.end}
      />
    </>
  );
};

export default GongComponent;
