import { TextField } from '@mui/material';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';

import { getSalesforceIcon } from '../SlashMenuUtils';
import { SalesforceInputComponentProps } from './SalesforceInputComponent';

const UrlInputComponent = forwardRef(
  ({ node, disabled, handleChangeValue, initialValue }: SalesforceInputComponentProps, ref) => {
    const [inputValue, setInputValue] = useState(initialValue ?? '');

    const handleChangeURLField = useCallback(
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = event.target;
        setInputValue(value);
        handleChangeValue(value, node.attrs.salesforceFieldName);
      },
      [handleChangeValue, node.attrs.salesforceFieldName]
    );

    const inputProps = useMemo(
      () => ({
        startAdornment: getSalesforceIcon({ node }),
        sx: { minHeight: '24px' },
        inputRef: ref,
        type: 'url',
        disableUnderline: true,
      }),
      [node, ref]
    );

    return (
      <TextField
        id={node.attrs.salesforceFieldName}
        fullWidth
        hiddenLabel
        disabled={disabled}
        value={inputValue}
        onChange={handleChangeURLField}
        variant="standard"
        multiline
        placeholder="No data"
        InputProps={inputProps}
      />
    );
  }
);

export default UrlInputComponent;
