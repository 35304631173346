import { SalesforceType } from '../types/salesforce';

export abstract class SalesforceRequiredKeys {
  salesforceKey!: string;

  salesforceType!: SalesforceType;
}

export abstract class SalesforceOptionalKeys {
  salesforceKey?: string;

  salesforceType?: SalesforceType;
}

export abstract class SalesforceRequired extends SalesforceRequiredKeys {
  SystemModstamp!: Date;
}
