import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { PicklistValues, SalesforceType } from '../../../@types/api/types/configTypes';
import SalesforceComponent from '../slash-menu/SalesforceComponent/SalesforceComponent';
import { SalesforceFieldTypes } from '../slash-menu/types';

export interface ISlashNodeInterface {
  attrs: {
    salesforceFieldLabel: string;
    salesforceFieldName: string;
    salesforceKey: string;
    salesforceType: SalesforceType;
    calculatedFormula: boolean;
    picklistValues: PicklistValues[];
    compoundFieldName: string | null;
    isCompoundParent: boolean;
    updateable: boolean;
    type: SalesforceFieldTypes;
    lastUpdated: Date | string;
    value: any;
  };
}

export const SalesforceNode = Node.create({
  name: 'contextSalesforce',

  group: 'block',

  content: 'block*',

  atom: true,

  selectable: true,

  isolating: true,

  parseHTML() {
    return [
      {
        tag: 'context-salesforce',
      },
    ];
  },

  addAttributes() {
    return {
      salesforceFieldName: {
        default: 'name',
      },
      salesforceFieldLabel: {
        default: 'label',
      },
      salesforceType: {
        default: 'type',
      },
      salesforceKey: {
        default: '',
      },
      calculatedFormula: {
        default: false,
      },
      picklistValues: {
        default: [],
      },
      compoundFieldName: {
        default: null,
      },
      isCompoundParent: {
        default: false,
      },
      updateable: {
        default: true,
      },
      type: {
        default: 'string',
      },
      lastUpdated: {
        default: '',
      },
      value: {
        default: '',
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['context-salesforce', mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SalesforceComponent);
  },
});
