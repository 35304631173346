import { Box, ClickAwayListener } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Editor } from '@tiptap/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Turndown from 'turndown';

import { useWebsocket } from '../../../hooks/zustand/useWebsocket';
import { ReconnectableWebSocket } from '../../../utils/websocket';
import '../styles.scss';
import OpenAIButtons from './OpenAIButtons';
import OpenAIInput from './OpenAIInput';
import OpenAIMenuNote from './OpenAIMenuNote';
import { convertAndSanitize, getPrompt, isWhitespaceString } from './OpenAIMenuUtils';
import { Buttons } from 'src/components/modal/Buttons';
import { GeneralModalComponent } from 'src/components/modal/GeneralModalComponent';
import { BASE_URL, NOTES } from 'src/config';

const WS_URL = BASE_URL.replace('http', 'ws');

const wsNoteAIPath = `ws/ai/openAI/note`;

const OpenAIMenu = ({
  editor,
  setIsEditingOpenAI,
}: {
  editor: Editor;
  setIsEditingOpenAI: (open: boolean) => void;
}) => {
  const theme = useTheme();
  const { sockets, initialize, disconnect } = useWebsocket();
  const [aiSocket, setAiSocket] = useState<ReconnectableWebSocket | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState('');
  const [isResponse, setIsResponse] = useState(false);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const [prevInputValue, setPrevInputValue] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const turndownService = useMemo(() => new Turndown(), []);

  const isEmptySelection =
    editor.state.selection.empty ||
    isWhitespaceString(editor.state.selection.$anchor.parent.textContent);

  useEffect(() => {
    if (sockets[wsNoteAIPath]) {
      sockets[wsNoteAIPath].connect().then(() => setAiSocket(sockets[wsNoteAIPath]));
    }
  }, [sockets]);

  useEffect(() => {
    initialize(`${WS_URL}/${wsNoteAIPath}`, wsNoteAIPath);
    return () => {
      disconnect(wsNoteAIPath);
    };
  }, [initialize, disconnect]);

  const handleClickSend = useCallback(
    async ({
      specialPrompt,
      prevPrompt,
      inputValue,
    }: {
      specialPrompt?: string;
      prevPrompt?: string;
      inputValue?: string;
    }) => {
      if (!aiSocket) {
        return;
      }
      if (result) {
        setResult('');
      }
      setIsLoading(true);
      const controller = new AbortController();
      setAbortController(controller);
      const prompt = getPrompt({
        editor,
        specialPrompt,
        inputValue,
        isEmptySelection,
        prevPrompt,
      });
      const markdown = turndownService.turndown(prompt || '');
      aiSocket.send(JSON.stringify({ messages: [{ role: 'user', content: markdown }] }));
      setPrevInputValue && setPrevInputValue(markdown);
    },
    [aiSocket, result, editor, isEmptySelection, turndownService]
  );

  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (isResponse) {
          return setOpenModal(true);
        }
        setIsEditingOpenAI(false);
      }
    },
    [isResponse, setIsEditingOpenAI]
  );

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => window.removeEventListener('keydown', keyDownHandler);
  }, [keyDownHandler]);

  const abortStream = () => {
    if (abortController && aiSocket) {
      abortController.abort();
      aiSocket.send(JSON.stringify({ messages: [] }));
      setIsLoading(false);
      setIsResponse(true);
      setAbortController(null);
    }
  };

  if (!aiSocket) {
    return null;
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const clickAwayHandler = () => {
    if (isResponse) {
      return setOpenModal(true);
    }
    setIsEditingOpenAI(false);
  };

  const clickAwayHandlerItems = (e: MouseEvent | TouchEvent) => {
    const target = e.target as HTMLElement;
    if (target.id === 'AIWrapper') {
      return clickAwayHandler();
    }
  };

  const handleDiscard = () => {
    editor.commands.focus();
    setIsEditingOpenAI(false);
  };

  const handleSubmit = (inputValue: string) => {
    handleClickSend({ inputValue });
  };

  return (
    <ClickAwayListener
      onClickAway={clickAwayHandler}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      disableReactTree={true}
    >
      <Box sx={{ maxWidth: NOTES.WIDTH, position: 'relative' }} id="AIWrapper">
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            boxShadow: theme.customShadows.dropdown,
            borderRadius: '12px',
            border: `1px solid ${theme.palette.grey[300]}`,
            marginBottom: '8px',
            padding: '12px 16px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          id="AIInput"
        >
          <OpenAIMenuNote
            aiSocket={aiSocket}
            handleResponse={setIsResponse}
            handleLoading={setIsLoading}
            handleResult={setResult}
          />

          <OpenAIInput
            isLoading={isLoading}
            isResponse={isResponse}
            abortStream={abortStream}
            handleSubmit={handleSubmit}
            result={result}
            startAdornmentPlaceholder="Ai is writing..."
            loadingSpinner={true}
            multiline={true}
            sx={{
              paddingBottom: '0px',
            }}
          />
        </Box>
        <OpenAIButtons
          isEmptySelection={isEmptySelection}
          editor={editor}
          isResponse={isResponse}
          handleClickSend={handleClickSend}
          prevInputValue={prevInputValue}
          cleanResult={convertAndSanitize(result)}
          clickAwayHandlerItems={clickAwayHandlerItems}
          setIsEditingOpenAI={setIsEditingOpenAI}
        />
        <GeneralModalComponent
          open={openModal}
          handleCloseModal={handleCloseModal}
          headerText={'Discard AI Response'}
          dividers={false}
          content={
            <Box sx={{ padding: '8px 24px', width: '100%' }}>
              Do you want to discard the AI response?
            </Box>
          }
          sx={{ width: '380px' }}
          titleSx={{ padding: '24px' }}
          buttons={
            <Buttons
              activeButtonName="Discard"
              handleClick={handleDiscard}
              handleCloseModal={handleCloseModal}
              buttonColor="primary"
            />
          }
        />
      </Box>
    </ClickAwayListener>
  );
};
export default OpenAIMenu;
