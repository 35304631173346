import { Box, Stack, Typography, useTheme } from '@mui/material';

import SpinnerIcon from '../../../spinner-icon/SpinnerIcon';
import { ISlashNodeInterface } from '../../extensions/ContextSalesforceExtension';
import { SalesforceType } from 'src/@types/api/types/salesforce';
import { getLastUpdated } from 'src/utils/formatTime';

export interface IFieldsForType {
  type: SalesforceType;
  fields: any;
}

const SalesforceComponentHeader = ({
  isSaving,
  node,
}: {
  isSaving: boolean;
  node: ISlashNodeInterface;
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', marginLeft: 'auto', minWidth: 'fit-content' }}>
      {isSaving ? (
        <Stack spacing={0.5} direction="row" alignItems="center" style={{ marginLeft: 'auto' }}>
          <SpinnerIcon text={'Saving...'} width={16} height={16} />
        </Stack>
      ) : (
        <Stack spacing={0} direction="row" style={{ marginLeft: 'auto' }}>
          {node.attrs.lastUpdated && node.attrs.salesforceKey && (
            <Typography variant={'caption'} color={theme.palette.grey[500]}>
              Last updated at {getLastUpdated(node.attrs.lastUpdated)}
            </Typography>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default SalesforceComponentHeader;
