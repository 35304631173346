import { closestTo, isFuture, isPast } from 'date-fns';

import { AuthUserType } from '../../auth/types';
import { getCurrentUser } from '../../components/share/ShareUtils';
import Deals from '../api/deals';
import Meetings from '../api/meetings';
import { AccessLevel } from '../api/types/accessLevel';
import { SalesforceType } from '../api/types/configTypes';
import Meeting from './meeting';
import MeetingAttendee, { MeetingAttendeeType } from './meetingAttendee';

export default class Deal extends Deals {
  getPeopleByType(type?: MeetingAttendeeType): MeetingAttendee[] {
    const meetingAttendees: MeetingAttendee[] = [];

    this.meetings?.forEach((meeting) => {
      const people = new Meeting(meeting).getPeopleByType(type);
      people.forEach((person) => {
        // check if the person's email is already in the set
        const existingPerson = Array.from(meetingAttendees).find(
          (attendee) => attendee.email === person.email
        );
        if (!existingPerson) {
          meetingAttendees.push(person);
        }
      });
    });

    meetingAttendees.sort((a, b) => a.name?.localeCompare(b.name));

    return meetingAttendees;
  }

  getAllPersonMeetings(person: MeetingAttendee): Meetings[] {
    return (
      this.meetings?.filter((meeting) =>
        meeting.meetingAttendees?.find((attendee) => {
          let curPersonId = person.person?.id ?? person.userId;
          let personId = attendee.peopleId ?? attendee.userId;
          return personId === curPersonId;
        })
      ) ?? []
    );
  }

  getNumberOfMeetings(person: MeetingAttendee): number {
    let count = 0;
    this.meetings?.forEach((meeting) => {
      meeting.meetingAttendees?.forEach((attendee) => {
        if (person.person?.id && attendee.peopleId === person.person?.id) {
          count++;
        }
        if (person.userId && attendee.userId === person.userId) {
          count++;
        }
      });
    });

    return count;
  }

  getLastMeeting(person: MeetingAttendee): Meeting | undefined {
    const allPersonMeetings = this.getAllPersonMeetings(person);
    // @ts-ignore
    const allPersonMeetingsData: Date[] = allPersonMeetings
      .map((meeting) => new Meeting(meeting).start)
      .filter((value) => value !== undefined);

    const lastMeeting = allPersonMeetings.find((meeting) => {
      const { start } = new Meeting(meeting);

      if (start) {
        return (
          new Date(start).toString() ===
          closestTo(
            new Date(),
            allPersonMeetingsData.map((date) => new Date(date)).filter((date) => isPast(date))
          )?.toString()
        );
      }
      return false;
    });

    return lastMeeting && new Meeting(lastMeeting);
  }

  getNextMeetingByPerson(person: MeetingAttendee): Meeting | undefined {
    const allPersonMeetings = this.getAllPersonMeetings(person);
    // @ts-ignore
    const allPersonMeetingsData: Date[] = allPersonMeetings
      .map((meeting) => new Meeting(meeting).start)
      .filter((value) => value !== undefined);

    const nextMeeting = allPersonMeetings.find((meeting) => {
      const { start } = new Meeting(meeting);
      if (start) {
        return (
          new Date(start).toString() ===
          closestTo(
            new Date(),
            allPersonMeetingsData.map((date) => new Date(date)).filter((date) => isFuture(date))
          )?.toString()
        );
      }
      return false;
    });

    return nextMeeting && new Meeting(nextMeeting);
  }

  getNextMeeting(): Meeting | undefined {
    const allMeetings = this.meetings;
    // @ts-ignore
    const allMeetingsData: Date[] = allMeetings
      ?.map((meeting) => new Meeting(meeting).start)
      .filter((value) => value !== undefined);

    const nextMeeting = allMeetings?.find((meeting) => {
      const { start } = new Meeting(meeting);
      if (start) {
        return (
          new Date(start).toString() ===
          closestTo(
            new Date(),
            allMeetingsData.map((date) => new Date(date)).filter((date) => isFuture(date))
          )?.toString()
        );
      }
      return false;
    });

    return nextMeeting && new Meeting(nextMeeting);
  }

  canEdit(user: AuthUserType): boolean {
    const currentUser = getCurrentUser(this.dealUsers, user);
    return currentUser?.accessLevel !== AccessLevel.Read;
  }

  getSalesforceDataStructure() {
    const { meta: metaAccount, ...otherFieldsAccount } = this.company.salesforceAccount;
    const { meta: metaOpportunity, ...otherFieldsOpportunity } = this.salesforceOpportunity;

    return [
      {
        type: SalesforceType.ACCOUNT,
        fields: { ...otherFieldsAccount, ...metaAccount },
      },
      {
        type: SalesforceType.OPPORTUNITY,
        fields: { ...otherFieldsOpportunity, ...metaOpportunity },
      },
    ];
  }

  constructor(data: Partial<Deal>) {
    super();
    Object.assign(this, data);
  }
}
