import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';

import { CommandsList } from './CommandsList';

export const Suggestions = ({ commands }: { commands?: any[] }) => ({
  items: ({ query }: { query: string }) =>
    commands?.filter((item: any) => item.name?.toLowerCase()?.includes(query.toLowerCase())) ?? [],

  render: () => {
    let reactRenderer: any;
    let popup: any;

    const main = document.querySelector('.deal-layout');

    const handleHidePopup = () => {
      popup[0].hide();
    };

    return {
      onStart: (props: any) => {
        reactRenderer = new ReactRenderer(CommandsList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          popperOptions: {
            modifiers: [
              // This comment out for placed only on bottom-start for any height of screen
              // {
              //   name: 'flip',
              //   options: {
              //     flipVariations: true,
              //     // fallbackPlacements: ['top-start', 'right', 'auto'],
              //     padding: 142,
              //   },
              // },
              {
                name: 'preventOverflow',
                options: {
                  padding: 0,
                },
              },
            ],
          },
          trigger: 'manual',
          placement: 'bottom-start',
        });
        main?.addEventListener('scroll', handleHidePopup);
      },

      onUpdate(props: any) {
        reactRenderer.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props: any) {
        if (props.event.key === 'Escape') {
          popup[0].destroy();
          main?.removeEventListener('scroll', handleHidePopup);
          reactRenderer.destroy();
          return true;
        }
        main?.removeEventListener('scroll', handleHidePopup);
        return reactRenderer?.ref?.onKeyDown(props);
      },

      onExit() {
        main?.removeEventListener('scroll', handleHidePopup);
        popup?.[0].destroy();
        reactRenderer.destroy();
      },
    };
  },
});
