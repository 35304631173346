export enum SearchType {
  PEOPLE = 'people',
  COMPANIES = 'companies',
  DEALS = 'deals',
  MEETINGS = 'meetings',
  NOTES = 'notes',
  USERS = 'users',
  ALL = 'all',
}

export interface SearchResult {
  id: string;
  description: string;
  dealId?: string;
  meetingDate?: Date | string;
}

export interface UsersSearchResult extends SearchResult {
  email: string;
  image: string;
}

export interface ModifiedSearchResult extends SearchResult {
  type: SearchType;
}

export interface SearchResultsAndType {
  type: SearchType;
  results: SearchResult[];
}

type SearchResultTemp = {
  [key in SearchType]: SearchResult[];
};

export type SearchResults = Omit<SearchResultTemp, 'all'>;
export type UsersSearchResults = {
  [SearchType.USERS]: UsersSearchResult[];
};
