export const sortByDate =
  <T>(getProperty: (a: T) => number | string | Date) =>
  (a: T, b: T) => {
    const value1 = new Date(getProperty(a)).valueOf();
    const value2 = new Date(getProperty(b)).valueOf();
    return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
  };

export const sortByDateReverse =
  <T>(getProperty: (a: T) => number | string | Date) =>
  (a: T, b: T) => {
    const value1 = new Date(getProperty(a)).valueOf();
    const value2 = new Date(getProperty(b)).valueOf();
    return value1 < value2 ? 1 : value1 > value2 ? -1 : 0;
  };

export const sortBySoonestDate =
  <T>(getProperty: (a: T) => number | string | Date) =>
  (a: T, b: T) => {
    const value1 = new Date(getProperty(a)).valueOf();
    const value2 = new Date(getProperty(b)).valueOf();
    const now = new Date().valueOf();

    // we want to sort by the closest future date to now
    // dates in the past should be sorted to the bottom

    // if both dates are in the past, sort by the most recent
    if (value1 < now && value2 < now) {
      return value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
    }

    // if one date is in the past and one is in the future, sort the future date to the top
    if (value1 < now && value2 > now) {
      return 1;
    }

    if (value1 > now && value2 < now) {
      return -1;
    }

    // if both dates are in the future, sort by the closest date to now
    return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
  };
