import {
  HocuspocusProvider,
  HocuspocusProviderWebsocket,
  onAuthenticationFailedParameters,
  onCloseParameters,
  onStatusParameters,
  onSyncedParameters,
} from '@hocuspocus/provider';
import { useTheme } from '@mui/material/styles';
import { Emoji, gitHubEmojis } from '@tiptap-pro/extension-emoji';
import { Editor, Extension } from '@tiptap/core';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import { Collaboration } from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import { Color } from '@tiptap/extension-color';
import { Document } from '@tiptap/extension-document';
import Focus from '@tiptap/extension-focus';
import HardBreak from '@tiptap/extension-hard-break';
import { Highlight } from '@tiptap/extension-highlight';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Italic from '@tiptap/extension-italic';
import { Link } from '@tiptap/extension-link';
import { Mention } from '@tiptap/extension-mention';
import OrderedList from '@tiptap/extension-ordered-list';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Placeholder } from '@tiptap/extension-placeholder';
import Strike from '@tiptap/extension-strike';
import { TaskList } from '@tiptap/extension-task-list';
import { Text } from '@tiptap/extension-text';
import { TextStyle } from '@tiptap/extension-text-style';
import Typography from '@tiptap/extension-typography';
import { Underline } from '@tiptap/extension-underline';
import { Transaction } from '@tiptap/pm/state';
import { EditorContent, FocusPosition, useEditor } from '@tiptap/react';
import StarterKit, { StarterKitOptions } from '@tiptap/starter-kit';
import { DecorationAttrs } from 'prosemirror-view';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useAuthContext } from '../../auth/useAuthContext';
import { AI_ENABLED, BASE_URL } from '../../config';
import { useDealContext } from '../../hooks/useDealContext';
import NoteClosedMessagePage from '../../pages/core/messages/NoteClosedMessagePage';
import '../../style/pm.css';
import LoadingScreen from '../loading-screen';
import { isNoteEditable } from '../note/NoteUtils';
import BubbleToolbar from './BubbleToolbar';
import ControlledBubbleMenu from './ControlledBubbleMenu';
import FloatingToolbar from './FloatingToolbar';
import suggestion from './emoji/Suggestion';
import { CustomParagraphAIMenu } from './extensions/AIMenu';
import { AIRequest, AIResponse } from './extensions/AIMessage';
import Commands from './extensions/ContextCommandsExtension';
import { ContextImage } from './extensions/ContextImageNode';
import { ContextListItem } from './extensions/ContextListItemExtension';
import { SalesforceNode } from './extensions/ContextSalesforceExtension';
import { ContextTaskItemExtension } from './extensions/ContextTaskItemExtension';
import { Gong } from './extensions/GongNode';
import Selection from './extensions/Selection';
import Title from './extensions/TitleNode';
import { Heading } from './extensions/heading/Heading';
import { TableBubbleMenu } from './extensions/table/TableBubbleMenu';
import { TableExtensionsPack } from './extensions/table/TableExtensionsPack';
import PersonCard from './mention/PersonCard';
import { Suggestions } from './mention/Suggestion';
import OpenAIMenu from './openai-menu/OpenAIMenu';
import { handleDropImage } from './slash-menu/SlashMenuUtils';
import { ICommands, useSlashMenuSuggestions } from './slash-menu/useSlashMenuSuggestions';
import './styles.scss';
import NoteTemplates from 'src/@types/api/noteTemplates';
import Notes from 'src/@types/api/notes';
import People from 'src/@types/api/people';
import Users from 'src/@types/api/users';
import { NoteContext } from 'src/hooks/useNoteContext';

interface IPlaceHolder {
  title?: string;
  paragraph?: string;
}

export interface ITiptapProps {
  atMentions?: (People | Users)[];
  editable: boolean;
  customClassName: string;
  noteId: string;
  placeHolder?: IPlaceHolder;
  customSchema?: string;
  showFloatingToolbar?: boolean;
  autofocus?: FocusPosition | boolean | null;
  isNotePage?: boolean;
  commands?: ICommands;
  isTemplate?: boolean;
  onClose?: (code: number) => void;
  onUpdate?: (editor: Editor, transaction: Transaction) => void;
  onCreate?: (editor: Editor) => void;
  collabCursor?: boolean;
  disableConnection?: boolean;
  openAI?: boolean;
  offlineEditing?: boolean;
  note?: Notes | NoteTemplates;
}
export const DEFAULT_EXTENSIONS = [
  HorizontalRule,
  AIRequest,
  AIResponse,
  Color,
  ContextImage.configure({
    HTMLAttributes: {
      class: 'upload-image',
    },
  }),
  ContextListItem,
  ContextTaskItemExtension,
  CustomParagraphAIMenu,
  Emoji.configure({
    emojis: gitHubEmojis,
    enableEmoticons: true,
    suggestion,
  }),
  Focus,
  Gong,
  Heading.configure({
    levels: [1, 2],
    HTMLAttributes: {
      class: 'heading-anchor',
    },
  }),
  Highlight.configure({
    multicolor: true,
    HTMLAttributes: {
      class: 'highlight-text',
    },
  }),
  Italic,
  Link.configure({
    autolink: true,
    HTMLAttributes: {
      class: 'navigate-link',
      'data-link': 'navigate-link',
    },
  }),
  Selection,
  StarterKit.configure({
    horizontalRule: false,
    codeBlock: false,
    heading: false,
    document: false,
    listItem: false,
    history: false,
    hardBreak: false,
    orderedList: false,
    bulletList: false,
    bold: false,
    strike: false,
    italic: false,
  }) as Extension<StarterKitOptions>,
  Strike,
  HardBreak,
  BulletList,
  OrderedList,
  TaskList,
  TextStyle,
  Title,
  Typography,
  Underline,
  ...TableExtensionsPack,
  Bold,
];

export const ADDITIONAL_EXTENSIONS = [
  Commands,
  Collaboration,
  Document.extend({
    content: 'title block+',
  }),
  Mention.extend({ name: 'mentionAtom' }).configure({
    HTMLAttributes: {
      class: 'person-mention',
    },
    suggestion: Suggestions({}),
  }),
  Paragraph,
  Placeholder,
  SalesforceNode,
  Text,
];

const TiptapEditor = ({
  atMentions = [],
  editable = true,
  customClassName,
  noteId,
  placeHolder,
  customSchema,
  showFloatingToolbar = true,
  autofocus = false,
  commands,
  isTemplate = false,
  onClose = () => {},
  onUpdate = () => {},
  onCreate = () => {},
  collabCursor = true,
  disableConnection = false,
}: ITiptapProps) => {
  const theme = useTheme();

  const [isEditingOpenAI, setIsEditingOpenAI] = useState(false);
  // const { activeSlashName, setActiveSlashName } = useSlashComponentContext();
  const { slashMenu } = useSlashMenuSuggestions({
    commands,
    setIsEditingOpenAI,
  });
  const { user } = useAuthContext();

  const tiptapRef = useRef<HTMLDivElement>(null);

  const onCreateRef = useRef(onCreate);
  const onCloseRef = useRef(onClose);
  const onUpdateRef = useRef(onUpdate);

  const [error, setError] = useState({ isError: false, reason: '' });

  const userProperties = useMemo(
    () => ({
      color: theme.palette.primary.main,
      name: user?.name?.split(' ').shift(),
    }),
    [user?.name, theme.palette.primary.main]
  );

  const provider = useMemo(() => {
    const url = `${BASE_URL.replace('http', 'ws')}/ws/collab/${isTemplate ? 'templates' : 'notes'}/${noteId}`;
    const socket = new HocuspocusProviderWebsocket({ url });
    return new HocuspocusProvider({
      name: noteId,
      websocketProvider: socket,
      connect: !disableConnection,
      preserveConnection: false,

      onAuthenticationFailed: (data: onAuthenticationFailedParameters) => {
        console.error('Authentication Failed', data);
      },
      onConnect: () => {
        // console.debug('Connected');
      },
      onStatus: (data: onStatusParameters) => {
        // console.debug('onStatus', data);
      },
      onSynced: (data: onSyncedParameters) => {
        // console.debug('onSynced', data);
      },
      onClose: (data: onCloseParameters) => {
        const { event } = data;
        onCloseRef.current(event.code);
        if ([4042, 4043, 4044].includes(event.code)) {
          setError({ isError: true, reason: event.reason });
        }
      },
    });
  }, [disableConnection, isTemplate, noteId]);

  useEffect(
    () => () => {
      provider.disconnect();
    },
    [provider]
  );

  const editor = useEditor({
    extensions: [
      ...DEFAULT_EXTENSIONS,
      Commands.configure({
        suggestion: slashMenu,
      }),
      Collaboration.configure({ document: provider.document }),
      ...(collabCursor
        ? [
            CollaborationCursor.configure({
              provider,
              user: userProperties,
              selectionRender(user: Record<string, any>): DecorationAttrs {
                return {
                  class: `collaboration-cursor__selection`,
                  style: `background-color: ${user.color}70`,
                };
              },
              render: (user) => {
                const cursor = document.createElement('span');

                cursor.classList.add('collaboration-cursor__caret');
                cursor.setAttribute('style', `border-color: ${user.color}`);

                const label = document.createElement('div');

                label.classList.add('collaboration-cursor__label');
                label.setAttribute('style', `background-color: ${user.color}`);
                label.insertBefore(document.createTextNode(user.name), null);
                cursor.insertBefore(label, null);

                return cursor;
              },
            }),
          ]
        : []),
      Document.extend({
        content: customSchema ? customSchema : 'title block+',
      }),
      Mention.extend({ name: 'mentionAtom' }).configure({
        HTMLAttributes: {
          class: 'person-mention',
        },
        suggestion: Suggestions({ atMentions: atMentions }),
      }),
      Placeholder.configure({
        showOnlyCurrent: false,
        placeholder: ({ node }: { node: any }) => {
          if (node.type.name === 'title') {
            return `${placeHolder?.title}`;
          }
          if (node.type.name === 'paragraph') {
            return `${placeHolder?.paragraph}`;
          }
          return '';
        },
      }),
      SalesforceNode,
    ],
    enableContentCheck: true,
    shouldRerenderOnTransaction: false,
    editorProps: {
      handleDrop: (view: any, event: DragEvent, slice: any, moved: boolean) =>
        handleDropImage({ view, event, slice, moved, noteId: noteId ?? '' }),
    },
    onUpdate: ({ editor, transaction }: { editor: Editor; transaction: Transaction }) => {
      onUpdateRef.current(editor, transaction);
    },
    onCreate: ({ editor }: { editor: Editor }) => {
      onCreateRef.current(editor);
    },
    onContentError({ editor, error, disableCollaboration }) {
      console.error('Content error', error);
      setError({ isError: true, reason: 'Note Content Error' });
    },
    editable,
    autofocus,
  });

  if (!editor) {
    return null;
  }

  if (provider.isConnected && !provider.isSynced) {
    provider.forceSync();
  }

  if (error.isError) {
    console.log('Error', error.reason);
    provider.disconnect();
    editor.setEditable(false);
    return <NoteClosedMessagePage reason={error.reason} custom={error.reason} />;
  }

  // we don't care if we are not synced
  if (!provider.isConnected && !provider.isSynced) {
    return <LoadingScreen color={theme.palette.primary.dark} />;
  }

  return (
    <div
      ref={tiptapRef}
      style={{
        flexGrow: 1,
        display: 'flex',
        position: 'relative',
        width: '100%',
      }}
    >
      {editable && <BubbleToolbar editor={editor} setIsEditingOpenAI={setIsEditingOpenAI} />}
      {editable && <TableBubbleMenu editor={editor} editable={editable} />}

      <EditorContent
        editor={editor}
        className={`${customClassName} ${isEditingOpenAI && 'padding'}`}
        id={`tiptap-editor-${noteId}`}
      />
      <PersonCard ref={tiptapRef} atMentions={atMentions} editable={editable} />
      {AI_ENABLED && (
        <ControlledBubbleMenu editor={editor} open={isEditingOpenAI}>
          <OpenAIMenu editor={editor} setIsEditingOpenAI={setIsEditingOpenAI} />
        </ControlledBubbleMenu>
      )}

      {showFloatingToolbar && editor && (
        <FloatingToolbar editor={editor} setIsEditingOpenAI={setIsEditingOpenAI} />
      )}
    </div>
  );
};

const EditorChooser = (props: ITiptapProps) => {
  const { atMentions, disableConnection, editable, note, isNotePage } = props;

  const { user } = useAuthContext();
  const { allPeople } = useDealContext();

  const allEditable = useMemo(() => {
    // Check props.editable
    if (editable !== undefined) {
      return editable;
    }
    // Check if the connection is disabled
    if (disableConnection) {
      return true;
    }
    return isNoteEditable(user, note);
  }, [editable, disableConnection, note, user]);

  const people = useMemo(() => {
    const atMentionsValue = atMentions
      ? atMentions
      : note instanceof Notes && note.noteUsers
        ? note.noteUsers.map((user) => new Users(user.user))
        : [];
    if (isNotePage) return atMentionsValue;
    return allPeople;
  }, [atMentions, allPeople, isNotePage, note]);

  return (
    <NoteContext.Provider value={{ note }}>
      <TiptapEditor {...props} atMentions={people} editable={allEditable} />
    </NoteContext.Provider>
  );
};
export default EditorChooser;
