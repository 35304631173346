import { Stack, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import { forEach } from 'lodash';
import React, { useState } from 'react';

import { PrimaryId } from '../../../../../@types/api/types/id';
import { Buttons } from '../../../../../components/modal/Buttons';
import ModalComponent from '../../../../../components/modal/Modal';
import { INoteTreeNode, getNoteTreeArray } from '../DashboardNavNotesUtils';

interface IMoveNotesProps {
  thisNote: INoteTreeNode;
  noteTree: INoteTreeNode[];
  open: boolean;
  handleCloseModal: any;
  handleClick: any;
}

function getOptionLabel(option: INoteTreeNode): string {
  const noteTitle = option.title ?? 'Untitled Note';
  if (option.parentNoteId === 'root') return noteTitle;
  if (option?.children?.length === 0) return '•  ' + noteTitle;
  return '▾  ' + noteTitle;
}

function getChildIds(noteId: PrimaryId, noteTree: INoteTreeNode[]) {
  const childIds: PrimaryId[] = [];
  forEach(noteTree, (note) => {
    if (note.id === noteId) {
      forEach(note.children, (child) => {
        childIds.push(child.id);
        childIds.push(...getChildIds(child.id, noteTree));
      });
    }
  });

  return childIds;
}

const MoveNotes = ({
  thisNote,
  noteTree,
  open,
  handleCloseModal,
  handleClick,
}: IMoveNotesProps) => {
  const theme = useTheme();

  const [selectedParentNote, setSelectedParentNote] = React.useState<INoteTreeNode | null>(null);

  const [inputValue, setInputValue] = useState<string>('');

  const handleChangeNote = (event: any, parentNote: INoteTreeNode | null) => {
    event.stopPropagation();
    setSelectedParentNote(parentNote);
  };

  const rootPlaceholder = { title: 'My Notes' } as INoteTreeNode;
  rootPlaceholder.depth = 0;
  rootPlaceholder.parentNoteId = 'root';
  const options = [rootPlaceholder, ...getNoteTreeArray(noteTree, thisNote)];
  const title = thisNote.title ?? 'Untitled Note';
  return (
    <ModalComponent
      open={open}
      handleClose={handleCloseModal}
      header={<Typography variant={'h6'}>Reorder Note</Typography>}
      buttons={
        <Buttons
          activeButtonName={'Move'}
          handleCloseModal={handleCloseModal}
          handleClick={() => handleClick(selectedParentNote)}
          disabled={selectedParentNote === null}
        />
      }
    >
      <Stack sx={{ padding: '0 24px', width: '100%' }}>
        <Autocomplete
          size={'small'}
          value={selectedParentNote}
          onChange={(event, note) => handleChangeNote(event, note)}
          inputValue={inputValue}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={options}
          getOptionLabel={(option) => (option.title ? option.title : 'Untitled Note')}
          getOptionDisabled={(option) =>
            [...getChildIds(thisNote.id, noteTree), thisNote.id, thisNote.parentNoteId].includes(
              option.id
            ) ||
            (thisNote.depth === 0 && option.parentNoteId === 'root')
              ? true
              : false
          }
          renderOption={(props, option) => (
            <div
              key={option?.id || 'root'}
              className="padded-menu"
              style={{ paddingLeft: option ? option.depth * 20 : 0 }}
            >
              <li {...props}>{getOptionLabel(option)}</li>
            </div>
          )}
          componentsProps={{
            popper: {
              sx: {
                zIndex: '5001',
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={`Place "${title}" under...`}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.grey[300]}!important`,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.grey[300]}!important`,
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.grey[300]}!important`,
                },
              }}
            />
          )}
        />
      </Stack>
    </ModalComponent>
  );
};

export default MoveNotes;
