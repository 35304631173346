import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration, replayIntegration } from '@sentry/react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import 'simplebar-react/dist/simplebar.min.css';

import App from './App';
import { AuthProvider } from './auth/AuthContext';
import { MotionLazyContainer } from './components/animate';
import ErrorBoundary from './components/error/error-boundary';
import GoogleTagManager from './components/google-tag-manager/GoogleTagManager';
import ScrollToTop from './components/scroll-to-top';
import { SettingsProvider, ThemeSettings } from './components/settings';
import SnackbarProvider from './components/snackbar';
import ThemeLocalization from './locales';
import './locales/i18n';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import './style/pm.css';
import ThemeProvider from './theme';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development',
  integrations: [browserTracingIntegration(), replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export const tagManagerArgs: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
  auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH || '',
  preview: process.env.REACT_APP_GOOGLE_TAG_MANAGER_PREVIEW || '',
};

if (tagManagerArgs.gtmId !== '') {
  TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => {
      console.error('QueryCache Error:', error.message);
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

root.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <ThemeLocalization>
              <ReduxProvider store={store}>
                <AuthProvider>
                  <GoogleTagManager>
                    <SnackbarProvider>
                      <HelmetProvider>
                        <PersistGate loading={null} persistor={persistor}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <SettingsProvider>
                              <BrowserRouter>
                                <ScrollToTop />
                                <App />
                              </BrowserRouter>
                            </SettingsProvider>
                          </LocalizationProvider>
                        </PersistGate>
                      </HelmetProvider>
                    </SnackbarProvider>
                  </GoogleTagManager>
                </AuthProvider>
              </ReduxProvider>
            </ThemeLocalization>
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
    </QueryClientProvider>
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (process.env.NODE_ENV === 'development') {
}
