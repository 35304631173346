export enum AccessLevel {
  Read = 'read',
  Write = 'write',
  Owner = 'owner',
}

export const AccessLevelValues = {
  [AccessLevel.Read]: 100,
  [AccessLevel.Write]: 200,
  [AccessLevel.Owner]: 300,
};
