import { SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import ModalComponent from './Modal';

type GeneralModalComponentProps = {
  open: boolean;
  handleCloseModal: any;
  buttons?: React.ReactNode;
  headerText: string;
  header?: React.ReactNode;
  content: React.ReactNode;
  tabs?: React.ReactNode;
  disablePortal?: boolean;
  sx?: SxProps;
  dividers?: boolean;
  titleSx?: SxProps;
};

export const GeneralModalComponent = ({
  open,
  handleCloseModal,
  buttons,
  headerText,
  header,
  content,
  tabs,
  disablePortal,
  sx,
  dividers,
  titleSx,
}: GeneralModalComponentProps) => {
  const theme = useTheme();

  return (
    <ModalComponent
      open={open}
      handleClose={handleCloseModal}
      dividers={dividers}
      header={
        header ? (
          header
        ) : (
          <Typography variant="h6" color={theme.palette.grey[800]}>
            {headerText}
          </Typography>
        )
      }
      customHeader={!!header}
      buttons={buttons}
      tabs={tabs}
      disablePortal={disablePortal}
      sx={sx}
      titleSx={titleSx}
    >
      {content}
    </ModalComponent>
  );
};
