import { Popper, PopperProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

// possibly not all available props (10/23/2023 by Kirill)

const MenuPopper = ({
  open,
  anchorEl,
  children,
  disablePortal,
  placement = 'bottom',
  sx,
  ...other
}: PopperProps) => {
  const theme = useTheme();
  return (
    <Popper
      anchorEl={anchorEl}
      open={Boolean(open)}
      disablePortal={disablePortal}
      sx={{
        padding: 1,
        borderRadius: 1,
        color: theme.palette.grey[600],
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.customShadows.dropdown,
        '& .MuiMenuItem-root': {
          px: 1,
          typography: 'subtitle2',
          borderRadius: 0.75,
          '& svg': { mr: 2, width: 20, height: 20, flexShrink: 0 },
        },
        ...sx,
      }}
      {...other}
    >
      {children}
    </Popper>
  );
};

export default MenuPopper;
