import { TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { forwardRef, useCallback, useMemo } from 'react';

import { getSalesforceIcon } from '../SlashMenuUtils';
import { SalesforceInputComponentProps } from './SalesforceInputComponent';

const TextInputComponent = forwardRef(
  ({ node, disabled, handleChangeValue, initialValue }: SalesforceInputComponentProps, ref) => {
    const theme = useTheme();

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        handleChangeValue(e.target.value, node.attrs.salesforceFieldName);
      },
      [handleChangeValue, node.attrs.salesforceFieldName]
    );

    const inputProps = useMemo(
      () => ({
        startAdornment: getSalesforceIcon({ node, isDisableEditing: disabled }),
        sx: { minHeight: '24px' },
        inputRef: ref,
        type: 'text',
        disableUnderline: true,
      }),
      [node, disabled, ref]
    );

    const textFieldStyles = useMemo(
      () => ({
        marginTop: '8px',
        '.Mui-disabled svg path': {
          fill: theme.palette.grey[500],
        },
      }),
      [theme.palette.grey]
    );

    return (
      <TextField
        id={node.attrs.salesforceFieldName}
        fullWidth
        hiddenLabel
        value={initialValue}
        disabled={disabled}
        onChange={handleChange}
        multiline
        variant="standard"
        placeholder="No data"
        sx={textFieldStyles}
        InputProps={inputProps}
      />
    );
  }
);

export default TextInputComponent;
