import {
  addHours,
  addMinutes,
  differenceInCalendarMonths,
  format,
  formatDistanceStrict,
  formatDistanceToNow,
  getHours,
  getMinutes,
  getTime,
  getUnixTime,
  getYear,
  intlFormatDistance,
  isThisMonth,
  isThisQuarter,
  isThisYear,
  startOfDay,
} from 'date-fns';

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string): string {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string): string {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue): number | '' {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue): string {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function fDateTimeUS(date: InputValue): string {
  return date ? format(new Date(date), 'dd MMM yyyy h:mm a') : '';
}

export function fDateTimeOnly(date: InputValue): string {
  return date ? format(new Date(date), 'h:mm a') : '';
}

export function fDateDay(date: InputValue): string {
  return date ? format(new Date(date), 'dd') : '';
}

export function fDateDOWMonthDay(date: InputValue): string {
  return date ? format(new Date(date), 'EEEE, MMMM dd') : '';
}

export function fDateMonthAbr(date: InputValue): string {
  return date ? format(new Date(date), 'MMMM') : '';
}

export function fDateFull(date: InputValue): string {
  return date ? format(new Date(date), 'MMMM dd, yyyy') : '';
}

export function fDateFullShortMonth(date: InputValue): string {
  return date ? format(new Date(date), 'MMM dd, yyyy') : '';
}

export function fDateFullSlash(date: InputValue): string {
  return date ? format(new Date(date), 'MM/dd/yy') : '';
}

export function fDateShort(date: InputValue): string {
  return date ? format(new Date(date), 'MMM dd (E)') : '';
}

export function fDateMonthAndDay(date: InputValue): string {
  return date ? format(new Date(date), 'MMM dd ') : '';
}

//Sunday, May 14⋅7:30 – 9:30pm
export function fDateMeeting(date: InputValue): string {
  return date ? format(new Date(date), 'EEEE, MMMM dd ⋅ h:mm a', {}) : '';
}

export function fDistance(start: InputValue, end: InputValue): string {
  const start_date = start ? new Date(start) : 0;
  const start_time = start ? format(start_date, 'h:mm a') : '';

  const end_date = end ? new Date(end) : 0;
  const end_time = end ? format(end_date, 'h:mm a') : '';

  const distance = formatDistanceStrict(start_date, end_date, { addSuffix: false, unit: 'minute' });

  return start_time + ' - ' + end_time + ' (' + distance + ')';
}

export function fGetMinutes(startDate: Date, endDate: Date): number {
  const start = getUnixTime(new Date(startDate));
  const end = getUnixTime(new Date(endDate));
  return (end - start) / 60;
}

export function fThisYear(date: InputValue): string {
  if (!date) return 'notThisYear';
  return isThisYear(new Date(date)) ? 'thisYear' : 'notThisYear';
}

export function fThisMonth(date: InputValue): string {
  if (!date) return 'notThisMonth';
  return isThisMonth(new Date(date)) ? 'thisMonth' : 'notThisMonth';
}

export function fThisQuarter(date: InputValue): string {
  if (!date) return 'notThisQuarter';
  return isThisQuarter(new Date(date)) ? 'thisQuarter' : 'notThisQuarter';
}

export function fNextMonth(date: InputValue): string {
  if (!date) return 'notNextMonth';
  return differenceInCalendarMonths(new Date(date), new Date()) === 1
    ? 'nextMonth'
    : 'notNextMonth';
}

export function getLastUpdated(date: InputValue): string {
  return date ? format(new Date(date), 'MMM dd, yyyy h:mm a') : '';
}

export function getNextMeetingTimeWithDot(date: InputValue): string {
  return format(date ? new Date(date) : new Date(), "MMM d '•' h:mm a");
}

export function connectDateAndTime(date: Date, newDateTime: Date): Date {
  return addMinutes(addHours(startOfDay(date), getHours(newDateTime)), getMinutes(newDateTime));
}

export const formatDate = (date: any, value: any) => {
  const year = getYear(value);
  return new Date(`${year}:${date}`);
};

export function getNextMeetingTimeInWords(date: InputValue): string {
  return intlFormatDistance(date ? new Date(date) : new Date(), new Date(), {
    locale: 'en-US',
  })
    .split(' ')
    .map((item) => item[0].toUpperCase() + item.substring(1))
    .join(' ');
}
