import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import '../styles.scss';

export const MentionList = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item.name) {
      const [firstName, lastName] = item.name.split(' ');
      const name = `${firstName} ${lastName}`;
      props.command({ id: item?.id, label: name });
      return;
    }

    if (item.email) {
      const beforeAt = item.email.split('@')[0];
      props.command({ id: item?.id, label: beforeAt });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: any) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="items">
      {props.items.length ? (
        props.items.map((item: any, index: any) => (
          <button
            id={item?.id}
            className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
            key={item?.id ?? index}
            onMouseMoveCapture={() => setSelectedIndex(index)}
            onClick={() => selectItem(index)}
          >
            {item.name ?? item.email}
          </button>
        ))
      ) : (
        <div className="item">No results</div>
      )}
    </div>
  );
});
