import * as Sentry from '@sentry/react';
import { useContext } from 'react';

import { AuthContext } from './AuthContext';

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuthContext context must be use inside AuthProvider');

  Sentry.setContext('user', context.user);
  return context;
};
