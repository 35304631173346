import { Stack, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import NoteTemplates from '../../../../@types/api/noteTemplates';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/navIcons/ic_dots.svg';
import MenuPopover from '../../../../components/menu-popover';
import DeleteTemplate from './actions/DeleteTemplate';
import DuplicateTemplate from './actions/DuplicateTemplate';

type Props = {
  templateId: string;
  isActive: boolean;
  isEditor: boolean;
  template: NoteTemplates;
  hideButtons: () => void;
};

const TemplateActionsNavItem = ({
  templateId,
  isActive,
  isEditor,
  template,
  hideButtons,
}: Props) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    hideButtons();
    setAnchorEl(null);
  };

  const activeColor = isActive ? theme.palette.common.white : theme.palette.grey[500];
  return (
    <Stack direction="row" sx={{ marginLeft: 'auto' }}>
      <Typography
        style={{
          height: '24px',
          width: '24px',
          padding: '4px',
        }}
        sx={{
          '&:hover': { borderRadius: '8px', backgroundColor: theme.palette.grey[700] },
        }}
        onClick={handleClick}
      >
        <Tooltip title={'More Actions'} placement={'top'}>
          <DotsIcon style={{ color: activeColor }} />
        </Tooltip>
      </Typography>
      <MenuPopover
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          handleClose();
        }}
        disabledArrow
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 1.5,
          color: theme.palette.common.white,
          backgroundColor: theme.palette.grey[900],
          boxShadow: theme.customShadows.dropdownDark,
        }}
      >
        <DuplicateTemplate templateId={templateId} handleClose={handleClose} />
        {isEditor && (
          <DeleteTemplate templateId={templateId} handleClose={handleClose} template={template} />
        )}
      </MenuPopover>
    </Stack>
  );
};

export default TemplateActionsNavItem;
